// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InputContent {
  width: 100%;
  word-wrap: break-word;
  min-width: 75px !important;
}

.FileSelector {
  display: flex;
  padding: 6px 12px;
  cursor: pointer;
  margin-left: auto;
  width: 100%;
  padding: 0 0;
  padding-bottom: 5px;
}

.FileInputValue {
  width: fit-content;
}

.FileInputIcon {
  width: fit-content;
  margin-right: 5px;
  margin-left: auto;
}

.CustomTextArea {
  width: 100%;
  min-height: 100px;
  border: none;
  border-bottom: solid 1px #7f7f7f;
  line-height: normal;
}
`, "",{"version":3,"sources":["webpack://./src/components/scheduler/plan/formComponents.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,qBAAqB;EACrB,0BAA0B;AAC5B;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,eAAe;EACf,iBAAiB;EACjB,WAAW;EACX,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,YAAY;EACZ,gCAAgC;EAChC,mBAAmB;AACrB","sourcesContent":[".InputContent {\n  width: 100%;\n  word-wrap: break-word;\n  min-width: 75px !important;\n}\n\n.FileSelector {\n  display: flex;\n  padding: 6px 12px;\n  cursor: pointer;\n  margin-left: auto;\n  width: 100%;\n  padding: 0 0;\n  padding-bottom: 5px;\n}\n\n.FileInputValue {\n  width: fit-content;\n}\n\n.FileInputIcon {\n  width: fit-content;\n  margin-right: 5px;\n  margin-left: auto;\n}\n\n.CustomTextArea {\n  width: 100%;\n  min-height: 100px;\n  border: none;\n  border-bottom: solid 1px #7f7f7f;\n  line-height: normal;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
