import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "./supportSubmit.css";

import {
    Field,
    Form,
    FormElement,
    FormRenderProps
} from "@progress/kendo-react-form";
import {
    DatePicker
} from "@progress/kendo-react-dateinputs";

import * as supportTicketsAPI from "../../api/netRail/support";

import { useSelector } from "react-redux";
import { AppState } from "../../store/store";
import { useAppDispatch } from "../../store/store";

import { useTranslation } from "react-i18next";
import { FloatingLabel, Hint } from "@progress/kendo-react-labels";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Spinner } from "../../components/spinner";

import rest from "../../rest";
import { supportTicketMessageType as supportTicketProps } from "../../store/user/types";
import { setNotification } from "../../store/user/userSlice";

interface CustomFieldProps {
    id: string;
    name: string;
    label: string;
    type?: string;
    component?: React.ElementType;
    valueGetter?: (name: string) => any;
    hint?: string;
    backendFilled?: boolean;
    formRenderProps: FormRenderProps;
    [x: string]: any;
}

interface BackendFilledFields {
    OccuredAt?: boolean;
    StepsToReproduce?: boolean;
    Category?: boolean;
    OperatorID?: boolean;
    MachineID?: boolean;
    VersionSW?: boolean;
    MachineType?: boolean;
    LastCalibratedAt?: boolean;
    Lat?: boolean;
    Long?: boolean;
    SystemCreatedOn?: boolean;
    Temperature?: boolean;
}

interface formValues {
    OccurredAt: string | Date;
    StepsToReproduce: string;
    Category: string;
    OperatorID: string | undefined;
    MachineID: string | undefined;
    VersionSW: string | undefined;
    MachineType: string | undefined;
    LastCalibratedAt: Date | string;
    Lat?: string;
    Long?: string;
    SystemCreatedOn: string;
}

const comboBoxOptions = [
    "Hardware",
    "Software",
    "Other",
];

const statusOptions = [
    "Created",
    "Open",
    "Closed",
    "In Progress",
    "On Hold",
];

const CustomField = ({
    id,
    name,
    label,
    type,
    options,
    hint,
    backendFilled,
    formRenderProps,
    allowEdit = false,
}: CustomFieldProps) => {
    const fieldValue = formRenderProps.valueGetter(name);


    if (backendFilled) {
        return null;
    }


    let Component;
    switch (type) {
        case "date":
            Component = DatePicker;
            break;
        case "dropdownlist":
            Component = DropDownList;
            break;
        case "textarea":
            Component = TextArea;
            break;
        default:
            Component = Input;
    }

    return (
        <div>
            <FloatingLabel label={label} editorId={id} editorValue={String(fieldValue)}>
                <Field
                    id={id}
                    name={name}
                    component={Component}
                    data={options}
                    value={fieldValue}
                    hint={hint}
                    format={"yyyy-MM-dd"}
                />
            </FloatingLabel>
            {hint && <Hint id={`${id}-hint`}>{hint}</Hint>}
        </div>
    );
};

export const postSupportTicket = async (supportTicket: supportTicketProps) => {
    const supportTicketToPost = {
        occurredAt: supportTicket.occurredAt,
        stepsToReproduce: supportTicket.stepsToReproduce,
        category: supportTicket.category,
        machineID: supportTicket.machineID,
        operatorID: supportTicket.operatorID,
        versionSW: supportTicket.versionSW,
        machineType: supportTicket.machineType,
        lastCalibratedAt: supportTicket.lastCalibratedAt,
        systemCreatedOn: supportTicket.systemCreatedOn,
        temperature: Number(supportTicket.temperature),
        positionLat: supportTicket.positionLat,
        positionLong: supportTicket.positionLong,
        sensorStatus: supportTicket.sensorStatus,
        installationType: supportTicket.installationType,
        subject: supportTicket.subject,
        comments: supportTicket.comments,
    };
    try {
        const response = await rest.post(`/supportTickets`, supportTicketToPost, { withCredentials: true });
        return { success: true, data: response.data };
    } catch (error: any) {
        return { success: false, error: error.response.data };
    }
};

export const SupportSubmit = ({
    onClose,
    pipelines,
    errorMessages,
    measurement
}) => {
    const dataPackage = JSON.stringify({
        pipelines: pipelines,
        errorMessages: errorMessages,
        measurement: measurement.id
    });
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const user = useSelector((state: AppState) => state.user.currentUser);
    const operatorID = user ? user.id : undefined;
    const companyID = user ? user.companyID : undefined;

    const machineID = null;
    const versionSW = null;
    const sensorStatus = null;
    const installationType = null;

    const [error, setError] = useState<string | undefined>(undefined);
    const [backendFilledFields, setBackendFilledFields] = useState<any>({});
    const [formKey, setFormKey] = useState(0);
    const [GPSValues, setGPSValues] = useState({ lat: '', long: '' });
    const [formData, setFormData] = useState<formValues>({
        OccurredAt: new Date(),
        StepsToReproduce: dataPackage, // We hijack the stepsToReproduce field to send the data package
        Category: "", // We hijack the Category field to use as a comments field
        OperatorID: operatorID || "",
        MachineID: machineID || "",
        VersionSW: versionSW || "",
        MachineType: "",
        LastCalibratedAt: new Date(),
        Temperature: "",
        Status: "Open",
        Lat: GPSValues.lat || "",
        Long: GPSValues.long || "",
        SystemCreatedOn: "Dashboard", // and when the support system sees the Dashboard value, it will parse the data package
        Subject: "",
        Comments: "",
    });

    const formRef = React.useRef<HTMLDivElement>(null);

    interface formValues {
        [key: string]: any;
    }


    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (formRef.current && !formRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClose]);

    useEffect(() => {
        const filledFields: formValues = {};
        const formDataCopy = { ...formData };

        Object.entries(formDataCopy).forEach(([key, value]) => {
            const isFieldFilled = value !== "";
            filledFields[key] = isFieldFilled;
            if (key === 'OccurredAt') {
                filledFields[key] = value && value !== new Date().toISOString().substring(0, 10);
            }
        });

        setBackendFilledFields(filledFields);
    }, [operatorID, machineID, versionSW]); // This should list all of the items that could be auto-filled

    const handleStepsChange = (value: any, fieldName: any) => {
        setFormData(prevState => ({
            ...prevState,
            fieldName: value,
        }));
        setFormKey(prevKey => prevKey + 1);
    }

    // This seems unnecessarily complex, but it is to be identical to the function in the dashboard
    function createCommentString(date: Date, type: string, userId: string, payload: string): string {
        const formattedDate = date.toISOString().substring(0, 10);
        return `${formattedDate} - ${type} (${userId}): ${payload}`;
    }

    const handleSubmit = (data: any) => {
        // var sensorStatusstr: string = "";
        // var towrite;
        // Object.entries(sensorStatus).map(([key, value]) => {
        //     if (value === null) {
        //         towrite = "null";
        //     } else {
        //         towrite = value.toString();
        //     }
        //     sensorStatusstr = sensorStatusstr.concat(key, ": ", towrite, ", ")
        // });
        // if (!sensorStatusstr) {
        //     sensorStatusstr = "UNKNOWN";
        // }
        // if (!installationType) {
        //     installationType = "UNKNOWN";
        // }

        const ticket = {
            id: "",
            subject: data.Subject,
            occurredAt: data.OccurredAt,
            stepsToReproduce: data.StepsToReproduce,
            category: data.Category,
            machineID: data.MachineID,
            operatorID: data.OperatorID,
            versionSW: "0",
            machineType: "0",
            lastCalibratedAt: data.LastCalibratedAt,
            systemCreatedOn: data.SystemCreatedOn,
            temperature: data.Temperature,
            positionLat: "0",
            positionLong: "0",
            status: "Created",
            comments: [`${createCommentString(new Date, "event", user?.id || "", "ticketCreated")}`],
            sensorStatus: "0",
            installationType: "0"
        } as supportTicketProps;
        postSupportTicket(ticket)
            .then((response) => {
                if (!response.success) {
                    setError(response.error);
                    dispatch(
                        setNotification({
                            style: "error",
                            message: t("support.submit_fail"),
                            open: true,
                        })
                    );
                } else {
                    setError(undefined);
                    dispatch(
                        setNotification({
                            style: "success",
                            message: t("support.submit_success"),
                            open: true,
                        })
                    );
                }

            })
    };

    return ReactDOM.createPortal(
        <div className="support-overlay">
            <div className="support" ref={formRef}>
                <Form
                    key={formKey}
                    initialValues={formData}
                    onSubmit={handleSubmit}
                    render={(formRenderProps: FormRenderProps) => (
                        <FormElement className={"support-form"}>
                            <fieldset className={"k-form-fieldset"}>
                                <legend className={"k-form-legend"} style={{ fontSize: "26px" }}>
                                    {t("support.support")}
                                </legend>
                                <CustomField
                                    id={"subject"}
                                    name={"Subject"}
                                    label={t("support.subject")}
                                    component={"input"}
                                    formRenderProps={formRenderProps}
                                />
                                <CustomField
                                    id={"category"}
                                    name={"Category"}
                                    label={t("support.details")}
                                    type={"input"}
                                    formRenderProps={formRenderProps}
                                />
                            </fieldset>
                            <div className="k-form-buttons">
                                <span className="errorSupport">{error && "Error: " + JSON.stringify(error)}</span>
                                <button
                                    type={"submit"}
                                    style={{ justifyContent: "space-around" }}
                                    className="k-button LogOutButton"
                                    disabled={!formRenderProps.allowSubmit}
                                >
                                    {t("support.submit")}
                                </button>

                                <button
                                    type="button"
                                    className="k-button k-secondary"
                                    onClick={onClose}
                                >
                                    {t("common.cancel")}
                                </button>
                            </div>
                        </FormElement>
                    )}
                />
            </div>
        </div>,
        document.getElementById("portal-root")
    );
};