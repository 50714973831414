// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AdminMessageContainer {
  width: 100%;
  max-width: 600px;
}

.AnnouncementCardContainer {
  box-shadow: 0px 3px 6px 0 rgba(0, 0, 0, 0.26);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
  padding-bottom: 30px;
}

.SplitRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
`, "",{"version":3,"sources":["webpack://./src/components/adminMessage/adminMessage.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,6CAA6C;EAC7C,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,aAAa;EACb,oBAAoB;AACtB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC","sourcesContent":[".AdminMessageContainer {\n  width: 100%;\n  max-width: 600px;\n}\n\n.AnnouncementCardContainer {\n  box-shadow: 0px 3px 6px 0 rgba(0, 0, 0, 0.26);\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  padding: 15px;\n  padding-bottom: 30px;\n}\n\n.SplitRow {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
