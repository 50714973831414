import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../store/store";
import {
  cPhoneHeightLim,
  cPhoneWidthLim,
  cPhoneWidthLimStandard,
} from "../../../app";
import { AppState } from "../../../store/store";
import {
  setObjectsToDisplay,
  setTolerancesToDisplay,
} from "../../../store/plot/plotSlice";
import "./toggleObjects.css";
import {
  objectTypes,
  translateToSelectedLanguage,
} from "../../../store/plot/types";
import { objectColors } from "../../visualization/gpsChartContainer";

interface ToggleObjectsProps {
  selectedMeasurement: string;
}

export const ToggleObjects: React.FunctionComponent<ToggleObjectsProps> = ({
  selectedMeasurement,
}) => {
  const initialObjects = [
    objectTypes.switch,
    objectTypes.levelCrossing,
    objectTypes.marker,
    objectTypes.notes,
    objectTypes.trackBarrier,
    objectTypes.trackCross,
    objectTypes.culvert,
    objectTypes.contactPole,
  ];

  const [objectReferences, setObjectReferences] =
    useState<string[]>(initialObjects);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const width = useSelector((state: AppState) => state.scheduler.screenWidth);
  const height = useSelector((state: AppState) => state.scheduler.screenHeight);

  useEffect(() => {
    setObjectReferences(initialObjects);
    dispatch(setObjectsToDisplay(initialObjects));
  }, [selectedMeasurement]);

  return (
    <div
      className="ObjectButtonsContainer"
      style={{
        paddingLeft: width >= cPhoneWidthLim ? "40px" : "10px",
        paddingRight: width >= cPhoneWidthLim ? "40px" : "10px",
        paddingTop: height >= 450 ? "0px" : "60px",
      }}
    >
      {initialObjects.map((obj: string) => {
        // const typedObjectsArr = Object.keys(objectReferences);
        const typedObject = obj as objectTypes;
        return (
          <button
            key={typedObject}
            className={"ObjectSelectorButton"}
            style={{
              opacity: objectReferences.includes(typedObject) ? 1 : 0.5,
            }}
            onClick={async () => {
              if (objectReferences.includes(obj)) {
                // Filter it!
                setObjectReferences(objectReferences.filter((o) => o !== obj));
                dispatch(
                  setObjectsToDisplay(
                    objectReferences.filter((o) => o !== obj)
                  )
                );
              } else {
                // Append it!
                setObjectReferences([...objectReferences, obj]);

                dispatch(setObjectsToDisplay([...objectReferences, obj]));
              }
            }}
          >
            <div
              className="ToleranceSelectorLine"
              style={{
                backgroundColor: objectColors(typedObject),
              }}
            ></div>
            <div
              style={{
                fontSize:
                  width < cPhoneWidthLimStandard || height < cPhoneHeightLim
                    ? "10px"
                    : "14px",
                fontWeight: "bold",
                marginLeft: "5px",
                color: "black",
              }}
            >
              {translateToSelectedLanguage(t, typedObject)}
            </div>
          </button>
        );
      })}
    </div>
  );
};
