import React, { useEffect, useState } from "react";
import DescriptiveIcon from "./DescriptiveIcon";
import "./planListItem.css";

import {
  getNumberOfMeasurements,
  // getPlanStatus,
} from "../../../store/scheduler/helpers";
/* Types */
import { planProps, planStatus } from "../../../store/scheduler/types";

/* Redux Stuff*/
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../store/store";
import { useNavigate } from "react-router-dom";
import { setLastVisitedPlanAndMeasurement } from "../../../helpers/genericHelpers";
import { AppState } from "../../../store/store";
import { addNewPlan, setSelectedPlan } from "../../../store/scheduler/schedulerSlice";
import { useTranslation } from "react-i18next";

interface PlanListItemProps {
  plan: planProps;
  onlySelectPlan?: boolean;
}

interface AddNewPlanParams {
  prefilledPlan?: planProps;
}

const PlanListItem: React.FC<PlanListItemProps> = ({
  plan,
  onlySelectPlan,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const prepareNewPlanPayload = (plan: planProps): AddNewPlanParams => {
    return {
      prefilledPlan: {
        ...plan,
        measurements: []
      }
    };
  };

  const copyPlan = () => {
    const payload = prepareNewPlanPayload(plan);
    dispatch(addNewPlan(payload));
  };

  const dispatch = useAppDispatch();
  const selectedPlan = useSelector(
    (state: AppState) => state.scheduler.selectedPlan
  );

  const trackPart = () => {
    return plan.trackPart ? plan.trackPart : "-";
  };

  const renderPlanInfo = () => {
    return (
      <div className="PlanInfoContainer">
        <div className="PlanItemWrapper">
          <div className="PlanItemTrack">
            <DescriptiveIcon
              data={
                // trackNumber() + "/" +
                trackPart()
              }
              icon={"./icons/rail.svg"}
            />
          </div>
          <div className="PlanItemNumberMeasurements">
            <DescriptiveIcon
              data={getNumberOfMeasurements(plan, plan.numberMeasurements)}
              icon={""}
            />
          </div>
          <div className="PlanItemStatus">
            <DescriptiveIcon
              data={t(
                "common.planStatus." +
                (plan.status.toString() === "in-progress"
                  ? planStatus.inProgress.toString()
                  : plan.status.toString())
              ).toString()}
              icon={"./icons/status.svg"}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderPlanName = () => {
    return plan.name !== "" ? plan.name : "";
  };

  const renderFromToText = () => {
    return plan.pathTo && plan.pathFrom ? (
      <div
        style={{
          fontSize: "12px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          alignContent: "flex-start",
          flexWrap: "wrap",
        }}
      >
        {plan.pathFrom + " "}{" "}
        <img
          className="fromToArrowSvg"
          src="./icons/fromToArrow.svg"
          alt="from to arrow"
        />{" "}
        {" " + plan.pathTo}
      </div>
    ) : (
      <div
        style={{
          fontSize: "12px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflowWrap: "anywhere",
        }}
      >
        {plan.place}
      </div>
    );
  };

  return (
    <button
      className={"PlanListButton"}
      style={{
        border:
          selectedPlan === plan.id ? "solid var(--primary-color,#782441)" : "",
      }}
      onClick={(e) => {
        dispatch(setSelectedPlan(plan.id));
        setLastVisitedPlanAndMeasurement(plan.id, undefined);
        if (onlySelectPlan === undefined || !onlySelectPlan) {
          navigate("/scheduler/plan");
        }
      }}
    >
      <div className="PlanListItem">
        <div className="PlanItemPlace subtitle">
          {renderPlanName()}
          {renderFromToText()}
        </div>
        <div className="PlanItemInfoContainer">
          <div className="PlanItemInfo">
            <div className="PlanItemDateCreated caption">
              {new Date(plan.createdAt).getFullYear() +
                "-" +
                (new Date(plan.createdAt).getMonth() + 1 < 10
                  ? "0" + (new Date(plan.createdAt).getMonth() + 1)
                  : new Date(plan.createdAt).getMonth() + 1) +
                "-" +
                (new Date(plan.createdAt).getDate() < 10
                  ? "0" + new Date(plan.createdAt).getDate()
                  : new Date(plan.createdAt).getDate())}
            </div>
            <div className="PlanItemDoneAt caption">
              {new Date(plan.scheduledAt).getFullYear() +
                "-" +
                (new Date(plan.scheduledAt).getMonth() + 1 < 10
                  ? "0" + (new Date(plan.scheduledAt).getMonth() + 1)
                  : new Date(plan.scheduledAt).getMonth() + 1) +
                "-" +
                (new Date(plan.scheduledAt).getDate() < 10
                  ? "0" + new Date(plan.scheduledAt).getDate()
                  : new Date(plan.scheduledAt).getDate())}
            </div>
          </div>
          {onlySelectPlan === undefined || !onlySelectPlan ? (
            <span
              className="k-icon k-font-icon k-i-copy CopyButton"
              style={{ fontSize: "24px", borderRadius: "3px", padding: "15px" }}
              onClick={(e) => {
                e.stopPropagation();
                copyPlan();
              }}
            />
          ) : null}
        </div>
        {renderPlanInfo()}
      </div>
    </button>
  );
};

export default PlanListItem;
