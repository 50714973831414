// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SupportContainer {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.supportTabs {
  width: 100%;
  max-width: 700px;
}

.supportTabs > div > ul:last-child {
  border-top-right-radius: 2px;
}

.statusContainer {
  z-index: 10;
}

.announcement-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: fit-content;
  padding-left: 10px;
  padding-top: 10px;
  padding-right: 10px;
  background-color: white;
  border-radius: 12px;
  border: solid var(--primary-color);
  overflow: hidden;
  z-index: 0;
}

.announcementCard-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: fit-content;
  padding-left: 10px;
  padding-top: 10px;
  padding-right: 10px;
  background-color: rgb(250, 250, 250);
  border-radius: 12px;
  position: relative;
  margin: 20px;
  border: solid 3px var(--primary-color);
}

.announcementContainerShadow {
  box-shadow: inset 0 -15px 15px -15px var(--primary-color);
  -webkit-box-shadow: inset 0 -15px 15px -15px var(--primary-color);
  -moz-box-shadow: inset 0 -15px 15px -15px var(--primary-color);
}

.announcement-container-wrapper {
  background-color: var(--background-color);
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 5px;
}

.announcementButton {
  width: 100%;
  margin: 5px;
}

.announcementButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
}

.announcementTextContainer {
  display: flex;
  flex-direction: column;
  height: 200px;
  width: 100%;
  overflow-y: scroll;
  overflow-wrap: break-word;
  color: var(--primary-color);
  padding-right: 1%;
}

.announcementCardTextContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 10px 0px 10px;
}

.urgentMessage {
  display: flex;
  align-items: center;
  font-weight: normal;
  flex-direction: row;
  align-items: center;
  font-size: 20px;
  color: red;
}

.messageBody {
  font-weight: normal;
  font-size: 16px;
  white-space: pre-line;
}

.messageBodyCard {
  font-weight: normal;
  font-size: 16px;
  white-space: pre-line;
  background-color: var(--background-color);
  overflow: auto;
  border-left: solid 2px rgba(168, 168, 168, 0.4);
  border-bottom: solid 2px rgba(168, 168, 168, 0.4);
  padding-left: 3px;
}

.messageBodyCard::-webkit-scrollbar {
  display: none;
}

.announcementTextContainer::-webkit-scrollbar {
  display: none;
}

.announcementCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.scrollBarContainer {
  overflow-x: hidden !important;
}

/*# sourceMappingURL=style.css.map */
`, "",{"version":3,"sources":["webpack://./src/pages/support/style.scss","webpack://./src/pages/support/style.css"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,cAAA;ACCF;;ADEA;EACE,WAAA;EACA,gBAAA;ACCF;;ADEA;EACE,4BAAA;ACCF;;ADEA;EACE,WAAA;ACCF;;ADEA;EACE,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,mBAAA;EACA,kBAAA;EACA,iBAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,kCAAA;EACA,gBAAA;EACA,UAAA;ACCF;;ADEA;EACE,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,mBAAA;EACA,kBAAA;EACA,iBAAA;EACA,mBAAA;EACA,oCAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;EACA,sCAAA;ACCF;;ADMA;EACE,yDAAA;EACA,iEAAA;EACA,8DAAA;ACHF;;ADMA;EACE,yCAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EAGA,gBAAA;EACA,kBAAA;ACLF;;ADQA;EACE,WAAA;EACA,WAAA;ACLF;;ADQA;EACE,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,mBAAA;EACA,gBAAA;ACLF;;ADQA;EACE,aAAA;EACA,sBAAA;EAEA,aAAA;EACA,WAAA;EACA,kBAAA;EACA,yBAAA;EACA,2BAAA;EACA,iBAAA;ACNF;;ADSA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;EACA,2BAAA;ACNF;;ADSA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;EACA,UAAA;ACNF;;ADSA;EACE,mBAAA;EACA,eAAA;EACA,qBAAA;ACNF;;ADSA;EACE,mBAAA;EACA,eAAA;EACA,qBAAA;EACA,yCAAA;EACA,cAAA;EACA,+CAAA;EACA,iDAAA;EACA,iBAAA;ACNF;;ADSA;EACE,aAAA;ACNF;;ADSA;EACE,aAAA;ACNF;;ADSA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;ACNF;;ADSA;EACE,6BAAA;ACNF;;AAEA,oCAAoC","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
