import { Slide } from "@progress/kendo-react-animation";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/store";
import { GpsVisualization } from "../../components/visualization/gpsVisualizationContainer";
import { setInformationRequest } from "../../store/user/userSlice";
import { MeasurementSelectionBarRight } from "./../../components/dashboard/sideBar";
import { Toolbar } from "./../../components/toolbar";
import "./style.scss";

import { cPhoneWidthLim } from "../../app";
import { AdminAnnouncement } from "../../components/announcement/announcement";
import { FilteringContent } from "../../components/dashboard/filteringContent";
import { ShareLinkPopup } from "../../components/scheduler/plan/measurementList/externalLinkShare";
import { ChartList } from "../../components/visualization/chartList";
import { CurrentLongitudinalLevelVisualization } from "../../components/visualization/currentLongitudinalLevelVisualization";
import { AppState } from "../../store/store";
import { measurementProps, planProps } from "../../store/scheduler/types";
import { helpInformationPath } from "../../store/user/types";
import { MetaData } from "./../../components/dashboard/metaData";
import { useTranslation } from "react-i18next";
import { ToggleObjects } from "../../components/generic/toggleObjects/toggleObjects";
import { ChartTabs } from "../../components/dashboard/chartTabs";
import SvgAnalysisGraph from "../../components/generic/componentIcons/AnalysisGraph";
import TrackGeometryIcon from "../../components/generic/componentIcons/TrackGeomertryIcon";

export interface tabProps {
  component: any;
  title: string;
  icon?: any;
  iconToTheLeft?: boolean;
}

export const Dashboard: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [show, setShow] = useState(false);
  const [showFilteringContent, setShowFilteringContent] = useState(false);

  const selectedMeasurement = useSelector(
    (state: AppState) => state.scheduler.selectedMeasurement
  );

  const measurement = useSelector((state: AppState) => state.scheduler.plans)
    .map((plan: planProps) => plan.measurements)
    .flat()
    .find(
      (measurement: measurementProps) => measurement.id === selectedMeasurement
    );

  const width = useSelector((state: AppState) => state.scheduler.screenWidth);
  const height = useSelector((state: AppState) => state.scheduler.screenHeight);

  const mainView = useRef(true);

  const filteringContent = showFilteringContent ? (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "white",
      }}
    >
      <FilteringContent
        height={height}
        width={width}
        setShow={setShowFilteringContent}
      />
    </div>
  ) : null;

  const filteringContentAnimation = (
    <Slide direction={"down"} style={{ position: "absolute", height: "100vh" }}>
      {filteringContent}
    </Slide>
  );

  const informationRequest = useSelector(
    (state: AppState) => state.user.informationRequest
  );

  useEffect(() => {
    return () => {
      dispatch(
        setInformationRequest({
          path: undefined,
          showInformation: false,
        })
      );
    };
  }, []);

  const tabs: tabProps[] = [
    {
      component: (
        <div>
          <ChartList key={t("dashboard.trackGeometry")} mainView={mainView} />
        </div>
      ),
      title: t("dashboard.trackGeometry"),
      icon: <TrackGeometryIcon />,
      iconToTheLeft: false,
    },
    {
      component: (
        <div>
          <CurrentLongitudinalLevelVisualization mainView={mainView} />
        </div>
      ),
      title: t("dashboard.railLoweringAnalysis"),
      icon: (
        <div style={{ height: "24px", width: "24px", marginRight: "5px" }}>
          <SvgAnalysisGraph />
        </div>
      ),
      iconToTheLeft: true,
    },
  ];

  return (
    <div
      className="DashboardContainer"
      style={
        width <= cPhoneWidthLim || height <= cPhoneWidthLim
          ? {
              position: "fixed",
              height: document.documentElement.clientHeight,
            }
          : {
              position: "inherit",
              height: document.documentElement.clientHeight,
              left: "44px",
              paddingRight: "44px",
            }
      }
    >
      <header className="DashboardToolbar">
        <Toolbar>
          <h5>
            {t("common.measurements")}
            <span
              style={{ marginLeft: "10px", fontSize: "18px" }}
              className="k-icon k-font-icon k-i-info informationButton"
              onClick={() => {
                dispatch(
                  setInformationRequest({
                    path: helpInformationPath.DashboardInformation,
                    showInformation:
                      informationRequest?.path ===
                      helpInformationPath.DashboardInformation
                        ? !informationRequest?.showInformation
                        : true,
                  })
                );
              }}
            />
          </h5>
          {width <= cPhoneWidthLim || height <= cPhoneWidthLim ? (
            <div className="MeasurementToolbarButtonContainer">
              {measurement !== undefined ? (
                <span
                  className="k-icon k-font-icon k-i-share ToolbarShareButton"
                  style={{
                    fontSize: "22px",
                    backgroundSize: "30px 30px",
                    marginRight: "10px",
                  }}
                  onClick={() => setShow(!show)}
                />
              ) : null}
              <img
                src={"./icons/filter_list_black_24dp.svg"}
                alt={"list"}
                onClick={(e) => {
                  setShowFilteringContent(!showFilteringContent);
                }}
              />
            </div>
          ) : (
            <>
              {measurement !== undefined ? (
                <span
                  className="k-icon k-font-icon k-i-share ToolbarShareButton"
                  style={{
                    marginRight: "50px",
                    fontSize: "22px",
                    backgroundSize: "30px 30px",
                  }}
                  onClick={() => setShow(!show)}
                />
              ) : null}
            </>
          )}
        </Toolbar>
      </header>

      <div className="DashboardContentContainer">
        <div>
          {width <= cPhoneWidthLim || height <= cPhoneWidthLim ? null : (
            <MeasurementSelectionBarRight />
          )}
          <div style={{ paddingTop: "50px", marginBottom: "-50px" }}>
            {/*Easy solution to make sure that the announcements are properly centered*/}
            <AdminAnnouncement />
          </div>
          {width <= cPhoneWidthLim || height <= cPhoneWidthLim
            ? filteringContentAnimation
            : null}
          {selectedMeasurement !== "-1" ? (
            <div
              className="DashboardMain"
              style={
                width <= cPhoneWidthLim || height <= cPhoneWidthLim
                  ? {}
                  : { left: "16.3333333334px" }
              }
            >
              {height >= 450 ? <MetaData /> : null}
              <div id="SignalsLL" />

              <ToggleObjects selectedMeasurement={selectedMeasurement} />
              <GpsVisualization selectedMeasurement={selectedMeasurement} />
              <ChartTabs tabs={tabs} />
            </div>
          ) : (
            <div
              className="DashboardMain"
              style={
                width <= cPhoneWidthLim || height <= cPhoneWidthLim
                  ? {}
                  : { left: "16.3333333334px" }
              }
            >
              <GpsVisualization selectedMeasurement={selectedMeasurement} />
              <div
                style={{
                  paddingTop: "90px",
                  paddingBottom: "200px",
                  textAlign: "center",
                }}
              >
                {t("dashboard.selectAMeasurementToDisplayItHere")}
              </div>
            </div>
          )}
        </div>
      </div>
      <ShareLinkPopup
        measurement={measurement as measurementProps}
        show={show}
        setShow={setShow}
      />
    </div>
  );
};
