import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "typeface-roboto";
import "source-code-pro/source-code-pro.css";
import "./index.scss";
import App from "./app";
import store from "./store/store";
import * as moment from "moment-timezone";

import "./i18n";
import i18n from "./i18n";
import { Spinner } from "./components/spinner";

// Set initial guessed language
// The guessed language is based on browser settings.
i18n.changeLanguage(i18n.language);

// Guess the current user timezone.
moment.tz.guess(true);

ReactDOM.render(
  <Provider store={store}>
    <React.Suspense fallback={<Spinner />}>
      <App />
    </React.Suspense>
  </Provider>,
  document.getElementById("root")
);
