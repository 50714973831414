import { Amplify } from "aws-amplify";

export const configureAmplify = () => {
  if (!process.env.REACT_APP_AMPLIFY_USER_POOL_ID) {
    process.env.REACT_APP_AMPLIFY_USER_POOL_ID = ''
  }

  if (!process.env.REACT_APP_AMPLIFY_USER_POOL_WEB_CLIENT_ID) {
    process.env.REACT_APP_AMPLIFY_USER_POOL_WEB_CLIENT_ID = ''
  }

  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: process.env.REACT_APP_AMPLIFY_USER_POOL_ID,
        userPoolClientId: process.env.REACT_APP_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
      }
    },
  });
}