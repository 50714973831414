// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ToleranceSelection {
  max-width: 1900px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 5px;
  margin-bottom: 5px;
}

.ToleranceSelectorButton {
  width: fit-content;
  max-height: 40px;
  min-height: 25px;
  padding: 2px 5px;
  border: solid 2px var(--primary-color);
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.ToleranceSelectorButtonDisabled {
  width: fit-content;
  max-height: 40px;
  min-height: 25px;
  padding: 2px 5px;
  border: solid 2px var(--primary-color);
  border-radius: 10px;
  display: flex;
  align-items: center;
  pointer-events: none;
}

.ToleranceSelectorLine {
  width: 20px;
  height: 5px;
  border-radius: 2px;
}
`, "",{"version":3,"sources":["webpack://./src/components/generic/toggleTolerances/toggleTolerances.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,sCAAsC;EACtC,mBAAmB;EACnB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,sCAAsC;EACtC,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,WAAW;EACX,WAAW;EACX,kBAAkB;AACpB","sourcesContent":[".ToleranceSelection {\n  max-width: 1900px;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  margin-bottom: 5px;\n  margin-bottom: 5px;\n}\n\n.ToleranceSelectorButton {\n  width: fit-content;\n  max-height: 40px;\n  min-height: 25px;\n  padding: 2px 5px;\n  border: solid 2px var(--primary-color);\n  border-radius: 10px;\n  display: flex;\n  align-items: center;\n}\n\n.ToleranceSelectorButtonDisabled {\n  width: fit-content;\n  max-height: 40px;\n  min-height: 25px;\n  padding: 2px 5px;\n  border: solid 2px var(--primary-color);\n  border-radius: 10px;\n  display: flex;\n  align-items: center;\n  pointer-events: none;\n}\n\n.ToleranceSelectorLine {\n  width: 20px;\n  height: 5px;\n  border-radius: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
