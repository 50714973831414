import rest from "./../../rest";
import {
  measurementProps,
  measurementStatus,
  requestMetaData,
  planSorterTypes,
  signalMetaData,
} from "../../store/scheduler/types";
import { TrafikverketErrorMsg } from "../../components/scheduler/plan/measurementList/statusProgress";
import { TFunction } from "i18next";
import { text } from "d3";

// CHEAT SHEET, REQUESTS
// opAnd            = "$and"
// opOr             = "$or"
// opExists         = "$exists"
// opIn             = "$in"
// opNotIn          = "$nin"
// opNotEqual       = "$ne"
// opLowerThan      = "$lt"
// opLowerOrEqual   = "$lte"
// opGreaterThan    = "$gt"
// opGreaterOrEqual = "$gte"
// opRegex          = "$regex"
// opElemMatch      = "$elemMatch"

const getFilteringDateInterval = (
  filterDateStart: Date | undefined,
  filterDateEnd: Date | undefined
) => {
  if (filterDateEnd && filterDateStart) {
    return {
      $and: [
        { doneAt: { $gte: filterDateStart } },
        { doneAt: { $lte: filterDateEnd } },
      ],
    };
  } else if (filterDateEnd) {
    return { doneAt: { $lte: filterDateEnd } };
  } else if (filterDateStart) {
    return { doneAt: { $gte: filterDateStart } };
  } else {
    return undefined;
  }
};

const getFilteringStatus = (
  t: TFunction<"translation", "translation">,
  filterStatus: string
) => {
  if (filterStatus === t("common.planStatus.done")) {
    // Concat "done statuses"
    return {
      $or: [
        { status: measurementStatus.SendingEmailDone },
        { status: measurementStatus.SendingEmail },
      ],
    };
  } else if (filterStatus === t("common.planStatus.inProgress")) {
    // Concat "On going statuses"
    return {
      $and: [
        {
          $or: [
            { status: measurementStatus.Uploading },
            { status: measurementStatus.UploadingDone },
            { status: measurementStatus.Processing },
            { status: measurementStatus.ProcessingDone },
            { status: measurementStatus.AdjustingDistance },
            { status: measurementStatus.AdjustingDistanceDone },
            { status: measurementStatus.WritingToDynamodb },
            { status: measurementStatus.WritingToDynamodbDone },
            { status: measurementStatus.Merging },
            { status: measurementStatus.MergingDone },
            { status: measurementStatus.CreatingExcel },
            { status: measurementStatus.CreatingExcelDone },
            { status: measurementStatus.CreatingCSV },
            { status: measurementStatus.CreatingCSVDone },
          ],
        },
        {
          $or: [{ errorMessage: "" }, { errorMessage: TrafikverketErrorMsg }],
        },
      ],
    };
  } else if (filterStatus === t("common.planStatus.faulty")) {
    // Concat "Error statuses"
    return {
      $and: [
        {
          $or: [
            { status: measurementStatus.Uploading },
            { status: measurementStatus.UploadingDone },
            { status: measurementStatus.Processing },
            { status: measurementStatus.ProcessingDone },
            { status: measurementStatus.AdjustingDistance },
            { status: measurementStatus.AdjustingDistanceDone },
            { status: measurementStatus.WritingToDynamodb },
            { status: measurementStatus.WritingToDynamodbDone },
            { status: measurementStatus.Merging },
            { status: measurementStatus.MergingDone },
            { status: measurementStatus.CreatingExcel },
            { status: measurementStatus.CreatingExcelDone },
            { status: measurementStatus.CreatingCSV },
            { status: measurementStatus.CreatingCSVDone },
          ],
        },
        { errorMessage: { $ne: "" } },
        { errorMessage: { $ne: TrafikverketErrorMsg } },
      ],
    };
  } else {
    return undefined;
  }
};

export const getMeasurements = (
  t: TFunction<"translation", "translation">,
  assigneeID?: string,
  machineID?: string,
  companyID?: string,
  textFilterValue?: string, 
  filterDateStart?: Date,
  filterDateEnd?: Date,
  limit?: number,
  page?: number,
  offset?: number
) => {
  return new Promise<[measurementProps[], requestMetaData]>(
    async (resolve, reject) => {
      const isNumber = new RegExp("^[0-9]*$");
      let filterQuery: any = {};

      let andQuery: any = [];

      if (assigneeID !== undefined)
        andQuery = andQuery.concat({ "measurements.creatorID": assigneeID });

      if (machineID !== undefined)
        andQuery = andQuery.concat({ "measurements.machineID": machineID });

      if (companyID !== undefined)
        andQuery = andQuery.concat({ "users.companyID": companyID });

      if (filterDateEnd !== undefined && filterDateStart !== undefined) {
        andQuery = andQuery.concat({
          $and: [
            { "measurements.doneAt": { $gte: filterDateStart } },
            { "measurements.doneAt": { $lte: filterDateEnd } },
          ],
        });
      } else if (filterDateEnd !== undefined) {
        andQuery = andQuery.concat({ "measurements.doneAt": { $lte: filterDateEnd } });
      } else if (filterDateStart !== undefined) {
        andQuery = andQuery.concat({ "measurements.doneAt": { $gte: filterDateStart } });
      }

      if (textFilterValue !== undefined)
        andQuery = andQuery.concat({
          $or: [
            { "plans.name": { $regex: `(?i)(${textFilterValue})` } }, // Case sensitivity off: (?i)
            { "plans.place": { $regex: `(?i)(${textFilterValue})` } },
            { "plans.customer": { $regex: `(?i)(${textFilterValue})` } },
            {
              // REGEX WON'T WORK ON NUMBERS
              trackPart:
                textFilterValue && isNumber.test(textFilterValue)
                  ? +textFilterValue
                  : undefined,
            },
          ],
        });
  

      if (textFilterValue !== undefined && textFilterValue !== null)
        andQuery.push({
          $or: [
            { "plans.name": { $regex: `(?i)(${textFilterValue})` } }, // Case sensitivity off: (?i)
            { "plans.place": { $regex: `(?i)(${textFilterValue})` } },
            { "plans.customer": { $regex: `(?i)(${textFilterValue})` } },
            {
              // REGEX WON'T WORK ON NUMBERS
              trackPart:
                textFilterValue && isNumber.test(textFilterValue)
                  ? +textFilterValue
                  : undefined,
            },
          ],
        });

      if (andQuery.length > 0) {
        filterQuery = { $and: andQuery };
      }

      rest
        .get("/generic/measurements", {
          params: {
            filter: JSON.stringify(filterQuery),
            limit,
            page,
            offset,
          },
        })
        .then(async (response) => {
          try {
            const serverMeasurements = response.data.list;
            const measurements: measurementProps[] = serverMeasurements.map(
              (serverMeasurement: measurementProps) => {
                return serverMeasurement;
              }
            );

            const metaData: requestMetaData = response.data.meta;
            resolve([measurements, metaData]);
          } catch (reason) {
            const err = `Failed to fetch machines from server: ${reason}`;
            console.error(err);
            reject(err);
          }
        })
        .catch((error) => {
          const err = `Failed to fetch machines from server: ${error}`;
          console.error(err);
          reject(err);
        });
    }
  );
};

export const getMeasurement = (measurementID: string) => {
  return new Promise<measurementProps>(async (resolve, reject) => {
    rest
      .get("/measurements/" + measurementID, { withCredentials: true })
      .then(async (response) => {
        try {
          const serverMeasurement = response.data;
          resolve(serverMeasurement);
        } catch (reason) {
          const err = `Failed to fetch measurement from server: ${reason}`;
          console.error(err);
          reject(err);
        }
      })
      .catch((error) => {
        const err = `Failed to fetch measurement from server: ${error}`;
        console.error(err);
        reject(err);
      });
  });
};

export const postMeasurement = (
  measurementToPost: measurementProps,
  nbrError: number,
  success: boolean,
  dispatch: any
) => {
  return new Promise<measurementProps>(async (resolve, reject) => {
    rest
      .post("/measurements", measurementToPost, { withCredentials: true })
      .then(async (fulfill) => {
        success = true;
        const measurementID = fulfill.data.id;

        const reduxMeasurementToAdd: measurementProps = {
          ...measurementToPost,
          id: measurementID,
        };

        resolve(reduxMeasurementToAdd);
      })
      .catch((reason) => {
        // If error got a response and the response data is duplicate, we should try to patch.
        if (
          reason.response &&
          reason.response.data.includes(
            "pq: duplicate key value violates unique constraint"
          )
        ) {
          rest
            .patch(
              `/measurements/${measurementToPost.id}`,
              { ...measurementToPost },
              { withCredentials: true }
            )
            .then((result: any) => {
              resolve(result);
            })
            .catch((reason) => {
              if (nbrError++ < 1) {
                alert(
                  "Misslyckades att ladda upp mätningen. Kontrollera att du är ansluten till internet och följ statusen under diagnostik."
                );
              }
              console.error("fail to upload measurement: ", reason);
              reject(reason);
            });
        } else {
          if (nbrError++ < 1) {
            alert(
              "Misslyckades att ladda upp mätningen. Kontrollera att du är ansluten till internet och följ statusen under diagnostik."
            );
          }
          console.error("fail to upload measurement: ", reason);
        }
        reject(reason);
      });
  });
};


export const getMeasurementMetaData = (
  signalType: string,
  measurementID: string
) => {
  if (measurementID === "-1") {
    console.error("measurementID is -1, cannot fetch metadata");
    return new Promise<signalMetaData>((resolve, reject) => {
      reject("measurementID is -1, cannot fetch metadata");
    });
  }
  return new Promise<signalMetaData>(async (resolve, reject) => {
    rest
      .get(
        `/processed-measurements/${measurementID}/metadata?signalType=${signalType}`
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.error("error ", error);
        reject(error);
      });
  });
};

export const reprocessMeasurement = (measurement: measurementProps) => {
  return new Promise(async (resolve, reject) => {
    const {
      id,
      createdAt,
      creatorID,
      files,
      pipelines,
      updatedAt,
      status,
      ...measurementToPatch
    } = measurement;
    rest
      .post("/measurements/" + measurement.id + "/reprocess", measurementToPatch, {
          withCredentials: true,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const patchMeasurement = (measurement: measurementProps) => {
  return new Promise(async (resolve, reject) => {
    const {
      id,
      createdAt,
      creatorID,
      files,
      pipelines,
      updatedAt,
      status,
      ...measurementToPatch
    } = measurement;
    rest
      .patch("/measurements/" + measurement.id, measurementToPatch, {
        withCredentials: true,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("error patch measurement", error);
        reject(error);
      });
  });
};
