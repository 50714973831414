import { objectTypes } from "../../../store/plot/types";

export const transitionDuration = 50;
export const smallStrokeWidth = 2;
export const bottomAxisHeight = 40;
export const numTicks = 10;
export const smallestSampleSize = 0.5;
export const numFrames = 3; // The number of viewport frames the chart has. E.g. How many frames will span outside of the view window.
export const leftAxisWidth = 30;
export const linePadding = 2;

export const resolutions = {
    x05: 0.5,
    x1: 1,
    x2: 2,
    x5: 5,
    x10: 10,
    x15: 15,
    x20: 20,
    x30: 30,
    x40: 40,
    x50: 50,
    x60: 60,
    x70: 70,
    x80: 80,
    x90: 90,
    x100: 100,
    x110: 110,
    x120: 120,
    x130: 130,
    x140: 140,
    x150: 150,
    x160: 160,
    x170: 170,
    x180: 180,
    x190: 190,
    x200: 200,
};

export const computeResolution = (
    xMin: number,
    xMax: number,
    width: number
  ) => {
    const numPoints = width; //*2 ;
    const chosenResolution =
      xMax - xMin <= numPoints * resolutions.x05
        ? resolutions.x05
        : xMax - xMin <= numPoints * resolutions.x1
        ? resolutions.x1
        : xMax - xMin <= numPoints * resolutions.x2
        ? resolutions.x2
        : xMax - xMin <= numPoints * resolutions.x5
        ? resolutions.x5
        : xMax - xMin <= numPoints * resolutions.x10
        ? resolutions.x10
        : xMax - xMin <= numPoints * resolutions.x15
        ? resolutions.x15
        : xMax - xMin <= numPoints * resolutions.x20
        ? resolutions.x20
        : xMax - xMin <= numPoints * resolutions.x30
        ? resolutions.x30
        : xMax - xMin <= numPoints * resolutions.x40
        ? resolutions.x40
        : xMax - xMin <= numPoints * resolutions.x50
        ? resolutions.x50
        : xMax - xMin <= numPoints * resolutions.x60
        ? resolutions.x60
        : xMax - xMin <= numPoints * resolutions.x70
        ? resolutions.x70
        : xMax - xMin <= numPoints * resolutions.x80
        ? resolutions.x80
        : xMax - xMin <= numPoints * resolutions.x90
        ? resolutions.x90
        : xMax - xMin <= numPoints * resolutions.x100
        ? resolutions.x100
        : xMax - xMin <= numPoints * resolutions.x110
        ? resolutions.x110
        : xMax - xMin <= numPoints * resolutions.x120
        ? resolutions.x120
        : xMax - xMin <= numPoints * resolutions.x150
        ? resolutions.x150
        : xMax - xMin <= numPoints * resolutions.x200
        ? resolutions.x200
        : resolutions.x200;
  
    return chosenResolution;
  };
  
export const objectColors = (object: string) => {
  return object === objectTypes.start
    ? "lightgreen"
    : object === objectTypes.end
    ? "red"
    : object === objectTypes.switch
    ? "blue"
    : object === objectTypes.culvert
    ? "yellow"
    : object === objectTypes.trackBarrier
    ? "orange"
    : object === objectTypes.contactPole
    ? "purple"
    : object === objectTypes.levelCrossing
    ? "lightblue"
    : object === objectTypes.trackCross
    ? "green"
    : object === objectTypes.notes
    ? "pink"
    : object === objectTypes.marker
    ? "red"
    : "none";
};