import { TFunction } from "i18next";

// ------Action Types
export enum schedulerActionType {
  setPlanSorterType = "SET_PLAN_SORTER_TYPE",
  setTotalPlansOnServer = "SET_TOTAL_PLANS_ON_SERVER",
  addNewReduxPlan = "ADD_NEW_REDUX_PLAN",
  setSelectedPlan = "SET_SELECTED_PLAN",
  setSelectedSubPage = "SET_SELECTED_SUB_PAGE",
  updatePlanParameter = "UPDATE_PLAN_PARAMETER",
  updatePlanProperty = "UPDATE_PLAN_PROPERTY",
  setPlanHasChanged = "SET_PLAN_HAS_CHANGED",
  getExcelFile = "GET_EXCEL_FILE",
  setAllPlans = "SET_ALL_PLANS",
  deletePlan = "DELETE_PLAN",
  setAllMeasurements = "SET_ALL_MEASUREMENTS",
  setReduxProjectedTrack = "SET_REDUX_PROJECTED_TRACK",
  addOpearatorToMeasurement = "ADD_OPERATOR_TO_MEASUREMENT",
  deleteMeasurement = "DELETE_MEASUREMENT",
  setSelectedMeasurement = "SET_SELECTED_MEASUREMENT",
  setExpandedMenu = "SET_EXPANDED_MENU",
  setExternalAccessToken = "SET_EXTERNAL_ACCESS_TOKEN",
  setScreenWidth = "SET_SCREEN_WIDTH",
  setScreenHeight = "SET_SCREEN_HEIGHT",
  setNewPlanSelected = "SET_NEW_PLAN_SELECTED",
  updateMeasurementProperty = "UPDATE_MEASUREMENT_PROPERTY",
  updateMeasurementStatus = "UPDATE_MEASUREMENT_STATUS",
  setMeasurementPipelines = "SET_MEASUREMENT_PIPELINE",
}

// ----------Action interfaces

export interface setPlanSorterType {
  type: typeof schedulerActionType.setPlanSorterType;
  payload: planSorterTypes;
}

export interface addNewReduxPlan {
  type: typeof schedulerActionType.addNewReduxPlan;
  payload: planProps;
}

export interface setTotalPlansOnServer {
  type: typeof schedulerActionType.setTotalPlansOnServer;
  payload: number;
}

export interface setSelectedPlan {
  type: typeof schedulerActionType.setSelectedPlan;
  payload: planProps["id"];
}

export interface setSelectedSubPage {
  type: typeof schedulerActionType.setSelectedSubPage;
  payload: planSubpages;
}

export interface updatePlanParameter<T extends keyof parametersProps> {
  type: typeof schedulerActionType.updatePlanParameter;
  payload: Pick<parametersProps, T>;
}

export interface updatePlanProperty<T extends keyof planProps> {
  type: typeof schedulerActionType.updatePlanProperty;
  payload: Pick<planProps, T>;
}

export interface updateMeasurementProperty<T extends keyof measurementProps> {
  type: typeof schedulerActionType.updateMeasurementProperty;
  payload: Pick<measurementProps, T>;
}

export interface updateMeasurementStatus<T extends keyof measurementProps> {
  type: typeof schedulerActionType.updateMeasurementStatus;
  payload: { id: string; properties: Pick<measurementProps, T> };
}

export interface setPlanHasChanged {
  type: typeof schedulerActionType.setPlanHasChanged;
  payload: boolean;
}

export interface getExcelFile {
  type: typeof schedulerActionType.getExcelFile;
}

export interface setAllPlans {
  type: typeof schedulerActionType.setAllPlans;
  payload: planProps[];
}

export interface setReduxProjectedTrack {
  type: typeof schedulerActionType.setReduxProjectedTrack;
  payload: { file: File; planID: string };
}

export interface deletePlan {
  type: typeof schedulerActionType.deletePlan;
  payload: string;
}

export interface deleteMeasurement {
  type: typeof schedulerActionType.deleteMeasurement;
  payload: string;
}

export interface setAllReduxMeasurements {
  type: typeof schedulerActionType.setAllMeasurements;
  payload: { measurements: measurementProps[]; plan: planProps };
}

export interface addOpearatorToMeasurement {
  type: typeof schedulerActionType.addOpearatorToMeasurement;
  payload: string;
}

export interface setSelectedMeasurement {
  type: typeof schedulerActionType.setSelectedMeasurement;
  payload: measurementProps["id"];
}

export interface setExpandedMenu {
  type: typeof schedulerActionType.setExpandedMenu;
  payload: boolean;
}

export interface setExternalAccessTokenType {
  type: typeof schedulerActionType.setExternalAccessToken;
  payload: string;
}

export interface setScreenHeight {
  type: typeof schedulerActionType.setScreenHeight;
  payload: number;
}
export interface setScreenWidth {
  type: typeof schedulerActionType.setScreenWidth;
  payload: number;
}
export interface setNewPlanSelected {
  type: typeof schedulerActionType.setNewPlanSelected;
  payload: boolean;
}

// export interface setMeasurementPipelines {
//   type: typeof schedulerActionType.setMeasurementPipelines;
//   payload: {
//     measurementID: string;
//     planID: string;
//     pipelines: pipelineProps[];
//   };
// }

export type SchedulerAction =
  | setPlanSorterType
  | addNewReduxPlan
  | setSelectedPlan
  | setSelectedSubPage
  | updatePlanParameter<keyof parametersProps>
  | updatePlanProperty<keyof planProps>
  | setPlanHasChanged
  | getExcelFile
  | setAllPlans
  | deletePlan
  | setAllReduxMeasurements
  | setReduxProjectedTrack
  | addOpearatorToMeasurement
  | deleteMeasurement
  | setSelectedMeasurement
  | setExpandedMenu
  | setExternalAccessTokenType
  | setScreenWidth
  | setScreenHeight
  | setTotalPlansOnServer
  | setNewPlanSelected
  | updateMeasurementProperty<keyof measurementProps>
  | updateMeasurementStatus<keyof measurementProps>;
// | setMeasurementPipelines;

// ---------Redux State Type ---------------
export interface schedulerState {
  plans: planProps[];
  totalPlansOnServer: number;
  planSorterType: planSorterTypes;
  selectedPlan: planProps["id"];
  selectedSubpage: planSubpages;
  planHasChanged: boolean;
  selectedMeasurement: measurementProps["id"];
  expandMenu: boolean;
  externalAccessToken: string;
  screenWidth: number;
  screenHeight: number;
  newPlanSelected: boolean;
}

export type measurementMode = "automatic" | "manual";

export interface parametersProps {
  name: string;
  place: string;
  pathFrom: string;
  pathTo: string;
  planStartAtKm: string;
  planStartAtMeter: string;
  planStartAt: string;
  planEndAtKm: string;
  planEndAtMeter: string;
  planEndAt: string;
  direction: trackDirection;
  trackPart: string;
  trackNumber: string;
  trackClassification: classificationProps;
  measurementMode: measurementMode;
  notes: string;
  measurementsSelection: measurementsSelectionProps;
}

export interface measurementsSelectionProps {
  alignment: boolean;
  crossLevel: boolean;
  longitudinal: boolean;
  twist: boolean;
  trackGauge: boolean;
  gps: boolean;
}

export const keyNames = [
  "alignment",
  "crossLevel",
  "longitudinal",
  "trackGauge",
  "twist",
  "gps",
];

export const keyNamePairs = (
  key: keyof measurementsSelectionProps,
  translator: TFunction<"translation", "translation">
) => {
  const translatedKeys = {
    alignment: translator("basicMeasurementKeys.alignment"),
    crossLevel: translator("basicMeasurementKeys.crossLevel"),
    longitudinal: translator("basicMeasurementKeys.longitudinal"),
    trackGauge: translator("basicMeasurementKeys.trackGauge"),
    twist: translator("basicMeasurementKeys.twist"),
    gps: translator("basicMeasurementKeys.gps"),
  };

  return translatedKeys[key];
};

export const extendedkeyNames = [
  "alignment",
  "crossLevel",
  "longitudinal",
  "trackGauge",
  "twist",
  "gps",
];
export const measurementKeys = (
  key: string,
  translator: TFunction<"translation", "translation">
) => {
  const translatedKeys = {
    alignment: translator("extendedMeasurementKeys.alignment"),
    crossLevel: translator("extendedMeasurementKeys.crossLevel"),
    crossLevelBIS: translator("extendedMeasurementKeys.crossLevelBIS"),
    crossLevelUnevenness: translator(
      "extendedMeasurementKeys.crossLevelUnevenness"
    ),
    longitudinalLevel: translator("extendedMeasurementKeys.longitudinalLevel"),
    trackGauge: translator("extendedMeasurementKeys.trackGauge"),
    twist3m: translator("extendedMeasurementKeys.twist3m"),
    twist6m: translator("extendedMeasurementKeys.twist6m"),
    alignmentLeft: translator("extendedMeasurementKeys.alignmentLeft"),
    longitudinalLevelLeft: translator(
      "extendedMeasurementKeys.longitudinalLevelLeft"
    ),
    alignmentRight: translator("extendedMeasurementKeys.alignmentRight"),
    longitudinalLevelRight: translator(
      "extendedMeasurementKeys.longitudinalLevelRight"
    ),
    gps: translator("extendedMeasurementKeys.gps"),
  };

  return translatedKeys[key as keyof typeof translatedKeys];
};

export type trackDirection = "positive" | "negative";

export type classificationProps = "H0" | "H1" | "H2" | "H3" | "H4" | "H5";

export enum planStatus {
  awaiting = "awaiting",
  inProgress = "inProgress",
  canceled = "canceled",
  done = "done",
  archived = "archived",
}

export enum measurementStatus {
  Initialized = "initialized",
  Uploading = "uploading",
  UploadingDone = "uploading-done",
  Processing = "processing",
  ProcessingDone = "processing-done",
  AdjustingDistance = "adjusting-distance",
  AdjustingDistanceDone = "adjusting-distance-done",
  CreatingExcel = "creating-excel",
  CreatingExcelDone = "creating-excel-done",
  Merging = "merging",
  MergingDone = "merging-done",
  CreatingCSV = "creating-csv",
  CreatingCSVDone = "creating-csv-done",
  SendingEmail = "sending-email",
  SendingEmailDone = "sending-email-done",
  WritingToDynamodb = "writing-to-dynamodb",
  WritingToDynamodbDone = "writing-to-dynamodb-done",
}

export type planSorterTypes =
  | "createdAt"
  | "-createdAt"
  | "numberMeasurements"
  | "-numberMeasurements"
  | "doneAt"
  | "-doneAt"
  | "updatedAt"
  | "-updatedAt"
  | "scheduledAt"
  | "-scheduledAt"
  | "status"
  | "-status";

export enum planSubpages {
  plan = 1,
  measurements = 2,
}

export interface FileProps {
  name: string;
  type: FileType;
  id: string;
  createdAt: string;
  creatorID: string;
  ownerID: string;
  ownerType: string;
  part: number;
  partType?: string;
  path: string;
  size: number;
  updatedAt: string;
}

export type FileType =
  | "generic"
  | "csv"
  | "processed_data"
  | "data_logs"
  | "excel"
  | "projected_track";

export interface measurementProps {
  createdAt: string;
  creatorID: string;
  machineID: string;
  endAt: string;
  endAtKm?: number;
  endAtMeter?: number;
  id: string;
  notes: string; // [];
  planID: string;
  receivers: string[];
  startAt: string;
  startAtKm: number;
  startAtMeter: number;
  updatedAt?: string;
  status: measurementStatus;
  errorMessage?: string;
  sentToServer?: boolean;
  doneAt?: string;
  trackNumber: string;
  speedAvg: number;
  speedStd: number;
  tempAvg: number;
  tempStd: number;
  files: FileProps[];
  pipelines: pipelineProps[];
  classification: string;
  machineNameTag?: string;
  reprocessCnt: number; 
  reprocessed: boolean;
}

export interface requestMetaData {
  total: number;
  limit: number;
  offset: number;
}

export interface planProps {
  id: string;
  machineAssignees: string[];
  userAssignees: string[];
  creatorID: string;
  createdAt: string;
  doneAt: string;
  status: planStatus;
  projectedTrackFile?: File;
  measurements: measurementProps[];
  receivers: string[];
  numberMeasurements: number;
  trackPart?: number;
  trackNumber?: string;
  updatedAt?: string;
  place: string;
  pathFrom: string;
  pathTo: string;
  startAtKm?: number;
  startAtMeter?: number;
  startAt: string;
  endAtKm?: number;
  endAtMeter?: number;
  endAt: string;
  direction: trackDirection;
  name: string;
  notes: string;
  scheduledAt: string;
  measurementsSelection: boolean[]; // measurementsSelectionProps;
  measurementMode: measurementMode;
  sentToServer?: boolean;
  files?: string[];
  classification: string;
}

export interface signalMetaData {
  deletingSince?: string;
  endKm: number;
  endMeter: number;
  endTick: string;
  startKm: number;
  startMeter: number;
  startTick: string;
  totalKrit: number;
  totalPlan: number;
  totalStop: number;
  totalUh1: number;
  totalUh2: number;
  snr?: number;
}

export interface measurementMetaData {
  trackGauge: signalMetaData;
  crossLevel: signalMetaData;
  crossLevelBIS: signalMetaData;
  crossLevelUnevenness: signalMetaData;
  twist6m: signalMetaData;
  twist3m: signalMetaData;
  alignment: signalMetaData;
  longitudinalLevel: signalMetaData;
  alignmentLeft: signalMetaData;
  longitudinalLevelLeft: signalMetaData;
  alignmentRight: signalMetaData;
  longitudinalLevelRight: signalMetaData;
}

export const planStatusToSwedish = (value: string) => {
  switch (value) {
    case planStatus.awaiting:
      return "Planerad";
    case planStatus.canceled:
      return "Inställd";
    case planStatus.done:
      return "Färdig";
    case planStatus.inProgress:
      return "Pågående";
    case planStatus.archived:
      return "Arkiverad";
    default:
      return "Pågående";
  }
};

export const swedishToPlanStatus = (
  value: string,
  translator: TFunction<"translation", "translation">
) => {
  switch (value) {
    case translator("common.planStatus.awaiting"):
      return planStatus.awaiting;
    case translator("common.planStatus.canceled"):
      return planStatus.canceled;
    case translator("common.planStatus.done"):
      return planStatus.done;
    case translator("common.planStatus.inProgress"):
      return planStatus.inProgress;
    case translator("common.planStatus.archived"):
      return planStatus.archived;
    default:
      return undefined;
  }
};

export interface pipelineProps {
  id: string;
  createdAt: string;
  doneAt?: string;
  updatedAt: string;
  stateMachineID?: string;
  state: processingState;
  status: processingStatus;
  note?: string;
  jobMessage?: string;
  version?: string;
  commitSHA?: string;
  parentID?: string;
  measurementID: string;
  output?: string;
  processNumber: number;
}

export enum processingState {
  dataProcessing = "data-processing",
  integration = "integration",
  excel = "excel",
  writingToDynamodb = "write-to-dynamodb",
  merging = "merging",
  email = "email",
  start = "start",
  end = "end",
}

export enum processingStatus {
  received = "received",
  processing = "processing",
  done = "done",
  error = "error",
  skipped = "skipped",
}
