import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppState } from "../../store/store";
import {
  ChartContainer,
  ChartDimensionProps,
} from "../generic/chartContainer/chartContainer";
import { Card } from "./../generic/card/card";
import { GroupChartRailShape } from "././groupChartRailShape";
import "./groupChartContainer.css";

interface GroupChartContainerRailShapeProps {
  chartID: string;
}

export const GroupChartContainerRailShape: React.FunctionComponent<
  GroupChartContainerRailShapeProps
> = ({ chartID }) => {
  const [chartDimensions, setChartDimensions] = useState<
    ChartDimensionProps | undefined
  >(undefined);

  const windowSize = useSelector(
    (state: AppState) => state.plot.specialCharts.windowSize
  );
  const { t } = useTranslation();
  const triggerRerender = true;

  return (
    <Card>
      <h5 style={{ paddingBottom: "5px" }}>
        {t("currentLongitudinalLevel.unfilteredLongitudinalLevel") +
          " - " +
          windowSize +
          "m"}
      </h5>
      <div className="GroupChartContainerRS">
        <ChartContainer
          chartDimensions={chartDimensions}
          setChartDimensions={setChartDimensions}
          rerenderEvent={triggerRerender}
        >
          <GroupChartRailShape
            chartDimensions={chartDimensions}
            chartID={chartID}
          />
        </ChartContainer>
      </div>
    </Card>
  );
};
