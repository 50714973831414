import { TFunction } from "i18next";
import { measurementStatusHMI } from "../components/scheduler/plan/measurementList/statusProgress";
import {
  dataPointType,
  GroupChartDataProps,
  plotState,
  plotStateLongitudinalLevel,
  SelectedTolerancesType,
} from "../store/plot/types";
import {
  FileType,
  measurementStatus,
  pipelineProps,
  processingState,
  processingStatus,
  signalMetaData,
} from "../store/scheduler/types";
import { roleType } from "../store/user/types";

export function intersection(setA: any, setB: any) {
  const result = new Set();

  for (const elem of setA) {
    if (setB.has(elem)) {
      result.add(elem);
    }
  }

  return result;
}

export function removeItemAll(arr: any, indexesToRemove: any) {
  let i = 0;
  const newArray: any = [];
  while (i < arr.length) {
    if (!indexesToRemove.includes(i)) {
      newArray.push(arr[i]);
    }

    ++i;
  }
  return newArray;
}

export const fromTickToMeters = (tick: string) => {
  const splitTick = tick.split("+");
  const kmInMeters = +splitTick[0] * 1000;
  const commaMeters = splitTick[1].split(",");
  const cleanMeters = +commaMeters[0];
  const decimals = +commaMeters[1].split("m")[0] * 0.1;
  const tickInMeters = kmInMeters + cleanMeters + decimals;
  return tickInMeters;
};

export const getHighestRoleTypeFromServerStruct = (roles: any[]) => {
  let highestRole: roleType = roleType.external;
  roles.forEach((role) => {
    switch (role.name) {
      case "ADMIN":
        highestRole = roleType.admin;
        break;

      case "DEVELOPER":
        if (highestRole !== roleType.admin) highestRole = roleType.developer;
        break;
      case "MANAGER":
        if (
          highestRole !== roleType.admin &&
          highestRole !== roleType.developer
        )
          highestRole = roleType.manager;
        break;
      case "OPEARATOR":
        if (
          highestRole !== roleType.admin &&
          highestRole !== roleType.developer &&
          highestRole !== roleType.manager
        )
          highestRole = roleType.operator;
        break;
      default:
        break;
    }
  });
  return highestRole;
};

export interface toleranceUpperLowerType {
  low: number;
  up: number;
}

export interface SpeedClassToleranceType {
  plan: toleranceUpperLowerType;
  uh1: toleranceUpperLowerType;
  uh2: toleranceUpperLowerType;
  krit: toleranceUpperLowerType;
  stopp: toleranceUpperLowerType;
}

export interface SpeedClassType {
  H0: SpeedClassToleranceType;
  H1: SpeedClassToleranceType;
  H2: SpeedClassToleranceType;
  H3: SpeedClassToleranceType;
  H4: SpeedClassToleranceType;
  H5: SpeedClassToleranceType;
}

export interface TolerancesType {
  trackGauge: SpeedClassType;
  crossLevel: SpeedClassType;
  crossLevelBIS: SpeedClassType;
  crossLevelUnevenness: SpeedClassType;
  twist3m: SpeedClassType;
  twist6m: SpeedClassType;
  alignment: SpeedClassType;
  longitudinalLevel: SpeedClassType;
  alignmentLeft: SpeedClassType;
  longitudinalLevelLeft: SpeedClassType;
  alignmentRight: SpeedClassType;
  longitudinalLevelRight: SpeedClassType;
  longitudinalAngleHP: SpeedClassType;
}

const nominal_trackGauge = 1435;
export const tolerances: TolerancesType = {
  trackGauge: {
    H5: {
      plan: {
        low: nominal_trackGauge - 3,
        up: nominal_trackGauge + 8,
        severity: 1,
      },
      uh1: {
        low: nominal_trackGauge - 3,
        up: nominal_trackGauge + 10,
        severity: 2,
      },
      uh2: {
        low: nominal_trackGauge - 4,
        up: nominal_trackGauge + 15,
        severity: 3,
      },
      krit: {
        low: nominal_trackGauge - 5,
        up: nominal_trackGauge + 28,
        severity: 4,
      },
      stopp: { low: -Infinity, up: nominal_trackGauge + 33, severity: 5 },
    },
    H4: {
      plan: {
        low: nominal_trackGauge - 3,
        up: nominal_trackGauge + 10,
        severity: 1,
      },
      uh1: {
        low: nominal_trackGauge - 4,
        up: nominal_trackGauge + 12,
        severity: 2,
      },
      uh2: {
        low: nominal_trackGauge - 5,
        up: nominal_trackGauge + 20,
        severity: 3,
      },
      krit: {
        low: nominal_trackGauge - 7,
        up: nominal_trackGauge + 28,
        severity: 4,
      },
      stopp: { low: -Infinity, up: nominal_trackGauge + 33, severity: 5 },
    },
    H3: {
      plan: {
        low: nominal_trackGauge - 3,
        up: nominal_trackGauge + 12,
        severity: 1,
      },
      uh1: {
        low: nominal_trackGauge - 4,
        up: nominal_trackGauge + 15,
        severity: 2,
      },
      uh2: {
        low: nominal_trackGauge - 5,
        up: nominal_trackGauge + 22,
        severity: 3,
      },
      krit: {
        low: nominal_trackGauge - 8,
        up: nominal_trackGauge + 33,
        severity: 4,
      },
      stopp: { low: -Infinity, up: nominal_trackGauge + 35, severity: 5 },
    },
    H2: {
      plan: {
        low: nominal_trackGauge - 4,
        up: nominal_trackGauge + 12,
        severity: 1,
      },
      uh1: {
        low: nominal_trackGauge - 4,
        up: nominal_trackGauge + 17,
        severity: 2,
      },
      uh2: {
        low: nominal_trackGauge - 5,
        up: nominal_trackGauge + 25,
        severity: 3,
      },
      krit: {
        low: nominal_trackGauge - 9,
        up: nominal_trackGauge + 33,
        severity: 4,
      },
      stopp: { low: -Infinity, up: nominal_trackGauge + 35, severity: 5 },
    },
    H1: {
      plan: {
        low: nominal_trackGauge - 4,
        up: nominal_trackGauge + 15,
        severity: 1,
      },
      uh1: {
        low: nominal_trackGauge - 4,
        up: nominal_trackGauge + 22,
        severity: 2,
      },
      uh2: {
        low: nominal_trackGauge - 5,
        up: nominal_trackGauge + 30,
        severity: 3,
      },
      krit: {
        low: nominal_trackGauge - 10,
        up: nominal_trackGauge + 35,
        severity: 4,
      },
      stopp: { low: -Infinity, up: nominal_trackGauge + 37, severity: 5 },
    },
    H0: {
      plan: {
        low: nominal_trackGauge - 4,
        up: nominal_trackGauge + 15,
        severity: 1,
      },
      uh1: {
        low: nominal_trackGauge - 4,
        up: nominal_trackGauge + 22,
        severity: 2,
      },
      uh2: {
        low: nominal_trackGauge - 5,
        up: nominal_trackGauge + 30,
        severity: 3,
      },
      krit: {
        low: nominal_trackGauge - 10,
        up: nominal_trackGauge + 35,
        severity: 4,
      },
      stopp: { low: -Infinity, up: nominal_trackGauge + 37, severity: 5 },
    },
  } as SpeedClassType,
  crossLevel: {
    H5: {
      plan: { low: -4, up: 4, severity: 1 },
      uh1: { low: -5, up: 5, severity: 2 },
      uh2: { low: -8, up: 8, severity: 3 },
      krit: { low: -20, up: 20, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
    H4: {
      plan: { low: -4, up: 4, severity: 1 },
      uh1: { low: -6, up: 6, severity: 2 },
      uh2: { low: -9, up: 9, severity: 3 },
      krit: { low: -20, up: 20, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
    H3: {
      plan: { low: -5, up: 5, severity: 1 },
      uh1: { low: -7, up: 7, severity: 2 },
      uh2: { low: -10, up: 10, severity: 3 },
      krit: { low: -20, up: 20, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
    H2: {
      plan: { low: -6, up: 6, severity: 1 },
      uh1: { low: -8, up: 8, severity: 2 },
      uh2: { low: -12, up: 12, severity: 3 },
      krit: { low: -20, up: 20, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
    H1: {
      plan: { low: -7, up: 7, severity: 1 },
      uh1: { low: -10, up: 10, severity: 2 },
      uh2: { low: -14, up: 14, severity: 3 },
      krit: { low: -20, up: 20, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
    H0: {
      plan: { low: -8, up: 8, severity: 1 },
      uh1: { low: -12, up: 12, severity: 2 },
      uh2: { low: -16, up: 16, severity: 3 },
      krit: { low: -20, up: 20, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
  } as SpeedClassType,
  crossLevelBIS: {
    H5: {
      plan: { low: -4, up: 4, severity: 1 },
      uh1: { low: -5, up: 5, severity: 2 },
      uh2: { low: -8, up: 8, severity: 3 },
      krit: { low: -20, up: 20, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
    H4: {
      plan: { low: -4, up: 4, severity: 1 },
      uh1: { low: -6, up: 6, severity: 2 },
      uh2: { low: -9, up: 9, severity: 3 },
      krit: { low: -20, up: 20, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
    H3: {
      plan: { low: -5, up: 5, severity: 1 },
      uh1: { low: -7, up: 7, severity: 2 },
      uh2: { low: -10, up: 10, severity: 3 },
      krit: { low: -20, up: 20, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
    H2: {
      plan: { low: -6, up: 6, severity: 1 },
      uh1: { low: -8, up: 8, severity: 2 },
      uh2: { low: -12, up: 12, severity: 3 },
      krit: { low: -20, up: 20, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
    H1: {
      plan: { low: -7, up: 7, severity: 1 },
      uh1: { low: -10, up: 10, severity: 2 },
      uh2: { low: -14, up: 14, severity: 3 },
      krit: { low: -20, up: 20, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
    H0: {
      plan: { low: -8, up: 8, severity: 1 },
      uh1: { low: -12, up: 12, severity: 2 },
      uh2: { low: -16, up: 16, severity: 3 },
      krit: { low: -20, up: 20, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
  } as SpeedClassType,

  crossLevelUnevenness: {
    H5: {
      plan: { low: -4, up: 4, severity: 1 },
      uh1: { low: -5, up: 5, severity: 2 },
      uh2: { low: -8, up: 8, severity: 3 },
      krit: { low: -20, up: 20, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
    H4: {
      plan: { low: -4, up: 4, severity: 1 },
      uh1: { low: -6, up: 6, severity: 2 },
      uh2: { low: -9, up: 9, severity: 3 },
      krit: { low: -20, up: 20, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
    H3: {
      plan: { low: -5, up: 5, severity: 1 },
      uh1: { low: -7, up: 7, severity: 2 },
      uh2: { low: -10, up: 10, severity: 3 },
      krit: { low: -20, up: 20, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
    H2: {
      plan: { low: -6, up: 6, severity: 1 },
      uh1: { low: -8, up: 8, severity: 2 },
      uh2: { low: -12, up: 12, severity: 3 },
      krit: { low: -20, up: 20, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
    H1: {
      plan: { low: -7, up: 7, severity: 1 },
      uh1: { low: -10, up: 10, severity: 2 },
      uh2: { low: -14, up: 14, severity: 3 },
      krit: { low: -20, up: 20, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
    H0: {
      plan: { low: -8, up: 8, severity: 1 },
      uh1: { low: -12, up: 12, severity: 2 },
      uh2: { low: -16, up: 16, severity: 3 },
      krit: { low: -20, up: 20, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
  } as SpeedClassType,
  twist6m: {
    H5: {
      plan: { low: -6, up: 6, severity: 1 },
      uh1: { low: -9, up: 9, severity: 2 },
      uh2: { low: -13, up: 13, severity: 3 },
      krit: { low: -20, up: 20, severity: 4 },
      stopp: { low: -23, up: 23, severity: 5 },
    },
    H4: {
      plan: { low: -6, up: 6, severity: 1 },
      uh1: { low: -9, up: 9, severity: 2 },
      uh2: { low: -13, up: 13, severity: 3 },
      krit: { low: -25, up: 25, severity: 4 },
      stopp: { low: -28, up: 28, severity: 5 },
    },
    H3: {
      plan: { low: -8, up: 8, severity: 1 },
      uh1: { low: -11, up: 11, severity: 2 },
      uh2: { low: -17, up: 17, severity: 3 },
      krit: { low: -25, up: 25, severity: 4 },
      stopp: { low: -28, up: 28, severity: 5 },
    },
    H2: {
      plan: { low: -9, up: 9, severity: 1 },
      uh1: { low: -13, up: 13, severity: 2 },
      uh2: { low: -19, up: 19, severity: 3 },
      krit: { low: -29, up: 29, severity: 4 },
      stopp: { low: -32, up: 32, severity: 5 },
    },
    H1: {
      plan: { low: -12, up: 12, severity: 1 },
      uh1: { low: -16, up: 16, severity: 2 },
      uh2: { low: -21, up: 21, severity: 3 },
      krit: { low: -29, up: 29, severity: 4 },
      stopp: { low: -32, up: 32, severity: 5 },
    },
    H0: {
      plan: { low: -15, up: 15, severity: 1 },
      uh1: { low: -19, up: 19, severity: 2 },
      uh2: { low: -23, up: 23, severity: 3 },
      krit: { low: -29, up: 29, severity: 4 },
      stopp: { low: -32, up: 32, severity: 5 },
    },
  } as SpeedClassType,
  twist3m: {
    H5: {
      plan: { low: -4, up: 6, severity: 1 },
      uh1: { low: -6, up: 6, severity: 2 },
      uh2: { low: -9, up: 9, severity: 3 },
      krit: { low: -12, up: 12, severity: 4 },
      stopp: { low: -15, up: 15, severity: 5 },
    },
    H4: {
      plan: { low: -5, up: 5, severity: 1 },
      uh1: { low: -7, up: 7, severity: 2 },
      uh2: { low: -10, up: 10, severity: 3 },
      krit: { low: -15, up: 15, severity: 4 },
      stopp: { low: -18, up: 18, severity: 5 },
    },
    H3: {
      plan: { low: -6, up: 6, severity: 1 },
      uh1: { low: -8, up: 8, severity: 2 },
      uh2: { low: -11, up: 11, severity: 3 },
      krit: { low: -15, up: 15, severity: 4 },
      stopp: { low: -18, up: 18, severity: 5 },
    },
    H2: {
      plan: { low: -7, up: 7, severity: 1 },
      uh1: { low: -9, up: 9, severity: 2 },
      uh2: { low: -12, up: 12, severity: 3 },
      krit: { low: -18, up: 18, severity: 4 },
      stopp: { low: -21, up: 21, severity: 5 },
    },
    H1: {
      plan: { low: -8, up: 8, severity: 1 },
      uh1: { low: -10, up: 10, severity: 2 },
      uh2: { low: -13, up: 13, severity: 3 },
      krit: { low: -18, up: 18, severity: 4 },
      stopp: { low: -21, up: 21, severity: 5 },
    },
    H0: {
      plan: { low: -9, up: 9, severity: 1 },
      uh1: { low: -12, up: 12, severity: 2 },
      uh2: { low: -14, up: 14, severity: 3 },
      krit: { low: -18, up: 18, severity: 4 },
      stopp: { low: -21, up: 21, severity: 5 },
    },
  } as SpeedClassType,
  alignment: {
    H5: {
      plan: { low: -3, up: 3, severity: 1 },
      uh1: { low: -4, up: 4, severity: 2 },
      uh2: { low: -5, up: 5, severity: 3 },
      krit: { low: -10, up: 10, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
    H4: {
      plan: { low: -3, up: 3, severity: 1 },
      uh1: { low: -4, up: 4, severity: 2 },
      uh2: { low: -6, up: 6, severity: 3 },
      krit: { low: -12, up: 12, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },

    H3: {
      plan: { low: -4, up: 4, severity: 1 },
      uh1: { low: -5, up: 5, severity: 2 },
      uh2: { low: -8, up: 8, severity: 3 },
      krit: { low: -14, up: 14, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
    H2: {
      plan: { low: -5, up: 5, severity: 1 },
      uh1: { low: -7, up: 7, severity: 2 },
      uh2: { low: -11, up: 11, severity: 3 },
      krit: { low: -17, up: 17, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
    H1: {
      plan: { low: -8, up: 8, severity: 1 },
      uh1: { low: -11, up: 11, severity: 2 },
      uh2: { low: -14, up: 14, severity: 3 },
      krit: { low: -22, up: 22, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
    H0: {
      plan: { low: -10, up: 10, severity: 1 },
      uh1: { low: -14, up: 14, severity: 2 },
      uh2: { low: -17, up: 17, severity: 3 },
      krit: { low: -22, up: 22, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
  } as SpeedClassType,
  alignmentLeft: {
    H5: {
      plan: { low: -3, up: 3, severity: 1 },
      uh1: { low: -4, up: 4, severity: 2 },
      uh2: { low: -5, up: 5, severity: 3 },
      krit: { low: -10, up: 10, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
    H4: {
      plan: { low: -3, up: 3, severity: 1 },
      uh1: { low: -4, up: 4, severity: 2 },
      uh2: { low: -6, up: 6, severity: 3 },
      krit: { low: -12, up: 12, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },

    H3: {
      plan: { low: -4, up: 4, severity: 1 },
      uh1: { low: -5, up: 5, severity: 2 },
      uh2: { low: -8, up: 8, severity: 3 },
      krit: { low: -14, up: 14, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
    H2: {
      plan: { low: -5, up: 5, severity: 1 },
      uh1: { low: -7, up: 7, severity: 2 },
      uh2: { low: -11, up: 11, severity: 3 },
      krit: { low: -17, up: 17, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
    H1: {
      plan: { low: -8, up: 8, severity: 1 },
      uh1: { low: -11, up: 11, severity: 2 },
      uh2: { low: -14, up: 14, severity: 3 },
      krit: { low: -22, up: 22, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
    H0: {
      plan: { low: -10, up: 10, severity: 1 },
      uh1: { low: -14, up: 14, severity: 2 },
      uh2: { low: -17, up: 17, severity: 3 },
      krit: { low: -22, up: 22, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
  } as SpeedClassType,
  alignmentRight: {
    H5: {
      plan: { low: -3, up: 3, severity: 1 },
      uh1: { low: -4, up: 4, severity: 2 },
      uh2: { low: -5, up: 5, severity: 3 },
      krit: { low: -10, up: 10, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
    H4: {
      plan: { low: -3, up: 3, severity: 1 },
      uh1: { low: -4, up: 4, severity: 2 },
      uh2: { low: -6, up: 6, severity: 3 },
      krit: { low: -12, up: 12, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },

    H3: {
      plan: { low: -4, up: 4, severity: 1 },
      uh1: { low: -5, up: 5, severity: 2 },
      uh2: { low: -8, up: 8, severity: 3 },
      krit: { low: -14, up: 14, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
    H2: {
      plan: { low: -5, up: 5, severity: 1 },
      uh1: { low: -7, up: 7, severity: 2 },
      uh2: { low: -11, up: 11, severity: 3 },
      krit: { low: -17, up: 17, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
    H1: {
      plan: { low: -8, up: 8, severity: 1 },
      uh1: { low: -11, up: 11, severity: 2 },
      uh2: { low: -14, up: 14, severity: 3 },
      krit: { low: -22, up: 22, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
    H0: {
      plan: { low: -10, up: 10, severity: 1 },
      uh1: { low: -14, up: 14, severity: 2 },
      uh2: { low: -17, up: 17, severity: 3 },
      krit: { low: -22, up: 22, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
  } as SpeedClassType,
  longitudinalLevel: {
    H5: {
      plan: { low: -4, up: 4, severity: 1 },
      uh1: { low: -6, up: 6, severity: 2 },
      uh2: { low: -8, up: 8, severity: 3 },
      krit: { low: -16, up: 16, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
    H4: {
      plan: { low: -5, up: 5, severity: 1 },
      uh1: { low: -7, up: 7, severity: 2 },
      uh2: { low: -9, up: 9, severity: 3 },
      krit: { low: -20, up: 20, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },

    H3: {
      plan: { low: -6, up: 6, severity: 1 },
      uh1: { low: -8, up: 8, severity: 2 },
      uh2: { low: -12, up: 12, severity: 3 },
      krit: { low: -23, up: 23, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
    H2: {
      plan: { low: -8, up: 8, severity: 1 },
      uh1: { low: -10, up: 10, severity: 2 },
      uh2: { low: -15, up: 15, severity: 3 },
      krit: { low: -26, up: 26, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
    H1: {
      plan: { low: -10, up: 10, severity: 1 },
      uh1: { low: -12, up: 12, severity: 2 },
      uh2: { low: -19, up: 19, severity: 3 },
      krit: { low: -28, up: 28, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
    H0: {
      plan: { low: -12, up: 12, severity: 1 },
      uh1: { low: -15, up: 15, severity: 2 },
      uh2: { low: -25, up: 25, severity: 3 },
      krit: { low: -28, up: 28, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
  } as SpeedClassType,
  longitudinalAngleHP: {
    H5: {
      plan: { low: -4, up: 4, severity: 1 },
      uh1: { low: -6, up: 6, severity: 2 },
      uh2: { low: -8, up: 8, severity: 3 },
      krit: { low: -16, up: 16, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
    H4: {
      plan: { low: -5, up: 5, severity: 1 },
      uh1: { low: -7, up: 7, severity: 2 },
      uh2: { low: -9, up: 9, severity: 3 },
      krit: { low: -20, up: 20, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },

    H3: {
      plan: { low: -6, up: 6, severity: 1 },
      uh1: { low: -8, up: 8, severity: 2 },
      uh2: { low: -12, up: 12, severity: 3 },
      krit: { low: -23, up: 23, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
    H2: {
      plan: { low: -8, up: 8, severity: 1 },
      uh1: { low: -10, up: 10, severity: 2 },
      uh2: { low: -15, up: 15, severity: 3 },
      krit: { low: -26, up: 26, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
    H1: {
      plan: { low: -10, up: 10, severity: 1 },
      uh1: { low: -12, up: 12, severity: 2 },
      uh2: { low: -19, up: 19, severity: 3 },
      krit: { low: -28, up: 28, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
    H0: {
      plan: { low: -12, up: 12, severity: 1 },
      uh1: { low: -15, up: 15, severity: 2 },
      uh2: { low: -25, up: 25, severity: 3 },
      krit: { low: -28, up: 28, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
  } as SpeedClassType,
  longitudinalLevelLeft: (<SpeedClassType>{
    H5: {
      plan: { low: -4, up: 4, severity: 1 },
      uh1: { low: -6, up: 6, severity: 2 },
      uh2: { low: -8, up: 8, severity: 3 },
      krit: { low: -16, up: 16, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
    H4: {
      plan: { low: -5, up: 5, severity: 1 },
      uh1: { low: -7, up: 7, severity: 2 },
      uh2: { low: -9, up: 9, severity: 3 },
      krit: { low: -20, up: 20, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },

    H3: {
      plan: { low: -6, up: 6, severity: 1 },
      uh1: { low: -8, up: 8, severity: 2 },
      uh2: { low: -12, up: 12, severity: 3 },
      krit: { low: -23, up: 23, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
    H2: {
      plan: { low: -8, up: 8, severity: 1 },
      uh1: { low: -10, up: 10, severity: 2 },
      uh2: { low: -15, up: 15, severity: 3 },
      krit: { low: -26, up: 26, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
    H1: {
      plan: { low: -10, up: 10, severity: 1 },
      uh1: { low: -12, up: 12, severity: 2 },
      uh2: { low: -19, up: 19, severity: 3 },
      krit: { low: -28, up: 28, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
    H0: {
      plan: { low: -12, up: 12, severity: 1 },
      uh1: { low: -15, up: 15, severity: 2 },
      uh2: { low: -25, up: 25, severity: 3 },
      krit: { low: -28, up: 28, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
  }) as SpeedClassType,
  longitudinalLevelRight: {
    H5: {
      plan: { low: -4, up: 4, severity: 1 },
      uh1: { low: -6, up: 6, severity: 2 },
      uh2: { low: -8, up: 8, severity: 3 },
      krit: { low: -16, up: 16, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
    H4: {
      plan: { low: -5, up: 5, severity: 1 },
      uh1: { low: -7, up: 7, severity: 2 },
      uh2: { low: -9, up: 9, severity: 3 },
      krit: { low: -20, up: 20, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },

    H3: {
      plan: { low: -6, up: 6, severity: 1 },
      uh1: { low: -8, up: 8, severity: 2 },
      uh2: { low: -12, up: 12, severity: 3 },
      krit: { low: -23, up: 23, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
    H2: {
      plan: { low: -8, up: 8, severity: 1 },
      uh1: { low: -10, up: 10, severity: 2 },
      uh2: { low: -15, up: 15, severity: 3 },
      krit: { low: -26, up: 26, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
    H1: {
      plan: { low: -10, up: 10, severity: 1 },
      uh1: { low: -12, up: 12, severity: 2 },
      uh2: { low: -19, up: 19, severity: 3 },
      krit: { low: -28, up: 28, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
    H0: {
      plan: { low: -12, up: 12, severity: 1 },
      uh1: { low: -15, up: 15, severity: 2 },
      uh2: { low: -25, up: 25, severity: 3 },
      krit: { low: -28, up: 28, severity: 4 },
      stopp: { low: -Infinity, up: Infinity, severity: 5 },
    },
  } as SpeedClassType,
};

export interface ColorType {
  stopp: string;
  krit: string;
  uh2: string;
  uh1: string;
  plan: string;
  ok: string;
}

export const color: ColorType = {
  stopp: "#c01616",
  krit: "#cd500d",
  uh2: "#d7790f",
  uh1: "#e0c244",
  plan: "#40903B",
  ok: "#4ed0a9",
};

export const badSampleColor = "gray";

export const getYLimXLim = (
  data: GroupChartDataProps,
  measurementsToDisplay: keyof GroupChartDataProps,
  selectedTolerances: SelectedTolerancesType
) => {
  const yMin = data[measurementsToDisplay].reduce((p, c) =>
    c.y < p.y ? c : p
  ).y;
  const yMax = data[measurementsToDisplay].reduce((p, c) =>
    c.y > p.y ? c : p
  ).y;
  // Offset Minimum and maximum yScale values to always keep errorlines in view
  const currentSelectedTolerancesArray = Object.keys(selectedTolerances).filter(
    (fieldName) => {
      const keyName = fieldName as keyof SelectedTolerancesType;
      return selectedTolerances[keyName];
    }
  );

  const highestTolerance = currentSelectedTolerancesArray[
    currentSelectedTolerancesArray.length - 1
  ] as keyof SpeedClassToleranceType;
  let yMaxToleranceMargin;
  let yMinToleranceMargin;
  if (highestTolerance && measurementsToDisplay !== "longitudinalAngleHP") {
    let dataRefMax = 0;
    let dataRefMin = 0;
    if (measurementsToDisplay === "crossLevel") {
      dataRefMax = data[measurementsToDisplay].reduce((p, c) =>
        c.y > p.y ? c : p
      ).y;
      dataRefMin = data[measurementsToDisplay].reduce((p, c) =>
        c.y < p.y ? c : p
      ).y;
    }

    if (
      Math.abs(tolerances[measurementsToDisplay].H0[highestTolerance].up) ===
      Infinity
    ) {
      yMaxToleranceMargin =
        yMax > dataRefMax + tolerances[measurementsToDisplay].H0.krit.up
          ? yMax
          : dataRefMax + tolerances[measurementsToDisplay].H0.krit.up;
    } else {
      yMaxToleranceMargin =
        yMax >
        dataRefMax + tolerances[measurementsToDisplay].H0[highestTolerance].up
          ? yMax
          : dataRefMax +
            tolerances[measurementsToDisplay].H0[highestTolerance].up; // add some margin
    }

    if (
      Math.abs(tolerances[measurementsToDisplay].H0[highestTolerance].low) ===
      Infinity
    ) {
      yMinToleranceMargin =
        yMin < dataRefMin + tolerances[measurementsToDisplay].H0.krit.low
          ? yMin
          : dataRefMin + tolerances[measurementsToDisplay].H0.krit.low;
    } else {
      yMinToleranceMargin =
        yMin <
        dataRefMin + tolerances[measurementsToDisplay].H0[highestTolerance].low
          ? yMin
          : dataRefMin +
            tolerances[measurementsToDisplay].H0[highestTolerance].low; // add some margin
    }
  } else {
    yMinToleranceMargin = yMin;
    yMaxToleranceMargin = yMax;
  }
  return [yMinToleranceMargin, yMaxToleranceMargin];
};

export const getColorOffsets = (
  yScale: any,
  tolerance: SpeedClassType,
  speedClass: keyof SpeedClassType,
  chartDimensions: any
) => {
  return [
    { offset: "0%", color: color.stopp },
    {
      offset: yScale(tolerance[speedClass].stopp.up) / chartDimensions.height,
      color: color.stopp,
    },
    {
      offset: yScale(tolerance[speedClass].stopp.up) / chartDimensions.height,
      color: color.krit,
    },
    {
      offset: yScale(tolerance[speedClass].krit.up) / chartDimensions.height,
      color: color.krit,
    },
    {
      offset: yScale(tolerance[speedClass].krit.up) / chartDimensions.height,
      color: color.uh2,
    },
    {
      offset: yScale(tolerance[speedClass].uh2.up) / chartDimensions.height,
      color: color.uh2,
    },
    {
      offset: yScale(tolerance[speedClass].uh2.up) / chartDimensions.height,
      color: color.uh1,
    },
    {
      offset: yScale(tolerance[speedClass].uh1.up) / chartDimensions.height,
      color: color.uh1,
    },

    {
      offset: yScale(tolerance[speedClass].uh1.up) / chartDimensions.height,
      color: color.plan,
    },
    {
      offset: yScale(tolerance[speedClass].plan.up) / chartDimensions.height,
      color: color.plan,
    },

    {
      offset: yScale(tolerance[speedClass].plan.up) / chartDimensions.height,
      color: color.ok,
    },
    {
      offset: yScale(tolerance[speedClass].plan.low) / chartDimensions.height,
      color: color.ok,
    },

    {
      offset: yScale(tolerance[speedClass].plan.low) / chartDimensions.height,
      color: color.plan,
    },
    {
      offset: yScale(tolerance[speedClass].uh1.low) / chartDimensions.height,
      color: color.plan,
    },

    {
      offset: yScale(tolerance[speedClass].uh1.low) / chartDimensions.height,
      color: color.uh1,
    },
    {
      offset: yScale(tolerance[speedClass].uh2.low) / chartDimensions.height,
      color: color.uh1,
    },
    {
      offset: yScale(tolerance[speedClass].uh2.low) / chartDimensions.height,
      color: color.uh2,
    },
    {
      offset: yScale(tolerance[speedClass].krit.low) / chartDimensions.height,
      color: color.uh2,
    },
    {
      offset: yScale(tolerance[speedClass].krit.low) / chartDimensions.height,
      color: color.krit,
    },
    {
      offset: yScale(tolerance[speedClass].stopp.low) / chartDimensions.height,
      color: color.krit,
    },
    {
      offset: yScale(tolerance[speedClass].stopp.low) / chartDimensions.height,
      color: color.stopp,
    },
    { offset: "100%", color: color.stopp },
  ];
};

export const getMaskingOffsets = (
  chunks: {
    x1: number;
    x2: number;
    quality: boolean;
  }[],
  xScale: any,
  width: any
) => {
  const masking = "gray";
  const noMasking = "transparent";
  let chunk;

  const maskingAreasArray: any = [];

  for (let iter = 0; iter < chunks.length; iter++) {
    chunk = chunks[iter];

    if (iter === 0) {
      // Beginning of graph
      maskingAreasArray.push({
        offset: "0%",
        color: chunk.quality ? noMasking : masking,
      });
      maskingAreasArray.push({
        offset: (xScale(chunk.x2) - 30) / width,
        color: chunk.quality ? noMasking : masking,
      });
    } else if (iter === chunks.length - 1) {
      // End of graph
      maskingAreasArray.push({
        offset: (xScale(chunk.x1) - 30) / width,
        color: chunk.quality ? noMasking : masking,
      });
      maskingAreasArray.push({
        offset: "100%",
        color: chunk.quality ? noMasking : masking,
      });
    } else {
      // Samples that are not edge cases
      maskingAreasArray.push({
        offset: (xScale(chunk.x1) - 30) / width,
        color: chunk.quality ? noMasking : masking,
      });
      maskingAreasArray.push({
        offset: (xScale(chunk.x2) - 30) / width,
        color: chunk.quality ? noMasking : masking,
      });
    }
  }

  return maskingAreasArray;
};

export const measurementColors = {
  trackGauge: "darkred",
  crossLevel: "blue",
  crossLevelBIS: "gray",
  crossLevelUnevenness: "#3CC221",
  twist3m: "black",
  twist6m: "purple",
  longitudinalLevel: "green",
  alignment: "rgb(145, 118, 29)",
  longitudinalLevelLeft: "#516e96",
  alignmentLeft: "#c23845",
  longitudinalLevelRight: "#b85c00",
  alignmentRight: "#9e3ba3",
  longitudinalAngleHP: "green",
};

export function getMobileOperatingSystem() {
  const userAgent = navigator.userAgent || navigator.vendor; //|| window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android|Linux/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  // && !window.MSStream COMPLAINS
  if (/iPad|iPhone|iPod|Macintosh|MacIntel|MacPPC|Mac68K/i.test(userAgent)) {
    return "iOS";
  }

  return "unknown";
}

export const getNewChart = (chartID: string): plotState => {
  return {
    chartID: chartID,
    plotData: {
      trackGauge: [] as dataPointType[],
      crossLevel: [] as dataPointType[],
      crossLevelBIS: [] as dataPointType[],
      crossLevelUnevenness: [] as dataPointType[],
      twist6m: [] as dataPointType[],
      twist3m: [] as dataPointType[],
      longitudinalLevel: [] as dataPointType[],
      alignment: [] as dataPointType[],
      longitudinalLevelLeft: [] as dataPointType[],
      alignmentLeft: [] as dataPointType[],
      longitudinalLevelRight: [] as dataPointType[],
      alignmentRight: [] as dataPointType[],
      longitudinalAngleHP: [] as dataPointType[],
    },
    plotMetaData: {
      trackGauge: {
        endKm: 0,
        endMeter: 0,
        endTick: "0",
        startKm: 0,
        startMeter: 0,
        startTick: "0",
        totalKrit: 0,
        totalPlan: 0,
        totalStop: 0,
        totalUh1: 0,
        totalUh2: 0,
      },
      crossLevel: {
        endKm: 0,
        endMeter: 0,
        endTick: "0",
        startKm: 0,
        startMeter: 0,
        startTick: "0",
        totalKrit: 0,
        totalPlan: 0,
        totalStop: 0,
        totalUh1: 0,
        totalUh2: 0,
      },
      crossLevelBIS: {
        endKm: 0,
        endMeter: 0,
        endTick: "0",
        startKm: 0,
        startMeter: 0,
        startTick: "0",
        totalKrit: 0,
        totalPlan: 0,
        totalStop: 0,
        totalUh1: 0,
        totalUh2: 0,
      },
      crossLevelUnevenness: {
        endKm: 0,
        endMeter: 0,
        endTick: "0",
        startKm: 0,
        startMeter: 0,
        startTick: "0",
        totalKrit: 0,
        totalPlan: 0,
        totalStop: 0,
        totalUh1: 0,
        totalUh2: 0,
      },
      twist6m: {
        endKm: 0,
        endMeter: 0,
        endTick: "0",
        startKm: 0,
        startMeter: 0,
        startTick: "0",
        totalKrit: 0,
        totalPlan: 0,
        totalStop: 0,
        totalUh1: 0,
        totalUh2: 0,
      },
      twist3m: {
        endKm: 0,
        endMeter: 0,
        endTick: "0",
        startKm: 0,
        startMeter: 0,
        startTick: "0",
        totalKrit: 0,
        totalPlan: 0,
        totalStop: 0,
        totalUh1: 0,
        totalUh2: 0,
      },
      longitudinalLevel: {
        endKm: 0,
        endMeter: 0,
        endTick: "0",
        startKm: 0,
        startMeter: 0,
        startTick: "0",
        totalKrit: 0,
        totalPlan: 0,
        totalStop: 0,
        totalUh1: 0,
        totalUh2: 0,
      },
      alignment: {
        endKm: 0,
        endMeter: 0,
        endTick: "0",
        startKm: 0,
        startMeter: 0,
        startTick: "0",
        totalKrit: 0,
        totalPlan: 0,
        totalStop: 0,
        totalUh1: 0,
        totalUh2: 0,
      },
      longitudinalLevelLeft: {
        endKm: 0,
        endMeter: 0,
        endTick: "0",
        startKm: 0,
        startMeter: 0,
        startTick: "0",
        totalKrit: 0,
        totalPlan: 0,
        totalStop: 0,
        totalUh1: 0,
        totalUh2: 0,
      },
      alignmentLeft: {
        endKm: 0,
        endMeter: 0,
        endTick: "0",
        startKm: 0,
        startMeter: 0,
        startTick: "0",
        totalKrit: 0,
        totalPlan: 0,
        totalStop: 0,
        totalUh1: 0,
        totalUh2: 0,
      },
      longitudinalLevelRight: {
        endKm: 0,
        endMeter: 0,
        endTick: "0",
        startKm: 0,
        startMeter: 0,
        startTick: "0",
        totalKrit: 0,
        totalPlan: 0,
        totalStop: 0,
        totalUh1: 0,
        totalUh2: 0,
      },
      alignmentRight: {
        endKm: 0,
        endMeter: 0,
        endTick: "0",
        startKm: 0,
        startMeter: 0,
        startTick: "0",
        totalKrit: 0,
        totalPlan: 0,
        totalStop: 0,
        totalUh1: 0,
        totalUh2: 0,
      },
    },

    signalInterval: {
      xMaxOrig: 0,
      xMinOrig: 0,
      xMax: 0,
      xMin: 0,
      resolution: 0.5,
    },
    measurementToDisplay: [],
    tolerancesToDisplay: {
      plan: true,
      uh1: true,
      uh2: true,
      krit: true,
      stopp: true,
    },
    finishedLoading: true,
  };
};

export const getLongitudinalLevelChart = (
  chartID: string
): plotStateLongitudinalLevel => {
  return {
    chartID: chartID,
    plotData: {
      trackGauge: [] as dataPointType[],
      crossLevel: [] as dataPointType[],
      crossLevelBIS: [] as dataPointType[],
      crossLevelUnevenness: [] as dataPointType[],
      twist6m: [] as dataPointType[],
      twist3m: [] as dataPointType[],
      longitudinalLevel: [] as dataPointType[],
      alignment: [] as dataPointType[],
      longitudinalLevelLeft: [] as dataPointType[],
      alignmentLeft: [] as dataPointType[],
      longitudinalLevelRight: [] as dataPointType[],
      alignmentRight: [] as dataPointType[],
      longitudinalAngleHP: [] as dataPointType[],
    },
    plotMetaData: {
      trackGauge: {
        endKm: 0,
        endMeter: 0,
        endTick: "0",
        startKm: 0,
        startMeter: 0,
        startTick: "0",
        totalKrit: 0,
        totalPlan: 0,
        totalStop: 0,
        totalUh1: 0,
        totalUh2: 0,
      },
      crossLevel: {
        endKm: 0,
        endMeter: 0,
        endTick: "0",
        startKm: 0,
        startMeter: 0,
        startTick: "0",
        totalKrit: 0,
        totalPlan: 0,
        totalStop: 0,
        totalUh1: 0,
        totalUh2: 0,
      },
      crossLevelBIS: {
        endKm: 0,
        endMeter: 0,
        endTick: "0",
        startKm: 0,
        startMeter: 0,
        startTick: "0",
        totalKrit: 0,
        totalPlan: 0,
        totalStop: 0,
        totalUh1: 0,
        totalUh2: 0,
      },
      crossLevelUnevenness: {
        endKm: 0,
        endMeter: 0,
        endTick: "0",
        startKm: 0,
        startMeter: 0,
        startTick: "0",
        totalKrit: 0,
        totalPlan: 0,
        totalStop: 0,
        totalUh1: 0,
        totalUh2: 0,
      },
      twist6m: {
        endKm: 0,
        endMeter: 0,
        endTick: "0",
        startKm: 0,
        startMeter: 0,
        startTick: "0",
        totalKrit: 0,
        totalPlan: 0,
        totalStop: 0,
        totalUh1: 0,
        totalUh2: 0,
      },
      twist3m: {
        endKm: 0,
        endMeter: 0,
        endTick: "0",
        startKm: 0,
        startMeter: 0,
        startTick: "0",
        totalKrit: 0,
        totalPlan: 0,
        totalStop: 0,
        totalUh1: 0,
        totalUh2: 0,
      },
      longitudinalLevel: {
        endKm: 0,
        endMeter: 0,
        endTick: "0",
        startKm: 0,
        startMeter: 0,
        startTick: "0",
        totalKrit: 0,
        totalPlan: 0,
        totalStop: 0,
        totalUh1: 0,
        totalUh2: 0,
      },
      alignment: {
        endKm: 0,
        endMeter: 0,
        endTick: "0",
        startKm: 0,
        startMeter: 0,
        startTick: "0",
        totalKrit: 0,
        totalPlan: 0,
        totalStop: 0,
        totalUh1: 0,
        totalUh2: 0,
      },
      longitudinalLevelLeft: {
        endKm: 0,
        endMeter: 0,
        endTick: "0",
        startKm: 0,
        startMeter: 0,
        startTick: "0",
        totalKrit: 0,
        totalPlan: 0,
        totalStop: 0,
        totalUh1: 0,
        totalUh2: 0,
      },
      alignmentLeft: {
        endKm: 0,
        endMeter: 0,
        endTick: "0",
        startKm: 0,
        startMeter: 0,
        startTick: "0",
        totalKrit: 0,
        totalPlan: 0,
        totalStop: 0,
        totalUh1: 0,
        totalUh2: 0,
      },
      longitudinalLevelRight: {
        endKm: 0,
        endMeter: 0,
        endTick: "0",
        startKm: 0,
        startMeter: 0,
        startTick: "0",
        totalKrit: 0,
        totalPlan: 0,
        totalStop: 0,
        totalUh1: 0,
        totalUh2: 0,
      },
      alignmentRight: {
        endKm: 0,
        endMeter: 0,
        endTick: "0",
        startKm: 0,
        startMeter: 0,
        startTick: "0",
        totalKrit: 0,
        totalPlan: 0,
        totalStop: 0,
        totalUh1: 0,
        totalUh2: 0,
      },
    },
    signalInterval: {
      xMaxOrig: 0,
      xMinOrig: 0,
      xMax: 0,
      xMin: 0,
      resolution: 0.5,
    },
    measurementToDisplay: [],
    tolerancesToDisplay: {
      plan: true,
      uh1: true,
      uh2: true,
      krit: true,
      stopp: true,
    },
  };
};

export const setLastVisitedPlanAndMeasurement = (
  planID?: string,
  measurementID?: string
) => {
  try {
    sessionStorage.setItem("lastPlan", planID ? planID : "");
    sessionStorage.setItem(
      "lastMeasurement",
      measurementID ? measurementID : ""
    );
  } catch (error) {
    console.error("Failed To Save Path");
  }
};

export const getLastVisitedPlanAndMeasurement = () => {
  try {
    const lastPlan = sessionStorage.getItem("lastPlan");
    const lastMeasurement = sessionStorage.getItem("lastMeasurement");
    
    return {
      lastPlan: lastPlan !== "" ? lastPlan : undefined,
      lastMeasurement: lastMeasurement !== "" ? lastMeasurement : undefined,
    };
  } catch (error) {
    console.error("Failed To Get Path");
    return undefined;
  }
};

export const roleEn2swe = (
  t: TFunction<"translation", "translation">,
  role: string
) => {
  switch (role) {
    case roleType.admin:
      return t("common.admin");
    case roleType.manager:
      return t("common.manager");
    default:
      return t("common.operator");
  }
};

export function convertToHmiStatus(status: measurementStatus | undefined) {
  switch (status) {
    case measurementStatus.UploadingDone:
    case measurementStatus.Processing:
      return measurementStatusHMI.processing;

    case measurementStatus.ProcessingDone:
      return measurementStatusHMI.ProcessingDone;

    case measurementStatus.AdjustingDistance:
    case measurementStatus.Merging:
    case measurementStatus.MergingDone:
      return measurementStatusHMI.trafikverket;

    case measurementStatus.AdjustingDistanceDone:
      return measurementStatusHMI.trafikverketDone;

    case measurementStatus.WritingToDynamodb:
      return measurementStatusHMI.plot;

    case measurementStatus.WritingToDynamodbDone:
      return measurementStatusHMI.plotDone;

    case measurementStatus.CreatingExcel:
      return measurementStatusHMI.excel;

    case measurementStatus.CreatingExcelDone:
    case measurementStatus.CreatingCSV:
    case measurementStatus.CreatingCSVDone:
      return measurementStatusHMI.excelDone;

    case measurementStatus.SendingEmail:
      return measurementStatusHMI.email;

    case measurementStatus.SendingEmailDone:
      return measurementStatusHMI.emailDone;

    default:
      return measurementStatusHMI.start;
  }
}

export function convertPipelinesToHmiStatus(
  pipelines: pipelineProps[] | undefined
) {
  if (
    pipelines?.some(
      (pipeline) =>
        pipeline.state === processingState.email &&
        pipeline.status === processingStatus.done
    )
  ) {
    return measurementStatusHMI.emailDone;
  } else if (
    pipelines?.some(
      (pipeline) =>
        pipeline.state === processingState.email &&
        pipeline.status === processingStatus.processing
    )
  ) {
    return measurementStatusHMI.email;
  } else if (
    pipelines?.some(
      (pipeline) =>
        pipeline.state === processingState.excel &&
        pipeline.status === processingStatus.done
    )
  ) {
    return measurementStatusHMI.excelDone;
  } else if (
    pipelines?.some(
      (pipeline) =>
        pipeline.state === processingState.excel &&
        pipeline.status === processingStatus.processing
    )
  ) {
    return measurementStatusHMI.excel;
  } else if (
    pipelines?.some(
      (pipeline) =>
        pipeline.state === processingState.writingToDynamodb &&
        pipeline.status === processingStatus.done
    )
  ) {
    return measurementStatusHMI.plot;
  } else if (
    pipelines?.some(
      (pipeline) =>
        pipeline.state === processingState.integration &&
        pipeline.status === processingStatus.done
    )
  ) {
    return measurementStatusHMI.trafikverketDone;
  } else if (
    pipelines?.some(
      (pipeline) =>
        pipeline.state === processingState.integration &&
        pipeline.status === processingStatus.processing
    )
  ) {
    return measurementStatusHMI.trafikverket;
  } else if (
    pipelines?.some(
      (pipeline) =>
        pipeline.state === processingState.dataProcessing &&
        pipeline.status === processingStatus.done
    )
  ) {
    return measurementStatusHMI.ProcessingDone;
  } else if (
    pipelines?.some(
      (pipeline) =>
        pipeline.state === processingState.dataProcessing &&
        pipeline.status === processingStatus.processing
    )
  ) {
    return measurementStatusHMI.processing;
  } else {
    return measurementStatusHMI.start;
  }

  // return measurementStatusHMI.ProcessingDone;
  // return measurementStatusHMI.trafikverket;
  // return measurementStatusHMI.trafikverketDone;
  // return measurementStatusHMI.plot;
  // return measurementStatusHMI.plotDone;
  // return measurementStatusHMI.excel;
  // return measurementStatusHMI.excelDone;
  // return measurementStatusHMI.email;
  // return measurementStatusHMI.emailDone;
  // return measurementStatusHMI.start;

  // switch (status) {
  //   case measurementStatus.UploadingDone:
  //   case measurementStatus.Processing:
  //     return measurementStatusHMI.processing;

  //   case measurementStatus.ProcessingDone:
  //     return measurementStatusHMI.ProcessingDone;

  //   case measurementStatus.AdjustingDistance:
  //   case measurementStatus.Merging:
  //   case measurementStatus.MergingDone:
  //     return measurementStatusHMI.trafikverket;

  //   case measurementStatus.AdjustingDistanceDone:
  //     return measurementStatusHMI.trafikverketDone;

  //   case measurementStatus.WritingToDynamodb:
  //     return measurementStatusHMI.plot;

  //   case measurementStatus.WritingToDynamodbDone:
  //     return measurementStatusHMI.plotDone;

  //   case measurementStatus.CreatingExcel:
  //     return measurementStatusHMI.excel;

  //   case measurementStatus.CreatingExcelDone:
  //   case measurementStatus.CreatingCSV:
  //   case measurementStatus.CreatingCSVDone:
  //     return measurementStatusHMI.excelDone;

  //   case measurementStatus.SendingEmail:
  //     return measurementStatusHMI.email;

  //   case measurementStatus.SendingEmailDone:
  //     return measurementStatusHMI.emailDone;

  //   default:
  //     return measurementStatusHMI.start;
  // }
}

export function convertToHmiError(errorMsg: string | undefined | null): string {
  if (errorMsg === "" || errorMsg === undefined || errorMsg === null) {
    return "";
  } else {
    return errorMsg as string;
  }
}

export function translateFileProps(
  type: FileType,
  translator: TFunction<"translation", "translation">
) {
  if (type === "generic") {
    return translator("metaData.uploaded"); // "uppladdad";
  } else if (type === "csv") {
    return "csv";
  } else if (type === "processed_data") {
    return translator("metaData.processedData");
  } else if (type === "data_logs") {
    return "datalog";
  } else if (type === "excel") {
    return "excel";
  } else if (type === "projected_track") {
    return translator("metaData.projectedValues");
  }
}

export const setEmptySignal = () => {
  const emptryMetaData: signalMetaData = {
    endKm: 0,
    endMeter: 0,
    endTick: "",
    startKm: 0,
    startMeter: 0,
    startTick: "",
    totalKrit: 0,
    totalPlan: 0,
    totalStop: 0,
    totalUh1: 0,
    totalUh2: 0,
  };

  return emptryMetaData;
};
