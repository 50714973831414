import React, { useEffect } from "react";
import "./plan.css";

/* Redux Stuff*/
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../store/store";
import { AppState } from "../../../store/store";

import { useNavigate } from "react-router-dom";
import { cPhoneWidthLim } from "../../../app";
import { Toolbar } from "../../toolbar/toolbar";
import MeasurementList from "./measurementList/measurementList";
import PlanForm from "./planForm";
import * as plansAPI from "./../../../api/netRail/plans";
import { setAllPlans } from "../../../store/scheduler/schedulerSlice";
import { useTranslation } from "react-i18next";

const Plan: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const selectedPlan = useSelector(
    (state: AppState) => state.scheduler.selectedPlan
  );
  const plans = useSelector((state: AppState) => state.scheduler.plans);

  const plan = useSelector((state: AppState) =>
    state.scheduler.plans.find((plan) => plan.id === selectedPlan)
  );
  const width = useSelector((state: AppState) => state.scheduler.screenWidth);
  const height = useSelector((state: AppState) => state.scheduler.screenHeight);

  // FALLBACK SOLUTION IF PLAN NOT AVAILABLE DUE TO UNCONTROLLED BACKING/FORWARD BROWSER MOVEMENT
  const getRecoveredPlan = async () => {
    if (selectedPlan !== "-1") {
      const recoveredPlan = await plansAPI.getPlan(selectedPlan);

      dispatch(setAllPlans([...plans, recoveredPlan]));
    } else {
      // If we can't find a selected plan, push us back so scheduler
      navigate("/scheduler");
    }
  };
  useEffect(() => {
    if (plan === undefined) {
      getRecoveredPlan();
    }
  }, [plan]);

  if (plan) {
    return (
      <div className="PlanContainer">
        <div
          className="PlanContainer"
          style={
            width <= cPhoneWidthLim || height <= cPhoneWidthLim
              ? {}
              : { position: "relative", left: "44px", paddingRight: "44px" }
          }
        >
          <div className="PlanToolbar">
            <Toolbar>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                }}
              >
                <button
                  className="NavigateBackButton"
                  onClick={() => {
                    navigate("/scheduler");
                  }}
                >
                  <img src="../../icons/back.svg" alt="" />
                </button>
                <h5>{t("common.plan")}</h5>
              </div>
            </Toolbar>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              overflow: "auto",
              width: "100%",
              alignItems: "center",
              paddingBottom: "50px",
            }}
          >
            <div className="PlanContainerChild">
              <PlanForm plan={plan} />
            </div>
            <div className="PlanContainerChild">
              <MeasurementList plan={plan} />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="EmptyPlan">
        {/* <h1>{t("plan.createPlanToShow")}</h1> */}
      </div>
    );
  }
};

export default Plan;
