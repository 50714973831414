import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import DescriptiveIcon from "./DescriptiveIcon";
import "./planListItem.css";

import {
  getNumberOfMeasurements,
  // getPlanStatus,
} from "../../../store/scheduler/helpers";
/* Types */
import { planProps, planStatus } from "../../../store/scheduler/types";
import { helpInformationPath } from "../../../store/user/types";
import {
  setInformationRequest,
} from "../../../store/user/userSlice";

/* Redux Stuff*/
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../store/store";
import { useNavigate } from "react-router-dom";
import { setLastVisitedPlanAndMeasurement } from "../../../helpers/genericHelpers";
import { AppState } from "../../../store/store";
import { addNewPlan, downloadRepeatabilityValidationResult, runRepeatabilityValidation, setSelectedPlan } from "../../../store/scheduler/schedulerSlice";
import { useTranslation } from "react-i18next";

interface PlanListItemProps {
  plan: planProps;
  onlySelectPlan?: boolean;
}

interface AddNewPlanParams {
  prefilledPlan?: planProps;
}

const PlanListItem: React.FC<PlanListItemProps> = ({
  plan,
  onlySelectPlan,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showPopup, setShowPopup] = useState(false);
  const [selectedMeasurements, setSelectedMeasurements] = useState<string[]>([]);

  const prepareNewPlanPayload = (plan: planProps): AddNewPlanParams => {
    return {
      prefilledPlan: {
        ...plan,
        measurements: []
      }
    };
  };

  const informationRequest = useSelector(
    (state: AppState) => state.user.informationRequest
  );


  const copyPlan = () => {
    const payload = prepareNewPlanPayload(plan);
    dispatch(addNewPlan(payload));
  };

  const dispatch = useAppDispatch();
  const selectedPlan = useSelector(
    (state: AppState) => state.scheduler.selectedPlan
  );

  const trackPart = () => {
    return plan.trackPart ? plan.trackPart : "-";
  };

  const renderPlanInfo = () => {
    return (
      <div className="PlanInfoContainer">
        <div className="PlanItemWrapper">
          <div className="PlanItemTrack">
            <DescriptiveIcon
              data={
                // trackNumber() + "/" +
                trackPart()
              }
              icon={"./icons/rail.svg"}
            />
          </div>
          <div className="PlanItemNumberMeasurements">
            <DescriptiveIcon
              data={getNumberOfMeasurements(plan, plan.numberMeasurements)}
              icon={""}
            />
          </div>
          <div className="PlanItemStatus">
            <DescriptiveIcon
              data={t(
                "common.planStatus." +
                (plan.status.toString() === "in-progress"
                  ? planStatus.inProgress.toString()
                  : plan.status.toString())
              ).toString()}
              icon={"./icons/status.svg"}
            />
          </div>
        </div>
      </div>
    );
  };

  const [selectedMachineID, setSelectedMachineID] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const renderRepeatabilityValidation = () => {
    if (!plan.isRepeatabilityValidation) return null; // Only show button if enabled

    const waitingForRepeatabilityFile = useSelector(
      (state: AppState) => state.scheduler.waitingForRepeatabilityFiles[plan.id] || false
    );

    const handleGenerateButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      if (!waitingForRepeatabilityFile) {
        setShowPopup(true);
      }
    };

    const handleDownloadButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      dispatch(downloadRepeatabilityValidationResult({ plan }));
    };

    const handleSubmit = () => {
      if (selectedMeasurements.length > 0) {
        dispatch(runRepeatabilityValidation({ t, plan, measurements: selectedMeasurements }));
        setShowPopup(false);
        setSelectedMeasurements([]);
      }
    };

    const toggleMeasurementSelection = (measurement) => {
      if (!selectedMachineID) {
        setSelectedMachineID(measurement.machineID);
      } else if (measurement.machineID !== selectedMachineID) {
        setErrorMessage(t("common.machineMismatchError"));
        return;
      }

      setSelectedMeasurements(prevSelected =>
        prevSelected.includes(measurement.id)
          ? prevSelected.filter(id => id !== measurement.id)
          : [...prevSelected, measurement.id]
      );

      if (selectedMeasurements.length === 1 && selectedMeasurements.includes(measurement.id)) {
        setSelectedMachineID(null);
      }
    };

    const extractTime = (dateString: string | null): string | null => {
      if (!dateString) return null;

      const timePart = dateString.split("T")[1]; // "HH:mm:ss.000z"
      return timePart ? timePart.substring(0, 5) : null; // "HH:mm"
    };

    const renderGenerateValidationText = () => {
      if (waitingForRepeatabilityFile) {
        return t("common.repeatabilityValidationInProgress");
      } else {
        return t("common.startRepeatabilityValidation");
      }
    };

    const clickOverlay = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setShowPopup(false);
      setSelectedMeasurements([]);
      setSelectedMachineID(null);
      setErrorMessage(null);
    };

    return (
      <div style={{ display: "flex", flexDirection: "column", width: "100%", gridArea: "repeatability" }}>
        <span style={{ flexDirection: "row" }}>
          <button className="BUTTON" style={{ margin: "2px 10px" }} onClick={handleGenerateButtonClick}>{renderGenerateValidationText()}</button>
          <button className="BUTTON" style={{ margin: "2px 10px", backgroundColor: !plan.hasRepeatabilityReport ? "lightgray" : "" }} disabled={!plan.hasRepeatabilityReport} onClick={handleDownloadButtonClick}>{t("common.repeatabilityValidation")}</button>
        </span>
        {showPopup &&
          ReactDOM.createPortal(
            <div className="validation-overlay" onClick={clickOverlay}>
              <div className="validation-content" onClick={(e) => e.stopPropagation()}>
                <span style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                  <h3>{t("common.selectMeasurement")}</h3>
                  <span
                    className="k-icon k-font-icon k-i-information informationButton"
                    style={{
                      marginLeft: "10px",
                    }}
                    onClick={() => {
                      dispatch(
                        setInformationRequest({
                          path: helpInformationPath.ValidationMeasurments,
                          showInformation:
                            informationRequest?.path ===
                              helpInformationPath.ValidationMeasurments
                              ? !informationRequest?.showInformation
                              : true,
                        })
                      );
                    }}
                  />

                </span>
                <div className="validation-measurement-list" style={{ maxHeight: "60vh", overflowY: "auto" }}>
                  {[...plan.measurements]
                    .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
                    .map((measurement, index) => {

                      const startTime = extractTime(measurement.createdAt);
                      const endTime = extractTime(measurement.doneAt);

                      let timeRange = "";
                      if (startTime && endTime) {
                        timeRange = `${startTime} - ${endTime}`;
                      } else if (startTime) {
                        timeRange = startTime;
                      } else if (endTime) {
                        timeRange = endTime;
                      }

                      const isSelected = selectedMeasurements.includes(measurement.id);
                      const isDisabled = selectedMachineID !== null && measurement.machineID !== selectedMachineID;

                      return (
                        <button
                          key={index}
                          className={`validation-measurement-button ${isSelected ? "selected" : ""} ${isDisabled ? "disabled" : ""}`}
                          onClick={() => !isDisabled && toggleMeasurementSelection(measurement)}
                          disabled={isDisabled}
                        >
                          {timeRange} {measurement.reprocessed && `(R${measurement.reprocessCnt})`}
                          <span className="validation-measurement-date">{measurement.createdAt.split("T")[0]}</span>
                        </button>
                      );
                    })}
                </div>

                <div className={"validation-buttons"}>
                  <button
                    className="BUTTON"
                    onClick={handleSubmit}
                    disabled={selectedMeasurements.length === 0}
                  >
                    {t("support.submit")}
                  </button>
                  <button className="BUTTON" onClick={() => setShowPopup(false)}>
                    {t("menu.closeButton")}
                  </button>
                </div>
              </div>
            </div>,
            document.getElementById("portal-root")
          )}
      </div>
    );
  };


  const renderPlanName = () => {
    return plan.name !== "" ? plan.name : "";
  };

  const renderFromToText = () => {
    return plan.pathTo && plan.pathFrom ? (
      <div
        style={{
          fontSize: "12px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          alignContent: "flex-start",
          flexWrap: "wrap",
        }}
      >
        {plan.pathFrom + " "}{" "}
        <img
          className="fromToArrowSvg"
          src="./icons/fromToArrow.svg"
          alt="from to arrow"
        />{" "}
        {" " + plan.pathTo}
      </div>
    ) : (
      <div
        style={{
          fontSize: "12px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflowWrap: "anywhere",
        }}
      >
        {plan.place}
      </div>
    );
  };

  return (
    <button
      className={"PlanListButton"}
      style={{
        border:
          selectedPlan === plan.id ? "solid var(--primary-color,#782441)" : "",
      }}
      onClick={(e) => {
        dispatch(setSelectedPlan(plan.id));
        setLastVisitedPlanAndMeasurement(plan.id, undefined);
        if (onlySelectPlan === undefined || !onlySelectPlan) {
          navigate("/scheduler/plan");
        }
      }}
    >
      <div className="PlanListItem">
        <div className="PlanItemPlace subtitle">
          {renderPlanName()}
          {renderFromToText()}
        </div>
        <div className="PlanItemInfoContainer">
          <div className="PlanItemInfo">
            <div className="PlanItemDateCreated caption">
              {new Date(plan.createdAt).getFullYear() +
                "-" +
                (new Date(plan.createdAt).getMonth() + 1 < 10
                  ? "0" + (new Date(plan.createdAt).getMonth() + 1)
                  : new Date(plan.createdAt).getMonth() + 1) +
                "-" +
                (new Date(plan.createdAt).getDate() < 10
                  ? "0" + new Date(plan.createdAt).getDate()
                  : new Date(plan.createdAt).getDate())}
            </div>
            <div className="PlanItemDoneAt caption">
              {new Date(plan.scheduledAt).getFullYear() +
                "-" +
                (new Date(plan.scheduledAt).getMonth() + 1 < 10
                  ? "0" + (new Date(plan.scheduledAt).getMonth() + 1)
                  : new Date(plan.scheduledAt).getMonth() + 1) +
                "-" +
                (new Date(plan.scheduledAt).getDate() < 10
                  ? "0" + new Date(plan.scheduledAt).getDate()
                  : new Date(plan.scheduledAt).getDate())}
            </div>
          </div>
          {onlySelectPlan === undefined || !onlySelectPlan ? (
            <span
              className="k-icon k-font-icon k-i-copy CopyButton"
              style={{ fontSize: "24px", borderRadius: "3px", padding: "15px" }}
              onClick={(e) => {
                e.stopPropagation();
                copyPlan();
              }}
            />
          ) : null}
        </div>
        {renderPlanInfo()}
        {plan.isRepeatabilityValidation && plan.measurements.length > 1 && renderRepeatabilityValidation()}
      </div>
    </button>
  );
};

export default PlanListItem;
