// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GroupChartContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: visible;
}

.GroupChartContainerRS {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: none;
}
/* 
.removeChart {
  color: black;
  background-color: var(--primary-color);
}

.removeChart:hover {
  background-color: black;
  color: var(--primary-color);
  border-radius: 6px;
} */
`, "",{"version":3,"sources":["webpack://./src/components/visualization/groupChartContainer.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,cAAc;AAChB;AACA;;;;;;;;;;GAUG","sourcesContent":[".GroupChartContainer {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  overflow: visible;\n}\n\n.GroupChartContainerRS {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  overflow: none;\n}\n/* \n.removeChart {\n  color: black;\n  background-color: var(--primary-color);\n}\n\n.removeChart:hover {\n  background-color: black;\n  color: var(--primary-color);\n  border-radius: 6px;\n} */\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
