import {
  planProps,
  requestMetaData,
  planSorterTypes,
  planStatus,
  pipelineProps,
} from "./../../store/scheduler/types";
import rest from "./../../rest";
import { useAppDispatch } from "../../store/store";
import { setNotification } from "../../store/user/userSlice";
import { copyFileSync } from "fs";
import * as pipelinesAPI from "../netRail/pipelines";

const buildQuery = (
  textFilterValue?: string,
  filterStatus?: string, // planStatus,
  assigneeID?: string,
  machineID?: string
) => {
  const isNumber = new RegExp("^[0-9]*$");

  let filterQuery: any = {};

  let andQuery: any = [];
  if (textFilterValue !== undefined)
    andQuery = andQuery.concat({
      $or: [
        { name: { $regex: `(?i)(${textFilterValue})` } }, // Case sensitivity off: (?i)
        { place: { $regex: `(?i)(${textFilterValue})` } },
        // { trackNumber: { $regex: `(?i)(${textFilterValue})` } },
        {
          // REGEX WON'T WORK ON NUMBERS
          trackPart:
            textFilterValue && isNumber.test(textFilterValue)
              ? +textFilterValue
              : undefined,
        },
      ],
    });

  // If no status is selected, get all statuses EXCEPT Archived ones.
  // Only return archived if they are requested.
  andQuery = andQuery.concat(
    filterStatus === undefined
      ? { status: { $ne: planStatus.archived } }
      : { status: filterStatus }
  );

  if (assigneeID)
    andQuery = andQuery.concat({
      $or: [
        {
          userAssignees: {
            $elemMatch: [assigneeID],
          },
        },
        { creatorID: assigneeID },
      ],
    });

  if (machineID)
    andQuery = andQuery.concat({
      machineAssignees: {
        $elemMatch: [machineID],
      },
    });

  if (andQuery.some((qry: any) => qry !== undefined)) {
    filterQuery = { $and: andQuery };
  }

  return filterQuery;
};

export const getPlans = (
  textFilterValue?: string,
  filterStatus?: any, // planStatus,
  assigneeID?: string,
  machineID?: string,
  sort?: planSorterTypes,
  limit?: number,
  page?: number,
  offset?: number
) => {
  return new Promise<[planProps[], requestMetaData]>(
    async (resolve, reject) => {
      const filterQuery = buildQuery(
        textFilterValue,
        filterStatus, // planStatus,
        assigneeID,
        machineID
      );
      rest
        .get(`/plans`, {
          params: {
            sort, // NUMBER CREATED MEASUREMENTS RELATES TO THE DESIRED MEASUREMENTS, NOT TO THE AMOUNT OF PERFORMED MEASUREMENTS
            filter: JSON.stringify(filterQuery),
            limit,
            page,
            offset,
          },
        })
        .then(async (response) => {
          const plans: planProps[] = response.data.list;
          const metaData: requestMetaData = response.data.meta;
          resolve([plans, metaData]);
        })
        .then(() => void 0)
        .catch((error) => {
          const err = `Failed to fetch plans from server: ${error}`;
          console.error(err);
          reject(err);
        });
    }
  );
};

export const getPlan = (planID: string) => {
  return new Promise<planProps>(async (resolve, reject) => {
    rest
      .get(`/plans/` + planID)
      .then(async (response) => {
        const plan: planProps = response.data;
        resolve(plan);
      })
      .catch((error) => {
        const err = `Failed to fetch plan from server: ${error}`;
        console.error(err);
      });
  });
};

export const patchPlan = (plan: planProps, nbrErrorPlan?: number) => {
  return new Promise<planProps>(async (resolve, reject) => {
    // Prepare for patch:
    const {
      id,
      creatorID,
      createdAt,
      measurements,
      updatedAt,
      sentToServer,
      doneAt,
      files,
      projectedTrackFile,
      ...planToPatch
    } = plan;
    // Do the patch
    rest
      .patch("/plans/" + plan.id, planToPatch, {
        withCredentials: true,
      })
      .then((fulfill) => {
        resolve(fulfill.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const postPlan = (plan: planProps, nbrErrorPlan?: number) => {
  return new Promise<planProps>(async (resolve, reject) => {
    const dispatch = useAppDispatch();

    // Do the post
    rest
      .post("/plans", plan, { withCredentials: true })
      .then((fulfill) => {
        const serverPlan: planProps = fulfill.data;

        resolve(serverPlan);
      })
      .catch((error) => {
        console.error("fail to upload plan: ", error);
        if (error.message === "Network Error" && nbrErrorPlan === 0) {
          dispatch(
            setNotification({
              style: "error",
              message:
                "Internetanslutningen förlorad. Nuvarande mätningen laddas upp \nnär en annan fil laddas upp eller IPC:n startas om.",
              open: true,
            })
          );
        } else {
          if (nbrErrorPlan ? nbrErrorPlan < 1 : false) {
            alert(
              "Ett oväntat fel har inträffat. Plan kunde inte skickas till servern."
            );
          }
        }
        reject(error);
      });
  });
};

export const deletePlan = (planID: string) => {
  return new Promise<void>(async (resolve, reject) => {
    rest
      .delete("/plans/" + planID, { withCredentials: true })
      .then((response) => {
        resolve();
      })
      .catch((error) => {
        reject(error);
        if (error.message === "Network Error") {
          console.error(error);
          alert("Ett fel har inträffat. Kontrollera din nätverksanslutning.");
        } else if (error.message === "Request failed with status code 401") {
          alert("Du har inte behörighet att radera denna planen.");
        } else {
          alert("Ett oväntat fel har inträffat.");
        }
      });
  });
};

export const putPlan = (plan: planProps, nbrErrorPlan?: number) => {
  return new Promise<planProps>(async (resolve, reject) => {
    const dispatch = useAppDispatch();

    // Do the post
    rest
      .put("/plans/" + plan.id, plan, { withCredentials: true })
      .then((fulfill) => {
        resolve({ ...plan, sentToServer: true });
      })
      .catch((error) => {
        console.error("fail to upload plan: ", error);
        if (error.message === "Network Error" && nbrErrorPlan === 0) {
          dispatch(
            setNotification({
              style: "error",
              message:
                "Internetanslutningen förlorad. Nuvarande mätningen laddas upp \nnär en annan fil laddas upp eller IPC:n startas om.",
              open: true,
            })
          );
        } else {
          if (nbrErrorPlan ? nbrErrorPlan < 1 : false) {
            alert(
              "Ett oväntat fel har inträffat. Plan kunde inte skickas till servern."
            );
          }
        }
        reject(error);
      });
  });
};
