import React, { useEffect } from "react";
import "./planListHeader.css";

/*Redux stuff */
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../store/store";
import { useNavigate } from "react-router-dom";
import { AppState } from "../../../store/store";
import { planStatus } from "../../../store/scheduler/types";
import {
  addNewPlan,
  setNewPlanSelected,
  setPlanSorterType,
} from "./../../../store/scheduler/schedulerSlice";
import { useTranslation } from "react-i18next";
import {
  planStatustoTranslation,
  translationToPlanStatus,
} from "../../../store/scheduler/helpers";

interface PlanListHeaderProps {
  textFilterName: string;
  filterOperator: string;
  filterMachine: string;
  setTextFilterName: React.Dispatch<React.SetStateAction<string>>;
  setFilterOperator: React.Dispatch<React.SetStateAction<string>>;
  setFilterMachine: React.Dispatch<React.SetStateAction<string>>;
  setFilterStatus?: React.Dispatch<
    React.SetStateAction<planStatus | undefined>
  >;
  filterStatus?: planStatus | undefined;
  limit: React.MutableRefObject<number>;
  setPage: React.Dispatch<React.SetStateAction<number>>; //React.MutableRefObject<number>;
  onlySelectPlan?: boolean;
}

const PlanListHeader: React.FC<PlanListHeaderProps> = ({
  textFilterName,
  filterOperator,
  filterMachine,
  filterStatus,
  setTextFilterName,
  setFilterOperator,
  setFilterMachine,
  setFilterStatus,
  limit,
  setPage,
  onlySelectPlan,
}) => {
  const { t } = useTranslation();
  const scheduler = useSelector((state: AppState) => state.scheduler);
  const newPlanSelected = useSelector(
    (state: AppState) => state.scheduler.newPlanSelected
  );
  const dispatch = useAppDispatch();
  const planListSorterType = scheduler.planSorterType;
  const navigate = useNavigate();
  const allCompanyUsers = useSelector(
    (state: AppState) => state.user.allCompanyUsers
  );

  const allMachines = useSelector(
    (state: AppState) => state.machine.allMachines
  );

  useEffect(() => {
    if (newPlanSelected) {
      navigate("/scheduler/plan");
      dispatch(setNewPlanSelected(false));
    }
  }, [newPlanSelected]);

  return (
    <div className="centerCardPlan">
      <div className="PlanListHeader">
        <div className="PlanListHeaderUpper">
          <Input
            style={{
              width: "100%",
              fontWeight: "bold",
              fontSize: "14px",
              marginTop: "5px",
            }}
            placeholder={t("planListHeader.searchForPlan").toString()}
            value={textFilterName}
            onChange={(e) => {
              // e.stopPropagation();
              e.nativeEvent.preventDefault();
              setPage(1);
              setTextFilterName(e.target.value as string);
            }}
          />
        </div>
        <div className="PlanListHeaderMiddle">
          <div className="PlanHeader8">
            <DropDownList
              dir="ltr"
              style={{
                minWidth: "100px",
                width: "100%",
                fontSize: "14px",
                marginTop: "1px",
                opacity:
                  filterOperator === t("common.operators").toString()
                    ? 0.75
                    : 1,
                color: "black",
              }}
              popupSettings={{ width: "200px" }}
              defaultItem={t("common.operators").toString()}
              value={filterOperator}
              data={allCompanyUsers.map(
                (user) =>
                  user.firstName +
                  " " +
                  user.lastName +
                  " | " +
                  "<" +
                  user.email +
                  ">"
              )}
              onChange={(dataItem) => {
                dataItem.nativeEvent.stopPropagation();
                dataItem.nativeEvent.preventDefault();
                setPage(1);
                let email: string;
                // extract the email from item
                if (dataItem.target.value.includes("<")) {
                  email = dataItem.target.value
                    .toString()
                    .substring(
                      dataItem.target.value.toString().indexOf("<") + 1,
                      dataItem.target.value.toString().indexOf(">")
                    );
                } else {
                  email = dataItem.target.value;
                }

                setFilterOperator(email);
              }}
            />
          </div>

          <div className="PlanHeader9">
            <DropDownList
              dir="ltr"
              style={{
                minWidth: "100px",
                width: "100%",
                fontSize: "14px",
                marginTop: "1px",
                opacity: filterStatus === undefined ? 0.75 : 1,
                color: "black",
                paddingRight: "5px",
                paddingLeft: "5px",
              }}
              popupSettings={{ width: "200px" }}
              defaultItem={t("common.status").toString()}
              data={[
                planStatustoTranslation(planStatus.awaiting, t),
                planStatustoTranslation(planStatus.canceled, t),
                planStatustoTranslation(planStatus.done, t),
                planStatustoTranslation(planStatus.inProgress, t),
                planStatustoTranslation(planStatus.archived, t),
              ]}
              onChange={(e) => {
                e.nativeEvent.stopPropagation();
                e.nativeEvent.preventDefault();
                setPage(1);
                if (setFilterStatus) {
                  if (e.target.value === t("common.status").toString()) {
                    setFilterStatus(undefined);
                  } else {
                    setFilterStatus(translationToPlanStatus(e.target.value, t));
                  }
                }
              }}
            />
          </div>

          <div className="PlanHeader10">
            <DropDownList
              dir="ltr"
              style={{
                minWidth: "100px",
                width: "100%",
                fontSize: "14px",
                marginTop: "1px",
                opacity:
                  filterMachine === t("common.machines").toString() ? 0.75 : 1,
                color: "black",
              }}
              popupSettings={{ width: "200px" }}
              defaultItem={t("common.machines").toString()}
              value={filterMachine}
              data={allMachines.map((machine) => machine.name)}
              onChange={(dataItem) => {
                dataItem.nativeEvent.stopPropagation();
                dataItem.nativeEvent.preventDefault();
                setPage(1);
                setFilterMachine(dataItem.target.value);
              }}
            />
          </div>
        </div>
        <div className="PlanListHeaderLower">
          <div className="PlanHeader4 SmallPadding SortButton">
            <div className="PlanHeaderDateCreated">
              <button
                className="caption SmallPadding SortButton"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();

                  planListSorterType === "createdAt"
                    ? dispatch(setPlanSorterType("-createdAt"))
                    : dispatch(setPlanSorterType("createdAt"));
                }}
              >
                <img
                  className={
                    planListSorterType === "createdAt"
                      ? ""
                      : planListSorterType === "-createdAt"
                      ? "Rotate180"
                      : "Hide"
                  }
                  src="icons/sortDown.svg"
                  alt=""
                />
                {t("planListHeader.createdAt")}
              </button>
            </div>

            <div className="PlanHeaderDoneAt">
              <button
                className="caption SmallPadding SortButton"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  planListSorterType === "scheduledAt"
                    ? dispatch(setPlanSorterType("-scheduledAt"))
                    : dispatch(setPlanSorterType("scheduledAt"));
                }}
              >
                <img
                  className={
                    planListSorterType === "scheduledAt"
                      ? ""
                      : planListSorterType === "-scheduledAt"
                      ? "Rotate180"
                      : "Hide"
                  }
                  src="icons/sortDown.svg"
                  alt=""
                />
                {t("planListHeader.scheduledAt")}
              </button>
            </div>
          </div>
          <button
            className="PlanHeader5 SmallPadding SortButton"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              planListSorterType === "numberMeasurements"
                ? dispatch(setPlanSorterType("-numberMeasurements"))
                : dispatch(setPlanSorterType("numberMeasurements"));
            }}
          >
            <div className="SortOnPlanItemIconComposition">
              <img
                className={
                  planListSorterType === "numberMeasurements"
                    ? ""
                    : planListSorterType === "-numberMeasurements"
                    ? "Rotate180"
                    : "Hide"
                }
                src="./icons/sortDown.svg"
                alt=""
                style={{ visibility: "hidden" }}
              />
              <span className="k-icon k-font-icon k-i-graph" style={{ color: "black" }} />
              <img
                className={
                  planListSorterType === "numberMeasurements"
                    ? ""
                    : planListSorterType === "-numberMeasurements"
                    ? "Rotate180"
                    : "Hide"
                }
                src="./icons/sortDown.svg"
                alt=""
              />
            </div>
            <div className="caption">
              {t("planListHeader.numberMeasurements")}
            </div>
          </button>

          <button
            className="PlanHeader6 SmallPadding SortButton"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              planListSorterType === "-status"
                ? dispatch(setPlanSorterType("status"))
                : dispatch(setPlanSorterType("-status"));
            }}
          >
            <div className="SortOnPlanItemIconComposition">
              <img
                className={
                  planListSorterType === "-status"
                    ? ""
                    : planListSorterType === "status"
                    ? "Rotate180"
                    : "Hide"
                }
                src="./icons/sortDown.svg"
                alt=""
              />
              <img src="./icons/status.svg" alt="" />
              <img
                className={
                  planListSorterType === "-status"
                    ? ""
                    : planListSorterType === "status"
                    ? "Rotate180"
                    : "Hide"
                }
                src="./icons/sortDown.svg"
                alt=""
                style={{ visibility: "hidden" }}
              />
            </div>
            <div className="caption">{t("common.status")}</div>
          </button>
        </div>
      </div>

      {!onlySelectPlan || onlySelectPlan === undefined ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            paddingTop: "10px",
            paddingBottom: "10px",
            justifyContent: "center",
          }}
        >
          <button
            className="AddPlanButton"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              dispatch(addNewPlan({}));
            }}
          >
            <img src="./icons/plus.svg" alt="" className="AddPlanImage" />
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default PlanListHeader;
