import rest from "./../../rest";
import { FileProps } from "../../store/scheduler/types";
import { getMobileOperatingSystem } from "../../helpers/genericHelpers";
interface presignedURLDataType {
  presignedURL: string;
  fileKey: string;
}

export const getUploadURL = (
  name: string,
  type: string,
  size: number,
  ownerType: string,
  ownerID: string,
  partType: string,
  part?: number
) => {
  return new Promise<presignedURLDataType>(async (resolve, reject) => {
    rest
      .post("/files/uploadURL", {
        // It is a POST request
        name: name,
        type: type,
        size: size,
        ownerID: ownerID,
        ownerType: ownerType,
        partType: partType,
        part: part,
      })
      .then((res) => {
        const presignedURL: string = res.data.presignedURL;
        const fileKey: string = res.data.fileKey;
        resolve({ presignedURL, fileKey });
      })
      .catch((err) => reject(err));
  });
};

export const downloadFile = (file: FileProps) => {
  rest
    .get("/files/" + file.id + "/downloadURL", {
      withCredentials: true,
    })
    .then(async (result) => {
      const link = document.createElement("a");
      // link.href = result.data.presignedURL; // The URL
      // link.setAttribute("download", file.name); //or any other extension
      // document.body.appendChild(link);
      // link.click();
      // link.remove();

      const response = await fetch(result.data.presignedURL);
      const data = await response.blob();
      const url = window.URL.createObjectURL(new Blob([data]));

      link.href = url;
      link.setAttribute("download", file.name);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      document.body.removeChild(link);
    })
    .catch((err) => {
      alert("Kunde inte hitta filen " + file.name + " på servern");
      console.error(err);
    });
};

export const getFiles = (measurementID: string) => {
  return new Promise<FileProps[]>(async (resolve, reject) => {
    rest
      .get("/files", {
        params: {
          filter: { ownerID: measurementID },
        },
        withCredentials: true,
      })
      .then((res) => {
        resolve(res.data.list);
      })
      .catch((err) => reject(err));
  });
};

export const deleteFile = (fileID: string) => {
  return new Promise(async (resolve, reject) => {
    rest
      .delete("/files/" + fileID, { withCredentials: true })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  });
};

export const downloadManual = () => {
  return new Promise(async (resolve, reject) => {
    const OS = getMobileOperatingSystem();
    const windowReference = window.open();
    rest
      .get("/files/manual/downloadURL", {
        withCredentials: true,
      })
      .then((result) => {
        const link = document.createElement("a");
        link.href = result.data.presignedURL; // The URL
        if (windowReference) windowReference.location = link.href;

        link.remove();
        resolve(result);
      })
      .catch((err) => {
        // alert("Kunde inte hitta filen TMS_MANUAL på servern");
        console.error(err);
        reject(err);
      });
  });
};
