// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DashboardMainSignals {
  position: relative;
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  width: 100%;
  height: fit-content;
  overflow-y: visible;
}

.DashboardMainSignalsLL {
  position: relative;
  display: flex;
  flex-direction: column;
  /* padding-top: 10px; */
  width: 100%;
  height: fit-content;
  overflow-y: visible;
  /* row-gap: 20px; */
  /* margin-top: 20px; */
  /* padding-bottom: 20px; */
  /* background-color: rgba(133, 126, 128, 0.1); */
  /* box-shadow: 0px 3px 6px 0 rgba(0, 0, 0, 0.2); */
}
`, "",{"version":3,"sources":["webpack://./src/components/visualization/chartList.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,WAAW;EACX,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,WAAW;EACX,mBAAmB;EACnB,mBAAmB;EACnB,mBAAmB;EACnB,sBAAsB;EACtB,0BAA0B;EAC1B,gDAAgD;EAChD,kDAAkD;AACpD","sourcesContent":[".DashboardMainSignals {\n  position: relative;\n  display: flex;\n  flex-direction: row;\n  padding-top: 20px;\n  width: 100%;\n  height: fit-content;\n  overflow-y: visible;\n}\n\n.DashboardMainSignalsLL {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  /* padding-top: 10px; */\n  width: 100%;\n  height: fit-content;\n  overflow-y: visible;\n  /* row-gap: 20px; */\n  /* margin-top: 20px; */\n  /* padding-bottom: 20px; */\n  /* background-color: rgba(133, 126, 128, 0.1); */\n  /* box-shadow: 0px 3px 6px 0 rgba(0, 0, 0, 0.2); */\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
