// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MeasurementListContainer {
  width: 100%;
  margin-top: 10px;
}

.MeasurementListWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.MeasurementsHeading {
  color: var(--primary-color);
}

.MeasurementsForm {
  margin-top: 10px;
  margin-bottom: 30px;
  padding: 20px 20px;
  width: 100%;
  height: 100%;
  background-color: white;
  box-shadow: 0px 3px 6px 0 rgba(0, 0, 0, 0.26);
}

.ArrowImgUp {
  transform: scaleY(-1);
}

.MeasurementCounter {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  font-size: 20px;
  color: black;
}
`, "",{"version":3,"sources":["webpack://./src/components/scheduler/plan/measurementList/measurementList.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,6CAA6C;AAC/C;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,YAAY;EACZ,eAAe;EACf,YAAY;AACd","sourcesContent":[".MeasurementListContainer {\n  width: 100%;\n  margin-top: 10px;\n}\n\n.MeasurementListWrapper {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n}\n\n.MeasurementsHeading {\n  color: var(--primary-color);\n}\n\n.MeasurementsForm {\n  margin-top: 10px;\n  margin-bottom: 30px;\n  padding: 20px 20px;\n  width: 100%;\n  height: 100%;\n  background-color: white;\n  box-shadow: 0px 3px 6px 0 rgba(0, 0, 0, 0.26);\n}\n\n.ArrowImgUp {\n  transform: scaleY(-1);\n}\n\n.MeasurementCounter {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 5px;\n  font-size: 20px;\n  color: black;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
