// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MessageCardContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 6px 0 rgba(0, 0, 0, 0.26);
  height: fit-content;
  width: 100%;
  max-width: 600px;
  margin-top: 30px;
  padding: 10px;
}

.MessageSplitRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.MessageSplitColumn {
  display: flex;
  width: 100%;
  height: 100%;

  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
}

.MessageBody {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #f3f0f0;
  border: solid rgb(197, 197, 197);
  padding: 5px;
  word-wrap: anywhere;
}

.MessageCompanies {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.Expired {
  background-color: rgb(255, 206, 206);
}

.SoonValid {
  background-color: rgb(255, 249, 193);
}
`, "",{"version":3,"sources":["webpack://./src/components/adminMessage/adminMessageCard.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,6CAA6C;EAC7C,mBAAmB;EACnB,WAAW;EACX,gBAAgB;EAChB,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,YAAY;;EAEZ,sBAAsB;EACtB,8BAA8B;EAC9B,0BAA0B;AAC5B;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,uBAAuB;EACvB,yBAAyB;EACzB,gCAAgC;EAChC,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,uBAAuB;AACzB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;AACtC","sourcesContent":[".MessageCardContainer {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  box-shadow: 0px 3px 6px 0 rgba(0, 0, 0, 0.26);\n  height: fit-content;\n  width: 100%;\n  max-width: 600px;\n  margin-top: 30px;\n  padding: 10px;\n}\n\n.MessageSplitRow {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n}\n\n.MessageSplitColumn {\n  display: flex;\n  width: 100%;\n  height: 100%;\n\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: space-between;\n}\n\n.MessageBody {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: flex-start;\n  background-color: #f3f0f0;\n  border: solid rgb(197, 197, 197);\n  padding: 5px;\n  word-wrap: anywhere;\n}\n\n.MessageCompanies {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: flex-start;\n}\n\n.Expired {\n  background-color: rgb(255, 206, 206);\n}\n\n.SoonValid {\n  background-color: rgb(255, 249, 193);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
