import React, { useEffect, useRef } from "react";
// Redux Stuff

import { useSelector } from "react-redux";
import { AppState } from "../../store/store";
import { roleType, userType } from "../../store/user/types";
import { UserListItem } from "../../components/manageUsers/userListItem";
import Scrollbars from "react-custom-scrollbars-2";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

export const roleEn2swe = (
  t: TFunction<"translation", "translation">,
  role: string
) => {
  switch (role) {
    case roleType.admin:
      return t("manageUsers.admin");
    case roleType.manager:
      return t("manageUsers.manager");
    // case roleType.external:
    //   return "Kontakt";
    default:
      return t("manageUsers.operator");
  }
};

export const roleSwe2En = (
  t: TFunction<"translation", "translation">,
  role: string
) => {
  switch (role) {
    case t("manageUsers.admin"):
      return roleType.admin;
    case t("manageUsers.manager"):
      return roleType.manager;
    // case "Kontakt":
    //   return roleType.external;
    default:
      return roleType.operator;
  }
};

export const customThumb = (props: any) => {
  return <div className="scrollerThumb" />;
};

export const customThumbHorizontal = (props: any) => {
  return <div className="scrollerThumbHorizontal" />;
};

interface UserListProps {
  filteredUserArray: any; // OBS! userType
  roleFilter: string;
  companyFilter: string;
  newUser: boolean;
  setNewUserAdded: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UserList: React.FC<UserListProps> = ({
  filteredUserArray,
  roleFilter,
  companyFilter,
  newUser,
  setNewUserAdded,
}) => {
  const { t } = useTranslation();

  const currentUser = useSelector((state: AppState) => state.user.currentUser);

  const allCompanies = useSelector(
    (state: AppState) => state.company.allCompanies
  );

  const selectedUser = useSelector(
    (state: AppState) => state.user.selectedUser
  );

  const revealRefs = useRef<any[]>([]);
  revealRefs.current = [];

  const companyFilteredUsers =
    companyFilter !== t("manageUsers.companyUserFilterDefault")
      ? filteredUserArray.filter((user: userType) => {
          const companyUser = allCompanies.find(
            (company) =>
              company.id === user.companyID && company.name === companyFilter
          );

          return companyUser;
        })
      : filteredUserArray;

  const roleFilteredUsers =
    roleFilter !== t("manageUsers.roleUserFilterDefault")
      ? companyFilteredUsers.filter((user: userType) => {
          return user.roles.some(
            (role) => role.name === roleSwe2En(t, roleFilter)
          );
        })
      : companyFilteredUsers;

  useEffect(() => {
    if (revealRefs.current.length > 0)
      revealRefs.current[0].scrollIntoView({
        block: "end",
        behavior: "smooth",
      });
  }, [newUser]);

  const addToRef = (elementRef: never) => {
    if (elementRef && !revealRefs.current.includes(elementRef)) {
      revealRefs.current.push(elementRef);
    }
  };
  const Rows = roleFilteredUsers
    .filter((user: userType) => user.id !== currentUser?.id)
    .map((user: userType) => {
      const edit = selectedUser === user.id ? true : false;

      return (
        <div className="UserListButtonUser" key={user.id} ref={addToRef}>
          <UserListItem
            user={user}
            edit={edit}
            selectedUser={selectedUser}
            setNewUserAdded={setNewUserAdded}
          />
        </div>
      );
    });

  Rows.reverse();

  return (
    <Scrollbars
      thumbSize={60}
      renderThumbVertical={customThumb}
      autoHide={true}
      autoHideTimeout={1000}
      autoHideDuration={500}
    >
      <div className="userListWrapper">{Rows}</div>
    </Scrollbars>
  );
};
