import React, { useEffect, useState } from "react";
import { setMessages } from "../../store/user/userSlice";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/store";
import "./adminMessageCard.css";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import { AppState } from "../../store/store";
import * as annoncementsAPI from "./../../api/netRail/announcements";
import { announcementType } from "../../store/user/types";
import { useTranslation } from "react-i18next";

interface AdminMessageCardProps {}

export const AdminMessageFilter: React.FunctionComponent<
  AdminMessageCardProps
> = ({}) => {
  const { t } = useTranslation();

  const allCompanies = useSelector(
    (state: AppState) => state.company.allCompanies
  );

  // Filters:
  // Type
  const [messageType, setMessageType] = useState<string | announcementType>(
    t("common.type").toString()
  );

  // Expiration date

  const [expiringAtDate, setExpiringAtDate] = useState<Date | null>(null);

  // Valid at

  const [validAtDate, setValidAtDate] = useState<Date | null>(null);

  // Companies
  const [companies, setCompanies] = useState<string[]>(
    allCompanies.map((company) => company.name)
  );

  const dispatch = useAppDispatch();

  const fetchAnnoncements = async (
    expiringAtDate: Date | null,
    validAtDate: Date | null,
    companies: string[] | undefined,
    messageType: string | undefined
  ) => {
    try {
      const newMessages = await annoncementsAPI.getAnnouncements(
        expiringAtDate,
        validAtDate,
        messageType,
        companies
      );

      dispatch(setMessages(newMessages));
    } catch (err) {
      console.error("err");
    }
  };

  useEffect(() => {
    // Get messages API request

    const companiesIDs = companies
      .map((comp) => {
        const currComp = allCompanies.find((company) => company.name === comp);

        return currComp?.id;
      })
      .filter((id) => id !== undefined);

    fetchAnnoncements(
      expiringAtDate,
      validAtDate,
      companiesIDs as any,
      messageType !== t("common.type").toString() ? messageType : undefined
    );
  }, [expiringAtDate, validAtDate, companies, messageType]);

  return (
    <div className={"MessageCardContainer"}>
      {/* <div className="MessageSplitRow"> */}
      <div className="MessageSplitColumn">
        <div style={{ width: "100%" }}>
          <DatePicker
            // style={{ width: "100%" }}
            onChange={(dataItem) => setValidAtDate(dataItem.target.value)}
          />
        </div>
        <div style={{ width: "100%" }}>
          <MultiSelect
            style={{ width: "100%" }}
            // style={{ width: "100px" }}
            data={allCompanies.map((company) => {
              return company.name;
            })}
            onChange={(e) => {
              setCompanies(e.value);
            }}
            value={companies}
            autoClose={false}
            placeholder={t("common.company").toString()}
          />
          {/* </div> */}
        </div>
        {/* <div className="MessageSplitColumn"> */}
        <div style={{ width: "100%" }}>
          <DatePicker
            // style={{ width: "100%" }}
            onChange={(dataItem) => setExpiringAtDate(dataItem.target.value)}
          />
        </div>
        <div style={{ width: "100%" }}>
          <DropDownList
            style={{ width: "100%" }}
            defaultItem={t("common.company").toString()}
            value={messageType}
            data={[announcementType.general, announcementType.urgent]}
            onChange={(dataItem) => setMessageType(dataItem.target.value)}
          />
        </div>
      </div>
      <span
        className="k-icon k-font-icon k-i-close removeChart"
        onClick={() => {
          setMessageType(t("common.company").toString());
          setExpiringAtDate(null);
          setValidAtDate(null);
          setCompanies([]);
        }}
      />
    </div>
    // </div>
  );
};
