// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GpsVisualizationContainer {
  width: 100%;
  height: fit-content;
  background-color: var(--background-color);
  max-height: 410px;
}
`, "",{"version":3,"sources":["webpack://./src/components/visualization/gpsVisualizationContainer.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,mBAAmB;EACnB,yCAAyC;EACzC,iBAAiB;AACnB","sourcesContent":[".GpsVisualizationContainer {\n  width: 100%;\n  height: fit-content;\n  background-color: var(--background-color);\n  max-height: 410px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
