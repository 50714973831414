import React, { useEffect, useState } from "react";
import "./style.scss";

import { Toolbar } from "./../../components/toolbar";
import PlanList from "../../components/scheduler/planList/planList";
import { useSelector } from "react-redux";
import { AdminAnnouncement } from "../../components/announcement/announcement";
import { cPhoneWidthLim } from "../../app";
import { AppState } from "../../store/store";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

export const Scheduler: React.FunctionComponent = () => {
  const width = useSelector((state: AppState) => state.scheduler.screenWidth);
  const height = useSelector((state: AppState) => state.scheduler.screenHeight);
  const { t } = useTranslation();

  return (
    <div
      className="scheduler-container"
      style={
        width <= cPhoneWidthLim || height <= cPhoneWidthLim
          ? {}
          : { position: "relative", left: "44px", paddingRight: "44px" }
      }
    >
      <header className="scheduler-toolbar">
        <Toolbar>
          <h5>{t("scheduler.title")}</h5>
        </Toolbar>
      </header>

      <AdminAnnouncement />

      <div className="scheduler-main">
        <div className="scheduler-list">
          <PlanList />
        </div>
      </div>
    </div>
  );
};
