import React, { useState, useEffect } from "react";
import "./style.scss";

import {
  Form,
  FormRenderProps,
  FormElement,
  Field,
  FieldRenderProps,
} from "@progress/kendo-react-form";
import {
  setUserHasChanged,
  updateUserProperty,
} from "../../store/user/userSlice";

import { Input } from "@progress/kendo-react-inputs";

// Redux Stuff
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/store";
import { AppState } from "../../store/store";

import * as usersAPI from "../../api/netRail/users";
import {
  getHighestRoleTypeFromServerStruct,
  roleEn2swe,
} from "../../helpers/genericHelpers";
import { UpdatePasswordForm } from "./updatePasswordForm";
import { useTranslation } from "react-i18next";

interface UpdateUserFormProps {
  user: any;
}

export const CustomInputUser = (fieldRenderProps: FieldRenderProps) => {
  const {
    label,
    value,
    onChange,
    visited,
    validationMessage,
    modified,
    ...other
  } = fieldRenderProps;

  const dispatch = useAppDispatch();

  const isValid = () =>
    visited && modified && validationMessage ? false : true;

  return (
    <div style={{ width: "100%" }}>
      <Input
        {...other}
        label={label}
        value={value}
        onChange={(e) => {
          onChange(e);
          dispatch(setUserHasChanged(true));
        }}
        valid={isValid()}
      />
      {!isValid() ? (
        <div
          style={{
            color: "red",
            height: "fit-content",
            fontSize: "10px",
            whiteSpace: "pre-line",
          }}
        >
          {validationMessage}
        </div>
      ) : (
        <div style={{ height: "11px" }} />
      )}
    </div>
  );
};

const emailRegex = new RegExp(/\S+@\S+\.\S+/);

export const UpdateUserForm: React.FunctionComponent<UpdateUserFormProps> = ({
  user,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const emailValidator = (value?: string) =>
    value && emailRegex.test(value) ? "" : t("common.emailValidator");

  const idCurrUser = useSelector(
    (state: AppState) => state.user.currentUser
  )?.id;
  const currentUser = useSelector(
    (state: AppState) => state.user.allContacts
  ).find((user) => user.id === idCurrUser);

  useEffect(() => {
    dispatch(setUserHasChanged(false));
    return () => {
      dispatch(setUserHasChanged(false));
    };
  }, []);
  const userHasChanged = useSelector(
    (state: AppState) => state.user.userHasChanged
  );

  const [allowEdit, setAllowEdit] = useState(false);

  async function patchUserAsync(
    user: any,
    firstName?: string,
    lastName?: string,
    email?: string,
    phone_number?: string
  ) {
    // Code here
    const updatedUser = await usersAPI.patchUser(
      user.id,
      {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone_number,
      }
    );

    dispatch(updateUserProperty(updatedUser));
  }

  function changeUserProps(
    user: any,
    email?: string,
    firstName?: string,
    lastName?: string,
    phone_number?: string
  ) {
    return () => {
      try {
        patchUserAsync(user, firstName, lastName, email, phone_number);
      } catch (reason) {
        console.error("err patch user", reason);
      }
    };
  }

  const allCompanies = useSelector(
    (state: AppState) => state.company.allCompanies
  );

  const currentCompany = allCompanies.find(
    (company: any) => currentUser?.companyID === company.id
  );

  const phoneNumberValidator = (value?: string) => {
    if (value === undefined) return t("common.phoneValidator");

    return !new RegExp("\\+(46)\\d{9}$").test(value) && value.length < 12
      ? t("common.phoneValidatorFormat")
      : "";
  };

  const nameRegex = new RegExp(/^[a-zA-ZåöäÅÄÖ]{1,30}$/);

  const notUndefined = (value?: string) => {
    return value && value !== "" && nameRegex.test(value)
      ? ""
      : t("common.usernameValidator");
  };

  const [edit, setEdit] = useState(false);

  const UserPassword = (fieldRenderProps: FieldRenderProps) => {
    return (
      <div className="userCardRow">
        <Input
          label={t("loginPage.password").toString()}
          value={"DummyPassword"}
          disabled={true}
          type={"password"}
        />
      </div>
    );
  };

  const renderCurrentUserFormContent = (formRenderProps: FormRenderProps) => {
    const { valid } = formRenderProps;

    return (
      <>
        <FormElement className="userCardUser" style={{ width: "100%" }}>
          <div className="userCardColumnL">
            <legend className={"k-form-legend"} style={{ color: "black" }}>
              {t("userSettingsPage.updateUser")}
            </legend>

            <div className="userCardRow">
              <div style={{ width: "45%", marginRight: "10%" }}>
                <Field
                  name={"firstName"}
                  component={CustomInputUser}
                  label={t("userSettingsPage.firstname").toString()}
                  validator={notUndefined}
                />
              </div>
              <div style={{ width: "45%" }}>
                <Field
                  name={"lastName"}
                  component={CustomInputUser}
                  label={t("userSettingsPage.surname").toString()}
                  validator={notUndefined}
                />
              </div>
            </div>
            <Field
              name={"email"}
              component={CustomInputUser}
              label={t("userSettingsPage.email").toString()}
              validator={emailValidator}
            />

            <Field
              name={"phone_number"}
              component={CustomInputUser}
              label={t("userSettingsPage.phone").toString()}
              validator={phoneNumberValidator}
            />
            {/* <Field
              name={"companyName"}
              component={Input}
              label={t("manageCompanies.company").toString()}
              disabled={true}
            /> */}
            {!valid ? (
              <div style={{ marginTop: "10px", color: "red" }}>
                {t("common.requiredFields")}
              </div>
            ) : null}
          </div>
          <div className="userCardColumnForm">
            <span
              className="k-icon k-font-icon  k-font-icon k-i-close closeCard"
              style={{
                fontSize: "24px",
                marginBottom: "auto",
                marginLeft: "auto",
              }}
              onClick={() => {
                setEdit(!edit);
                setAllowEdit(false);
              }}
            />
            <button
              className="AddUserButtonForm BUTTON"
              type={"submit"}
              disabled={!valid || !userHasChanged}
              style={
                !valid || !userHasChanged
                  ? {
                      opacity: 0.5,
                    }
                  : {}
              }
            >
              {t("common.update")}
            </button>
          </div>
        </FormElement>
        {!allowEdit ? (
          <FormElement className="userCardUser" style={{ width: "100%" }}>
            <div className="userCardColumnL">
              <div className="userCardUser">
                <legend
                  className={"k-form-legend"}
                  style={{
                    color: "black",
                    marginTop: "30px",
                    marginBottom: "0px",
                  }}
                >
                  {t("userSettingsPage.updatePassword")}
                </legend>
                <span
                  className="k-icon k-font-icon  k-font-icon k-i-change-manually editUser "
                  style={{
                    fontSize: "24px",
                    marginRight: "auto",
                    marginTop: "auto",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setAllowEdit(!allowEdit);
                  }}
                />
              </div>
              <Field
                name={"Lösenord"}
                component={UserPassword}
                label={t("loginPage.password")}
              />
            </div>
          </FormElement>
        ) : null}
      </>
    );
  };

  const openedContent = () => {
    return (
      <div className="formContainer">
        <Form
          initialValues={{
            firstName: user?.firstName,
            lastName: user?.lastName,
            phone_number: user?.phone ? user?.phone : "+46",
            email: user?.email,
            roleType: user
              ? roleEn2swe(t, getHighestRoleTypeFromServerStruct(user.roles))
              : "",
            companyName: currentCompany?.name,
          }}
          key={JSON.stringify(user)}
          onSubmit={(dataItem) => {
            if (dataItem && currentUser) {
              dispatch(
                changeUserProps(
                  user,
                  user.email ? user.email : dataItem.email.toLowerCase(),
                  user.firstName ? user.firstName : dataItem.firstName,
                  user.lastName ? user.lastName : dataItem.lastName,
                  user.phone ? user.phone : dataItem.phone_number
                )
              );
            }
          }}
          render={(formRenderProps) =>
            renderCurrentUserFormContent(formRenderProps)
          }
        />
        {currentUser && allowEdit ? (
          <UpdatePasswordForm
            user={currentUser}
            allowEdit={allowEdit}
            setAllowEdit={setAllowEdit}
          />
        ) : (
          <div> </div>
        )}
      </div>
    );
  };
  
  const closedContent = () => {
    return (
      <div className="closedContentContainer">
        <div className="currUserCardUser">
          <h5 className="currUserCardRow">
            {user?.firstName !== ""
              ? user?.firstName + " " + user?.lastName
              : "-"}
          </h5>

          <div className="currUserCardRow">
            {user?.email !== "" ? user?.email : "-"}
          </div>
          <div className="currUserCardRow">
            {user
              ? roleEn2swe(
                  t,
                  // Work around to no change function:
                  getHighestRoleTypeFromServerStruct(user.roles)
                )
              : t("userSettingsPage.operator")}
          </div>
          <div className="currUserCardRow">
            {user?.phone ? user?.phone : "-"}
          </div>
        </div>
        <div className="currUserCardColumnR">
          <div
            style={{
              flexDirection: "row",
            }}
          >
            <div
              className="k-icon k-font-icon  k-font-icon k-i-change-manually editUser"
              style={{
                fontSize: "24px",
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setEdit(true);
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="userForm">{edit ? openedContent() : closedContent()}</div>
  );
};
