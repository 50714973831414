import React, { useEffect, useState } from "react";
import "./style.scss";
import Scrollbars, { positionValues } from "react-custom-scrollbars-2";
import { adminMessageType, announcementType } from "../../store/user/types";
import { useTranslation } from "react-i18next";
import { fetchStoredAnnouncement } from "./announcement";
import {  useSelector } from "react-redux";
import { useAppDispatch } from "../../store/store";
import { AppState } from "../../store/store";
import { setAnnouncementsCount } from "../../store/user/userSlice";
import { customThumb } from "../manageUsers/userList";

interface AdminAnnouncementProps {
  announcement: adminMessageType;
  index: number;
}

export const AnnouncementCard: React.FunctionComponent<
  AdminAnnouncementProps
> = ({ announcement, index }) => {
  const { t } = useTranslation("translation", { keyPrefix: "announcement" });

  const [bottomReached, setBottomReached] = useState(true);
  const [markAsRead, setMarkAsRead] = useState(false);
  const [storedAnnouncementsLocally, setStoredAnnouncementsLocally] = useState<
    string[]
  >([]);
  const [focusedCard, setFocusedCard] = useState(false);

  const announcementsCount = useSelector(
    (state: AppState) => state.user.announcementsCount
  );

  const dispatch = useAppDispatch();

  const Today = new Date();

  const handleUpdate = (values: positionValues) => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    const pad = 5;
    const t = (scrollTop + pad) / (scrollHeight - clientHeight);
    if (t > 1) {
      setBottomReached(true);
    } else {
      setBottomReached(false);
    }
  };

  useEffect(() => {
    const storedAnnouncements = fetchStoredAnnouncement();
    if (markAsRead) {
      if (!storedAnnouncements.includes(announcement.id)) {
        storedAnnouncements.push(announcement.id);
        localStorage.setItem(
          "storedAnnouncements",
          JSON.stringify(storedAnnouncements)
        );
      }
    }
    setStoredAnnouncementsLocally(storedAnnouncements);
  }, [announcementsCount, markAsRead]);

  const renderAnnouncementText = () => {
    return (
      <div
        className="announcementCardTextContainer"
        style={
          !focusedCard && index !== 0
            ? { height: "100px" }
            : { height: "300px" }
        }
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "10px",
              alignItems: "baseline",
            }}
          >
            <span
              style={{
                fontStyle: "italic",
              }}
            >
              {new Date(announcement.validAt).toLocaleString("sv", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })}
            </span>
            {announcement?.type === announcementType.urgent ? (
              <div className="urgentMessage">
                <span
                  className="k-icon k-font-icon k-i-warning"
                  style={{
                    fontSize: "20px",
                    paddingLeft: "10px",
                  }}
                />
              </div>
            ) : null}
          </div>
          {new Date(announcement.expiresAt) >= Today &&
          new Date(announcement.validAt) <= Today &&
          !storedAnnouncementsLocally.includes(announcement.id) ? (
            <button
              className="BUTTON"
              onClick={(e) => {
                setMarkAsRead(true);
                dispatch(setAnnouncementsCount(announcementsCount - 1));
              }}
            >
              <span className="k-icon k-font-icon k-i-check" style={{ fontSize: "20px" }} />
            </button>
          ) : null}
        </div>

        <h4
          style={{
            color: "black",
            borderBottom: "solid 2px rgba(168, 168, 168, 0.4)",
            paddingBottom: "5px",
            overflowWrap: "break-word",
            margin: "0px",
          }}
        >
          {announcement?.title}
        </h4>
        {!focusedCard && index !== 0 ? null : (
          <Scrollbars
            thumbSize={0}
            renderThumbVertical={customThumb}
            autoHide={true}
            autoHideTimeout={1000}
            autoHideDuration={500}
            onUpdate={handleUpdate}
            style={{ height: "200px" }}
          >
            <div className="messageBodyCard" style={{ color: "black" }}>
              {announcement?.type === announcementType.urgent ? (
                <div className="urgentMessage">{t("importantInformation")}</div>
              ) : null}
              {announcement?.message}
            </div>
          </Scrollbars>
        )}
      </div>
    );
  };

  const renderAnnouncementNotification = () => {
    return (
      <div
        className={
          !bottomReached
            ? "announcementCard-container announcementContainerShadow"
            : "announcementCard-container "
        }
        style={
          index === 0 || focusedCard
            ? { border: "solid 3px var(--primary-color)" }
            : { border: "solid 1px var(--primary-color)", opacity: "0.4" }
        }
        onClick={() => {
          if (index !== 0) {
            setFocusedCard(!focusedCard);
          }
        }}
      >
        {index === 0 ? (
          <span style={{ fontWeight: "bold", alignSelf: "center" }}>
            {t("latestAnnouncement")}
          </span>
        ) : null}
        <div className="announcementCard">{renderAnnouncementText()}</div>
      </div>
    );
  };

  return renderAnnouncementNotification();
};
