import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit"; import {
    roleType,
    userType,
    roleProps,
    announcementResponseType,
    languageType,
    userState,
} from "./types";

import { setAllMachines } from "./../machine/machineSlice";
import {
    fetchAuthSession,
    forgetDevice,
    signOut
} from "aws-amplify/auth";

import {
    setAllCompanies,
    setCurrentCompany,
} from "./../company/companySlice";

import {
    setSelectedChart,
    setAllCharts
} from "../plot/plotSlice";

import {
    setAllPlans,
    setTotalPlansOnServer,
    setSelectedMeasurement,
    setSelectedPlan,
} from "./../scheduler/schedulerSlice";

import { getNewUser } from "./../../development/initializer";
import rest from "../../rest";
import { AppState } from "../store";
import * as usersAPI from "./../../api/netRail/users";
import * as plansAPI from "./../../api/netRail/plans";
import * as machinesAPI from "./../../api/netRail/machines";
import { getLastVisitedPlanAndMeasurement } from "../../helpers/genericHelpers";
import { TFunction } from "i18next";

const initialState: userState = {
    currentUser: undefined,
    authenticationSuccess: false,
    allCompanyUsers: [
        {
            companyID: "31166ee3-fd93-4044-8a44-ec38c5cf8bad",
            createdAt: "2021-09-22T06:46:08.590395Z",
            email: "tms19@redemptor.se",
            firstName: "admin",
            id: "e209a8e8-01ec-4415-86fc-7d6b5bb3184f",
            lastName: "",
            roles: [],
            syncedAt: "2021-09-22T06:46:08.590261Z",
            updatedAt: "2021-09-22T06:46:08.590395Z",
            sentToServer: false,
        },
    ],
    allContacts: [
        {
            companyID: "31166ee3-fd93-4044-8a44-ec38c5cf8bad",
            createdAt: "2021-09-22T06:46:08.590395Z",
            email: "tms19@redemptor.se",
            firstName: "admin",
            id: "e209a8e8-01ec-4415-86fc-7d6b5bb3184f",
            lastName: "",
            roles: [],
            syncedAt: "2021-09-22T06:46:08.590261Z",
            updatedAt: "2021-09-22T06:46:08.590395Z",
            sentToServer: false,
        },
    ],
    selectedUser: "-1",
    userHasChanged: false,
    notification: { message: "", style: "success", open: false },
    announcementResponse: announcementResponseType.show,
    messages: [],
    selectedMessage: "-1",
    informationRequest: { showInformation: false, path: undefined },
    selectedLanguage: languageType.englishGB,
    announcementsCount: 0,
};


export const performLogout = createAsyncThunk('user/logout', async (_, { dispatch }) => {
    try {
        await fetchAuthSession();
        await forgetDevice();
        await signOut();
        dispatch(userSlice.actions.logout());
    } catch (error) {
        dispatch(userSlice.actions.logout());
        console.error("error signing out: ", error);
    }
});

export const addNewUser = createAsyncThunk('user/addNewUser', async (_, { dispatch, getState }) => {
    const { user } = getState() as AppState;
    const userID = user.currentUser ? user.currentUser.id : "";
    const newUser = getNewUser(userID);
    dispatch(userSlice.actions.addNewReduxUser(newUser));
});

export const saveUserToServer = createAsyncThunk(
    'user/saveUserToServer',
    async ({ t, email, type, firstName, lastName, userID, companyID, sentToServer, phone_number }:
        { t: TFunction<"translation", "translation">, email: string, type: roleType, firstName: string, lastName: string, userID: string, companyID: string, sentToServer?: boolean, phone_number?: string },
        { dispatch, getState }: { dispatch: any, getState: any }) => {
        if (!sentToServer) {
            try {
                const response = await rest.post("/users/register", {
                    email,
                    firstName,
                    lastName,
                    companyID,
                }, { withCredentials: true });
                const newUser = response.data;

                const roleResponse = await rest.get("/roles");
                const allRoles: roleProps[] = roleResponse.data.list;
                let roleID;

                if (type === roleType.admin) {
                    roleID = allRoles.find((role) => role.name === "ADMIN")?.id;
                } else if (type === roleType.manager) {
                    roleID = allRoles.find((role) => role.name === "MANAGER")?.id;
                } else if (type === roleType.operator) {
                    roleID = allRoles.find((role) => role.name === "OPERATOR")?.id;
                }

                const attachRoleResponse = await rest.post("/users/" + newUser.id + "/attachRole/" + roleID);
                const newUserID = response.data.id;
                const currentUser = getState().user.currentUser;

                if (newUserID.length !== 0) {
                    const allCompanyUsers = getState().user.allCompanyUsers.filter(
                        (operator: any) => operator.companyID === currentUser?.companyID
                    );

                    dispatch(userSlice.actions.setAllCompanyUsers([
                        ...allCompanyUsers,
                        {
                            id: newUserID,
                            email,
                            firstName,
                            lastName,
                            roles: attachRoleResponse.data.list,
                            companyID,
                            sentToServer: true,
                            createdAt: response.data.createdAt,
                            syncedAt: response.data.syncedAt,
                            updatedAt: response.data.updatedAt,
                        },
                    ]));
                }

                const usersResponse = await rest.get("/users", { withCredentials: true });
                const allCompanyUsers = usersResponse.data.list;

                if (currentUser?.roles.some((entry: roleProps) => entry.name === roleType.admin)) {
                    dispatch(userSlice.actions.setAllContacts([...allCompanyUsers]));
                } else {
                    dispatch(userSlice.actions.setAllContacts(
                        [...allCompanyUsers].filter(
                            (contact: userType) => contact.companyID === currentUser?.companyID
                        )
                    ));
                }

                dispatch(userSlice.actions.setSelectedUser(newUserID));
                dispatch(userSlice.actions.setUserHasChanged(false));
                dispatch(userSlice.actions.setNotification({
                    message: t("store.user.userAdded"),
                    open: true,
                    style: "success",
                }));
            } catch (error: any) {
                if (error.message === "Network Error") {
                    alert(t("store.networkError"));
                } else if (error.message === "Request failed with status code 500") {
                    dispatch(userSlice.actions.setNotification({
                        style: "error",
                        message: t("store.user.usersExists"),
                        open: true,
                    }));
                } else {
                    alert(t("statusProgress.unexpectedError"));
                }
            }
        } else if (sentToServer) {
            try {
                const roleResponse = await rest.get("/roles", { withCredentials: true });
                const allRoles: roleProps[] = roleResponse.data.list;

                const userResponse = await rest.get("/users/" + userID, { withCredentials: true });
                const userRoles = userResponse.data.roles;

                let newRole: roleProps | undefined = undefined;

                if (!userRoles.some((role: roleProps) => role.name === type)) {
                    if (type === roleType.admin) {
                        newRole = allRoles.find((role) => role.name === "ADMIN");
                    } else if (type === roleType.manager) {
                        newRole = allRoles.find((role) => role.name === "MANAGER");
                    } else if (type === roleType.operator) {
                        newRole = allRoles.find((role) => role.name === "OPERATOR");
                    }

                    await detachAllUserRoles(userRoles, userID);

                    const attachRoleResponse = await rest.post("/users/" + userID + "/attachRole/" + newRole?.id);

                    if (newRole) {
                        dispatch(userSlice.actions.updateUserProperty({ roles: [newRole] }));
                    }
                }

                const response = await rest.patch("/users/" + userID, {
                    email,
                    firstName,
                    lastName,
                    phone: phone_number,
                }, { withCredentials: true });

                dispatch(userSlice.actions.updateUserProperty({
                    email: email.toLowerCase(),
                    firstName,
                    lastName,
                    companyID,
                    phone: phone_number,
                }));

                dispatch(userSlice.actions.setNotification({
                    style: "success",
                    message: t("store.user.userUpdated"),
                    open: true,
                }));
                dispatch(userSlice.actions.setUserHasChanged(false));
            } catch (error: any) {
                if (error.message === "Network Error") {
                    alert(t("store.networkError"));
                } else {
                    dispatch(userSlice.actions.setNotification({
                        style: "error",
                        message: t("store.user.userUpdateError"),
                        open: true,
                    }));
                }
                dispatch(userSlice.actions.setUserHasChanged(true));
            }
        } else {
            alert(t("store.user.passwordMatchError"));
        }
    }
);

export const removeUser = createAsyncThunk(
    'user/removeUser',
    async ({ t, user }: { t: TFunction<"translation", "translation">, user: userType }, { dispatch }) => {
        try {
            await rest.delete("/users/" + user.id, { withCredentials: true });
            dispatch(userSlice.actions.deleteUser(user.id));
            dispatch(userSlice.actions.setNotification({
                style: "success",
                message: t("store.user.userDeleted"),
                open: true,
            }));
        } catch {
            console.error("Failed to delete user with userID ", user.id);
            dispatch(userSlice.actions.setNotification({
                style: "error",
                message: t("store.user.userDeleteError") + " " + user.email,
                open: true,
            }));
        }
    }
);

export const detachAllUserRoles = async (userRolesList: roleProps[], userID: string) => {
    userRolesList.map((oldRole: roleProps) => {
        rest
            .delete("/users/" + userID + "/detachRole/" + oldRole?.id)
            .then((result) => void 0)
            .catch((error) => console.error("detachRole error: ", error));
        return oldRole;
    });
}

export const onLogin = createAsyncThunk(
    'user/onLogin',
    async ({ t }: { t: TFunction<"translation", "translation"> }, { dispatch }) => {
        try {
            await dispatch(populateCurrentSession({ t }));
        } catch (error) {
            console.error("Error during login:", error);
        }
    }
);

const fetchCurrentUserSession = async () => {
    const session = await fetchAuthSession();
    const userIDToken = session.tokens?.idToken?.payload.sub;
    if (!userIDToken) {
        throw new Error("No user ID token found");
    }
    const currentUser = await usersAPI.getUser(userIDToken);
    return currentUser;
};

const checkUserRoles = (currentUser: any, t: TFunction<"translation", "translation">, dispatch: any) => {
    const allowedRoles = ["ADMIN", "MANAGER", "DEVELOPER"];
    if (!currentUser.roles.some((role: any) => allowedRoles.includes(role.name))) {
        dispatch(setNotification({
            message: t("store.authorizationBlock"),
            style: "error",
            open: true,
        }));

        dispatch(performLogout());
        dispatch(setAllPlans([]));
        dispatch(setAllCompanyUsers([]));
        dispatch(setAllCompanies([]));
        dispatch(setAllMachines([]));
        // Clear important redux states:
        dispatch(setAllCharts([]));
        dispatch(setSelectedMeasurement("-1"));
        dispatch(setSelectedPlan("-1"));
        dispatch(setSelectedChart("1"));
        dispatch(setAuthenticationSuccess(false));
        sessionStorage.removeItem("lastPlan");
        sessionStorage.removeItem("lastMeasurement");
        throw new Error("User does not have the necessary roles");
    }
};

const fetchCompanyUsers = async (currentUser: any, dispatch: any) => {
    const users = await usersAPI.getUsers();
    const companyUsers = users.filter((user: any) => user.companyID === currentUser.companyID);
    dispatch(setAllCompanyUsers(companyUsers));
    dispatch(setAllContacts(users));
};

const fetchMachines = async (dispatch: any) => {
    const machines = await machinesAPI.getMachines();
    dispatch(setAllMachines(machines));
};

const fetchCompanies = async (currentUser: any, dispatch: any) => {
    const companyResponse = await rest.get("/companies", { withCredentials: true });
    const allFetchedCompanies = companyResponse.data.list.map((element: any) => {
        return { ...element, sentToServer: true };
    });
    const currentCompany = allFetchedCompanies.find((company: any) => company.id === currentUser.companyID);
    dispatch(setAllCompanies(allFetchedCompanies));
    if (currentCompany) {
        dispatch(setCurrentCompany(currentCompany));
    }
};

const fetchPlans = async (dispatch: any) => {
    const lastVisited = getLastVisitedPlanAndMeasurement();
    let limit = 25;
    let plan;
    if (lastVisited?.lastPlan) {
        limit = 24;
        plan = await plansAPI.getPlan(lastVisited.lastPlan);
    }

    const [plans, metaData] = await plansAPI.getPlans(undefined, undefined, undefined, undefined, undefined, limit, 1, 0);
    dispatch(setAllPlans(plan !== undefined ? [...plans, plan] : plans));
    dispatch(setTotalPlansOnServer(plans.length));
};

export const populateCurrentSession = createAsyncThunk(
    'user/populateCurrentSession',
    async ({ t }: { t: TFunction<"translation", "translation"> }, { dispatch, getState }) => {
        try {
            const currentUser = await fetchCurrentUserSession();
            checkUserRoles(currentUser, t, dispatch);

            dispatch(login(currentUser));
            dispatch(setAuthenticationSuccess(true));

            await fetchCompanyUsers(currentUser, dispatch);
            await fetchMachines(dispatch);
            await fetchCompanies(currentUser, dispatch);
            await fetchPlans(dispatch);
        } catch (error) {
            console.error("populateCurrentSession error: ", error);
        }
    }
);

export const changeUserSettings = createAsyncThunk(
    'user/changeUserSettings',
    async ({ userID, email, currentPassword, newPassword, firstName, lastName }:
        { userID: string, email?: string, currentPassword?: string, newPassword?: string, firstName?: string, lastName?: string }, { dispatch }) => {
        try {
            await rest.post("/users/login", { email, password: currentPassword }, { withCredentials: true });
            await rest.patch("/users/" + userID, {
                email,
                password: newPassword,
                firstName,
                lastName,
            }, { withCredentials: true });
        } catch (error: any) {
            if (error.message === "Network Error") {
                console.error(error);
                alert("Kontrollera din nätverksanslutning."); //Translate
            } else {
                alert("Användare med email " + email + "  finns redan."); //Translate
                console.error("Failed to add user with email ", email);
            }
        }
    }
);

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        login: (state, action: PayloadAction<any>) => {
            state.currentUser = action.payload;
        },
        logout: (state) => {
            state.currentUser = undefined;
        },
        setAllCompanyUsers: (state, action: PayloadAction<any[]>) => {
            state.allCompanyUsers = action.payload;
        },
        setAllContacts: (state, action: PayloadAction<any[]>) => {
            state.allContacts = action.payload.map((user) => ({
                ...user,
                sentToServer: true,
            }));
            state.selectedUser = "";
            state.userHasChanged = false;
        },
        deleteUser: (state, action: PayloadAction<string>) => {
            state.allCompanyUsers = state.allCompanyUsers.filter(
                (user) => user.id !== action.payload
            );
            state.allContacts = state.allContacts.filter(
                (user) => user.id !== action.payload
            );
        },
        setSelectedUser: (state, action: PayloadAction<string>) => {
            state.selectedUser = action.payload;
        },
        updateUserProperty: (state, action: PayloadAction<any>) => {
            state.allContacts = state.allContacts.map((user) =>
                user.id === state.selectedUser
                    ? { ...user, ...action.payload }
                    : user
            );
        },
        updateCurrentUserProperty: (state, action: PayloadAction<any>) => {
            if (state.currentUser) {
                state.currentUser = { ...state.currentUser, ...action.payload };
            }
        },
        addNewReduxUser: (state, action: PayloadAction<any>) => {
            state.allContacts.push({ ...action.payload });
            state.selectedUser = action.payload.id;
            state.userHasChanged = true;
        },
        setUserHasChanged: (state, action: PayloadAction<boolean>) => {
            state.userHasChanged = action.payload;
        },
        setAuthenticationSuccess: (state, action: PayloadAction<boolean>) => {
            state.authenticationSuccess = action.payload;
        },
        setNotification: (state, action: PayloadAction<any>) => {
            state.notification = action.payload;
        },
        setAnnouncementResponse: (state, action: PayloadAction<any>) => {
            state.announcementResponse = action.payload;
        },
        setMessages: (state, action: PayloadAction<any[]>) => {
            state.messages = action.payload;
        },
        setSelectedMessage: (state, action: PayloadAction<string>) => {
            state.selectedMessage = action.payload;
        },
        addNewMessage: (state, action: PayloadAction<any>) => {
            state.messages.unshift(action.payload);
            state.selectedMessage = action.payload.id;
        },
        setInformationRequest: (state, action: PayloadAction<any>) => {
            state.informationRequest = action.payload;
        },
        setSelectedLanguage: (state, action: PayloadAction<languageType>) => {
            state.selectedLanguage = action.payload;
        },
        setAnnouncementsCount: (state, action: PayloadAction<number>) => {
            state.announcementsCount = action.payload;
        },
    },
});

export const {
    login,
    logout,
    setAllCompanyUsers,
    setAllContacts,
    deleteUser,
    setSelectedUser,
    updateUserProperty,
    updateCurrentUserProperty,
    addNewReduxUser,
    setUserHasChanged,
    setAuthenticationSuccess,
    setNotification,
    setAnnouncementResponse,
    setMessages,
    setSelectedMessage,
    addNewMessage,
    setInformationRequest,
    setSelectedLanguage,
    setAnnouncementsCount,
} = userSlice.actions;

export default userSlice.reducer;