import React from "react";
import "./sideBarHeader.css";
/*Redux stuff */
import { useAppDispatch } from "../../store/store";
import { planProps } from "../../store/scheduler/types";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { setLastVisitedPlanAndMeasurement } from "../../helpers/genericHelpers";
import { setSelectedPlan } from "../../store/scheduler/schedulerSlice";

interface SideBarHeaderProps {
  plan: planProps;
}

const SideBarListHeader: React.FC<SideBarHeaderProps> = ({ plan }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <div className="MeasurementListHeader">
      <div
        className="body-1 CardHeaderItem"
        style={{
          color: "var(--primary-color)",
          display: "flex",
          flexDirection: "row",
          borderBottom: "2px solid var(--primary-color)",
        }}
      >
        <div className="PlanHeaderTitle" style={{ fontWeight: "bold" }}>
          <div>
            {t("common.plan")} -{" "}
            <Link
              className="PlanLink"
              to="/scheduler/plan"
              onClick={() => {
                setLastVisitedPlanAndMeasurement(plan?.id, undefined);
                if (plan) dispatch(setSelectedPlan(plan.id));
              }}
            >
              {plan?.name} <span className="k-icon k-font-icon k-i-redo GoToIcon" />
            </Link>
          </div>

          <span
            style={{ fontSize: "24px" }}
            className="k-icon k-font-icon k-i-close 
            removeChart"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              dispatch(setSelectedPlan("-1"));
            }}
          />
        </div>
      </div>
      <div className="PlanHeaderColumns">
        <div className="PlanHeaderColumn">
          <div className="PlanItemName">{t("metaData.name")}</div>{" "}
          <div className="PlanItemValue">{plan.name}</div>
          <div className="PlanItemName">{t("planForm.place")}</div>{" "}
          <div className="PlanItemValue">{plan.place}</div>
        </div>
        <div className="PlanHeaderColumn">
          <div className="PlanItemName">{t("common.trackPart")} </div>
          <div className="PlanItemValue">{plan.trackPart}</div>
          <div className="PlanItemName">
            {t("metaData.from")}-{t("metaData.to")}{" "}
          </div>
          <div className="PlanItemValue">
            {plan.startAtKm} + {plan.startAtMeter}m - {plan.endAtKm} +{" "}
            {plan.endAtMeter}m
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBarListHeader;
