import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/store";
import { AppState } from "../../store/store";
import {
  removeChart,
  setDataPoints,
  setMainXScale,
  setSelectedChart,
} from "../../store/plot/plotSlice";
import { dataPointType, GroupChartDataProps } from "../../store/plot/types";
import {
  ChartContainer,
  ChartDimensionProps,
} from "../generic/chartContainer/chartContainer";
import {
  ToggleMeasurements,
} from "../generic/toggleMeasurements/toggleMeasurements";
import { fetchInitialData } from "../../store/plot/plotSlice";
import { ToggleTolerances } from "../generic/toggleTolerances/toggleTolerances";
import { Card } from "./../generic/card/card";
// import { GroupChart } from "./groupChart/groupChart";
import { leftAxisWidth } from "./groupChart/chartConfig";
import "./groupChartContainer.css";
import { scaleLinear } from "d3";

const GroupChart = React.lazy(() => import('./groupChart/groupChart'));

interface GroupChartContainerProps {
  chartID: string;
  mainView: React.MutableRefObject<boolean>;
  finishedLoading: boolean;
  setFinishedLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const emptyDataSet: GroupChartDataProps = {
  trackGauge: [] as dataPointType[],
  crossLevelBIS: [] as dataPointType[],
  crossLevel: [] as dataPointType[],
  crossLevelUnevenness: [] as dataPointType[],
  twist3m: [] as dataPointType[],
  twist6m: [] as dataPointType[],
  alignment: [] as dataPointType[],
  longitudinalLevel: [] as dataPointType[],
  alignmentLeft: [] as dataPointType[],
  longitudinalLevelLeft: [] as dataPointType[],
  alignmentRight: [] as dataPointType[],
  longitudinalLevelRight: [] as dataPointType[],
  longitudinalAngleHP: [] as dataPointType[],
};

export const GroupChartContainer: React.FunctionComponent<
  GroupChartContainerProps
> = ({ chartID, mainView, finishedLoading, setFinishedLoading }) => {
  const [chartDimensions, setChartDimensions] = useState<
    ChartDimensionProps | undefined
  >(undefined);

  const height = useSelector((state: AppState) => state.scheduler.screenHeight);
  const dispatch = useAppDispatch();

  const triggerRerender = height >= 450 ? true : false;

  const screenWidth = useSelector(
    (state: AppState) => state.scheduler.screenWidth
  );

  const signalInterval = useSelector(
    (state: AppState) => state.plot.globalSignalInterval
  );

  const selectedMeasurement = useSelector(
    (state: AppState) => state.scheduler.selectedMeasurement
  );

  const allCharts = useSelector((state: AppState) => state.plot.charts);

  const graphCharts = allCharts.filter((chart) => chart.chartID !== "999999");
  const allChartsArray = graphCharts;

  return (
    <Card>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          maxHeight: "40px",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          overflow: "visible",
        }}
      >
        <ToggleTolerances chartID={chartID} />
        <div style={{ display: "flex", flexDirection: "row" }}>
          <span className="k-icon k-font-icon k-font-icon k-i-home removeChart" style={{ fontSize: "24px", marginRight: "20px" }} onClick={async () => {
              if (finishedLoading) {
                setFinishedLoading(false);

                const setsToPlot: GroupChartDataProps = {
                  trackGauge: [] as dataPointType[],
                  crossLevelBIS: [] as dataPointType[],
                  crossLevel: [] as dataPointType[],
                  crossLevelUnevenness: [] as dataPointType[],
                  twist3m: [] as dataPointType[],
                  twist6m: [] as dataPointType[],
                  alignment: [] as dataPointType[],
                  longitudinalLevel: [] as dataPointType[],
                  alignmentLeft: [] as dataPointType[],
                  longitudinalLevelLeft: [] as dataPointType[],
                  alignmentRight: [] as dataPointType[],
                  longitudinalLevelRight: [] as dataPointType[],
                  longitudinalAngleHP: [] as dataPointType[],
                };

                for (const i in allChartsArray) {
                  // Set for every chart
                  const chart = allChartsArray[i];
        
                  // To update one chart set the selected chart to the current looped one, this will be overwritten later when user do any action.
                  dispatch(setSelectedChart(chart.chartID)); // Make sure that the chart we want to use is activated
        
                  for (const key of chart.measurementToDisplay) {
                    try {
                      const initialData = await dispatch(
                        fetchInitialData({
                          measurement: key as keyof GroupChartDataProps,
                          chartID: chart.chartID,
                          signalInterval,
                          measurementID: selectedMeasurement,
                          screenWidth,
                          reset: true
                        })
                      ).unwrap(); // Unwrap the promise to handle the result directly
        
                      setsToPlot[key as keyof GroupChartDataProps] = initialData;
                    } catch (error) {
                      console.error("Error fetching initial data:", error);
                    }
                  }
                }

                setTimeout(() => {
                  if (chartDimensions) {
                    const xScale = scaleLinear()
                      .domain([
                        signalInterval.xMinOrig,
                        signalInterval.xMaxOrig,
                      ])
                      .range([leftAxisWidth, chartDimensions.width]);
                    dispatch(setMainXScale(xScale));
                  }

                  mainView.current = true;

                  setFinishedLoading(true);
                }, 100);
              }
            }} />
          <span
            className="k-icon k-font-icon k-i-close removeChart"
            style={{ fontSize: "24px" }}
            onClick={() => {
              dispatch(removeChart(chartID));
            }}
          />
        </div>
      </div>
      <div className="GroupChartContainer">
        <ChartContainer
          chartDimensions={chartDimensions}
          setChartDimensions={setChartDimensions}
          rerenderEvent={triggerRerender}
        >
          <GroupChart
            chartDimensions={chartDimensions}
            chartID={chartID}
            mainView={mainView}
            finishedLoading={finishedLoading}
            setFinishedLoading={setFinishedLoading}
          />
        </ChartContainer>
      </div>
      <ToggleMeasurements chartID={chartID} chartDimensions={chartDimensions} />
    </Card>
  );
};
