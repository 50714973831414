import React, { useRef, useState, useEffect, useCallback } from 'react';
import { DateRangePicker, Calendar, CalendarCell, CalendarCellProps } from '@progress/kendo-react-dateinputs';
import { RadioButton, RadioButtonChangeEvent } from '@progress/kendo-react-inputs';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { getMeasurements } from '../../api/netRail/measurements';
import { useTranslation } from 'react-i18next';
import { userType } from '@/store/user/types';
import { machineType } from '@/store/machine/types';
import './ExcelReport.css';
import { useSelector } from 'react-redux';

const CustomCell = (props: CalendarCellProps & { measurementDates: Date[] }) => {
  const { measurementDates, ...rest } = props;
  const cellDate = new Date(props.value);
  const isMeasurementDate = measurementDates.some(
    (date) => date.toDateString() === cellDate.toDateString()
  );

  const style: React.CSSProperties = isMeasurementDate
    ? { border: '1px blue solid' }
    : {};

  return <CalendarCell {...rest} style={style} />;
};

export const ExcelReport: React.FC = () => {
  const { t } = useTranslation();
  const [refreshKey, setRefreshKey] = useState(Math.random());
  const [dateRange, setDateRange] = useState<{ start: Date | null, end: Date | null }>({ start: null, end: null });
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [measurements, setMeasurements] = useState<any[]>([]);
  const [measurementDates, setMeasurementDates] = useState<Date[]>([]);
  const [filterType, setFilterType] = useState<string>('Machine');
  const [filterOptions, setFilterOptions] = useState<string[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
  const [allOperators, setAllOperators] = useState<userType[]>([]);
  const [allMachines, setAllMachines] = useState<machineType[]>([]);
  const [allCustomers, setAllCustomers] = useState<string[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState<string>('en');
  const excelExportRef = useRef<ExcelExport | null>(null);

  const machines = useSelector((state: any) => state.machine.allMachines);

  useEffect(() => {
    const fetchAllMeasurements = async () => {
      try {
        const [allMeasurements, metaData] = await getMeasurements(t);
        console.log('All Measurements:', allMeasurements);
        console.log('MetaData:', metaData);
        const dates = allMeasurements.map((measurement: any) => new Date(measurement.doneAt));
        setMeasurementDates(dates);

        // Extract unique operators, machines, and customers
        const operatorsMap = new Map<string, userType>();
        const machinesMap = new Map<string, machineType>();
        const customersSet = new Set<string>();

        allMeasurements.forEach((measurement: any) => {
          if (measurement.creatorID) {
            operatorsMap.set(measurement.creatorID, {
              id: measurement.creatorID,
              companyID: measurement.creator.companyID,
              createdAt: measurement.creator.createdAt,
              email: measurement.creator.email,
              firstName: measurement.creator.firstName,
              lastName: measurement.creator.lastName,
              roles: measurement.creator.roles,
              syncedAt: measurement.creator.syncedAt,
              updatedAt: measurement.creator.updatedAt,
              sentToServer: measurement.creator.sentToServer,
              phone: measurement.creator.phone,
            });
          }
          if (measurement.machineID) {
            machinesMap.set(measurement.machineID, {
              id: measurement.machineID,
              name: measurement.machineNameTag,
              companyID: measurement.creator.companyID,
              description: measurement.machineDescription,
              status: measurement.machineStatus,
              coordinates: measurement.machineCoordinates,
              sentToServer: measurement.machineSentToServer,
              clientID: measurement.machineClientID,
              secretKey: measurement.machineSecretKey,
              nameTag: measurement.machineNameTag,
              kmCounter: measurement.machineKmCounter,
            });
          }
          if (measurement.plan && measurement.plan.customer) {
            customersSet.add(measurement.plan.customer);
          }
        });

        const updatedAllMachines = Array.from(machinesMap.values()).map((machine) => {
          const updatedMachine = machines.find((m: any) => m.id === machine.id);
          if (updatedMachine) {
            return {
              ...machine,
              name: updatedMachine.name,
              nameTag: updatedMachine.nameTag
            };
          }
          return machine;
        });

        setAllOperators(Array.from(operatorsMap.values()));
        setAllMachines(updatedAllMachines);
        setAllCustomers(Array.from(customersSet));
      } catch (error) {
        console.error('Error fetching all measurements:', error);
      }
    };

    fetchAllMeasurements();
  }, [t, machines]);

  useEffect(() => {
    // Fetch filter options based on the selected filter type
    const fetchFilterOptions = () => {
      let options: string[] = [];
      if (filterType === 'Machine') {
        options = allMachines.map(machine => machine.name || machine.id);
      } else if (filterType === 'Customer') {
        options = allCustomers.map(customer => customer || 'Unknown Customer');
      } else if (filterType === 'Operator') {
        options = allOperators.map(operator => {
          const name = `${operator.firstName} ${operator.lastName}`.trim();
          return name || operator.id;
        });
      }
      setFilterOptions(options);
    };

    fetchFilterOptions();
  }, [filterType, allMachines, allCustomers, allOperators]);

  const handleFetchMeasurements = async () => {
    // Fetching measurements for the actual Excel sheet
    if (dateRange.start && dateRange.end) {
      try {
        console.log(allCustomers)
        const selectedFilterID = filterType === 'Operator'
          ? allOperators.find(operator => `${operator.firstName} ${operator.lastName}`.trim() === selectedFilter)?.id || selectedFilter
          : filterType === 'Machine'
            ? allMachines.find(machine => machine.name === selectedFilter)?.id || selectedFilter
            : filterType === 'Customer'
              ? allCustomers.find(customer => customer === selectedFilter) || selectedFilter
              : undefined;
        console.log(selectedFilterID)

        const [filteredMeasurements, metaData] = await getMeasurements(
          t,
          filterType === 'Operator' ? selectedFilterID : undefined,
          filterType === 'Machine' ? selectedFilterID : undefined,
          undefined,
          filterType === 'Customer' ? selectedFilterID : undefined,
          dateRange.start,
          dateRange.end
        );
        console.log('Filtered Measurements:', filteredMeasurements);
        console.log('MetaData:', metaData);
        if (filteredMeasurements.length === 0) {
          setMeasurements([]);
          setAlertMessage('No measurements found for the selected date range.');
        } else {
          setAlertMessage(null);
          setMeasurements(filteredMeasurements);
          setRefreshKey(Math.random());
        }
      } catch (error) {
        setAlertMessage('Error fetching measurements. Please try again.');
        setMeasurements([]);
        console.error('Error fetching measurements:', error);
      }
    } else {
      setAlertMessage('Please select a date range.');
    }
  };

  const handleFilterChange = useCallback((e: RadioButtonChangeEvent) => {
    setFilterType(e.value);
    setRefreshKey(Math.random());
    setSelectedFilter(null);
  }, []);

  const handleLanguageChange = useCallback((e) => {
    setSelectedLanguage(e.value);
  }, []);

  const handleExport = async () => {
    const machineNameMap = new Map(allMachines.map(machine => [machine.id, machine.name || machine.nameTag]));

    const exportData = [
      {
        id: selectedLanguage === 'en' ? 'Usage Report for:' : 'Användningsrapport för:',
        distance: filterType === 'Machine' ? `Machine: ${selectedFilter}` : filterType === 'Customer' ? `Customer: ${selectedFilter}` : filterType === 'Operator' ? `Operator: ${selectedFilter}` : '',
        customer: "",
        trackNumber: '',
        machineID: `Date Range: ${dateRange.start?.toLocaleDateString()} - ${dateRange.end?.toLocaleDateString()}`,
        operator: '',
        operatorEmail: '',
        doneAt: '',
        place: '',
        startAt: '',
        endAt: '',
        trackPart: ''
      },
      {
        id: selectedLanguage === 'en' ? 'Total measurements: ' : 'Totala mätningar: ',
        distance: `${measurements.length}`,
        customer: '',
        trackNumber: '',
        machineID: '',
        operator: '',
        operatorEmail: '',
        doneAt: '',
        place: '',
        startAt: '',
        endAt: '',
        trackPart: ''
      },
      {
        id: '',
        distance: ``,
        customer: '',
        trackNumber: '',
        machineID: '',
        operator: '',
        operatorEmail: '',
        doneAt: '',
        place: '',
        startAt: '',
        endAt: '',
        trackPart: ''
      },
      // {
      //   id: selectedLanguage === 'en' ? 'Measurement ID' : 'Mätning ID',
      //   distance: selectedLanguage === 'en' ? 'Distance Measured (km)' : 'Uppmätt avstånd (km)',
      //   customer: selectedLanguage === 'en' ? 'Customer' : 'Kund',
      //   trackNumber: selectedLanguage === 'en' ? 'Track Designation' : 'Spårbeteckning',
      //   machineID: selectedLanguage === 'en' ? 'Machine Name' : 'Maskinnamn',
      //   operator: selectedLanguage === 'en' ? 'Operator' : 'Operatör',
      //   operatorEmail: selectedLanguage === 'en' ? 'Operator Email' : 'Operatörs e-post',
      //   doneAt: selectedLanguage === 'en' ? 'Done At' : 'Gjort vid',
      //   place: selectedLanguage === 'en' ? 'Place' : 'Plats',
      //   startAt: selectedLanguage === 'en' ? 'Start At (km.m)' : 'Start vid (km.m)',
      //   endAt: selectedLanguage === 'en' ? 'End At (km.m)' : 'Slut vid (km.m)',
      //   trackPart: selectedLanguage === 'en' ? 'Track Part' : 'Spårdel',
      // },
      ...measurements.map((measurement) => ({
        id: measurement.id,
        distance: measurement.kmCounter || 0,
        customer: measurement.plan.customer,
        trackNumber: measurement.plan.trackNumber,
        machineID: machineNameMap.get(measurement.machineID) || measurement.machineID,
        operator: `${measurement.creator.firstName} ${measurement.creator.lastName}`,
        operatorEmail: measurement.creator.email,
        doneAt: new Date(measurement.doneAt).toLocaleString(selectedLanguage === 'sv' ? 'sv-SE' : 'en-GB', {
          weekday: 'short',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        }),
        place: measurement.place,
        startAt: `${measurement.startAtKm} + ${measurement.startAtMeter}`,
        endAt: `${measurement.endAtKm} + ${measurement.endAtMeter}`,
        trackPart: measurement.plan.trackPart
      })),
      {
        id: selectedLanguage === 'en' ? 'Total' : 'Totalt',
        distance: measurements.reduce(
          (acc, measurement) => acc + measurement.kmCounter,
          0
        ),
        customer: '',
        trackNumber: '',
        machineID: '',
        operator: '',
        operatorEmail: '',
        doneAt: '',
        place: '',
        startAt: '',
        endAt: '',
        trackPart: ''
      },
    ];

    if (excelExportRef.current) {
      excelExportRef.current.save(exportData);
    }
  };

  const machineNameMap = new Map(allMachines.map(machine => [machine.id, machine.name || machine.nameTag]));

  const columnHeaders = {
    en: {
      id: "Measurement ID",
      startAt: "Start At",
      endAt: "End At",
      distance: "Distance Measured",
      customer: "Customer",
      machineID: "Machine Name",
      trackNumber: "Track Number",
      trackPart: "Track Part",
      operator: "Operator",
      operatorEmail: "Operator Email",
      doneAt: "Done At",
      place: "Place"
    },
    sv: {
      id: "Mätning ID",
      startAt: "Start vid",
      endAt: "Slut vid",
      distance: "Uppmätt avstånd",
      customer: "Kund",
      machineID: "Maskinnamn",
      trackNumber: "Spårbeteckning",
      trackPart: "Spårdel",
      operator: "Operatör",
      operatorEmail: "Operatörs e-post",
      doneAt: "Gjort vid",
      place: "Plats"
    }
  };

  const columns = [
    { field: "id", title: columnHeaders[selectedLanguage].id, width: "200px" },
    { field: "startAt", title: columnHeaders[selectedLanguage].startAt, width: "75px" },
    { field: "endAt", title: columnHeaders[selectedLanguage].endAt, width: "75px" },
    { field: "distance", title: columnHeaders[selectedLanguage].distance, width: "200px" },
    { field: "customer", title: columnHeaders[selectedLanguage].customer, width: "200px" },
    { field: "machineID", title: columnHeaders[selectedLanguage].machineID, width: "200px" },
    { field: "trackNumber", title: columnHeaders[selectedLanguage].trackNumber, width: "200px" },
    { field: "trackPart", title: columnHeaders[selectedLanguage].trackPart, width: "200px" },
    { field: "operator", title: columnHeaders[selectedLanguage].operator, width: "200px" },
    { field: "operatorEmail", title: columnHeaders[selectedLanguage].operatorEmail, width: "200px" },
    { field: "doneAt", title: columnHeaders[selectedLanguage].doneAt, width: "200px" },
    { field: "place", title: columnHeaders[selectedLanguage].place, width: "200px" }
  ];

  return (
    <div className="excel-report-container">
      <h1>{t("excel.exportReport")}</h1>
      <div className="date-range-picker">
        <DateRangePicker
          value={dateRange}
          calendarSettings={{ cell: (props) => <CustomCell {...props} measurementDates={measurementDates} /> }}
          onChange={(e) => setDateRange(e.value)}
          startDateInputSettings={{ label: 'Start Date' }}
          endDateInputSettings={{ label: 'End Date' }}
        />
      </div>
      <div className="filter-type">
        <h3>{t("excel.reportType")}</h3>
        <RadioButton
          name="filterType"
          value="Machine"
          checked={filterType === 'Machine'}
          label="Machine"
          onChange={handleFilterChange}
        />
        <RadioButton
          name="filterType"
          value="Customer"
          checked={filterType === 'Customer'}
          label="Customer"
          onChange={handleFilterChange}
        />
        <RadioButton
          name="filterType"
          value="Operator"
          checked={filterType === 'Operator'}
          label="Operator"
          onChange={handleFilterChange}
        />
      </div>
      <div className="filter-options">
        <DropDownList
          data={filterOptions}
          value={selectedFilter}
          onChange={(e) => setSelectedFilter(e.value)}
        />
      </div>
      <div className="buttons">
        <Button onClick={handleFetchMeasurements}>{t("excel.collectData")}</Button>
      </div>
      {alertMessage && (<div className="alerts">{alertMessage}</div>)}
      {measurements.length > 0 && (
        <div style={{ width: '100%', overflowX: 'auto' }}>
          <ExcelExport
            data={measurements.map(measurement => ({
              id: measurement.id,
              distance: measurement.kmCounter,
              customer: measurement.plan.customer,
              trackNumber: measurement.plan.trackNumber,
              machineID: machineNameMap.get(measurement.machineID) || measurement.machineID,
              operator: `${measurement.creator.firstName} ${measurement.creator.lastName}`,
              operatorEmail: measurement.creator.email,
              doneAt: new Date(measurement.doneAt).toLocaleString(selectedLanguage === 'sv' ? 'sv-SE' : 'en-GB', {
                weekday: 'short',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
              }),
              place: measurement.place,
              startAt: `${measurement.startAtKm} + ${measurement.startAtMeter}`,
              endAt: `${measurement.endAtKm} + ${measurement.endAtMeter}`,
              trackPart: measurement.plan.trackPart
            }))}
            ref={excelExportRef}
            fileName={`Usage Report ${dateRange.start?.toLocaleDateString("en-GB")} - ${dateRange.end?.toLocaleDateString("en-GB")}.xlsx`}
          >
            <Grid
              style={{ minWidth: '1200px' }}
              resizable={true}
              reorderable={true}
              sortable={true}
              groupable={true}
              data={measurements.map(measurement => ({
                id: measurement.id,
                distance: measurement.kmCounter,
                customer: measurement.plan.customer,
                trackNumber: measurement.plan.trackNumber,
                trackPart: measurement.plan.trackPart,
                machineID: machineNameMap.get(measurement.machineID) || measurement.machineID,
                operator: `${measurement.creator.firstName} ${measurement.creator.lastName}`,
                operatorEmail: measurement.creator.email,
                doneAt: new Date(measurement.doneAt).toLocaleString(selectedLanguage === 'sv' ? 'sv-SE' : 'en-GB', {
                  weekday: 'short',
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit'
                }),
                place: measurement.place,
                startAt: `${measurement.startAtKm} + ${measurement.startAtMeter}`,
                endAt: `${measurement.endAtKm} + ${measurement.endAtMeter}`,
              }))}
            >
              <GridToolbar>
                <div className="language-selector">
                  <p>{t("excel.selectLanguage")}: </p>
                  <div className="language-selector-buttons">

                    <RadioButton
                      name="selectedLanguage"
                      value="en"
                      checked={selectedLanguage === 'en'}
                      label="English"
                      onChange={handleLanguageChange}
                    />
                    <RadioButton
                      name="selectedLanguage"
                      value="sv"
                      checked={selectedLanguage === 'sv'}
                      label="Swedish"
                      onChange={handleLanguageChange}
                    />
                  </div>
                </div>
                <button className="BUTTON AddUserButtonForm" onClick={handleExport}> <span className="k-icon k-font-icon k-i-file-excel" /> {t("excel.export")} </button>
              </GridToolbar>
              {columns.map((col) => (
                <Column
                  key={col.field}
                  field={col.field}
                  title={col.title}
                  width={col.width}
                />
              ))}
            </Grid>
          </ExcelExport>
        </div>
      )}
    </div>
  );
};

export default ExcelReport;