import React, { useState, useEffect } from "react";
import "./style.scss";

import {
  Form,
  FormRenderProps,
  FormElement,
  Field,
  FieldRenderProps,
} from "@progress/kendo-react-form";
import {
  setUserHasChanged,
  setNotification,
  updateCurrentUserProperty,
} from "../../store/user/userSlice";

import { Input } from "@progress/kendo-react-inputs";

// Redux Stuff
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/store";
import { userType } from "../../store/user/types";
import { AppState } from "../../store/store";

import { roleEn2swe } from "../manageUsers/userList";
import { getHighestRoleTypeFromServerStruct } from "../../helpers/genericHelpers";
import * as userAPI from "./../../api/netRail/users";
import { UpdatePasswordForm } from "../manageUsers/updatePasswordForm";
import { CustomInputUser } from "../manageUsers/userForm";
import { useTranslation } from "react-i18next";

interface UpdateUserFormProps {
  user: userType;
}

const emailRegex = new RegExp(/\S+@\S+\.\S+/);
const nameRegex = new RegExp(/^[a-zA-ZåöäÅÄÖ]{1,30}$/);

export const UpdateUserForm: React.FunctionComponent<UpdateUserFormProps> = ({
  user,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const idCurrUser = useSelector(
    (state: AppState) => state.user.currentUser
  )?.id;
  const currentUser = useSelector(
    (state: AppState) => state.user.allContacts
  ).find((user) => user.id === idCurrUser);

  useEffect(() => {
    dispatch(setUserHasChanged(false));
    return () => {
      dispatch(setUserHasChanged(false));
    };
  }, []);
  const userHasChanged = useSelector(
    (state: AppState) => state.user.userHasChanged
  );

  const [allowEdit, setAllowEdit] = useState(false);

  const allCompanies = useSelector(
    (state: AppState) => state.company.allCompanies
  );

  const currentCompany = allCompanies.find(
    (company) => currentUser?.companyID === company.id
  );

  const phoneNumberValidator = (value?: string) => {
    if (value === undefined) return t("common.phoneValidator");

    return !new RegExp("\\+(46)\\d{9}$").test(value) && value.length < 12
      ? t("common.phoneValidatorFormat")
      : "";
  };
  const emailValidator = (value?: string) =>
    value && emailRegex.test(value) ? "" : t("common.emailValidator");

  const notUndefined = (value?: string) => {
    return value && value !== "" && nameRegex.test(value)
      ? ""
      : t("common.usernameValidator");
  };

  const [edit, setEdit] = useState(false);

  const UserPassword = (fieldRenderProps: FieldRenderProps) => {
    return (
      <div className="userCardRow">
        <Input
          label={t("loginPage.password").toString()}
          value={"DummyPassword"}
          disabled={true}
          type={"password"}
        />
      </div>
    );
  };

  const renderCurrentUserFormContent = (formRenderProps: FormRenderProps) => {
    const { valid } = formRenderProps;
    return (
      <>
        <FormElement className="userCardUser" style={{ width: "100%" }}>
          <div className="userCardColumnL">
            <legend className={"k-form-legend"} style={{ color: "black" }}>
              {user && user.sentToServer
                ? t("helpSideBarHeaders.updateUser")
                : t("manageUsers.addUser")}
            </legend>

            <div className="userCardRow">
              <div style={{ width: "45%", marginRight: "10%" }}>
                <Field
                  name={"firstName"}
                  component={CustomInputUser}
                  label={t("manageUsers.firstname").toString()}
                  validator={notUndefined}
                />
              </div>
              <div style={{ width: "45%" }}>
                <Field
                  name={"lastName"}
                  component={CustomInputUser}
                  label={t("manageUsers.surname").toString()}
                  validator={notUndefined}
                />
              </div>
            </div>
            <Field
              name={"email"}
              component={CustomInputUser}
              label={t("manageUsers.email").toString()}
              validator={emailValidator}
            />

            <Field
              name={"phone_number"}
              component={CustomInputUser}
              label={t("manageUsers.phone").toString()}
              validator={phoneNumberValidator}
            />
            <Field
              name={"companyName"}
              component={Input}
              label={t("manageCompanies.company").toString()}
              disabled={true}
            />
            {!valid ? (
              <div style={{ marginTop: "10px", color: "red" }}>
                {t("common.requiredFields")}
              </div>
            ) : null}
            <div className="AddUserButtonFormContainer">
              <button
                className="AddUserButtonForm BUTTON"
                type={"submit"}
                disabled={!valid || !userHasChanged}
                style={
                  !valid || !userHasChanged
                    ? {
                      opacity: 0.5,
                    }
                    : {}
                }
              >
                {t("common.update")}
              </button>
            </div>
          </div>
        </FormElement>
        {!allowEdit ? (
          <FormElement className="userCardUser" style={{ width: "100%" }}>
            <div className="userCardColumnL">
              <div className="userCardRow">
                <legend
                  className={"k-form-legend"}
                  style={{
                    color: "black",
                    marginTop: "30px",
                    marginBottom: "0px",
                  }}
                >
                  {user && user.sentToServer
                    ? t("helpSideBarHeaders.updatePassword")
                    : t("helpSideBarHeaders.addUser")}
                </legend>
                <span
                  className="k-icon k-font-icon k-i-change-manually editUser "
                  style={{
                    fontSize: "24px",
                    marginRight: "auto",
                    marginTop: "auto",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setAllowEdit(!allowEdit);
                  }}
                />
              </div>
              <Field
                name={"Lösenord"}
                component={UserPassword}
                label={t("loginPage.password")}
              />
            </div>
          </FormElement>
        ) : null}
      </>
    );
  };

  const openedContent = () => {
    return (
      <div className="formContainer">
        <span className="k-icon k-font-icon k-i-x editUser"
          style={{
            fontSize: "24px",
            marginRight: "auto",
            marginTop: "auto",
            float: "right",
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setEdit(false);
          }}
        >
        </span>
        <Form
          initialValues={{
            firstName: user?.firstName,
            lastName: user?.lastName,
            phone_number: user?.phone ? user?.phone : "+46",
            email: user?.email,
            roleType: user
              ? roleEn2swe(t, getHighestRoleTypeFromServerStruct(user.roles))
              : "",
            companyName: currentCompany ? currentCompany.name : "",
          }}
          key={JSON.stringify(user)}
          onSubmit={async (dataItem) => {
            if (dataItem && currentUser) {
              try {
                await userAPI.patchUser(currentUser.id, {
                  email: dataItem.email.toLowerCase(),
                  firstName: dataItem.firstName,
                  lastName: dataItem.lastName,
                  phone: dataItem.phone_number,
                });
                dispatch(
                  updateCurrentUserProperty({
                    email: dataItem.email.toLowerCase(),
                    firstName: dataItem.firstName,
                    lastName: dataItem.lastName,
                    phone: dataItem.phone_number,
                  })
                );

                dispatch(
                  setNotification({
                    message: t("manageUsers.userUpdated"),
                    style: "success",
                    open: true,
                  })
                );
              } catch {
                dispatch(
                  setNotification({
                    message: t("manageUsers.userNotUpdated"),
                    style: "error",
                    open: true,
                  })
                );
              }
            }
          }}
          render={(formRenderProps) =>
            renderCurrentUserFormContent(formRenderProps)
          }
        />
        {currentUser && allowEdit ? (
          <UpdatePasswordForm
            user={currentUser}
            allowEdit={allowEdit}
            setAllowEdit={setAllowEdit}
          />
        ) : (
          <div> </div>
        )}
      </div>
    );
  };

  const closedContent = () => {
    return (
      <div className="closedContentContainer">
        <div className="currUserCardUser">
          <h5 className="currUserCardRow">
            {user?.firstName !== ""
              ? user?.firstName + " " + user?.lastName
              : "-"}
          </h5>

          <div className="currUserCardRow">
            {user?.email !== "" ? user?.email : "-"}
          </div>
          <div className="currUserCardRow">
            {user?.roles
              ? roleEn2swe(t, getHighestRoleTypeFromServerStruct(user?.roles))
              : t("manageUsers.operator")}
          </div>
          <div className="currUserCardRow">
            {user?.phone ? user?.phone : "-"}
          </div>
        </div>
        <div className="currUserCardColumnR">
          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <span
              className="k-icon k-font-icon k-i-change-manually editUser"
              style={{
                fontSize: "24px",
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setEdit(true);
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="userForm">{edit ? openedContent() : closedContent()}</div>
  );
};
