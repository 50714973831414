import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState, useAppDispatch } from "../../store/store";
import { onLogin } from "../../store/user/userSlice";
import { fetchAuthSession } from "aws-amplify/auth";
import { t } from "i18next";

const PLATFORM_KEY = "selectedPlatform";

export const LandingPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector((state: AppState) => state.user.currentUser);

  const getHmiURL = () => {
    const ua = navigator.userAgent;
    return /iPad|iPhone|iPod/.test(ua) ? "http://tms19.local" : "http://tms19";
  };

  useEffect(() => {
    const routeAfterLogin = async () => {
      try {
        await fetchAuthSession();
        if (!currentUser) {
          dispatch(onLogin({ t }));
        }

        const selected = sessionStorage.getItem(PLATFORM_KEY);

        if (selected === "dashboard") {
          navigate("/scheduler", { replace: true });
        } else if (selected?.startsWith("http")) {
          window.location.href = selected;
        } else {
          navigate("/choose-platform", { replace: true });
        }
      } catch (err) {
        console.error("Auth session not found:", err);
        navigate("/login", { replace: true });
      }
    };

    routeAfterLogin();
  }, []);

  return null;
};
