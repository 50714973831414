import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../store/store";
import { cPhoneHeightLim, cPhoneWidthLim, cPhoneWidthLimStandard } from "../../../app";
import { color } from "../../../helpers/genericHelpers";
import { AppState } from "../../../store/store";
import { setSelectedChart, setTolerancesToDisplay } from "../../../store/plot/plotSlice";
import { SelectedTolerancesType } from "../../../store/plot/types";
import { measurementMetaData } from "../../../store/scheduler/types";
import "./toggleTolerances.css";

interface ToggleTolerancesProps {
  chartID: string;
}

export const emptyTolerances = {
  plan: false,
  uh1: false,
  uh2: false,
  krit: false,
  stopp: false,
};

export const ToggleTolerances: React.FunctionComponent<ToggleTolerancesProps> = ({ chartID }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  // Combine the selected charts into one hook for stability
  const selectedChart = useSelector((state: AppState) => {
    const chartFromCharts = state.plot.charts.find((chart) => chart.chartID === chartID);
    const chartFromSpecialCharts = state.plot.specialCharts.specialChartArray.find((chart) => chart.chartID === chartID);
    return chartFromCharts || chartFromSpecialCharts || null;
  });

  const width = useSelector((state: AppState) => state.scheduler.screenWidth);
  const height = useSelector((state: AppState) => state.scheduler.screenHeight);

  // Handle the case where no chart is found using a fallback mechanism
  const tolerancesToDisplay: SelectedTolerancesType = selectedChart?.tolerancesToDisplay || emptyTolerances;
  const measurementToDisplay = selectedChart?.measurementToDisplay || [];

  const scaleFactor = width >= cPhoneWidthLim
    ? 1
    : 1 - (cPhoneWidthLim - width) / cPhoneWidthLim > 0.75
    ? 1 - (cPhoneWidthLim - width) / cPhoneWidthLim
    : 0.75;

  // Ensures tolerances are reset when the component unmounts
  useEffect(() => {
    return () => {
      dispatch(
        setTolerancesToDisplay({
          plan: true,
          uh1: true,
          uh2: true,
          krit: true,
          stopp: true,
        })
      );
    };
  }, [dispatch]);

  const toleranceButton = (currentTolerance: keyof typeof tolerancesToDisplay) => {
    if (!selectedChart || !currentTolerance) {
      return null;
    }

    let numberOfErrors = 0;
    const allowedSignals = selectedChart.plotMetaData
      ? Object.keys(selectedChart.plotMetaData as measurementMetaData)
      : [];

    if (
      measurementToDisplay.length === 1 &&
      allowedSignals.includes(measurementToDisplay[0])
    ) {
      const measurementData = selectedChart.plotMetaData[measurementToDisplay[0]];
      if (measurementData) {
        numberOfErrors = measurementData[`total${currentTolerance.charAt(0).toUpperCase() + currentTolerance.slice(1)}`] || 0;
      }
    }

    const measToDisplayNoBIS = measurementToDisplay.filter(
      (filt: any) => filt !== "crossLevelBIS"
    );

    const opacity = (!tolerancesToDisplay[currentTolerance] || measToDisplayNoBIS.length > 1 || measToDisplayNoBIS.length === 0) ? 0.5 : 1;

    return (
      <div
        key={currentTolerance}
        className={
          measToDisplayNoBIS.length > 1 || measToDisplayNoBIS.length === 0
            ? "ToleranceSelectorButtonDisabled caption"
            : "ToleranceSelectorButton caption"
        }
        style={{ opacity }}
        onClick={() => {
          dispatch(setSelectedChart(chartID)); 
          dispatch(
            setTolerancesToDisplay({
              ...tolerancesToDisplay,
              [currentTolerance]: !tolerancesToDisplay[currentTolerance],
            })
          );
        }}
      >
        <div
          className="ToleranceSelectorLine"
          style={{ backgroundColor: color[currentTolerance] }}
        />
        <div
          style={{
            fontSize:
              width < cPhoneWidthLimStandard || height < cPhoneHeightLim
                ? "10px"
                : "14px",
            fontWeight: "bold",
            marginLeft: "5px",
          }}
        >
          {currentTolerance.toUpperCase()}
          {numberOfErrors > 0 ? ` (${numberOfErrors})` : ""}
        </div>
      </div>
    );
  };

  return (
    <div
      className="ToleranceSelection"
      style={{
        transform: `scale(${scaleFactor})`,
        minWidth: "200px",
        transformOrigin: `center`,
        paddingRight:
          width >= cPhoneWidthLim && height >= cPhoneHeightLim
            ? "100px"
            : width >= cPhoneWidthLim
            ? "45px"
            : "0px",
      }}
    >
      {["plan", "uh1", "uh2", "krit", "stopp"].map((tol) => toleranceButton(tol as keyof typeof tolerancesToDisplay))}
    </div>
  );
};
