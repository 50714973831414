import { Slide } from "@progress/kendo-react-animation";
import { TFunction } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/store";
import { cPhoneWidthLim } from "../../app";
import { AppState } from "../../store/store";
import { setInformationRequest } from "../../store/user/userSlice";
import { helpInformationPath } from "../../store/user/types";
import "./style.scss";
import SvgAnalysisGraph from "../componentIcons/AnalysisGraph";

const getHeader = (
  t: TFunction<"translation", "translation">,
  path?: string
) => {
  if (path === undefined) return;
  switch (path) {
    case helpInformationPath.UpdateMachineForm:
      return t("helpSideBarHeaders.updateMachine"); // OK
    case helpInformationPath.CreateMachineForm:
      return t("helpSideBarHeaders.createMachine");
    case helpInformationPath.UpdateUserForm:
      return t("helpSideBarHeaders.updateUser");
    case helpInformationPath.CreateUserForm:
      return t("helpSideBarHeaders.createUser");
    case helpInformationPath.PlanFormParameters:
      return t("helpSideBarHeaders.planParameters");
    case helpInformationPath.PlanFormMeasurement:
      return t("helpSideBarHeaders.planFormMeasurements");
    case helpInformationPath.PlanFormMiscellaneous:
      return t("helpSideBarHeaders.miscellaneous");
    case helpInformationPath.FinishedMeasurements:
      return t("helpSideBarHeaders.finishedMeasurements");
    case helpInformationPath.DashboardInformation:
      return t("helpSideBarHeaders.dashboardInformation");
    case helpInformationPath.updatePassword:
      return t("helpSideBarHeaders.updatePassword");
    case helpInformationPath.measurementTemperature:
      return t("helpSideBarHeaders.measurementTemperature");
    case helpInformationPath.currentLongitudinalLevel:
      return t("helpSideBarHeaders.longitudinalLevelAnalysis");
    case helpInformationPath.AnnouncementList:
      return t("helpSideBarHeaders.announcementList");
    default:
      return t("helpSideBarHeaders.error");
  }
};

const getText = (
  t: TFunction<"translation", "translation">,
  path?: string
) => {
  if (path === undefined) return;
  switch (path) {
    case helpInformationPath.UpdateMachineForm:
      return (
        <div className="helpText">{t("helpSideBarTexts.updateMachine")}</div>
      );
    case helpInformationPath.CreateMachineForm:
      return (
        <div className="helpText">{t("helpSideBarTexts.updateMachine")}</div>
      );

    case helpInformationPath.UpdateUserForm:
      return (
        <div className="helpText">{t("helpSideBarTexts.updateUser")} </div>
      );

    case helpInformationPath.CreateUserForm:
      return <div className="helpText">{t("helpSideBarTexts.createUser")}</div>;
    case helpInformationPath.PlanFormParameters:
      return (
        <div className="helpText">{t("helpSideBarTexts.planParameters")}</div>
      );
    case helpInformationPath.PlanFormMeasurement:
      return (
        <div className="helpText">{t("helpSideBarTexts.measurements")}</div>
      );
    case helpInformationPath.PlanFormMiscellaneous:
      return (
        <div className="helpText">{t("helpSideBarTexts.miscellaneous")}</div>
      );
    case helpInformationPath.FinishedMeasurements:
      return (
        <div className="helpText">
          {t("helpSideBarTexts.finishedMeasurements")}
          <h5 style={{ paddingTop: "10px" }} className="SideBarHeader">
            {t("helpSideBarHeaders.reprocess")}
          </h5>
          {t("helpSideBarTexts.reprocess")}
        </div>
      );
    case helpInformationPath.DashboardInformation:
      return (
        <div className="helpText">
          <p>{t("helpSideBarTexts.dashboardInfo.visualizeMeasurementsHere")}</p>

          <h5> {t("helpSideBarTexts.dashboardInfo.measurementListHeader")} </h5>
          <p>{t("helpSideBarTexts.dashboardInfo.measurementListInfo")}</p>

          <h5> {t("helpSideBarTexts.dashboardInfo.gpsHeader")} </h5>
          <p>{t("helpSideBarTexts.dashboardInfo.gpsInfo")}</p>

          <h5> {t("helpSideBarTexts.dashboardInfo.graphsHeader")} </h5>

          {/* CONTINUE TODO */}
          <p>
            <b>{t("dashboard.trackGeometry")}</b> <br />
            {t("helpSideBarTexts.dashboardInfo.graphsInfoAddGraph")}{" "}
            <img
              src="./icons/plus.svg"
              alt=""
              style={{
                height: "18px",
                backgroundColor: "white",
                borderRadius: "5px",
              }}
            />
            {t("helpSideBarTexts.dashboardInfo.graphsInfoAddGraph2")}{" "}
            <b>
              {t("helpSideBarTexts.dashboardInfo.graphsInfoAddGraphZoomBold")}
            </b>{" "}
            {t("helpSideBarTexts.dashboardInfo.graphsInfoAddGraphZoom")}{" "}
            <b>
              {t("helpSideBarTexts.dashboardInfo.graphsInfoAddGraphPanBold")}
            </b>{" "}
            {t("helpSideBarTexts.dashboardInfo.graphsInfoAddGraphPan")}{" "}
            {t("helpSideBarTexts.dashboardInfo.graphsInfoAddGraphReset")}{" "}
            <span className="k-icon k-font-icon k-i-home" />{" "}
            {t("helpSideBarTexts.dashboardInfo.graphsInfoAddGraphReset2")}
            <br />
            <br />
            <b style={{ position: "relative" }}>
              {t("dashboard.railLoweringAnalysis")}
            </b>
            <br />
            {t(
              "helpSideBarTexts.dashboardInfo.graphsInfoAddGraphLongitudinalLevel2"
            )}
          </p>
        </div>
      );
    case helpInformationPath.updatePassword:
      return (
        <div className="helpText">{t("helpSideBarTexts.updatePassword")}</div>
      );

    case helpInformationPath.measurementTemperature:
      return (
        <div className="helpText">
          {t("helpSideBarTexts.measurementTemperature")}
        </div>
      );
    case helpInformationPath.currentLongitudinalLevel:
      return (
        <div className="helpText">
          <p>{t("helpSideBarTexts.longitudinalLevelAnalysis.infoTextStart")}</p>

          <h6>{t("helpSideBarTexts.longitudinalLevelAnalysis.graphOne")}</h6>
          <p>{t("helpSideBarTexts.longitudinalLevelAnalysis.graphOneInfo")}</p>
          <h6>{t("helpSideBarTexts.longitudinalLevelAnalysis.graphTwo")}</h6>
          <p>{t("helpSideBarTexts.longitudinalLevelAnalysis.graphTwoInfo")}</p>
          <h6>{t("helpSideBarTexts.longitudinalLevelAnalysis.graphThree")}</h6>
          <p>
            {t("helpSideBarTexts.longitudinalLevelAnalysis.graphThreeInfo")}
          </p>

          <h6>
            {t(
              "helpSideBarTexts.longitudinalLevelAnalysis.generalInfoTextHeader"
            )}
          </h6>
          <p>
            <b>
              {t(
                "helpSideBarTexts.longitudinalLevelAnalysis.generalInfoTextZoom"
              )}
            </b>{" "}
            {t("helpSideBarTexts.longitudinalLevelAnalysis.generalInfoTextAnd")}{" "}
            <b>
              {t(
                "helpSideBarTexts.longitudinalLevelAnalysis.generalInfoTextPan"
              )}
            </b>{" "}
            {t("helpSideBarTexts.longitudinalLevelAnalysis.generalInfoText")}
          </p>
        </div>
      );
    case helpInformationPath.AnnouncementList:
      return (
        <div className="helpTextContainer">
          {t("helpSideBarTexts.announcementList")}
        </div>
      );
    default:
      return "Fel";
  }
};

export const HelpSideBar: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const informationRequest = useSelector(
    (state: AppState) => state.user.informationRequest
  );
  const dispatch = useAppDispatch();
  const text = getText(t, informationRequest?.path);
  const header = getHeader(t, informationRequest?.path);

  const screenWidth = useSelector(
    (state: AppState) => state.scheduler.screenWidth
  );

  const helpPagePhoneOrPCStyle =
    screenWidth > cPhoneWidthLim
      ? { width: "300px" } // PC
      : { width: "100%", borderLeft: "none" }; // Phone

  const helpPageContent = informationRequest?.showInformation ? (
    <div
      className="HelpSideBarContainer"
      style={helpPagePhoneOrPCStyle}
      onClick={(e) => {
        e.stopPropagation();
        dispatch(
          setInformationRequest({
            path: undefined,
            showInformation: false,
          })
        );
      }}
    >
      <h5 className="SideBarHeader">
        {header}
        <span
          className="k-icon k-font-icon k-i-close closeHelpButton"
          style={{ fontSize: "24px" }}
          onClick={() => {
            dispatch(
              setInformationRequest({
                path: undefined,
                showInformation: false,
              })
            );
          }}
        />
      </h5>
      <p className="SideBarText">{text}</p>
    </div>
  ) : null;

  const animation = (
    <Slide direction={"left"} className="SlideContent">
      {helpPageContent}
    </Slide>
  );

  return animation;
};
