import React, { useState } from "react";
import { measurementProps } from "../../../../store/scheduler/types";
import "./externalLinkShare.css";
import rest from "./../../../../rest";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { FloatingLabel } from "@progress/kendo-react-labels";
import {
  Field,
  FieldRenderProps,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Fade } from "@progress/kendo-react-animation";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../store/store";
import { Popup } from "@progress/kendo-react-popup";
import { useTranslation } from "react-i18next";
import { ListItemProps, MultiSelect } from "@progress/kendo-react-dropdowns";
import { AppState } from "../../../../store/store";
import { setNotification } from "../../../../store/user/userSlice";
import { updateMeasurementStatus, updateMeasurementProperty } from "../../../../store/scheduler/schedulerSlice";
import * as measurementsAPI from "./../../../../api/netRail/measurements";

interface ShareLinkPopupProps {
  measurement: measurementProps;
  show: boolean;
  setShow: any;
}

export interface sharedLinkBody {
  email: boolean;
  expiration?: number;
  receivers?: string[];
  message?: string;
}

const emailRegex = new RegExp(/\S+@\S+/);

export const ShareLinkPopup: React.FC<ShareLinkPopupProps> = ({
  measurement,
  show,
  setShow,
}) => {
  const { t } = useTranslation();

  const [shareState, setshareState] = useState("");
  const [msgState, setmsgState] = useState("");
  const [showLink, setshowLink] = useState(false);
  const [receivers, setReceivers] = useState<string[]>([]);
  const [copyLinkFlag, setcopyLinkFlag] = useState(false);
  const [emailListValid, setEmailListValid] = useState(true);
  const [emailListEmpty, setemailListEmpty] = useState(true);
  const dispatch = useAppDispatch();
  const allRecieversEmails = useSelector(
    (state: AppState) => state.user.allContacts
  ).map((contact) => contact.email);

  const savedRecieversEmails = localStorage.getItem("savedRecievers");
  const savedRecieversEmailsArr: string[] = JSON.parse(
    savedRecieversEmails != null ? savedRecieversEmails : "[]"
  );

  const unionOfEmails = Array.from(
    new Set([...allRecieversEmails, ...savedRecieversEmailsArr])
  );
  const [recieverData, setRecieverData] = useState<string[]>(unionOfEmails);

  const requestShareLink = (email: boolean) => {
    return new Promise<string>(async (resolve, reject) => {
      let data: sharedLinkBody = {
        email: email,
        expiration: 1344, // 2 Months hardcoded requested from Kristoffer 30-08-2024
      };

      if (email) {
        data = { ...data, receivers: receivers, message: msgState };
      }

      rest
        .post(`/measurements/${measurement.id}/share`, data)
        .then(async (response) => {
          setshareState(response.data.link);
          resolve(response.data.link);
          if (email) {
            //Append new receivers to measurement in DB
            let tmp = measurement.receivers.concat(receivers);
            tmp = tmp.filter((item,
              index) => tmp.indexOf(item) === index);
            const tmpMeas = {...measurement};          
            tmpMeas.receivers = tmp;

            dispatch(
              updateMeasurementStatus({
                id: measurement.id,
                properties: {
                  receivers: tmp
                },
              })
            );
          
            await measurementsAPI.patchMeasurement(tmpMeas);
            
            dispatch(
              setNotification({
                style: "success",
                message: t("externalLinkShare.messageSent"),
                open: true,
              })
            );
          }
        })
        .catch((error) => {
          reject(error);
          if (email) {
            dispatch(
              setNotification({
                style: "warning",
                message: t("externalLinkShare.messageNotSent"),
                open: true,
              })
            );
          }
        });
    });
  };

  const filterChange = (event: any) => {
    setRecieverData(
      unionOfEmails
        .filter((reciever) => {
          if (
            reciever.toLowerCase().includes(event.filter.value.toLowerCase())
          ) {
            return true;
          } else {
            return false;
          }
        })
        .map((contact) => {
          return contact;
        })
    );
  };

  const handleChangeTextArea = (event: any) => {
    setmsgState(event.target.value);
  };

  const handleChange = (event: any, path?: string) => {
    // const values = event.target.value; // a b
    let values = event.target.value.filter((val: string) =>
      emailRegex.test(val)
    );
    if (path === "onClose") {
      values.length === 0
        ? (values = [event.target.state.text])
        : values.push(event.target.state.text);
    }
    const lastItem = values[values.length - 1]; //b
    let valuesValidity: boolean[];

    if (values.length === 0) {
      if (lastItem) {
        values.pop();
        const sameItem = values.find((value: string) => value === lastItem);
        if (sameItem === undefined) {
          values.push(lastItem);
          if (!unionOfEmails.includes(lastItem) && emailRegex.test(lastItem)) {
            savedRecieversEmailsArr.push(lastItem);
            localStorage.setItem(
              "savedRecievers",
              JSON.stringify(savedRecieversEmailsArr)
            );
          }
        }
      }
      setReceivers(values);
      setemailListEmpty(true);
      setEmailListValid(true);
    } else {
      if (lastItem) {
        values.pop();
        const sameItem = values.find((value: string) => value === lastItem);
        if (sameItem === undefined) {
          values.push(lastItem);
          if (!unionOfEmails.includes(lastItem) && emailRegex.test(lastItem)) {
            savedRecieversEmailsArr.push(lastItem);
            localStorage.setItem(
              "savedRecievers",
              JSON.stringify(savedRecieversEmailsArr)
            );
          }
        }
      }
      setReceivers(values.filter((val: string) => emailRegex.test(val)));
      valuesValidity = values
        .filter((val: string) => emailRegex.test(val))
        .map((value: any) => {
          return emailRegex.test(value);
        });
      setemailListEmpty(false);
      setEmailListValid(!valuesValidity.includes(false));
    }
  };

  const itemRenderDropDown = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const savedRecieversEmails = localStorage.getItem("savedRecievers");
    const savedRecieversEmailsArr: string[] = JSON.parse(
      savedRecieversEmails != null ? savedRecieversEmails : "[]"
    );
    if (savedRecieversEmailsArr.includes(itemProps.dataItem)) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "center",
            paddingRight: "10px",
          }}
        >
          <div style={{ width: "90%" }}>{li}</div>
          <span
            className="k-icon k-font-icon k-i-close"
            style={{ fontSize: "25px", width: "10%" }}
            onClick={() => {
              const emailToDelete = savedRecieversEmailsArr.find(
                (email) => email === itemProps.dataItem
              );
              setRecieverData((emails) =>
                emails.filter((email) => emailToDelete !== email)
              );
              localStorage.setItem(
                "savedRecievers",
                JSON.stringify(
                  savedRecieversEmailsArr.filter(
                    (email) => email !== itemProps.dataItem
                  )
                )
              );
            }}
          />
        </div>
      );
    } else {
      return li;
    }
  };

  const renderSharePopUpContent = (formRenderProps: FormRenderProps) => {
    return (
      <FormElement style={{ height: "100%", position: "relative" }}>
        <fieldset className={"k-form-fieldset"}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                opacity: "75%",
              }}
            >
              {t("externalLinkShare.linkExpiresIn")}
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
            >
              {t("externalLinkShare.twoMonths")}
            </div>
          </div>
          <FloatingLabel
            label={t("externalLinkShare.sendResultsTo").toString()}
            editorId="mailArea"
            editorValue={receivers ? receivers[0] : ""}
            id="mailAreaLabel"
            style={{ position: "relative" }}
          >
            <MultiSelect
              data={recieverData.filter((val: string) => emailRegex.test(val))}
              onChange={(e) => {
                handleChange(e);
              }}
              value={receivers.filter((val: string) => emailRegex.test(val))}
              allowCustom={true}
              id={"mailArea"}
              itemRender={itemRenderDropDown}
              required={true}
              valid={emailListValid}
              disabled={copyLinkFlag}
              onFilterChange={filterChange}
              filterable={true}
              popupSettings={{ className: "popUpFilter" }}
              onClose={(e) => {
                if (e.target.state.text) {
                  handleChange(e, "onClose");
                }
              }}
            />
          </FloatingLabel>
          {!emailListValid ? (
            <div
              style={{
                position: "absolute",
                height: "14px",
                fontSize: "10px",
                color: "red",
                left: "10px",
              }}
            >
              {t("externalLinkShare.supplyValidEmail")}
            </div>
          ) : (
            <div style={{ height: "14px", position: "absolute" }}></div>
          )}

          <FloatingLabel
            label={t("externalLinkShare.message").toString()}
            editorId="textArea"
            editorValue={msgState}
            id="textAreaLabel"
          >
            <div style={{ marginBottom: "2px" }}></div>
            <textarea
              id={"textarea"}
              value={msgState}
              onChange={(e) => {
                handleChangeTextArea(e);
              }}
              maxLength={100}
              style={{ maxHeight: "300px" }}
            />
          </FloatingLabel>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <button
              className="MeasurementButton BUTTON"
              onClick={() => {
                setmsgState("");
                setReceivers([]);
                setcopyLinkFlag(false);
                setshareState("");
                setemailListEmpty(false);
                requestShareLink(true);
              }}
              disabled={!emailListValid || emailListEmpty}
              style={{
                opacity: !emailListValid || emailListEmpty ? "0.5" : "1",
              }}
            >
              {t("common.send")}
            </button>
            <div style={{ width: "100%" }}></div>
          </div>
          {copyLinkContent}
        </fieldset>
      </FormElement>
    );
  };

  const linkCopied = showLink ? (
    <div className="content">{t("externalLinkShare.copied")}</div>
  ) : null;
  const animationLinkCopied = <Fade>{linkCopied}</Fade>;

  const input: any = document.getElementById("text-box");

  const copyLinkContent = copyLinkFlag ? (
    <div className="copyContainer">
      <Input
        label={t("externalLinkShare.link").toString()}
        style={{ width: "100%" }}
        value={shareState}
        id="text-box"
        onClick={(e) => {
          if (input) {
            input.focus();
            input.setSelectionRange(0, shareState.length);
          }
          e.preventDefault();
          e.stopPropagation();
        }}
      />

      {window.location.protocol === "https:" ? (
        <div className="buttonContainer" style={{ position: "relative" }}>
          <button
            className="copy BUTTON"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              navigator.clipboard.writeText(shareState);
              setshowLink(true);
              setTimeout(function () {
                setshowLink(false);
              }, 2000);
            }}
          >
            <img src={"../../icons/copyIcon.svg"} alt={"copy"}></img>
          </button>
          <div className="animationContent">{animationLinkCopied}</div>
        </div>
      ) : (
        <div />
      )}
    </div>
  ) : (
    <div className="copyContainer">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "flex-end",
          opacity: "75%",
        }}
      >
        {t("externalLinkShare.orCopyLink").toString()}
      </div>
      <div className="buttonContainer" style={{ position: "relative" }}>
        <button
          className="copy BUTTON"
          onClick={async (e) => {
            e.preventDefault();
            e.stopPropagation();
            setcopyLinkFlag(true);
            const shareLink = await requestShareLink(false);
            if (window.location.protocol === "https:") {
              navigator.clipboard.writeText(shareLink);
              setshowLink(true);
              setemailListEmpty(true);
              setTimeout(function () {
                setshowLink(false);
              }, 2000);
            }
          }}
        >
          <img src={"../../icons/copyIcon.svg"} alt="copy"></img>
        </button>
        <div className="animationContent">{animationLinkCopied}</div>
      </div>
    </div>
  );

  return (
    <div>
      <div>
        <span
          onClick={(e) => {
            setShow(!show);
          }}
        ></span>
      </div>

      <Popup
        show={show}
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(120, 120, 120, 0.5)",
        }}
        onClose={() => {
          setmsgState("");
          setReceivers([]);
          setcopyLinkFlag(false);
          setshareState("");
          setemailListEmpty(true);
          setEmailListValid(true);
        }}
      >
        <div
          className="PopupContentShare"
          onDoubleClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <span
            className="k-icon k-font-icon k-i-close removeChart"
            style={{ marginLeft: "auto", fontSize: "24px" }}
            onClick={() => {
              setShow(!show);
            }}
          />
          <Form
            render={(formRenderProps) =>
              renderSharePopUpContent(formRenderProps)
            }
          />
        </div>
      </Popup>
    </div>
  );
};
