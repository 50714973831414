import React, { useState } from "react";
import {
  Form,
  FormElement,
  Field,
  FieldRenderProps,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { Error } from "@progress/kendo-react-labels";

// Redux Stuff
import {
  saveCompanyToServer,
  deleteCompany,
  setSelectedCompany,
} from "../../store/company/companySlice";
import { useAppDispatch } from "../../store/store";
import { useSelector } from "react-redux";
import { AppState } from "../../store/store";
import { companyType } from "../../store/company/types";
import { useTranslation } from "react-i18next";

const nameRegex = new RegExp(/^[0-9a-zA-ZåöäÅÄÖ ]{1,30}$/);
const nameValidator = (value: string) =>
  nameRegex.test(value) ? "" : "Please enter a valid name.";

interface CompanyFormProps {
  setNewCompanyAdded: React.Dispatch<React.SetStateAction<boolean>>;
}

const CompanyInput = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div className="k-form-field-wrap">
      <Input {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

export const CompanyForm: React.FunctionComponent<CompanyFormProps> = ({
  setNewCompanyAdded,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [companyHasChanged, setCompanyHasChanged] = useState(false);

  const selectedCompany = useSelector(
    (state: AppState) => state.company.selectedCompany
  );
  const currentCompany = useSelector(
    (state: AppState) => state.company.currentCompany
  );

  const allCompanies = useSelector(
    (state: AppState) => state.company.allCompanies
  ).filter((company) => company.id !== currentCompany?.id);

  const company = useSelector((state: AppState) =>
    state.company.allCompanies.find((company) => company.id === selectedCompany)
  );

  const currentUser = useSelector((state: AppState) => state.user.currentUser);

  const handleBlur = () => {
    setCompanyHasChanged(true);
  };

  const handleSubmit = (dataItem: any) => {
    const company = allCompanies.find(
      (company) => company.id === selectedCompany
    );

    if (dataItem && currentUser) {
      dispatch(
        saveCompanyToServer(
          t,
          dataItem.name,
          dataItem.type,
          dataItem?.description,
          company?.id,
          company?.sentToServer
        )
      );
      setNewCompanyAdded(true);
    }
  };

  return (
    <Form
      initialValues={{
        name: company?.name,
        description: company?.description,
        type: "inspection",
      }}
      onSubmit={handleSubmit}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement className="userCardUser" style={{ width: "100%" }}>
          <div className="userCardColumnL">
            <legend className={"k-form-legend"} style={{ color: "black" }}>
              {company && company.sentToServer
                ? t("common.update") + " " + t("manageCompanies.company")
                : t("common.add") + " " + t("manageCompanies.company")}
            </legend>
            <Field
              name={"name"}
              component={CompanyInput}
              onChange={handleBlur}
              label={t("manageCompanies.companyName")}
              validator={nameValidator}
            />
            <Field
              name={"description"}
              component={CompanyInput}
              onChange={handleBlur}
              label={t("manageCompanies.companyDescription")}
            />

            {!formRenderProps.valid && (
              <div style={{ marginTop: "10px", color: "red" }}>
                {t("common.requiredFields")}
              </div>
            )}
          </div>
          <div className="CardColumnForm">
            {company && company.sentToServer ? (
              <Button
                className="AddUserButtonForm BUTTON"
                type={"submit"}
                disabled={!formRenderProps.valid || !companyHasChanged}
                style={
                  !formRenderProps.valid || !companyHasChanged
                    ? {
                        opacity: 0.5,
                      }
                    : {}
                }
              >
                {t("common.update")}
              </Button>
            ) : (
              <Button
                className="AddUserButtonForm BUTTON"
                type={"submit"}
                disabled={!formRenderProps.valid}
                style={
                  !formRenderProps.valid
                    ? {
                        opacity: 0.5,
                      }
                    : {}
                }
              >
                {t("common.add")}
              </Button>
            )}
          </div>
        </FormElement>
      )}
    />
  );
};