import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./style.scss";

export const NotFound: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const goToHome = () => {
    navigate("/login");
  };

  return (
    <div className="container">
      <div className="container-not-found">
        <h1 className="header-1">404</h1>
        <h2 className="header-2">{t("notFoundPage.header")}</h2>
        <p>{t("notFoundPage.info")}</p>
        <button className="btn1 pretty" onClick={goToHome}>
          {t("notFoundPage.home")}
        </button>
      </div>
    </div>
  );
};
