import React from "react";
import { useNavigate } from "react-router-dom";
import "./platformSelectionPage.css";

const PLATFORM_KEY = "selectedPlatform";

export const PlatformSelectionPage: React.FC = () => {
    const navigate = useNavigate();

    const getHmiURL = (): string => {
        const ua = navigator.userAgent;
        if (/iPad|iPhone|iPod/.test(ua)) return "http://tms19.local";
        return "http://tms19";
    };

    const handleSelection = (value: "dashboard" | "hmi") => {
        if (value === "dashboard") {
            sessionStorage.setItem(PLATFORM_KEY, value);
            navigate("/scheduler");
        } else {
            const hmiURL = getHmiURL();
            if (hmiURL) {
                sessionStorage.setItem(PLATFORM_KEY, hmiURL);
                window.location.href = hmiURL;
            }
        }
    };

    return (
        <div className="platform-selection">
            <div className="platform-buttons">
                <button className="platform-button" onClick={() => handleSelection("hmi")}>
                    <div className="platform-selection-border-hmi platform-selection-border">
                        <p>HMI</p>
                    </div >
                </button>
                <button className="platform-button" onClick={() => handleSelection("dashboard")}>
                    <div className="platform-selection-border-dashboard platform-selection-border">
                        <p>Dashboard</p>
                    </div>
                </button>
            </div>
        </div>
    );
};
