import { ChartType, objectTypes } from './types';
import { scaleLinear } from 'd3-scale';
import { v4 as uuidv4 } from 'uuid';

const initialChartID = uuidv4();

const initialState: ChartType = {
    charts: [
        {
            chartID: "999999",
            finishedLoading: true,
            plotData: {
                trackGauge: [],
                crossLevel: [],
                crossLevelBIS: [],
                crossLevelUnevenness: [],
                alignment: [],
                longitudinalLevel: [],
                alignmentRight: [],
                longitudinalLevelRight: [],
                alignmentLeft: [],
                longitudinalLevelLeft: [],
                twist3m: [],
                twist6m: [],
                longitudinalAngleHP: [],
            },
            plotMetaData: {
                trackGauge: {
                    endKm: 0,
                    endMeter: 0,
                    endTick: "0",
                    startKm: 0,
                    startMeter: 0,
                    startTick: "0",
                    totalKrit: 0,
                    totalPlan: 0,
                    totalStop: 0,
                    totalUh1: 0,
                    totalUh2: 0,
                },
                crossLevel: {
                    endKm: 0,
                    endMeter: 0,
                    endTick: "0",
                    startKm: 0,
                    startMeter: 0,
                    startTick: "0",
                    totalKrit: 0,
                    totalPlan: 0,
                    totalStop: 0,
                    totalUh1: 0,
                    totalUh2: 0,
                },
                crossLevelBIS: {
                    endKm: 0,
                    endMeter: 0,
                    endTick: "0",
                    startKm: 0,
                    startMeter: 0,
                    startTick: "0",
                    totalKrit: 0,
                    totalPlan: 0,
                    totalStop: 0,
                    totalUh1: 0,
                    totalUh2: 0,
                },
                crossLevelUnevenness: {
                    endKm: 0,
                    endMeter: 0,
                    endTick: "0",
                    startKm: 0,
                    startMeter: 0,
                    startTick: "0",
                    totalKrit: 0,
                    totalPlan: 0,
                    totalStop: 0,
                    totalUh1: 0,
                    totalUh2: 0,
                },
                longitudinalLevel: {
                    endKm: 0,
                    endMeter: 0,
                    endTick: "0",
                    startKm: 0,
                    startMeter: 0,
                    startTick: "0",
                    totalKrit: 0,
                    totalPlan: 0,
                    totalStop: 0,
                    totalUh1: 0,
                    totalUh2: 0,
                },
                alignment: {
                    endKm: 0,
                    endMeter: 0,
                    endTick: "0",
                    startKm: 0,
                    startMeter: 0,
                    startTick: "0",
                    totalKrit: 0,
                    totalPlan: 0,
                    totalStop: 0,
                    totalUh1: 0,
                    totalUh2: 0,
                },
                longitudinalLevelLeft: {
                    endKm: 0,
                    endMeter: 0,
                    endTick: "0",
                    startKm: 0,
                    startMeter: 0,
                    startTick: "0",
                    totalKrit: 0,
                    totalPlan: 0,
                    totalStop: 0,
                    totalUh1: 0,
                    totalUh2: 0,
                },
                alignmentLeft: {
                    endKm: 0,
                    endMeter: 0,
                    endTick: "0",
                    startKm: 0,
                    startMeter: 0,
                    startTick: "0",
                    totalKrit: 0,
                    totalPlan: 0,
                    totalStop: 0,
                    totalUh1: 0,
                    totalUh2: 0,
                },
                longitudinalLevelRight: {
                    endKm: 0,
                    endMeter: 0,
                    endTick: "0",
                    startKm: 0,
                    startMeter: 0,
                    startTick: "0",
                    totalKrit: 0,
                    totalPlan: 0,
                    totalStop: 0,
                    totalUh1: 0,
                    totalUh2: 0,
                },
                alignmentRight: {
                    endKm: 0,
                    endMeter: 0,
                    endTick: "0",
                    startKm: 0,
                    startMeter: 0,
                    startTick: "0",
                    totalKrit: 0,
                    totalPlan: 0,
                    totalStop: 0,
                    totalUh1: 0,
                    totalUh2: 0,
                },
                twist6m: {
                    endKm: 0,
                    endMeter: 0,
                    endTick: "0",
                    startKm: 0,
                    startMeter: 0,
                    startTick: "0",
                    totalKrit: 0,
                    totalPlan: 0,
                    totalStop: 0,
                    totalUh1: 0,
                    totalUh2: 0,
                },
                twist3m: {
                    endKm: 0,
                    endMeter: 0,
                    endTick: "0",
                    startKm: 0,
                    startMeter: 0,
                    startTick: "0",
                    totalKrit: 0,
                    totalPlan: 0,
                    totalStop: 0,
                    totalUh1: 0,
                    totalUh2: 0,
                },
            },
            measurementToDisplay: [], //["trackGauge"],
            tolerancesToDisplay: {
                plan: true,
                uh1: true,
                uh2: true,
                krit: true,
                stopp: true,
            },
            signalInterval: {
                xMinOrig: 0,
                xMaxOrig: 1,
                xMin: 0,
                xMax: 1,
                resolution: 1,
            },
        },

        {
            chartID: initialChartID,
            finishedLoading: true,
            plotData: {
                trackGauge: [],
                crossLevel: [],
                crossLevelBIS: [],
                crossLevelUnevenness: [],
                alignment: [],
                longitudinalLevel: [],
                alignmentRight: [],
                longitudinalLevelRight: [],
                alignmentLeft: [],
                longitudinalLevelLeft: [],
                twist3m: [],
                twist6m: [],
                longitudinalAngleHP: [],
            },
            plotMetaData: {
                trackGauge: {
                    endKm: 0,
                    endMeter: 0,
                    endTick: "0",
                    startKm: 0,
                    startMeter: 0,
                    startTick: "0",
                    totalKrit: 0,
                    totalPlan: 0,
                    totalStop: 0,
                    totalUh1: 0,
                    totalUh2: 0,
                },
                crossLevel: {
                    endKm: 0,
                    endMeter: 0,
                    endTick: "0",
                    startKm: 0,
                    startMeter: 0,
                    startTick: "0",
                    totalKrit: 0,
                    totalPlan: 0,
                    totalStop: 0,
                    totalUh1: 0,
                    totalUh2: 0,
                },
                crossLevelBIS: {
                    endKm: 0,
                    endMeter: 0,
                    endTick: "0",
                    startKm: 0,
                    startMeter: 0,
                    startTick: "0",
                    totalKrit: 0,
                    totalPlan: 0,
                    totalStop: 0,
                    totalUh1: 0,
                    totalUh2: 0,
                },
                crossLevelUnevenness: {
                    endKm: 0,
                    endMeter: 0,
                    endTick: "0",
                    startKm: 0,
                    startMeter: 0,
                    startTick: "0",
                    totalKrit: 0,
                    totalPlan: 0,
                    totalStop: 0,
                    totalUh1: 0,
                    totalUh2: 0,
                },
                longitudinalLevel: {
                    endKm: 0,
                    endMeter: 0,
                    endTick: "0",
                    startKm: 0,
                    startMeter: 0,
                    startTick: "0",
                    totalKrit: 0,
                    totalPlan: 0,
                    totalStop: 0,
                    totalUh1: 0,
                    totalUh2: 0,
                },
                alignment: {
                    endKm: 0,
                    endMeter: 0,
                    endTick: "0",
                    startKm: 0,
                    startMeter: 0,
                    startTick: "0",
                    totalKrit: 0,
                    totalPlan: 0,
                    totalStop: 0,
                    totalUh1: 0,
                    totalUh2: 0,
                },
                longitudinalLevelLeft: {
                    endKm: 0,
                    endMeter: 0,
                    endTick: "0",
                    startKm: 0,
                    startMeter: 0,
                    startTick: "0",
                    totalKrit: 0,
                    totalPlan: 0,
                    totalStop: 0,
                    totalUh1: 0,
                    totalUh2: 0,
                },
                alignmentLeft: {
                    endKm: 0,
                    endMeter: 0,
                    endTick: "0",
                    startKm: 0,
                    startMeter: 0,
                    startTick: "0",
                    totalKrit: 0,
                    totalPlan: 0,
                    totalStop: 0,
                    totalUh1: 0,
                    totalUh2: 0,
                },
                longitudinalLevelRight: {
                    endKm: 0,
                    endMeter: 0,
                    endTick: "0",
                    startKm: 0,
                    startMeter: 0,
                    startTick: "0",
                    totalKrit: 0,
                    totalPlan: 0,
                    totalStop: 0,
                    totalUh1: 0,
                    totalUh2: 0,
                },
                alignmentRight: {
                    endKm: 0,
                    endMeter: 0,
                    endTick: "0",
                    startKm: 0,
                    startMeter: 0,
                    startTick: "0",
                    totalKrit: 0,
                    totalPlan: 0,
                    totalStop: 0,
                    totalUh1: 0,
                    totalUh2: 0,
                },
                twist6m: {
                    endKm: 0,
                    endMeter: 0,
                    endTick: "0",
                    startKm: 0,
                    startMeter: 0,
                    startTick: "0",
                    totalKrit: 0,
                    totalPlan: 0,
                    totalStop: 0,
                    totalUh1: 0,
                    totalUh2: 0,
                },
                twist3m: {
                    endKm: 0,
                    endMeter: 0,
                    endTick: "0",
                    startKm: 0,
                    startMeter: 0,
                    startTick: "0",
                    totalKrit: 0,
                    totalPlan: 0,
                    totalStop: 0,
                    totalUh1: 0,
                    totalUh2: 0,
                },
            },
            measurementToDisplay: [], //["trackGauge"],
            tolerancesToDisplay: {
                plan: true,
                uh1: true,
                uh2: true,
                krit: true,
                stopp: true,
            },
            signalInterval: {
                xMinOrig: 0,
                xMaxOrig: 1,
                xMax: 1,
                xMin: 0,
                resolution: 0,
            },
        },
    ],

    specialCharts: {
        specialChartArray: [],
        xAxisIndex: 0,
        longitudinalLevelChartID: "LLplt1",
        longitudinalAngleChartID: "LAplt1",
        windowSize: 30,
        initialWindowPositon: true,
        windowXPosition: 70,
        maxWindowSize: 30,
        viewSpecialCharts: false,
    },
    globalSignalInterval: {
        xMinOrig: 0,
        xMaxOrig: 0,
        xMax: 0,
        xMin: 0,
        resolution: 0,
    },
    globalXScale: {
        mainXScale: scaleLinear().domain([0, 1]).range([0, 1]),
        xScaleTicks: { x: [0, 1], tick: ["0", "1"] },
    },
    gpsData: undefined,
    selectedChart: initialChartID,
    allObjects: [],
    allNotes: [],
    objectsToDisplay: [
        objectTypes.switch,
        objectTypes.levelCrossing,
        objectTypes.marker,
        objectTypes.notes,
        objectTypes.trackBarrier,
        objectTypes.trackCross,
        objectTypes.culvert,
        objectTypes.contactPole,
    ],
    accelerationArea: [],
};

export default initialState;