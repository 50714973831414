// Header.tsx
import React from "react";
import { useTranslation } from "react-i18next";

interface HeaderProps {
  measurementNumber: string;
  createdAt: string;
  arrowImg: JSX.Element;
  reprocessTag: boolean;
  reprocessCount: number;
  time?: string;
}

const Header: React.FC<HeaderProps> = ({
  time,
  measurementNumber,
  createdAt,
  arrowImg,
  reprocessTag,
  reprocessCount,
}) => {
  const { t } = useTranslation();
  const slicedMeasurementNumber = measurementNumber.split("-")[0].slice(0, 4)
  return (
    <div
      className="body-1 CardHeaderItem"
      style={{
        color: "var(--primary-color)",
        marginLeft: "10px",
        display: "flex",
        flexDirection: "row",
        position: "relative",
        borderBottom: "2px solid var(--primary-color)",
      }}
    >
      <div style={{ fontWeight: "500", margin: "6px" }}>
        {time && time} {reprocessTag && `(R${reprocessCount})`} <span style={{ fontSize: "0.8em", lineHeight: "0.8em", fontWeight: "normal", marginLeft: "10px" }}> {createdAt}</span>
      </div>
      <div className="CardHeaderArrow">{arrowImg}</div>
    </div>
  );
};

export default Header;
