import React, { useEffect, useState } from "react";
import "./formComponents";
import "./planForm.css";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { Popup } from "@progress/kendo-react-popup";

/* Redux Stuff*/
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../store/store";
import { useNavigate } from "react-router-dom";
import { AppState } from "../../../store/store";
import { measurementStatus, planProps } from "../../../store/scheduler/types";
import {
  setInformationRequest,
  setNotification,
} from "../../../store/user/userSlice";
import { helpInformationPath } from "../../../store/user/types";
import * as plansAPI from "../../../api/netRail/plans";
import {
  savePlanToServer,
  setPlanHasChanged,
  setSelectedPlan,
  deletePlan,
} from "./../../../store/scheduler/schedulerSlice";
import {
  AssigneesSelector,
  ContactSelection,
  MachinesSelector,
  NameInput,
  NotesTextArea,
  NumberMeasurementsInput,
  PathFromInput,
  PathToInput,
  PlaceInput,
  CustomerInput,
  PlanEndAtInput,
  PlanEndAtKmInput,
  PlanEndAtMeterInput,
  PlanStartAtInput,
  PlanStartAtKmInput,
  PlanStartAtMeterInput,
  ScheduledAtDatePicker,
  StatusSelection,
  TrackClassificationInput,
  TrackPartInput,
  ValidationToggle
} from "./formComponents";
import { useTranslation } from "react-i18next";
import {
  getMeasurementStatusFromPipeline,
} from "../../../store/scheduler/helpers";

interface planFormProps {
  plan: planProps;
}

const numberRegexTrackPart = new RegExp(/^[0-9]{1,6}$/);

const numberRegex = new RegExp(/^[0-9]*$/);

const PlanForm: React.FC<planFormProps> = ({ plan }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const notEmptyValidator = (input?: string) =>
    input && input !== "" ? "" : t("common.requiredField");

  const numberValidatorTrackPart = (input?: string) =>
    input && numberRegexTrackPart.test(input)
      ? ""
      : t("common.numberLessThanSixChar");

  const numberValidator = (input?: string) =>
    input && numberRegex.test(input) ? "" : t("common.number");

  const numberValidatorForMeter = (input?: string) =>
    !(input && numberRegex.test(input))
      ? t("common.number")
      : +input > 1100
        ? t("common.lessThan1100m")
        : "";

  const numberOrEmptyValidator = (input?: string) =>
    (input && numberRegex.test(input)) || input === ""
      ? ""
      : t("common.number");

  const planHasChanged = useSelector(
    (state: AppState) => state.scheduler.planHasChanged
  );

  const authenticationSuccess = useSelector(
    (state: AppState) => state.user.authenticationSuccess
  );

  const [editPlan, setEditPlan] = useState<boolean>(
    plan.sentToServer !== undefined ? !plan.sentToServer : false
  );

  const [deletePlanBtn, setDeletePlanBtn] = useState<boolean>(false);

  const navigate = useNavigate();
  useEffect(() => {
    dispatch(setPlanHasChanged(false));
  }, [plan.id]);

  const renderDeletePlanForm = (formRenderProps: FormRenderProps) => {
    return (
      <FormElement>
        <div className="deletePlanPwField">
          <Field
            name={"radera"}
            placeholder={t("common.delete")}
            component={Input}
            label={t("measurementListItem.confirmDelete")}
          />
          <div style={{ color: "red" }}>
            {authenticationSuccess ? "" : t("manageUsers.wrongPassword")}
          </div>
        </div>
        <div className="DeletePlanButtonContainer">
          <button
            className="PopupButton BUTTON"
            onClick={() => {
              setDeletePlanBtn(false);
            }}
          >
            {t("common.no")}
          </button>
          <button
            type={"submit"}
            className="PopupButton BUTTON"
            disabled={
              formRenderProps.valueGetter("radera") !== t("common.delete")
            }
            style={{
              opacity:
                formRenderProps.valueGetter("radera") !== t("common.delete")
                  ? 0.5
                  : 1,
            }}
          >
            {t("common.delete")}
          </button>
        </div>
      </FormElement>
    );
  };

  useEffect(() => {
    return () => {
      dispatch(
        setInformationRequest({
          path: undefined,
          showInformation: false,
        })
      );
    };
  }, []);

  const informationRequest = useSelector(
    (state: AppState) => state.user.informationRequest
  );

  const renderPlanContent = (formRenderProps: FormRenderProps) => {
    const { valueGetter } = formRenderProps;

    const invalidEndPoint =
      +valueGetter("planStartAtKm") * 1000 + +valueGetter("planStartAtMeter") >
      +valueGetter("planEndAtKm") * 1000 + +valueGetter("planEndAtMeter");

    return (
      <div style={{ position: "relative" }}>
        <FormElement
          className={editPlan ? "" : "content-under-overlay"}
          style={{ height: "100%" }}
        >
          <fieldset className={"k-form-fieldset"}>
            <legend
              className={"k-form-legend"}
              style={{
                color: "black",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                position: "relative",
              }}
            >
              {t("planForm.parameters")}
              <span
                className="k-icon k-font-icon k-i-info informationButton"
                style={{ marginLeft: "10px" }}
                onClick={() => {
                  dispatch(
                    setInformationRequest({
                      path: helpInformationPath.PlanFormParameters,
                      showInformation:
                        informationRequest?.path ===
                          helpInformationPath.PlanFormParameters
                          ? !informationRequest?.showInformation
                          : true,
                    })
                  );
                }}
              />
            </legend>
            <Field
              name={"name"}
              component={NameInput}
              label={t("planForm.name") + "*"}
              validator={notEmptyValidator}
            // disabled={!editPlan}
            />
            <Field
              name={"place"}
              component={PlaceInput}
              label={t("planForm.place") + "*"}
              validator={notEmptyValidator}
            // disabled={!editPlan}
            />
            <Field
              name={"customer"}
              component={CustomerInput}
              label={t("planForm.customer")}
            // disabled={!editPlan}
            />
            <Field
              name={"customer"}
              component={CustomerInput}
              label={t("planForm.customer")}
              // disabled={!editPlan}
            />
            <div className="SplitField">
              <Field
                name={"distanceFrom"}
                component={PathFromInput}
                label={t("planForm.distanceFrom")}
              // disabled={!editPlan}
              />
              <div style={{ flex: "0 0 10px" }}></div>
              <Field
                name={"distanceTo"}
                component={PathToInput}
                label={t("planForm.distanceTo")}
              // disabled={!editPlan}
              />
            </div>

            <div className="SplitField">
              <Field
                name={"trackPart"}
                component={TrackPartInput}
                label={t("planForm.trackPart") + "*"}
                validator={numberValidatorTrackPart}
                disabled={
                  // !editPlan ||
                  plan.measurements.some((measurement) => {
                    const status = getMeasurementStatusFromPipeline(
                      t,
                      measurement.pipelines
                    );
                    return status === t("common.measurementStatus.inProgress");
                  })
                }
              />
              <div style={{ flex: "0 0 10px" }}></div>
              <Field
                name={"numberMeasurements"}
                component={NumberMeasurementsInput}
                label={t("planForm.numberMeasurements") + "*"}
                validator={numberOrEmptyValidator}
              // disabled={!editPlan}
              />

              {/* <Field
                name={"trackNumber"}
                component={TrackNumberInput}
                label={t("planForm.trackNumber") + "*"}
                validator={notEmptyValidator}
              /> */}
            </div>

            <div className="SplitField">
              <Field
                name={"planStartAtKm"}
                component={PlanStartAtKmInput}
                label={t("planForm.startAtKm") + "*"}
                validator={numberValidator}
              // disabled={!editPlan}
              />
              <div style={{ flex: "0 0 10px" }}></div>
              <Field
                name={"planStartAtMeter"}
                component={PlanStartAtMeterInput}
                label={t("planForm.startAtMeter") + "*"}
                validator={numberValidatorForMeter}
              // disabled={!editPlan}
              />
              <div style={{ flex: "0 0 10px" }}></div>
              <Field
                name={"planStartAt"}
                component={PlanStartAtInput}
                label={t("planForm.startAt")}
              // disabled={!editPlan}
              />
            </div>

            <div className="SplitField">
              <Field
                name={"planEndAtKm"}
                component={PlanEndAtKmInput}
                label={t("planForm.endAtKm") + "*"}
                validator={numberValidator}
              // disabled={!editPlan}
              />
              <div style={{ flex: "0 0 10px" }}></div>
              <Field
                name={"planEndAtMeter"}
                component={PlanEndAtMeterInput}
                label={t("planForm.endAtMeter") + "*"}
                validator={numberValidatorForMeter}
              // disabled={!editPlan}
              />
              <div style={{ flex: "0 0 10px" }}></div>
              <Field
                name={"planEndAt"}
                component={PlanEndAtInput}
                label={t("planForm.endAt")}
              // disabled={!editPlan}
              />
            </div>
            <div>
              {!invalidEndPoint ? null : (
                <div style={{ color: "red" }}>
                  {t("planForm.errors.startGreaterThanEnd")}
                </div>
              )}
            </div>
            <Field
              name={"trackClassification"}
              component={TrackClassificationInput}
              label={t("planForm.speedClass")}
              validator={notEmptyValidator}
            // disabled={!editPlan}
            />
            <div className="ContentDivider"></div>
            <legend
              className={"k-form-legend"}
              style={{
                color: "black",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {t("planForm.other")}
              <span
                className="k-icon k-font-icon k-i-information informationButton"
                style={{
                  marginLeft: "10px",
                }}
                onClick={() => {
                  dispatch(
                    setInformationRequest({
                      path: helpInformationPath.PlanFormMiscellaneous,
                      showInformation:
                        informationRequest?.path ===
                          helpInformationPath.PlanFormMiscellaneous
                          ? !informationRequest?.showInformation
                          : true,
                    })
                  );
                }}
              />
            </legend>
            <div className="SplitField">
              <Field
                name={"operatorSelector"}
                component={AssigneesSelector}
                label={t("common.operators")}
              // disabled={!editPlan}
              />
              <div style={{ flex: "0 0 10px" }}></div>
              <Field
                name={"machineSelector"}
                component={MachinesSelector}
                label={t("common.machines")}
              // disabled={!editPlan}
              />
            </div>

            <div className="SplitField">
              <Field
                name={"status"}
                component={StatusSelection}
                label={t("common.status")}
              // disabled={!editPlan}
              />
              <div style={{ flex: "0 0 10px" }}></div>
              <Field
                name={"doneAt"}
                component={ScheduledAtDatePicker}
                label={t("planForm.planedTo")}
              // disabled={!editPlan}
              />
            </div>
            <div className="SplitField">
              <Field
                name={"contactSelection"}
                component={ContactSelection}
                label={t("planForm.sendResultsTo")}
                allowCustom={true}
              // disabled={!editPlan}
              />
              <Field
                name={"validationToggle"}
                component={ValidationToggle}
                label={t("common.validation")}
              />
            </div>
            <Field
              name={"notes"}
              component={NotesTextArea}
              label={t("planForm.notes")}
            // disabled={!editPlan}
            />

            <div className="ContentDivider"></div>
          </fieldset>
          <div className="k-form-buttons">
            {editPlan ? (
              <button
                className="PlanDeleteButton BUTTON"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setDeletePlanBtn(true);
                }}
              >
                {t("planForm.deletePlan")}
              </button>
            ) : null}
            <Popup
              show={deletePlanBtn}
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(120, 120, 120, 0.5)",
              }}
            >
              <div className="PopupContentShare">
                <p style={{ fontWeight: "bold" }}>
                  {t("planForm.deletePlan")}?{" "}
                </p>
                <strong>{t("planForm.observe")} </strong>{" "}
                {t("planForm.measurementsWillBeDeleted")}{" "}
                <b>{t("planForm.dataWillBeDeleted")}</b>
                <br />
                <Form
                  initialValues={{ radera: "" }}
                  onSubmit={async (dataItem) => {
                    await handleDeletePlan(dataItem.radera, plan);
                  }}
                  render={(formRenderProps) =>
                    renderDeletePlanForm(formRenderProps)
                  }
                />
              </div>
            </Popup>

            <button
              type={"submit"}
              className="FormSubmitButton BUTTON"
              disabled={
                !(formRenderProps.allowSubmit && planHasChanged) ||
                invalidEndPoint
              }
              style={
                !(formRenderProps.allowSubmit && planHasChanged) ||
                  invalidEndPoint
                  ? {
                    opacity: 0.5,
                    display: editPlan ? "flex" : "none",
                  }
                  : {
                    display: editPlan ? "flex" : "none",
                  }
              }
            >
              {plan.sentToServer ? t("common.save") : t("common.upload")}
            </button>
            <button
              className="FormSubmitButton BUTTON"
              style={{
                display: !editPlan ? "flex" : "none",
                pointerEvents: "auto",
              }}
              onClick={(e) => {
                e.preventDefault();
                setEditPlan(true);
              }}
            >
              {t("common.edit")}
            </button>
          </div>
        </FormElement>
        {!editPlan && <div className="overlay"></div>}
      </div>
    );
  };

  const handleDeletePlan = async (confirmationString: any, plan: planProps) => {
    if (plan.sentToServer === true) {
      if (confirmationString.toLowerCase() === t("common.delete").toLowerCase()) {
        // dispatch(removePlan(t, plan.id));
        try {
          await plansAPI.deletePlan(plan.id);
          dispatch(setSelectedPlan("-1"));
          dispatch(deletePlan(plan.id));
          dispatch(
            setNotification({
              style: "success",
              message: t("store.plan.deletedPlan"),
              open: true,
            })
          );
          navigate("/scheduler");
          setDeletePlanBtn(false);
        } catch (err) {
          dispatch(
            setNotification({
              style: "error",
              message: t("store.plan.deletedPlanError"),
              open: true,
            })
          );
        }
      }
    } else {
      return null;
    }
  };

  const renderPlan = () => {
    return (
      <div className="PlanContent" key={plan.id}>
        <Form
          onSubmit={() => {
            setEditPlan(false);
            dispatch(savePlanToServer({ t, plan }));
          }}
          render={(formRenderProps) => renderPlanContent(formRenderProps)}
        />
      </div>
    );
  };

  return <div className="PlanFormContainer">{renderPlan()}</div>;
};

export default PlanForm;
