import React, { useState } from "react";
import "./style.scss";
import { AppState } from "../../store/store";

import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { roleType } from "../../store/user/types";
import { getMobileOperatingSystem } from "../../helpers/genericHelpers";
import { useAppDispatch } from "../../store/store";
import { setExpandedMenu } from "../../store/scheduler/schedulerSlice";
import { cPhoneWidthLim } from "../../app";
import { useTranslation } from "react-i18next";

const CustomerSupport = () => {
  <svg viewBox="0 0 512 512"><path d="M352 192c0 45.2-23.4 65.9-40.6 81-15.1 13.3-23.4 20.7-23.4 47h-64c0-55.2 27.1-79.2 45.1-95 13.8-12.1 18.9-16.7 18.9-33 0-17.6-14.4-32-32-32s-32 14.4-32 32h-64c0-53 43-96 96-96s96 43 96 96M224 416h64v-64h-64zm256-160c0 123.7-100.3 224-224 224S32 379.7 32 256 132.3 32 256 32s224 100.3 224 224m-32 0c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192"></path></svg>
}

export const Menu: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const width = useSelector((state: AppState) => state.scheduler.screenWidth);
  const height = useSelector((state: AppState) => state.scheduler.screenHeight);
  const announcementsCount = useSelector(
    (state: AppState) => state.user.announcementsCount
  );
  const [menuExtended, setMenuExtended] = useState(false);
  const onMenuClick = () => {
    setMenuExtended(!menuExtended);
  };
  const adminBool = useSelector(
    (state: AppState) => state.user.currentUser
  )?.roles.some((entry) => entry.name === roleType.admin);

  const machinePageName = t("menu.machinePageName");
  const companyPageName = t("menu.companyPageName");
  const schedulerPageName = t("menu.schedulerPageName");
  const dashboardPageName = t("menu.dashboardPageName");
  const usersPageName = t("menu.usersPageName");
  const userPageName = t("menu.userPageName");
  const adminMessagePageName = t("menu.adminMessagePageName");
  const announcementsPageName = t("menu.announcementsPageName");
  const supportPageName = "Support";

  let menuItems = [
    {
      pageName: dashboardPageName,
      icon: "./../icons/dashboard.svg",
      route: "/dashboard",
    },
    {
      pageName: schedulerPageName,
      icon: "./../icons/clock.svg",
      route: "/scheduler",
    },
    {
      pageName: machinePageName,
      icon: "./../icons/directions_subway_black_24dp.svg",
      route: "/manageMachines",
    },
    {
      pageName: companyPageName,
      icon: "./../icons/business_black_24dp.svg",
      route: "/manageCompanies",
    },
    {
      pageName: usersPageName,
      icon: "./../icons/manageUsers.svg",
      route: "/manageUsers",
    },
    {
      pageName: userPageName,
      icon: "./../icons/account.svg",
      route: "/userSettings",
    },
    {
      pageName: announcementsPageName,
      icon: "./../announcement.png",
      route: "/messageLog",
    },
    {
      pageName: adminMessagePageName,
      icon: "./../icons/announcement_black_24dp.svg",
      route: "/announcements",
    },
    {
      pageName: supportPageName,
      icon: "./../icons/customer-support.svg",
      route: "/support",
    },
  ];

  if (!adminBool)
    menuItems = menuItems.filter(
      (page) =>
        page.pageName !== companyPageName &&
        page.pageName !== adminMessagePageName
    );

  // removes machine page

  const renderMenuItem = (pageName: string, icon: string, route: string) => {
    const prefix = "black-";
    const newIcon = icon.replace(/\/([^/]+)$/, `/${prefix}$1`);

    const iconToDisplay = location.pathname.includes(route)
      ? newIcon
      : icon;

    return (
      <div
        className={"menu-item"}
        style={{
          backgroundColor: location.pathname.includes(route)
            ? "var(--primary-color,#782441)"
            : "transparent",
        }}
        key={pageName}
      >
        <button
          className="menu-page-button"
          onClick={() => {
            navigate(route);
          }}
        >
          {announcementsCount > 0 && route === "/messageLog" ? (
            <div className="numberCircleAnnouncementsDesktop">
              {announcementsCount}
            </div>
          ) : null}
          <img
            src={iconToDisplay}
            alt=""
            style={
              route === "/messageLog" ? { width: "24px", height: "24px" } : {}
            }
          />
        </button>
        <button
          className="menu-page-button-extended BUTTON menu-text"
          style={
            menuExtended
              ? {
                  display: "flex",
                  boxShadow: "none",
                  color: location.pathname.includes(route)
                    ? "black"
                    : "#c3c3c3",
                }
              : {
                  display: "none",
                  color: location.pathname.includes(route)
                    ? "black"
                    : "#c3c3c3",
                }
          }
          onClick={() => {
            navigate(route);
          }}
        >
          {pageName}
        </button>
      </div>
    );
  };

  const renderPhoneMenuItem = (
    pageName: string,
    icon: string,
    route: string
  ) => {
    const prefix = "black-";
    const newIcon = icon.replace(/\/([^/]+)$/, `/${prefix}$1`);

    const iconToDisplay = location.pathname.includes(route)
      ? newIcon
      : icon;

    return (
      <div
        className={"menu-item"}
        style={{
          backgroundColor:
            location.pathname === route
              ? "var(--primary-color,#782441)"
              : "transparent",
        }}
        key={pageName}
      >
        <div
          className="menu-page-button"
          onClick={() => {
            navigate(route);
            dispatch(setExpandedMenu(false));
          }}
        >
          {announcementsCount > 0 && route === "/messageLog" ? (
            <div className="numberCircleAnnouncements">
              {announcementsCount}
            </div>
          ) : null}
          <img
            src={iconToDisplay}
            alt=""
            style={
              route === "/messageLog" ? { width: "24px", height: "24px" } : {}
            }
          />
        </div>
        <div
          className="menu-page-button-extended menu-text"
          style={{
            display: "flex",
            boxShadow: "none",
            color: location.pathname.includes(route)
              ? "black"
              : "#c3c3c3",
          }}
          onClick={() => {
            navigate(route);
            dispatch(setExpandedMenu(false));
          }}
        >
          {pageName}
        </div>
      </div>
    );
  };

  const expandPhoneMenu = useSelector(
    (state: AppState) => state.scheduler.expandMenu
  );

  return (
    <div style={{ zIndex: "10000000", position: "absolute", left: "0" }}>
      {width <= cPhoneWidthLim || height <= cPhoneWidthLim ? (
        expandPhoneMenu ? (
          <div
            className={
              getMobileOperatingSystem() === "iOS"
                ? "menu-container-ios"
                : "menu-container-android"
            }
          >
            <div className="menu-body">
              <div className="menu-item">
                <div
                  className="menu-button"
                  onClick={() => {
                    dispatch(setExpandedMenu(!expandPhoneMenu));
                  }}
                >
                  <img src={"./../icons/close.svg"} alt="" />
                </div>
                <div
                  onClick={() => {
                    dispatch(setExpandedMenu(!expandPhoneMenu));
                  }}
                  className="menu-button-extended menu-text"
                  style={{
                    display: "flex",
                    marginTop: "2px",
                    color: "#c3c3c3",
                  }}
                >
                  {t("menu.closeButton")}
                </div>
              </div>
              <div className="menu-items">
                {menuItems.map((menuItem) =>
                  renderPhoneMenuItem(
                    menuItem.pageName,
                    menuItem.icon,
                    menuItem.route
                  )
                )}
              </div>
            </div>
          </div>
        ) : (
          <div> </div>
        )
      ) : (
        <div className="menu-container">
          <div className="menu-header">
            <button className="menu-button" onClick={onMenuClick} style={{alignItems: "center"}}>
              <img
                src={
                  menuExtended ? "./../icons/close.svg" : "./../icons/menu.svg"
                }
                alt=""
              />
            </button>
            <button
              className="menu-button-extended BUTTON menu-text"
              style={
                menuExtended
                  ? {
                      display: "flex",
                      marginTop: "2px",
                      boxShadow: "none",
                      color: "#c3c3c3",
                      alignItems: "center",
                    }
                  : { display: "none", color: "#c3c3c3" }
              }
              onClick={onMenuClick}
            >
              {t("menu.closeButton")}
            </button>
          </div>
          <div className="menu-body">
            <div className="menu-items">
              {menuItems.map((menuItem) =>
                renderMenuItem(menuItem.pageName, menuItem.icon, menuItem.route)
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
