import React, { useEffect, useState, useReducer, useRef } from "react";
import "./style.scss";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState } from "../../store/store";
import { onLogin, setNotification } from "./../../store/user/userSlice";

import { Spinner } from "../../components/spinner/spinner";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../store/store";
import { LoginForm } from "../../components/login/loginForm";
import { MfaRequiredForm } from "../../components/login/mfaRequiredForm";
import { NewPasswordForm } from "../../components/login/newPasswordForm";
import { ForgotPasswordSubmitForm } from "../../components/login/forgotPasswordSubmitForm";
import { ForgotPasswordForm } from "../../components/login/forgotPasswordForm";

export enum errorCodes {
  signInError = 1,
  getPrefferedMFAError = 2,
  verifyPhoneNumberSigninError = 3,
  completeNewPasswordError = 4,
  verifyPhoneNumberNewPWError = 5,
  verifyPNSubmitError = 6,
  setPrefferedMFAError = 7,
  confirmMFAError = 8,
  forgotPasswordError = 9,
  forgotPWSubmitError = 10,
  updatePhoneNumberError = 11,
  verifyPhoneNumberUpdatePNError = 12,
  verifyPhoneNumberResendCodeError = 13,
}

const getErrorMessage = (
  t: TFunction<"translation", "translation">,
  err: Error
) => {
  let errorMessage = "";
  console.error("Error:", err);
  if (err.toString().includes("UserNotConfirmedException")) {
    // The error happens if the user didn't finish the confirmation step when signing up
    // In this case you need to resend the code and confirm the user
    // About how to resend the code and confirm the user, please check the signUp part
    errorMessage = t("loginPage.userNotVerified");
  } else if (err.toString().includes("PasswordResetRequiredException")) {
    // The error happens when the password is reset in the Cognito console
    // In this case you need to call forgotPassword to reset the password
    // Please check the Forgot Password part.
    errorMessage = t("loginPage.passwordResetRequired");
  } else if (err.toString().includes("NotAuthorizedException")) {
    // The error happens when the incorrect password is provided
    errorMessage = t("loginPage.wrongUsernameOrPassword");
  } else if (err.toString().includes("UserNotFoundException")) {
    // The error happens when the supplied username/email does not exist in the Cognito user pool
    errorMessage = t("loginPage.wrongEmail");
  } else if (err.toString().includes("CodeMismatchException")) {
    errorMessage = t("loginPage.wrongAuthenticationCode");
  } else if (err.toString().includes("NetworkError")) {
    errorMessage = t("loginPage.networkError");
  } else {
    errorMessage = t("loginPage.unexpectedError") + ` ${err.toString()}`;
  }
  return errorMessage;
};

export type SignInSteps =
  | "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE"
  | "CONTINUE_SIGN_IN_WITH_MFA_SELECTION"
  | "CONFIRM_SIGN_IN_WITH_SMS_CODE"
  | "CONFIRM_SIGN_IN_WITH_TOTP_CODE"
  | "CONTINUE_SIGN_IN_WITH_TOTP_SETUP"
  | "CONFIRM_SIGN_UP"
  | "RESET_PASSWORD"
  | "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"
  | "CONFIRM_RESET_PASSWORD_WITH_CODE" // From AuthResetPasswordStep type
  | "SHOW_FORGOT_PASSWORD_CONTENT" // Custom step to display a field to enter email to reset pw
  | "UPDATE_PHONE_NUMBER" // Custom step to allow the user to update the phonenumber if it's wrong
  | "DONE";

export const Login: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const forgotPasswordEmail = useRef<string>();

  const [nextStep, setNextStep] = useState<SignInSteps>();

  const handleError = useCallback(
    (err: any, setAuthError: React.Dispatch<React.SetStateAction<string>>) => {
      // TODO: Fix error type!!
      const errorMessage = getErrorMessage(t, err);
      dispatch(
        setNotification({
          style: "error",
          message: errorMessage,
          open: true,
        })
      );
      setAuthError(errorMessage);
    },
    [dispatch]
  );

    const isAuthenticated = useSelector((state: AppState) => state.user.authenticationSuccess);
    if (isAuthenticated) {
      navigate("/");
    }

    const selectForm = (step: SignInSteps | undefined) => {
      switch (step) {
        case "CONFIRM_SIGN_IN_WITH_SMS_CODE":
          return (
            <MfaRequiredForm
              handleError={handleError}
              setNextStep={setNextStep}
            />
          );
        case "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED":
          return (
            <NewPasswordForm
              handleError={handleError}
              setNextStep={setNextStep}
            />
          );
        case "RESET_PASSWORD":
        case "SHOW_FORGOT_PASSWORD_CONTENT":
          return (
            <ForgotPasswordForm
              handleError={handleError}
              setNextStep={setNextStep}
              forgotPasswordEmail={forgotPasswordEmail}
            />
          );
        case "CONFIRM_RESET_PASSWORD_WITH_CODE":
          return (
            <ForgotPasswordSubmitForm
              handleError={handleError}
              setNextStep={setNextStep}
              forgotPasswordEmail={forgotPasswordEmail}
            />
          );
        case "DONE":
          dispatch(onLogin({ t }));
          break;
        default:
          return (
            <LoginForm handleError={handleError} setNextStep={setNextStep} />
          );
      }
    };
    return (
      <div className="LoginContainer" style={{ height: window.innerHeight }}>
        <div className="LoginContent">
          <h1>{t("loginPage.welcome")}</h1>
          {selectForm(nextStep)}
          <img
            className="LoginRedemptorLogo"
            src="./Rityta 1Mellan[3437].png"
            alt=""
          />
        </div>
      </div>
    );
  };

  const timerReducer = (state: any, action: any) => {
    switch (action.type) {
      case "TICK":
        if (state.seconds > 0) {
          return { ...state, seconds: state.seconds - 1 };
        }
        if (state.seconds === 0 && state.minutes > 0) {
          return { minutes: state.minutes - 1, seconds: 59 };
        }
        return state;
      case "RESET":
        return { minutes: action.minutes, seconds: 0 };
      default:
        return state;
    }
  };

  const Timer = ({
    initialMinute,
    isTimerDone,
    setIsTimerDone,
    setNextStep,
  }: any) => {
    const { t } = useTranslation();
    const [timerState, dispatch] = useReducer(timerReducer, {
      minutes: initialMinute,
      seconds: 0,
    });
    useEffect(() => {
      const myInterval = setInterval(() => {
        dispatch({ type: "TICK" });
      }, 1000);

      return () => {
        clearInterval(myInterval);
      };
    }, []);

    useEffect(() => {
      if (!isTimerDone) {
        dispatch({ type: "RESET", minutes: initialMinute });
      }
    }, [isTimerDone, initialMinute]);

    const { minutes, seconds } = timerState;

    if (minutes === 0 && seconds === 0) {
      setIsTimerDone(true);
      setNextStep(undefined);
    }

    return (
      <div>
        {minutes === 0 && seconds === 0 ? null : (
          <p>
            {t("loginPage.codeValidFor")} {minutes}:
            {seconds < 10 ? `0${seconds}` : seconds}
          </p>
        )}
      </div>
    );
  };

  export default Timer;
