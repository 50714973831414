import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { Scheduler } from "./pages/scheduler";
import { UserSettings } from "./pages/userSettings";
import { ManageUsers } from "./pages/manageUsers";
import { ManageMachines } from "./pages/manageMachines";
import { ManageCompanies } from "./pages/manageCompanies";
import { Login } from "./pages/login";
import { DashboardViewOnly } from "./pages/dashboardView";
import { NotFound } from "./pages/notFound";
import { Dashboard } from "./pages/dashboard";
import { Announcements } from "./pages/announcements";
import { Support } from "./pages/support"
import "./theme/variables.scss";
import '@progress/kendo-theme-default/dist/all.css';
import '@progress/kendo-font-icons/scss/all.scss';
import { useSelector } from "react-redux";
import { useAppDispatch } from "./store/store";
import { AppState } from "./store/store";
import {
  setScreenHeight,
  setScreenWidth,
} from "./store/scheduler/schedulerSlice";
import { NotificationComponent } from "./components/notification/notificationComponent";
import { HelpSideBar } from "./components/helpSideBar/helpSideBar";
import { getMobileOperatingSystem } from "./helpers/genericHelpers";
import Plan from "./components/scheduler/plan/plan";
import { ProtectedRoute } from "./protectedRoute";
import { LandingPage } from "./pages/landingPage/landingPage";
import { ErrorFallback } from "./pages/fallback/page";
import { AnnouncementsLog } from "./pages/announcementsLog";
import * as announcementsAPI from "./api/netRail/announcements";
import { fetchStoredAnnouncement } from "./components/announcement/announcement";
import { setAnnouncementsCount } from "./store/user/userSlice";
import { configureAmplify } from "./amplify";
import { ErrorBoundary } from "react-error-boundary";
import '@aws-amplify/ui-react/styles.css';
import FirefoxWarning from "./helpers/firefoxWarning";

export const developmentMode = false;

// change to phone width
export const cPhoneWidthLimStandard = 658;
export const cPhoneWidthLim = cPhoneWidthLimStandard - 100;
export const cPhoneHeightLim = 658;
export const numberOfPlansToDisplay = 50;
export const numberOfMeasurementsToDisplay = 50;

const paths = [
  { path: "/dashboard",       component: Dashboard,         },
  { path: "/scheduler",       component: Scheduler,         },
  { path: "/scheduler/plan",  component: Plan,              },
  { path: "/manageUsers",     component: ManageUsers,       },
  { path: "/userSettings",    component: UserSettings,      },
  { path: "/manageMachines",  component: ManageMachines,    },
  { path: "/manageCompanies", component: ManageCompanies,   },
  { path: "/announcements",   component: Announcements,     },
  { path: "/messageLog",      component: AnnouncementsLog,  },
  { path: "/support",         component: Support,           },
];

configureAmplify();

const App: React.FC = () => {
  const dispatch = useAppDispatch();

  async function getAPIAnnouncements() {
    try {
      const Today = new Date();
      const announcements = await announcementsAPI.getAnnouncements();
      const storedAnnouncements = fetchStoredAnnouncement();
      const filteredAnnouncementList = announcements.filter((announcement) => {
        return (
          new Date(announcement.expiresAt) >= Today &&
          new Date(announcement.validAt) <= Today &&
          !storedAnnouncements.includes(announcement.id)
        );
      });
      dispatch(setAnnouncementsCount(filteredAnnouncementList.length));
    } catch (err) {
      console.error("err", err);
    }
  }

  const updateWidthAndHeight = () => {
    dispatch(setScreenWidth(window.innerWidth));
    dispatch(setScreenHeight(window.innerHeight));
  };

  const updateWidthAndHeightiOS = () => {
    dispatch(setScreenWidth(window.innerWidth));
    dispatch(setScreenHeight(document.documentElement.clientHeight));
  };
  useEffect(() => {
    getAPIAnnouncements();

    if (getMobileOperatingSystem() === "iOS") {
      window.removeEventListener("resize", updateWidthAndHeightiOS);
      window.addEventListener("resize", updateWidthAndHeightiOS);
    } else {
      window.addEventListener("resize", updateWidthAndHeight);
    }
    return () => {
      if (getMobileOperatingSystem() === "iOS") {
        window.removeEventListener("resize", updateWidthAndHeightiOS);
      } else {
        window.removeEventListener("resize", updateWidthAndHeight);
      }
    };
  }, []);

  const windowHeight = useSelector(
    (state: AppState) => state.scheduler.screenHeight
  );

  return (
    <div
      className="app-container"
      style={{
        height: windowHeight,
      }}
    > 
    <FirefoxWarning />
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={async () => {
          await navigator.serviceWorker
            .getRegistrations()
            .then((registrations) => {
              registrations.forEach((registration) => {
                registration.unregister();
              });
            });

          await caches.keys().then((keyList) => {
            return Promise.all(
              keyList.map((key) => {
                return caches.delete(key);
              })
            );
          });

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }}
      >
        <Router>
          <Routes>
            <Route path="https://localhost:3000" element={<Navigate to="http://localhost:3000" />} />
            <Route path="/shared-link" element={<DashboardViewOnly />} />
            <Route path="/" element={<LandingPage />} />
            {paths.map((path) => (
              <Route
                key={path.path}
                path={path.path}
                element={
                  <ProtectedRoute>
                    <path.component />
                  </ProtectedRoute>
                }
              />
            ))}
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
        <HelpSideBar />
        <NotificationComponent />
      </ErrorBoundary>
    </div>
  );
};

export default App;