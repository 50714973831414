import React, { useState, useEffect } from "react";
import "./style.scss";
import { t } from "i18next";
import { patchSupportTicket } from "../../api/netRail/support";
import { supportTicketMessageType } from "../../store/user/types";
import { deleteSupportTicket } from "../../api/netRail/support";
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { MaxHeight, MinHeight } from "./supportHelpers";
import { Toolbar, SupportField, TicketStatus, CommentSection } from "./supportComponents";

interface AdminSupportTicketProps {
  supportTicket: supportTicketMessageType;
  listView?: boolean;
  removeSupportTicket: (id: string) => void;
  updateSupportTicket: (supportTicket: supportTicketMessageType) => void;
}

export const PipelineCard: React.FunctionComponent<any> = ({ pipeline, index }) => {
  // This is a component for the alternate expanded view of the Dashboard support ticket
  // Not used in the HMI support ticket view
  const [isExpanded, setIsExpanded] = useState(false);

  const isStatusCritical = !["received", "done", "sent"].includes(pipeline.status);

  return (
    <div key={index} className="pipeline-item">
      {isExpanded ?
        <div style={{ border: "1px solid darkgrey", borderRadius: "5px"}}>
          <span
            onClick={() => setIsExpanded(!isExpanded)}
            style={{ paddingLeft: "5px", cursor: "pointer", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "baseline"}}>
            <h4>Pipeline {index + 1}</h4> <p style={{color: "blue"}}>minimize</p>
          </span>
          <SupportField label="ID" value={pipeline.id} />
          <SupportField label="State" value={pipeline.state} />
          <SupportField label="Status" value={pipeline.status} />
          <SupportField label="Created At" value={new Date(pipeline.createdAt).toLocaleString()} />
          <SupportField
            label="Updated At"
            value={new Date(pipeline.updatedAt).toLocaleString()}
          />
          {pipeline.doneAt && (
            <SupportField label="Done At" value={new Date(pipeline.doneAt).toLocaleString()} />
          )}
          {pipeline.note && <SupportField label="Note" value={pipeline.note} />}
          {pipeline.output && <SupportField label="Output" value={pipeline.output} />}
          {pipeline.parentID && <SupportField label="Parent ID" value={pipeline.parentID} />}
          {pipeline.measurementID && (
            <SupportField label="Measurement ID" value={pipeline.measurementID} />
          )}
          {pipeline.stateMachineID && (
            <SupportField label="State Machine ID" value={pipeline.stateMachineID} />
          )}
          {pipeline.commitSHA && (
            <SupportField label="Commit SHA" value={pipeline.commitSHA} />
          )}
          {pipeline.jobMessage && <SupportField label="Job Message" value={pipeline.jobMessage} />}
          {pipeline.version && <SupportField label="Version" value={pipeline.version} />}
        </div>
        :
        <div
          onClick={() => setIsExpanded(!isExpanded)}
          style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "baseline", border: "1px solid darkgrey", borderRadius: "5px" }}>
          <span style={{ display: "flex", fontWeight: "700", width: "100%" }}>
            <div style={{
              flexGrow: 1,
              marginRight: "10px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: isStatusCritical ? "red" : "black"
            }}>
              {pipeline.state}
            </div>
            {new Date(pipeline.createdAt).toLocaleString()}
          </span>
        </div>
      }
    </div>
  );
};

export const SupportCard: React.FunctionComponent<AdminSupportTicketProps> = ({ supportTicket, listView, removeSupportTicket, updateSupportTicket }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [menuHeight, setMenuHeight] = useState("500px");
  const [formattedStepsToReproduce, setFormattedStepsToReproduce] = useState<any>({})

  useEffect(() => {
    if (supportTicket.systemCreatedOn === "Dashboard") {
      setFormattedStepsToReproduce(JSON.parse(supportTicket.stepsToReproduce));
    }
  }, [supportTicket]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleDeleteTicket = async () => {
    try {
      await deleteSupportTicket(supportTicket.id);
      removeSupportTicket(supportTicket.id);
    } catch (err) {
      console.error("Error deleting ticket", err);
    }
  };

  const handleChangeStatus = async (updatedTicket: any) => {
    try {
      const { id, status, comments } = updatedTicket;
      const updatedTicketResponse = await patchSupportTicket(id, { status, comments });
      updateSupportTicket(updatedTicketResponse);
    } catch (err) {
      console.error("Error updating status", err);
    }
  };

  const formatOccurredAt = (occurredAt: any) => {
    const occurredDate: any = new Date(occurredAt);
    const now: any = new Date();
    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;

    const elapsed = now - occurredDate;

    const hoursAgo = t("support.hoursAgo");
    if (elapsed < msPerDay) {
      const hours = Math.round(elapsed / msPerHour);
      return `${hours} ${hoursAgo}`;
    } else if (now.getFullYear() === occurredDate.getFullYear()) {
      return occurredDate.toLocaleDateString("en-US", { month: "short", day: "2-digit" });
    } else {
      return occurredDate.toLocaleDateString("en-US", { month: "short", day: "2-digit", year: "2-digit" });
    }
  };

  const renderCollapsedView = () => (
    <div className="announcementCardTextContainer" style={{ height: "auto", cursor: "pointer", display: "flex", flexDirection: "column" }} onClick={toggleExpand}>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "baseline", marginBottom: "10px" }}>
        <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
          <span style={{ display: "flex", fontWeight: "700", width: "100%" }}>
            <div style={{
              flexGrow: 1,
              marginRight: "10px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}>
              {supportTicket.subject}
            </div>
            {formatOccurredAt(supportTicket.occurredAt)}
          </span>
          <TicketStatus status={supportTicket.status} supportTicket={supportTicket} handleChangeStatus={handleChangeStatus} />
        </div>
      </div>
    </div>
  );

  const renderCollapsedListView = () => (
    <div className="announcementCardTextContainer" style={{ height: "auto", cursor: "pointer" }} onClick={toggleExpand}>
      <table style={{ width: "100%", tableLayout: "fixed" }}>
        <tbody>
          <tr>
            <td style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
              {supportTicket.subject}
            </td>
            <td></td>
            <td></td>
            <td><TicketStatus status={supportTicket.status} supportTicket={supportTicket} handleChangeStatus={handleChangeStatus} /></td>
            <td>{formatOccurredAt(supportTicket.occurredAt)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  const renderSizeAdjustButton = () => (
    <button
      className="sizeAdjustButton toolbarButton"
      onClick={() => setMenuHeight(menuHeight === "500px" ? "auto" : "500px")}
    >
      {menuHeight === "500px" ? <MaxHeight /> : <MinHeight />}
    </button>
  );

  const renderExpandedView = () => (
    <div className="announcementCardTextContainer" style={listView ? { height: menuHeight, minHeight: "300px", border: "solid 3px var(--primary-color)", borderRadius: "12px" } : { height: menuHeight, minHeight: "300px" }}>
      <Toolbar
        collapse={toggleExpand}
        deleteTicket={handleDeleteTicket}
        sizeAdjustButton={renderSizeAdjustButton}
      />
      <TicketStatus status={supportTicket.status} supportTicket={supportTicket} handleChangeStatus={handleChangeStatus} />
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "baseline" }}>
        <span style={{ display: "flex", fontWeight: "700", width: "100%" }}>
          <div style={{
            flexGrow: 1,
            marginRight: "10px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
          }}>
            {supportTicket.systemCreatedOn === "HMI" ? 
            supportTicket.subject.substring(0, 30) + (supportTicket.subject.length > 30 ? "...  " : "  ")
          :
            formattedStepsToReproduce.measurement
          }
          </div>
          {formatOccurredAt(supportTicket.occurredAt)}
        </span>
      </div>
      {/* 
      The support ticket has a different format depending on where it is submitted from.
      The HMI tickets make sense and require no explanation.
      Dashboard support tickets are BIS tickets. 
      */}
      {
        supportTicket.systemCreatedOn === "HMI" ? // Detecting if the ticket is from the HMI
          <TabStrip selected={selectedTab} onSelect={(e) => setSelectedTab(e.selected)} className="myCustomTabStrip">
            <TabStripTab title={t("support.timeline")}>
              <CommentSection supportTicket={supportTicket} updateSupportTicket={updateSupportTicket} />
            </TabStripTab>
            <TabStripTab title={t("support.details")}>
              <div className="messageBodyCard" style={{ color: "black" }}>
                <SupportField label="Steps to Reproduce" value={supportTicket.stepsToReproduce} />
                <SupportField label="Category" value={supportTicket.category} />
                <SupportField label="Creator ID" value={supportTicket.creatorID} />
                <SupportField label="Installation Type" value={supportTicket.installationType} />
                <SupportField label="Last Calibrated At" value={new Date(supportTicket.lastCalibratedAt).toLocaleString()} />
                <SupportField label="Machine ID" value={supportTicket.machineID} />
                <SupportField label="Machine Type" value={supportTicket.machineType} />
                <SupportField label="Operator ID" value={supportTicket.operatorID} />
                <SupportField label="Position Latitude" value={supportTicket.positionLat} />
                <SupportField label="Position Longitude" value={supportTicket.positionLong} />
                <SupportField label="Sensor Status" value={supportTicket.sensorStatus} />
                <SupportField label="System Created On" value={supportTicket.systemCreatedOn} />
                <SupportField label="Temperature" value={supportTicket.temperature} />
                <SupportField label="Version SW" value={supportTicket.versionSW} />
              </div>
            </TabStripTab>
          </TabStrip>
          : // ABOVE: The HMI support ticket display, BELOW: the Dashboard support ticket display.
          <TabStrip selected={selectedTab} onSelect={(e) => setSelectedTab(e.selected)} className="myCustomTabStrip">
            <TabStripTab title={t("support.timeline")}>
              <CommentSection supportTicket={supportTicket} updateSupportTicket={updateSupportTicket} />
            </TabStripTab>
            <TabStripTab title={t("support.details")}>
              <div className="messageBodyCard" style={{ color: "black", width: "510px" }}>
                <h3>Error messages:</h3>
                {formattedStepsToReproduce.errorMessages.map((error, index) => (
                  <div key={index}>
                    <p style={{ color: "red" }}>{error}</p>
                  </div>
                ))}
                <h3>Pipelines:</h3>
                {formattedStepsToReproduce.pipelines.map((pipeline, index) => (
                  <PipelineCard pipeline={pipeline} index={index} />
                ))}
              </div>
            </TabStripTab>
          </TabStrip>
      }
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <span style={{ fontWeight: "bold", alignSelf: "center" }}>ID: {supportTicket.id}</span>
      </div>
    </div>
  );

  return (
    <div className={listView ? "announcementCardListContainer" : "announcementCard-container"}>
      <div className="announcementCard">
        {isExpanded ? renderExpandedView() : listView ? renderCollapsedListView() : renderCollapsedView()}
      </div>
    </div>
  );
};