import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { onLogin } from "../../store/user/userSlice";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/store";
import { AppState } from "../../store/store";
import { useTranslation } from "react-i18next";
import { fetchAuthSession } from "aws-amplify/auth";

export const LandingPage: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const currentUser = useSelector((state: AppState) => state.user.currentUser);

  useEffect(() => {
    fetchAuthSession()
      .then((user: any) => {
        // If user isn't logged in, then log them in.
        if (currentUser === undefined) {
          dispatch(onLogin({t}));
        }

        // We are now logged in, push to scheduler
        if (
          (location.pathname === "/" ||
            location.pathname === "/login")
          //    &&
          // user.attributes.phone_number_verified !== undefined
        ) {
          navigate("/scheduler", { replace: true });
        }
      })
      .catch((err) => {
        navigate("/login", { replace: true });
        console.error("Authentication failed: ", err);
      });
  }, []);

  return <div></div>;
};
