import { TFunction } from "i18next";

const emailRegex = new RegExp(/\S+@\S+/);

export const emailValidator = (
  t: TFunction<"translation", undefined>,
  value?: string
) => (value && emailRegex.test(value) ? "" : t("common.emailValidator"));

export const passwordValidator = (
  t: TFunction<"translation", undefined>,
  value?: string
) => {
  if (value === undefined) return t("user.fillInPassword");
  if (value.length < 8) return t("user.atleast8Char");
  return "";
};

export const notEmptyValidator = (
  t: TFunction<"translation", undefined>,
  input?: string
) => (input && input !== "" ? "" : t("common.requiredField"));

export const newPasswordValidator = (
  t: TFunction<"translation", undefined>,
  value?: string
) => {
  let error = "";
  if (value === undefined) return t("user.fillInPassword");
  const error0 = value.length < 8 ? t("user.atleast8Char") + "\n" : "";
  const error1 = !new RegExp("(?=.*[a-z])").test(value)
    ? t("user.lowerCase") + "\n"
    : "";
  const error2 = !new RegExp("(?=.*[A-Z])").test(value)
    ? t("user.upperCase") + "\n"
    : "";
  const error3 = !new RegExp("(?=.*[0-9].*)").test(value)
    ? t("user.number") + "\n"
    : "";
  const error4 = !new RegExp("(?=.*[-+_!@#$%^&*.,?])").test(value)
    ? t("user.specialCharacter") + "\n"
    : "";
  error = error0 + error1 + error2 + error3 + error4;
  return error;
};

export const phoneNumberValidator = (
  t: TFunction<"translation", undefined>,
  value?: string
) => {
  if (value === undefined) return t("common.phoneValidator");
  return !new RegExp("\\+(46)\\d{9}$").test(value) && value.length < 12
    ? t("common.phoneValidatorFormat")
    : "";
};
