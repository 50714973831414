import React, { useEffect } from "react";
import {
  Form,
  FormRenderProps,
  FormElement,
  Field,
  FieldRenderProps,
} from "@progress/kendo-react-form";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import { roleEn2swe, roleSwe2En } from "./userList";

// Redux Stuff
import {
  // addUser,
  setUserHasChanged,
  saveUserToServer,
  setSelectedUser,
  deleteUser,
  setNotification,
  setInformationRequest,
} from "../../store/user/userSlice";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/store";
import { AppState } from "../../store/store";

import {
  helpInformationPath,
  roleType,
  userType,
} from "../../store/user/types";
import { getHighestRoleTypeFromServerStruct } from "../../helpers/genericHelpers";
import * as userAPI from "./../../api/netRail/users";
import { useTranslation } from "react-i18next";

export const CustomInputUser = (fieldRenderProps: FieldRenderProps) => {
  const {
    label,
    value,
    onChange,
    visited,
    validationMessage,
    modified,
    ...other
  } = fieldRenderProps;

  const dispatch = useAppDispatch();
  const isValid = () =>
    visited && modified && validationMessage ? false : true;

  return (
    <div style={{ width: "100%" }}>
      <Input
        {...other}
        label={label}
        value={value}
        onChange={(e) => {
          onChange(e);
          dispatch(setUserHasChanged(true));
        }}
        valid={isValid()}
      />
      {!isValid() ? (
        <div
          style={{
            color: "red",
            height: "fit-content",
            fontSize: "10px",
            whiteSpace: "pre-line",
          }}
        >
          {validationMessage}
        </div>
      ) : (
        <div style={{ height: "11px" }} />
      )}
    </div>
  );
};

const emailRegex = new RegExp(/\S+@\S+\.\S+/);

const nameRegex = new RegExp(/^[a-zA-ZåöäÅÄÖ]{1,30}$/);

// export const getRoleType = (role: string) => {
//   switch (role) {
//     case "Admin":
//       return roleType.admin;
//     case "Projektledare":
//       return roleType.manager;
//     // case "Kontakt":
//     //   return roleType.external;
//     default:
//       return roleType.operator;
//   }
// };

interface userFormProps {
  setNewUserAdded?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UserForm: React.FunctionComponent<userFormProps> = ({
  setNewUserAdded,
}) => {
  const { t } = useTranslation();

  const emailValidator = (value?: string) =>
    value && emailRegex.test(value) ? "" : t("common.emailValidator");

  const phoneNumberValidator = (value?: string) => {
    if (value === undefined || value === "") return t("common.phoneValidator");

    return !new RegExp("\\+(46)\\d{9}$").test(value) && value.length < 12
      ? t("common.phoneValidatorFormat")
      : "";
  };

  const notUndefined = (value?: string) => {
    return value && value !== "" && nameRegex.test(value)
      ? ""
      : t("common.usernameValidator");
  };

  const notEmptyValidator = (value?: string) => {
    return value && value.length > 0 ? "" : t("common.requiredField");
  };

  const validValidator = (value?: string) => {
    return "";
  };

  const selectedUser = useSelector(
    (state: AppState) => state.user.selectedUser
  );

  const dispatch = useAppDispatch();

  const currentUser = useSelector((state: AppState) => state.user.currentUser);
  const allCompanies = useSelector(
    (state: AppState) => state.company.allCompanies
  );
  const user = useSelector((state: AppState) =>
    state.user.allContacts.find((user) => user.id === selectedUser)
  );

  const userHasChanged = useSelector(
    (state: AppState) => state.user.userHasChanged
  );

  useEffect(() => {
    return () => {
      dispatch(
        setInformationRequest({
          path: undefined,
          showInformation: false,
        })
      );
    };
  }, []);

  const informationRequest = useSelector(
    (state: AppState) => state.user.informationRequest
  );

  const renderFormContent = (
    formRenderProps: FormRenderProps,
    user?: userType
  ) => {
    const { valid } = formRenderProps;

    const userRegistered =
      user?.phone === "" || user?.phone === null ? true : false;

    const companyField = (
      <Field
        name={"companyName"}
        component={DropDownList}
        label={t("manageUsers.userCompany")}
        data={allCompanies.map((company) => company.name)}
        validator={!user?.sentToServer ? notEmptyValidator : validValidator}
        disabled={user?.sentToServer ? true : false}
      />
    );

    return (
      <FormElement className="userCardUser" style={{ width: "100%" }}>
        <div className="userCardColumnL">
          <div className={"k-form-legend updateUsersTitle"}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color: "black",
              }}
            >
              {user && user.sentToServer
                ? t("common.update") + " " + t("manageUsers.user")
                : t("common.add") + " " + t("manageUsers.user")}
              <span
                className="k-icon k-font-icon k-i-info informationButton"
                style={{ marginLeft: "10px" }}
                onClick={() => {
                  if (user && user.sentToServer) {
                    dispatch(
                      setInformationRequest({
                        path: helpInformationPath.UpdateUserForm,
                        showInformation:
                          informationRequest?.path ===
                          helpInformationPath.UpdateUserForm
                            ? !informationRequest?.showInformation
                            : true,
                      })
                    );
                  } else {
                    dispatch(
                      setInformationRequest({
                        path: helpInformationPath.CreateUserForm,
                        showInformation:
                          informationRequest?.path ===
                          helpInformationPath.CreateUserForm
                            ? !informationRequest?.showInformation
                            : true,
                      })
                    );
                  }
                }}
              />
            </div>{" "}
            <span
              className="k-icon k-font-icon k-i-close removeChart"
              style={{ fontSize: "24px" }}
              onClick={() => {
                if (!user?.sentToServer && user)
                  dispatch(deleteUser(user.id));
                // edit = false;
                dispatch(setSelectedUser(""));
                if (setNewUserAdded) setNewUserAdded(true);
              }}
            />
          </div>
          <div className="userCardRow">
            <div style={{ width: "45%", marginRight: "10%" }}>
              <Field
                name={"firstName"}
                component={CustomInputUser}
                label={t("manageUsers.firstname")}
                validator={notUndefined}
                disabled={userRegistered}
              />
            </div>
            <div style={{ width: "45%" }}>
              <Field
                name={"lastName"}
                component={CustomInputUser}
                label={t("manageUsers.surname")}
                validator={notUndefined}
                disabled={userRegistered}
              />
            </div>
          </div>
          <Field
            name={"email"}
            component={CustomInputUser}
            label={t("manageUsers.email")}
            validator={emailValidator}
            disabled={userRegistered}
          />
          {user && !user.sentToServer ? null : (
            <Field
              name={"phone_number"}
              component={CustomInputUser}
              label={t("manageUsers.phone")}
              validator={phoneNumberValidator}
              disabled={
                !currentUser?.roles.some((role) => {
                  return role.name !== roleType.operator;
                }) || userRegistered
              }
            />
          )}
          <Field
            name={"roleType"}
            component={DropDownList}
            onFocus={() => {
              dispatch(setUserHasChanged(true));
            }}
            data={
              currentUser?.roles.some((role) => role.name === roleType.admin)
                ? [
                    t("manageUsers.operator"),
                    t("manageUsers.manager"),
                    t("manageUsers.admin"),
                  ]
                : [t("manageUsers.operator"), t("manageUsers.manager")]
            }
            label={t("manageUsers.role")}
            disabled={
              userRegistered || // Block managers from changing admin roles
              (currentUser?.roles.some(
                (role) => role.name !== roleType.admin
              ) &&
                user?.roles.some((role) => role.name === roleType.admin))
            }
          />
          <div>
            {currentUser?.roles.some((role) => role.name === roleType.admin)
              ? companyField
              : ""}
          </div>
          {
            !valid ? (
              <div style={{ marginTop: "10px", color: "red" }}>
                {t("common.requiredFields")}
              </div>
            ) : null
            // ? "Vänligen fyll i ett giltligt namn."
            // : ""
          }
          <div className="CardColumnForm">
            {phoneNumberValidator(user?.phone ? user.phone : "-") !== "" &&
            user &&
            user.sentToServer ? (
              <button
                className="AddUserButtonForm BUTTON"
                style={{ marginRight: "20px" }}
                title={
                  t("manageUsers.resendInvitation") != null
                    ? t("manageUsers.resendInvitation").toString()
                    : ""
                }
                onClick={async (e) => {
                  try {
                    if (user) {
                      await userAPI.resendUserInvite(user?.id);
                    } else {
                      throw Error("failed sending invite.");
                    }
                    dispatch(
                      setNotification({
                        message: t("manageUsers.invitationSent"),
                        style: "success",
                        open: true,
                      })
                    );
                  } catch (error) {
                    dispatch(
                      setNotification({
                        message: t("manageUsers.invitationSentError"),
                        style: "error",
                        open: true,
                      })
                    );
                  }
                }}
              >
                {t("manageUsers.sendNewInvite")}
              </button>
            ) : null}

            {user && user.sentToServer ? (
              <button
                className="AddUserButtonForm BUTTON"
                type={"submit"}
                disabled={!valid || !userHasChanged}
                style={
                  !valid || !userHasChanged
                    ? {
                        opacity: 0.5,
                      }
                    : {}
                }
                onClick={() => {
                  if (setNewUserAdded) setNewUserAdded(true);
                }}
              >
                {t("common.update")}
              </button>
            ) : (
              <button
                className="AddUserButtonForm BUTTON"
                type={"submit"}
                disabled={!valid}
                style={
                  !valid
                    ? {
                        opacity: 0.5,
                      }
                    : {}
                }
                onClick={() => {
                  if (setNewUserAdded) setNewUserAdded(true);
                }}
              >
                {t("common.add")}
              </button>
            )}
          </div>
        </div>
      </FormElement>
    );
  };

  const renderUserContent = (user?: userType) => {
    const company = allCompanies.find((comp) => comp.id === user?.companyID);

    return (
      <div className="formContainer">
        <Form
          initialValues={{
            firstName: user?.firstName,
            lastName: user?.lastName,
            phone_number: user?.phone ? user?.phone : "+46",
            email: user?.email,
            roleType: user
              ? roleEn2swe(t, getHighestRoleTypeFromServerStruct(user.roles))
              : "",
            companyName: company ? company.name : "",
            // password: "",
          }}
          key={JSON.stringify(user)}
          onSubmit={(dataItem) => {
            const company = allCompanies.find(
              (company) => company.name === dataItem.companyName
            );

            const currCompany = allCompanies.find(
              (company) => company.id === currentUser?.companyID
            );

            if (dataItem && currentUser) {
              if (currCompany)
                dispatch(
                  saveUserToServer({
                    t: t,
                    email: dataItem.email.toLowerCase(),
                    type: roleSwe2En(t, dataItem.roleType),
                    firstName: dataItem.firstName,
                    lastName: dataItem.lastName,
                    userID: selectedUser,
                    companyID: currentUser?.roles.some((role) => role.name === roleType.admin) && company
                      ? company.id
                      : currCompany.id, // use selected company or inherit it from manager
                    sentToServer: user?.sentToServer,
                    phone_number: dataItem.phone_number === "+46"
                      ? undefined
                      : dataItem.phone_number
                  })
                );
            }
          }}
          render={(formRenderProps) => renderFormContent(formRenderProps, user)}
        />
      </div>
    );
  };
  return <div className="userCardUser">{renderUserContent(user)}</div>;
};
