import { useEffect, useState } from "react";
import { fetchAuthSession } from "aws-amplify/auth";
import { useAppDispatch } from "../store/store";
import { populateCurrentSession, setAuthenticationSuccess } from "../store/user/userSlice";
import { useTranslation } from "react-i18next";

export const useAuthInitializer = () => {
  const [initialized, setInitialized] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const init = async () => {
      try {
        const session = await fetchAuthSession();
        if (session?.tokens?.idToken) {
          await dispatch(populateCurrentSession({ t }));
          dispatch(setAuthenticationSuccess(true));
        } else {
          dispatch(setAuthenticationSuccess(false));
        }
      } catch (err) {
        console.warn("Auth session not found or invalid", err);
        dispatch(setAuthenticationSuccess(false));
      } finally {
        setInitialized(true);
      }
    };

    init();
  }, []);

  return { initialized };
};
