import React from "react";
import "./DescriptiveIcon.css";

interface ItemProps {
  icon: string;
  data: string | number | undefined;
  text?: string;
}
const DescriptiveIcon: React.FC<ItemProps> = ({ icon, data, text }) => {
  return (
    <div className="DescriptiveIcon">
      {icon != "" ? (
        <img className="ItemIcon" src={icon} alt="" />
      ) : (
        <span className="k-icon k-font-icon k-i-graph" style={{ color: "black" }} />
      )}
      <div className="ItemData body-1">{data === undefined ? "-" : data}</div>
      {text ? <div className="ItemText caption">{text}</div> : null}
    </div>
  );
};

export default DescriptiveIcon;
