import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import { Toolbar } from "../../components/toolbar";
import { AppState } from "../../store/store";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/store";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Scrollbars from "react-custom-scrollbars-2";
import * as announcementsAPI from "../../api/netRail/announcements";
import { adminMessageType, helpInformationPath } from "../../store/user/types";
import { AnnouncementCard } from "../../components/announcement/announcementCard";
import { setInformationRequest } from "../../store/user/userSlice";
import { customThumb } from "../../components/manageUsers/userList";
import { cPhoneWidthLim } from "../../app";

export const AnnouncementsLog: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const [announcementList, setAnnouncementList] = useState<adminMessageType[]>(
    []
  );

  const width = useSelector((state: AppState) => state.scheduler.screenWidth);
  const height = useSelector((state: AppState) => state.scheduler.screenHeight);

  const dispatch = useAppDispatch();

  const path = useSelector(
    (state: AppState) => state.user.informationRequest?.path
  );

  const showInformation = useSelector(
    (state: AppState) => state.user.informationRequest?.showInformation
  );

  const informationRequest = useSelector(
    (state: AppState) => state.user.informationRequest
  );

  const announcementsCount = useSelector(
    (state: AppState) => state.user.announcementsCount
  );

  const Today = new Date();

  async function getAPIAnnouncementsLocally() {
    try {
      const announcements = await announcementsAPI.getAnnouncements();
      const sortedAnnouncementList = announcements
        .filter((announcement) => {
          return !(new Date(announcement.validAt) >= Today);
        })
        .sort((a, b) =>
          a.validAt > b.validAt ? 1 : b.validAt > a.validAt ? -1 : 0
        )
        .reverse();

      setAnnouncementList(sortedAnnouncementList);
    } catch (err) {
      console.error("err", err);
    }
  }

  useEffect(() => {
    getAPIAnnouncementsLocally();
  }, []);

  const Rows = announcementList.map((announcement, idx) => {
    return (
      <AnnouncementCard
        key={announcement.id}
        announcement={announcement}
        index={idx}
      />
    );
  });

  return (
    <div
      className="AnnouncementsLogContainer"
      style={
        width <= cPhoneWidthLim || height <= cPhoneWidthLim
          ? {}
          : { position: "relative", left: "44px", paddingRight: "44px" }
      }
    >
      <header className="ManageUsersToolbar">
        <Toolbar>
          <h5>{t("menu.announcementsPageName")}</h5>
        </Toolbar>
      </header>

      <div className="AnnouncementsListContainer">
        <div className="AnnouncementsListHeader">
          <div>
            <span
              className="k-icon k-font-icon k-i-inbox"
              style={{ marginRight: "5px", fontSize: "24px" }}
            />
            {announcementsCount}
          </div>
          <span
            className="k-icon k-font-icon k-i-information informationButton"
            style={{
              fontSize: "16px",
              marginLeft: "10px ",
            }}
            onClick={() => {
              dispatch(
                setInformationRequest({
                  path: helpInformationPath.AnnouncementList,
                  showInformation:
                    informationRequest?.path ===
                    helpInformationPath.AnnouncementList
                      ? !informationRequest?.showInformation
                      : true,
                })
              );
            }}
          />
        </div>
        <div className="AnnouncementsListWrapper">
          <Scrollbars
            thumbSize={0}
            renderThumbVertical={customThumb}
            autoHide={true}
            autoHideTimeout={1000}
            autoHideDuration={500}
          >
            <div className="AnnouncementsListItemWrapper">{Rows}</div>
          </Scrollbars>
        </div>
      </div>
    </div>
  );
};
