import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/store";
import { getMeasurementMetaData } from "../../api/netRail/measurements";
import { AppState } from "../../store/store";
import {
  setDataPoints,
  setDataPointsLongitudinalLevel,
  setInitialWindowPosition,
  setLongitudinalLevelWindow,
  setMainXScale,
  setSelectedChart,
  setWindowXPosition,
} from "../../store/plot/plotSlice";
import { dataPointType, GroupChartDataProps } from "../../store/plot/types";
import {
  ChartContainer,
  ChartDimensionProps,
} from "../generic/chartContainer/chartContainer";
import { fetchInitialData } from "../../store/plot/plotSlice";
import { ToggleMeasurementsLongitudinalLevel } from "../generic/toggleMeasurements/toggleMeasurementsLongitudinalLevel";
import { ToggleTolerances } from "../generic/toggleTolerances/toggleTolerances";
import { Card } from "./../generic/card/card";
import "./groupChartContainer.css";
// import { GroupChartLongitudinalLevel } from "./groupChartLongitudinalLevel";
import { cPhoneWidthLim } from "../../app";
import { leftAxisWidth } from "./groupChart/chartConfig";
import { scaleLinear } from "d3";

const GroupChartLongitudinalLevel = React.lazy(() => import ('./groupChartLongitudinalLevel'))

interface GroupChartContainerLongitudinalLevelProps {
  chartID: string;
  mainView: React.MutableRefObject<boolean>;
  finishedLoading: boolean;
  setFinishedLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const emptyDataSet: GroupChartDataProps = {
  trackGauge: [] as dataPointType[],
  crossLevelBIS: [] as dataPointType[],
  crossLevel: [] as dataPointType[],
  crossLevelUnevenness: [] as dataPointType[],
  twist3m: [] as dataPointType[],
  twist6m: [] as dataPointType[],
  alignment: [] as dataPointType[],
  longitudinalLevel: [] as dataPointType[],
  alignmentLeft: [] as dataPointType[],
  longitudinalLevelLeft: [] as dataPointType[],
  alignmentRight: [] as dataPointType[],
  longitudinalLevelRight: [] as dataPointType[],
  longitudinalAngleHP: [] as dataPointType[],
};

export const GroupChartContainerLongitudinalLevel: React.FunctionComponent<
  GroupChartContainerLongitudinalLevelProps
> = ({ chartID, mainView, finishedLoading, setFinishedLoading }) => {
  const [chartDimensions, setChartDimensions] = useState<
    ChartDimensionProps | undefined
  >(undefined);

  const data = useSelector((state: AppState) =>
    state.plot.specialCharts.specialChartArray.find(
      (chart: any) => chart.chartID === chartID
    )
  )?.plotData;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const allChartsSpecial = useSelector(
    (state: AppState) => state.plot.specialCharts.specialChartArray
  );

  const maxWindowSize = useSelector(
    (state: AppState) => state.plot.specialCharts.maxWindowSize
  );

  const allCharts = useSelector((state: AppState) => state.plot.charts);

  const screenWidth = useSelector(
    (state: AppState) => state.scheduler.screenWidth
  );
  const height = useSelector((state: AppState) => state.scheduler.screenHeight);

  // const height = useSelector((state: AppState) => state.scheduler.screenHeight);

  const triggerRerender = height >= 450 ? true : false;

  const longitudinalAngleChartID = useSelector(
    (state: AppState) => state.plot.specialCharts.longitudinalAngleChartID
  );
  const longitudinalLevelChartID = useSelector(
    (state: AppState) => state.plot.specialCharts.longitudinalLevelChartID
  );

  const signalInterval = useSelector(
    (state: AppState) => state.plot.globalSignalInterval
  );

  const selectedMeasurement = useSelector(
    (state: AppState) => state.scheduler.selectedMeasurement
  );

  const graphCharts = allCharts.filter((chart) => chart.chartID !== "999999");
  const allChartsArray = [allChartsSpecial, graphCharts];
  return (
    <Card>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          maxHeight: "40px",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          overflow: "visible",
        }}
      >
        {chartID === longitudinalAngleChartID ? null : (
          <ToggleTolerances chartID={chartID} />
        )}
        <div style={{ display: "flex", flexDirection: "row" }}>
          {chartID === longitudinalAngleChartID ? (
            <h5
              style={{
                paddingBottom: "5px",
                // paddingLeft: screenWidth >= cPhoneWidthLim ? "40px" : "10px",
              }}
            >
              {t("currentLongitudinalLevel.bodyAngle")}
            </h5>
          ) : (
            <div />
          )}
          <span
            className="k-icon k-font-icon k-i-home removeChart"
            style={
              chartID === longitudinalAngleChartID
                ? {
                    fontSize: "24px",
                    marginRight: "20px",
                    marginLeft: chartDimensions
                      ? chartDimensions?.width - 90
                      : 30,
                  }
                : { fontSize: "24px", marginRight: "20px" }
            }
            onClick={async () => {
              const setsToPlot: GroupChartDataProps = {
                trackGauge: [] as dataPointType[],
                crossLevelBIS: [] as dataPointType[],
                crossLevel: [] as dataPointType[],
                crossLevelUnevenness: [] as dataPointType[],
                twist3m: [] as dataPointType[],
                twist6m: [] as dataPointType[],
                alignment: [] as dataPointType[],
                longitudinalLevel: [] as dataPointType[],
                alignmentLeft: [] as dataPointType[],
                longitudinalLevelLeft: [] as dataPointType[],
                alignmentRight: [] as dataPointType[],
                longitudinalLevelRight: [] as dataPointType[],
                longitudinalAngleHP: [] as dataPointType[],
              };

              setFinishedLoading(false);
              for (const i in allChartsArray) {
                const charts = allChartsArray[i];
                for (const i in charts) {
                  const chart = charts[i];
                  // To update one chart set the selected chart to the current looped one, this will be overwritten later when user do any action.
                  dispatch(setSelectedChart(chart.chartID)); // Make sure that the chart we want to use is activated


                  for (const key of chart.measurementToDisplay) {
                    try {
                      const initialData = await dispatch(
                        fetchInitialData({
                          measurement: key as keyof GroupChartDataProps,
                          chartID: chart.chartID,
                          signalInterval,
                          measurementID: selectedMeasurement,
                          screenWidth,
                          reset: true
                        })
                      ).unwrap();
        
                      setsToPlot[key as keyof GroupChartDataProps] = initialData;
                    } catch (error) {
                      console.error("Error fetching initial data:", error);
                    }
                  }
                  if (
                    chart.chartID === longitudinalLevelChartID ||
                    chart.chartID === longitudinalAngleChartID
                  ) {
                    dispatch(setDataPointsLongitudinalLevel(setsToPlot));
                  } else {
                    dispatch(setDataPoints(setsToPlot));
                  }
                }
              }

              const plotMetaData = await getMeasurementMetaData(
                "longitudinalLevel",
                selectedMeasurement
              );
              const xMin = plotMetaData.startKm * 1000 + plotMetaData.startMeter;
              const xMax = plotMetaData.endKm * 1000 + plotMetaData.endMeter;

              if (xMax - xMin <= 30) {
                dispatch(
                  setLongitudinalLevelWindow(
                    Number(((xMax - xMin) * 0.5).toPrecision(3))
                  )
                );
              } else {
                dispatch(setLongitudinalLevelWindow(maxWindowSize));
              }
              dispatch(setInitialWindowPosition(true));
              dispatch(setWindowXPosition(70));

              if (chartDimensions) {
                const xScale = scaleLinear()
                  .domain([signalInterval.xMinOrig, signalInterval.xMaxOrig])
                  .range([leftAxisWidth, chartDimensions.width]);

                dispatch(setMainXScale(xScale));
              }
              mainView.current = true;

              setFinishedLoading(true);
            }}
          />
        </div>
      </div>

      <div className="GroupChartContainer">
        <ChartContainer
          chartDimensions={chartDimensions}
          setChartDimensions={setChartDimensions}
          rerenderEvent={triggerRerender}
        >
          <GroupChartLongitudinalLevel
            chartDimensions={chartDimensions}
            data={data ? data : emptyDataSet}
            chartID={chartID}
            mainView={mainView}
            finishedLoading={finishedLoading}
            setFinishedLoading={setFinishedLoading}
          />
        </ChartContainer>
      </div>
      {chartID === longitudinalAngleChartID ? null : (
        <ToggleMeasurementsLongitudinalLevel
          chartID={chartID}
          chartDimensions={chartDimensions}
        />
      )}
    </Card>
  );
};
