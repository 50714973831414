// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PlanList {
  width: 100%;
  height: 100%;
  overflow: visible;
  padding-right: 1px;
  padding-left: 1px;
  z-index: 1;
}

.PlanListWrapper {
  width: 100%;
  scrollbar-width: none !important;
  padding-bottom: 2%;
  padding-bottom: 4px;
  overflow: auto;
}
.PlanListWrapper::-webkit-scrollbar {
  display: none;
}
.PlanListItemWrapper {
  width: calc(100%);
  display: flex;
  justify-content: center;
}

.PlanListFooterContainer {
  width: 100%;
  height: fit-content;
  padding: 15px 50px 70px 50px;
  overflow-x: hidden;
}

.PlanListFooterPages {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PlanListFooterPagesIcon {
  border-radius: 4px;
}

.PlanListFooterPages:last-child {
  margin-left: auto;
}

.PlanListFooterSelectPages {
  margin: auto;
}

.PlanListFooterSelect {
  outline: none;
  border: none;
  background-color: transparent;
  text-align-last: right;
}
`, "",{"version":3,"sources":["webpack://./src/components/scheduler/planList/planList.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,iBAAiB;EACjB,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,gCAAgC;EAChC,kBAAkB;EAClB,mBAAmB;EACnB,cAAc;AAChB;AACA;EACE,aAAa;AACf;AACA;EACE,iBAAiB;EACjB,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,4BAA4B;EAC5B,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,6BAA6B;EAC7B,sBAAsB;AACxB","sourcesContent":[".PlanList {\n  width: 100%;\n  height: 100%;\n  overflow: visible;\n  padding-right: 1px;\n  padding-left: 1px;\n  z-index: 1;\n}\n\n.PlanListWrapper {\n  width: 100%;\n  scrollbar-width: none !important;\n  padding-bottom: 2%;\n  padding-bottom: 4px;\n  overflow: auto;\n}\n.PlanListWrapper::-webkit-scrollbar {\n  display: none;\n}\n.PlanListItemWrapper {\n  width: calc(100%);\n  display: flex;\n  justify-content: center;\n}\n\n.PlanListFooterContainer {\n  width: 100%;\n  height: fit-content;\n  padding: 15px 50px 70px 50px;\n  overflow-x: hidden;\n}\n\n.PlanListFooterPages {\n  width: 100%;\n  height: fit-content;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.PlanListFooterPagesIcon {\n  border-radius: 4px;\n}\n\n.PlanListFooterPages:last-child {\n  margin-left: auto;\n}\n\n.PlanListFooterSelectPages {\n  margin: auto;\n}\n\n.PlanListFooterSelect {\n  outline: none;\n  border: none;\n  background-color: transparent;\n  text-align-last: right;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
