import React, { useEffect, useState } from "react";

import { DropDownList } from "@progress/kendo-react-dropdowns";
import {
  Field,
  FieldRenderProps,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";

// Redux Stuff
import {
  deleteMachine,
  setSelectedMachine,
  saveMachineToServer,
  setMachineHasChanged,
} from "../../store/machine/machineSlice";

import { Fade } from "@progress/kendo-react-animation";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/store";
import { AppState } from "../../store/store";
import { machineType } from "../../store/machine/types";
import {
  setInformationRequest,
  setNotification,
} from "../../store/user/userSlice";
import { helpInformationPath, roleType } from "../../store/user/types";
import { CustomInputUser } from "../manageUsers/userForm";
import { useTranslation } from "react-i18next";

const nameRegex = new RegExp(/^[0-9a-zA-ZåöäÅÄÖ]{1,30}$/);

const nameRegex2 = new RegExp(/^[0-9a-zA-ZåöäÅÄÖ ]{0,30}$/);

interface machineFormProps {
  setAddMachine: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MachineForm: React.FunctionComponent<machineFormProps> = ({
  setAddMachine,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const notEmptyValidator = (value?: string) =>
    value && value.length > 0 ? "" : t("common.cannotBeEmptyValidator");
  const notUndefined2 = (value?: string) => {
    if (value === undefined || value === "") return "";
    return value && nameRegex2.test(value) ? "" : t("common.lengthValidator");
  };
  const notUndefined = (value?: string) => {
    return value && value !== "" && nameRegex.test(value)
      ? ""
      : t("common.notAValidNameValidator");
  };

  const selectedMachine = useSelector(
    (state: AppState) => state.machine.selectedMachine
  );

  const machine = useSelector((state: AppState) =>
    state.machine.allMachines.find((machine) => machine.id === selectedMachine)
  );
  const currentCompanies = useSelector((state: AppState) =>
    state.company.allCompanies.find(
      (company) => machine?.companyID === company.id
    )
  );

  const allCompanies = useSelector(
    (state: AppState) => state.company.allCompanies
  );
  const machineHasChanged = useSelector(
    (state: AppState) => state.machine.machineHasChanged
  );
  const currentUser = useSelector((state: AppState) => state.user.currentUser);

  const [copiedSecretKeyInput, setCopiedSecretKeyInput] = useState("");
  const [showSecretKey, setShowSecretKey] = useState(false);

  const [copiedClientIdInput, setCopiedClientIdInput] = useState("");
  const [showClientId, setShowClientId] = useState(false);

  const [copiedMachineIdInput, setCopiedMachineIdInput] = useState("");
  const [showMachineId, setShowMachineId] = useState(false);

  useEffect(() => {
    // Needed since we don't get the hashkeys during the initial setup.
    if (machine) {
      setCopiedSecretKeyInput(machine.secretKey ? machine.secretKey : "");
      setCopiedClientIdInput(machine.clientID ? machine.clientID : "");
      setCopiedMachineIdInput(machine.id ? machine.id : "");
    } else {
      setCopiedSecretKeyInput("");
      setCopiedClientIdInput("");
      setCopiedMachineIdInput("");
    }
  }, [selectedMachine]);

  const childrenMachineID = showMachineId ? (
    <div className="content">{t("externalLinkShare.copied")}</div>
  ) : null;
  const animationMachineID = <Fade>{childrenMachineID}</Fade>;

  const TextOutputMachineID = (fieldRenderProps: FieldRenderProps) => {
    return (
      <div className="copyContainer">
        <Input
          style={{ width: "80%" }}
          label={"machineID"}
          value={copiedMachineIdInput} // valueOuto
          disabled={true}
          type={"password"}
        />{" "}
        <div className="buttonContainer" style={{ position: "relative" }}>
          <button
            className="copy BUTTON"
            onClick={(e) => {
              e.preventDefault();
              navigator.clipboard.writeText(copiedMachineIdInput);
              setShowMachineId(true);
              setTimeout(function () {
                setShowMachineId(false);
              }, 2000);
            }}
          >
            <img src={"./icons/copyIcon.svg"} alt="copy"></img>
          </button>
          <div className="animationContent">{animationMachineID}</div>
        </div>
      </div>
    );
  };

  const childrenClientID = showClientId ? (
    <div className="content">{t("externalLinkShare.copied")}</div>
  ) : null;
  const animationClientID = <Fade>{childrenClientID}</Fade>;

  const TextOutputClientID = (fieldRenderProps: FieldRenderProps) => {
    return (
      <div className="copyContainer">
        <Input
          style={{ width: "80%" }}
          label={"clientID"}
          value={copiedClientIdInput} // valueOuto
          disabled={true}
          type={"password"}
        />{" "}
        <div className="buttonContainer" style={{ position: "relative" }}>
          <button
            className="copy BUTTON"
            onClick={(e) => {
              e.preventDefault();
              navigator.clipboard.writeText(copiedClientIdInput);
              setShowClientId(true);
              setTimeout(function () {
                setShowClientId(false);
              }, 2000);
            }}
          >
            <img src={"./icons/copyIcon.svg"} alt="copy"></img>
          </button>
          <div className="animationContent">{animationClientID}</div>
        </div>
      </div>
    );
  };

  const childrenSeceretKey = showSecretKey ? (
    <div className="content">{t("externalLinkShare.copied")}</div>
  ) : null;
  const animationSecretKey = <Fade>{childrenSeceretKey}</Fade>;

  const TextOutputSecretKey = (fieldRenderProps: FieldRenderProps) => {
    return (
      <div className="copyContainer">
        <Input
          style={{ width: "80%" }}
          label={"secretKey"}
          value={copiedSecretKeyInput} // valueOuto
          disabled={true}
          type={"password"}
        />{" "}
        <div className="buttonContainer" style={{ position: "relative" }}>
          <button
            className="copy BUTTON"
            onClick={(e) => {
              e.preventDefault();
              navigator.clipboard.writeText(copiedSecretKeyInput);
              setShowSecretKey(true);
              setTimeout(function () {
                setShowSecretKey(false);
              }, 2000);
            }}
          >
            <img src={"./icons/copyIcon.svg"} alt="copy"></img>
          </button>
          <div className="animationContent">{animationSecretKey}</div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    return () => {
      dispatch(
        setInformationRequest({
          path: undefined,
          showInformation: false,
        })
      );
    };
  }, []);

  const informationRequest = useSelector(
    (state: AppState) => state.user.informationRequest
  );

  const renderFormContent = (formRenderProps: FormRenderProps) => {
    const { valid } = formRenderProps;
    return (
      <FormElement className="userCardUser" style={{ width: "100%" }}>
        <div className="userCardColumnL">
          <legend
            className={"k-form-legend"}
            style={{
              color: "black",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {machine && machine.sentToServer
              ? t("common.update") + " " + t("manageMachines.machine")
              : t("common.add") + " " + t("manageMachines.machine")}
            <span
              className="k-icon k-font-icon k-i-info informationButton"
              style={{ marginLeft: "10px" }}
              onClick={() => {
                if (machine && machine.sentToServer) {
                  dispatch(
                    setInformationRequest({
                      path: helpInformationPath.UpdateMachineForm,
                      showInformation:
                        informationRequest?.path ===
                        helpInformationPath.UpdateMachineForm
                          ? !informationRequest?.showInformation
                          : true,
                    })
                  );
                } else {
                  dispatch(
                    setInformationRequest({
                      path: helpInformationPath.CreateMachineForm,
                      showInformation:
                        informationRequest?.path ===
                        helpInformationPath.CreateMachineForm
                          ? !informationRequest?.showInformation
                          : true,
                    })
                  );
                }
              }}
            />
          </legend>
          <Field
            name={"nameTag"}
            component={CustomInputUser}
            onBlur={() => dispatch(setMachineHasChanged(true))}
            label={t("manageMachines.machineNameTag")}
            validator={notUndefined}
          />
          <Field
            name={"description"}
            component={CustomInputUser}
            onBlur={() => dispatch(setMachineHasChanged(true))}
            label={t("manageMachines.machineDescription")}
            validator={notUndefined2}
          />
          <Field
            name={"name"} // Actually nameID only admins can change and create
            component={CustomInputUser}
            onBlur={() => dispatch(setMachineHasChanged(true))}
            label={t("manageMachines.machineName")}
            validator={notUndefined}
            disabled={currentUser?.roles.some((role) => {
              return role.name !== roleType.admin;
            })}
          />
          <Field
            name={"kmCounter"}
            component={CustomInputUser}
            label={t("manageMachines.distanceCounter")}
            disabled={true}
          />

          {currentUser?.roles.some(
            (entry) => entry.name === "ADMIN" 
          ) ? (
            <>
              <Field
                name={"companyName"}
                component={DropDownList}
                onFocus={() => dispatch(setMachineHasChanged(true))}
                label={t("manageMachines.machineCompany")}
                data={allCompanies.map((company) => company.name)}
                validator={notEmptyValidator}
                disabled={machine ? machine.sentToServer : false}
              />
            </>
          ) : (
            null
          )}
          {machine ? (
            <>
              <Field
                name={"MaskinID"}
                component={TextOutputMachineID}
                label={"MaskinID"}
              />
              <Field
                name={"clientID"}
                component={TextOutputClientID}
                label={"clientID"}
              />
              <Field
                name={"secretKey"}
                component={TextOutputSecretKey}
                label={"secretKey"}
              />
            </>
          ) : (
              null
          )}
          {!valid ? (
            <div style={{ marginTop: "10px", color: "red" }}>
              {t("common.requiredFields")}
            </div>
          ) : null}
        </div>
        <div className="CardColumnForm">
          {machine && machine.sentToServer ? (
            <button
              className="AddUserButtonForm BUTTON"
              type={"submit"}
              disabled={!valid || !machineHasChanged}
              style={
                !valid || !machineHasChanged
                  ? {
                      opacity: 0.5,
                    }
                  : {}
              }
              onClick={() => {
                if (setAddMachine) setAddMachine(true);
              }}
            >
              {t("common.update")}
            </button>
          ) : (
            <button
              className="AddUserButtonForm BUTTON"
              type={"submit"}
              disabled={!valid}
              style={
                !valid
                  ? {
                      opacity: 0.5,
                    }
                  : {}
              }
              onClick={() => {
                if (setAddMachine) setAddMachine(true);
              }}
            >
              {t("common.add")}
            </button>
          )}
        </div>
      </FormElement>
    );
  };

  const renderMachineContent = (machine?: machineType) => {
    return (
      <div className="formContainer">
        <span
          className="k-icon k-font-icon k-i-close removeChart"
          style={{ fontSize: "24px", marginLeft: "95%" }}
          onClick={() => {
            if (!machine?.sentToServer && machine)
              dispatch(deleteMachine(machine.id));
            dispatch(setSelectedMachine(""));
            setAddMachine(true);
          }}
        />
        <Form
          initialValues={{
            name: machine?.name,
            nameTag: machine?.nameTag,
            MaskinID: machine?.id,
            clientID: machine?.clientID,
            secretKey: machine?.secretKey,
            description: machine?.description,
            companyName: currentCompanies?.name,
            kmCounter: machine?.kmCounter,
          }}
          key={JSON.stringify(machine)}
          onSubmit={(dataItem) => {
            const company = allCompanies.find(
              (company) => company.name === dataItem.companyName
            );

            if (dataItem && currentUser) {
              const currCompany = allCompanies.find(
                (company) => currentUser?.companyID === company.id
              );

              const isUserAdmin = currentUser?.roles.some(
                (entry) => entry.name === roleType.admin
              );
              if (currCompany) {
                dispatch(
                  saveMachineToServer(
                    t,
                    dataItem.name,
                    dataItem.description,
                    isUserAdmin && company ? company.id : currCompany.id, // use selected company or inherit it from manager'
                    machine?.id,
                    machine?.sentToServer,
                    dataItem.nameTag
                  )
                );
              } else {
                dispatch(
                  setNotification({
                    style: "error",
                    message: t("manageMachines.errerManageMachine"),
                    open: true,
                  })
                );
              }
            }
          }}
          render={(formRenderProps) => renderFormContent(formRenderProps)}
        />
      </div>
    );
  };

  return <div className="userCardUser">{renderMachineContent(machine)}</div>;
};
