// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.excel-report-container {
  display: flex;
  flex-direction: column;
  margin: 50px;
  align-items: center;
  width: 100%;
}

.date-range-picker {
  margin-bottom: 20px;
}

.filter-options {
  width: 300px;
}

.excel-report-container h1 {
  margin-bottom: 20px;
}

.custom-selected {
  color: green;
}

.k-grid {
  max-width: 95vw;
  overflow: scroll;
}

.k-grid-toolbar {
  display: flex;
  justify-content: space-evenly !important;
  padding-right: 15% !important;
}

.k-radio {
  margin: 10px;
}

.language-selector-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.language-selector {
  display: inline;
}`, "",{"version":3,"sources":["webpack://./src/pages/excelReport/ExcelReport.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,wCAAwC;EACxC,6BAA6B;AAC/B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".excel-report-container {\n  display: flex;\n  flex-direction: column;\n  margin: 50px;\n  align-items: center;\n  width: 100%;\n}\n\n.date-range-picker {\n  margin-bottom: 20px;\n}\n\n.filter-options {\n  width: 300px;\n}\n\n.excel-report-container h1 {\n  margin-bottom: 20px;\n}\n\n.custom-selected {\n  color: green;\n}\n\n.k-grid {\n  max-width: 95vw;\n  overflow: scroll;\n}\n\n.k-grid-toolbar {\n  display: flex;\n  justify-content: space-evenly !important;\n  padding-right: 15% !important;\n}\n\n.k-radio {\n  margin: 10px;\n}\n\n.language-selector-buttons {\n  display: flex;\n  justify-content: center;\n  margin-bottom: 20px;\n}\n\n.language-selector {\n  display: inline;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
