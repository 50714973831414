import { ScaleLinear } from "d3";
import { TFunction } from "i18next";
import { measurementMetaData } from "../scheduler/types";

// ------Action Types
export enum plotActionType {
  setDataPoints = "SET_DATA_POINTS",
  setMeasurementToDisplay = "SET_MEASUREMENT_TO_DISPLAY",
  setTolerancesToDisplay = "SET_TOLERANCES_TO_DISPLAY",
  setSignalInterval = "SET_DISPLAY_INTERVAL",
  setGlobalSignalInterval = "SET_GLOBAL_SIGNAL_INTERVAL",
  setSignalIntervalProperty = "SET_SIGNAL_INTERVAL_PROPERTY",
  setTolerancesToDisplayNew = "SET_TOLERANCES_TO_DISPLAY_NEW",
  setAllCharts = "SET_ALL_CHARTS",
  setSelectedChart = "SET_SELECTED_CHART",
  addNewChart = "ADD_NEW_CHART",
  removeChart = "REMOVE_CHART",
  setPlotMetaData = "SET_PLOT_META_DATA",
  setGpsPoints = "SET_GPS_POINTS",
  setDisplayProjectedTrack = "SET_DISPLAY_PROJECTED_TRACK",
  setDataPointsAnyChart = "SET_DATA_POINTS_ANY_CHART",
  setMainXScale = "SET_MAIN_X_SCALE",
  setMainXScaleTicks = "SET_MAIN_X_SCALE_TICKS",
  setFinishedLoading = "SET_FINISIHED_LOADING",
  setXAxisIndex = "SET_X_AXIS_INDEX",
  addLongitudinalLevelChart = "ADD_LONGITUDINAL_LEVEL_CHART",
  setDataPointsLongitudinalLevel = "SET_DATA_POINTS_LONGITUDINAL_LEVEL",
  setLongitudinalLevelToDisplay = "SET_LONGITUDINAL_LEVEL_TO_DISPLAY",
  setLongitudinalLevelWindow = "SET_LONGITUDINAL_LEVEL_WINDOW",
  setInitialWindowPosition = "SET_INITIAL_WINDOW_POSITION",
  setWindowXPosition = "SET_WINDOW_X_POSITION",
  setAllMeasurementObjects = "SET_ALL_MEASUREMENT_OBJECTS",
  setViewSpecialCharts = "SET_VIEW_SPECIAL_CHARTS",
  setObjectsToDisplay = "SET_OBJECTS_TO_DISPLAY",
  setAccelerationArea = "SET_ACCELERATION_AREA",
  setAllMeasurementNotes = "SET_ALL_MEASUREMENT_NOTES",
}

// ----------Action interfaces
export interface setMainXScale {
  type: typeof plotActionType.setMainXScale;
  payload: ScaleLinear<number, number>;
}

export interface setMainXScaleTicks {
  type: typeof plotActionType.setMainXScaleTicks;
  payload: { x: number[]; tick: string[] };
}

export interface setDataPointsAnyChart {
  type: typeof plotActionType.setDataPointsAnyChart;
  payload: { chartID: string; data: GroupChartDataProps };
}

export interface setDataPoints {
  type: typeof plotActionType.setDataPoints;
  payload: GroupChartDataProps;
}

export interface setGpsPoints {
  type: typeof plotActionType.setGpsPoints;
  payload: gpsPointType[];
}

export interface setDisplayProjectedTrack {
  type: typeof plotActionType.setDisplayProjectedTrack;
  payload: boolean;
}

export interface setMeasurementToDisplay {
  type: typeof plotActionType.setMeasurementToDisplay;
  payload: (keyof GroupChartDataProps)[];
}

export interface setTolerancesToDisplay {
  type: typeof plotActionType.setTolerancesToDisplay;
  payload: SelectedTolerancesType;
}

export interface setAllCharts {
  type: typeof plotActionType.setAllCharts;
  payload: plotState[];
}

export interface setSignalInterval {
  type: typeof plotActionType.setSignalInterval;
  payload: {
    xMinOrig: number;
    xMaxOrig: number;
    xMin: number;
    xMax: number;
    resolution: number;
  };
}

export interface setGlobalSignalInterval {
  type: typeof plotActionType.setGlobalSignalInterval;
  payload: {
    xMinOrig: number;
    xMaxOrig: number;
    xMin: number;
    xMax: number;
    resolution: number;
  };
}

export interface addNewChart {
  type: typeof plotActionType.addNewChart;
  payload: plotState;
}
export interface removeChart {
  type: typeof plotActionType.removeChart;
  payload: string;
}

export interface setSignalIntervalProperty {
  type: typeof plotActionType.setSignalIntervalProperty;
  payload: { key: string; value: number };
}
export interface setSelectedChart {
  type: typeof plotActionType.setSelectedChart;
  payload: string;
}

export interface setPlotMetaData {
  type: typeof plotActionType.setPlotMetaData;
  payload: measurementMetaData;
}

export interface setFinishedLoading {
  type: typeof plotActionType.setFinishedLoading;
  payload: { chartID: string; finishedLoading: boolean };
}

export interface setXAxisIndex {
  type: typeof plotActionType.setXAxisIndex;
  payload: number;
}

export interface addLongitudinalLevelChart {
  type: typeof plotActionType.addLongitudinalLevelChart;
  payload: plotStateLongitudinalLevel;
}

export interface setDataPointsLongitudinalLevel {
  type: typeof plotActionType.setDataPointsLongitudinalLevel;
  payload: GroupChartDataProps;
}

export interface setLongitudinalLevelToDisplay {
  type: typeof plotActionType.setLongitudinalLevelToDisplay;
  payload: (keyof GroupChartDataProps)[];
}

export interface setLongitudinalLevelWindow {
  type: typeof plotActionType.setLongitudinalLevelWindow;
  payload: number;
}

export interface setInitialWindowPosition {
  type: typeof plotActionType.setInitialWindowPosition;
  payload: boolean;
}

export interface setWindowXPosition {
  type: typeof plotActionType.setWindowXPosition;
  payload: number;
}

export interface setAllMeasurementObjects {
  type: typeof plotActionType.setAllMeasurementObjects;
  payload: objectPointType[];
}

export interface setAllMeasurementNotes {
  type: typeof plotActionType.setAllMeasurementNotes;
  payload: notePointType[];
}

export interface setViewSpecialCharts {
  type: typeof plotActionType.setViewSpecialCharts;
  payload: boolean;
}

export interface setObjectsToDisplay {
  type: typeof plotActionType.setObjectsToDisplay;
  payload: string[];
}

export interface setAccelerationArea {
  type: typeof plotActionType.setAccelerationArea;
  payload: number[];
}

export type PlotAction =
  | setDataPoints
  | setMeasurementToDisplay
  | setTolerancesToDisplay
  | setSignalInterval
  | setSignalIntervalProperty
  | setAllCharts
  | setSelectedChart
  | addNewChart
  | removeChart
  | setPlotMetaData
  | setGpsPoints
  | setDisplayProjectedTrack
  | setDataPointsAnyChart
  | setGlobalSignalInterval
  | setMainXScale
  | setMainXScaleTicks
  | setFinishedLoading
  | setXAxisIndex
  | addLongitudinalLevelChart
  | setDataPointsLongitudinalLevel
  | setLongitudinalLevelToDisplay
  | setLongitudinalLevelWindow
  | setInitialWindowPosition
  | setWindowXPosition
  | setAllMeasurementObjects
  | setAllMeasurementNotes
  | setViewSpecialCharts
  | setObjectsToDisplay
  | setAccelerationArea;

export interface GroupChartDataProps {
  trackGauge: dataPointType[];
  crossLevelBIS: dataPointType[];
  crossLevel: dataPointType[];
  crossLevelUnevenness: dataPointType[];
  twist3m: dataPointType[];
  twist6m: dataPointType[];
  alignment: dataPointType[];
  longitudinalLevel: dataPointType[];
  alignmentLeft: dataPointType[];
  longitudinalLevelLeft: dataPointType[];
  alignmentRight: dataPointType[];
  longitudinalLevelRight: dataPointType[];
  longitudinalAngleHP: dataPointType[];
}

export interface IntegrationChartProps {
  longitudinalLevelDeltaYMean: dataPointType[];
  longitudinalLevelDeltaYRight: dataPointType[];
  longitudinalLevelDeltaYLeft: dataPointType[];
}

// ---------Redux State Type ---------------

export interface dataPointType {
  x: number;
  y: number;
  t: number;
  class: string;
  tick: string;
  errors: string;
  reference: number;
  bis: number;
  quality: boolean;
  objects?: string[];
  notes?: string;
}

export interface gpsPointType {
  x: number;
  gpsX: number;
  gpsY: number;
  t: number;
  class: string;
  tick: string;
  errors: string;
  reference: number;
  objects: string[];
  notes: string;
}

export interface objectPointType {
  x: number;
  gpsX: number;
  gpsY: number;
  tick: string;
  type: objectTypes[];
  value: string;
  note?: string;
}

export interface objectPointTypeReduced {
  x: number;
  gpsX: number;
  gpsY: number;
  tick: string;
  type: objectTypes;
  value: string;
  note?: string;
}

export interface objectPointType {
  x: number;
  gpsX: number;
  gpsY: number;
  tick: string;
  type: objectTypes[];
  value: string;
  note?: string;
}

export interface notePointType {
  x: number;
  gpsX: number;
  gpsY: number;
  tick: string;
  type: objectTypes;
  value: string;
  note?: string;
}
// TODO: transle these?
export enum objectTypes {
  // switches = "Spårväxel",
  switch = "Switch",
  // culverts = "Trumma",
  culvert = "Culvert",
  // trackBarriers = "Spårspärr",
  trackBarrier = "Derailer",
  // levelCrossings = "Plankorsning",
  levelCrossing = "Level crossing",
  // notes = "Noteringar",
  notes = "Notes",
  marker = "Flag",
  // trackCrosses = "Spårkors",
  trackCross = "Track cross",
  // contactPoles = "Ktl-stolpe",
  contactPole = "Contact pole",
  start = "Start",
  end = "End",
}

export const translateObject = (objName: string) => {
  if (objName === objectTypes.switch) {
    return "switch";
  } else if (objName === objectTypes.culvert) {
    return "culvert";
  } else if (objName === objectTypes.trackBarrier) {
    return "trackBarrier";
  } else if (objName === objectTypes.levelCrossing) {
    return "levelCrossing";
  } else if (objName === objectTypes.notes) {
    return "notes";
  } else if (objName === objectTypes.marker) {
    return "marker";
  } else if (objName === objectTypes.trackCross) {
    return "trackCross";
  } else if (objName === objectTypes.contactPole) {
    return "contactPole";
  } else if (objName === objectTypes.start) {
    return "start";
  } else if (objName === objectTypes.end) {
    return "end";
  } else {
    return "";
  }
};

export const translateToSelectedLanguage = (
  t: TFunction<"translation", "translation">,
  objName: string | undefined
) => {
  if (objName === objectTypes.switch) {
    return t("gps.switch");
  } else if (objName === objectTypes.culvert) {
    return t("gps.culvert");
  } else if (objName === objectTypes.trackBarrier) {
    return t("gps.trackBarrier");
  } else if (objName === objectTypes.levelCrossing) {
    return t("gps.levelCrossing");
  } else if (objName === objectTypes.notes) {
    return t("gps.notes");
  } else if (objName === objectTypes.marker) {
    return t("gps.marker");
  } else if (objName === objectTypes.trackCross) {
    return t("gps.trackCross");
  } else if (objName === objectTypes.contactPole) {
    return t("gps.contactPole");
  } else if (objName === objectTypes.start) {
    return t("gps.start");
  } else if (objName === objectTypes.end) {
    return t("gps.end");
  } else {
    return "";
  }
};

export const translateFromSwe = (objName: string) => {
  if (objName === "Spårväxel") {
    return objectTypes.switch;
  } else if (objName === "Trumma") {
    return objectTypes.culvert;
  } else if (objName === "Spårspärr") {
    return objectTypes.trackBarrier;
  } else if (objName === "Plankorsning") {
    return objectTypes.levelCrossing;
  } else if (objName === "Noteringar") {
    return objectTypes.notes;
  } else if (objName === "Markering") {
    return objectTypes.marker;
  } else if (objName === "Spårkors") {
    return objectTypes.trackCross;
  } else if (objName === "Ktl-stolpe") {
    return objectTypes.contactPole;
  } else if (objName === "Start") {
    return objectTypes.start;
  } else if (objName === "Slut") {
    return objectTypes.end;
  } else {
    return "";
  }
};

export interface SelectedTolerancesType {
  plan: boolean;
  uh1: boolean;
  uh2: boolean;
  krit: boolean;
  stopp: boolean;
}

export interface specialChartsDataProps {
  specialChartArray: plotStateLongitudinalLevel[];
  xAxisIndex: number;
  longitudinalLevelChartID: string;
  longitudinalAngleChartID: string;
  windowSize: number;
  initialWindowPositon: boolean;
  windowXPosition: number;
  maxWindowSize: number;
  viewSpecialCharts: boolean;
}

export interface ChartType {
  charts: plotState[];
  specialCharts: specialChartsDataProps;
  gpsData?: gpsPointType[];
  selectedChart: string;
  globalSignalInterval: SignalInterval;
  globalXScale: globalXScale;
  allObjects: objectPointType[];
  allNotes: notePointType[];
  objectsToDisplay: string[];
  accelerationArea: number[];
}

export interface globalXScale {
  mainXScale: ScaleLinear<number, number>;
  xScaleTicks: { x: number[]; tick: string[] };
}

export interface globalSignalInterval {
  xMinOrig: number;
  xMaxOrig: number;
  xMin: number;
  xMax: number;
  resolution: number;
}

export interface SignalInterval {
  xMinOrig: number;
  xMaxOrig: number;
  xMin: number;
  xMax: number;
  resolution: number;
}

export interface plotState {
  chartID: string;
  plotData: GroupChartDataProps;
  plotMetaData: measurementMetaData;
  measurementToDisplay: (keyof GroupChartDataProps)[];
  tolerancesToDisplay: SelectedTolerancesType;
  signalInterval: SignalInterval;
  finishedLoading: boolean;
  reset?: boolean;
}

export interface plotStateLongitudinalLevel {
  chartID: string;
  plotData: GroupChartDataProps;
  plotMetaData: measurementMetaData;
  measurementToDisplay: (keyof GroupChartDataProps)[];
  tolerancesToDisplay: SelectedTolerancesType;
  signalInterval: SignalInterval;
}
export interface FinishedLoadingStruct {
  chartID: string;
  finishedLoading: boolean;
}
