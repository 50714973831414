// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./../../../public/redemptor-bg.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./../../../public/dashboard-bg.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.platform-buttons {
    margin: 0 auto;
    justify-content: space-evenly;
    display: flex;
}

.platform-button {
    width: 18vw;
    height: 25vh;
    border-radius: 15px;
    border: 1px solid darkgrey;
}

.platform-selection {
    height: 100vh;
    width: 100vw;
    align-content: center;
}

.platform-selection-border {
    border: 2px solid var(--primary-color);
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 10px;
    height: 95%;
    margin: 5px;  padding-top: 40px;
    font-weight: bold;
    color: white;
    line-height: 28px;
    font-size: 24px;
}

.platform-selection-border > p {
    filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 1));
}

.platform-selection-border-hmi {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.platform-selection-border-dashboard {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}`, "",{"version":3,"sources":["webpack://./src/pages/platformSelection/platformSelectionPage.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,6BAA6B;IAC7B,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,0BAA0B;AAC9B;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,qBAAqB;AACzB;;AAEA;IACI,sCAAsC;IACtC,sBAAsB;IACtB,4BAA4B;IAC5B,mBAAmB;IACnB,WAAW;IACX,WAAW,GAAG,iBAAiB;IAC/B,iBAAiB;IACjB,YAAY;IACZ,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,+CAA+C;AACnD;;AAEA;IACI,yDAA2D;AAC/D;;AAEA;IACI,yDAA2D;AAC/D","sourcesContent":[".platform-buttons {\n    margin: 0 auto;\n    justify-content: space-evenly;\n    display: flex;\n}\n\n.platform-button {\n    width: 18vw;\n    height: 25vh;\n    border-radius: 15px;\n    border: 1px solid darkgrey;\n}\n\n.platform-selection {\n    height: 100vh;\n    width: 100vw;\n    align-content: center;\n}\n\n.platform-selection-border {\n    border: 2px solid var(--primary-color);\n    background-size: cover;\n    background-repeat: no-repeat;\n    border-radius: 10px;\n    height: 95%;\n    margin: 5px;  padding-top: 40px;\n    font-weight: bold;\n    color: white;\n    line-height: 28px;\n    font-size: 24px;\n}\n\n.platform-selection-border > p {\n    filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 1));\n}\n\n.platform-selection-border-hmi {\n    background-image: url(\"./../../../public/redemptor-bg.jpg\");\n}\n\n.platform-selection-border-dashboard {\n    background-image: url(\"./../../../public/dashboard-bg.jpg\");\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
