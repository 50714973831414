// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CardContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: fit-content;
  padding-right: 50px;
  padding-bottom: 20px;
  background-color: var(--card-color, #ffffff);
  /* box-shadow: 0px 3px 6px 0 rgba(0, 0, 0, 0.06); */
  padding: 5px;
  /* border-radius: 10px; */
}
`, "",{"version":3,"sources":["webpack://./src/components/generic/card/card.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,WAAW;EACX,mBAAmB;EACnB,mBAAmB;EACnB,oBAAoB;EACpB,4CAA4C;EAC5C,mDAAmD;EACnD,YAAY;EACZ,yBAAyB;AAC3B","sourcesContent":[".CardContainer {\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  width: 100%;\n  height: fit-content;\n  padding-right: 50px;\n  padding-bottom: 20px;\n  background-color: var(--card-color, #ffffff);\n  /* box-shadow: 0px 3px 6px 0 rgba(0, 0, 0, 0.06); */\n  padding: 5px;\n  /* border-radius: 10px; */\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
