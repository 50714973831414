import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { AppState, useAppDispatch } from "./store/store";
import { useSelector } from "react-redux";
import { setSelectedMeasurement, setSelectedPlan } from "./store/scheduler/schedulerSlice";
import { logout, onLogin, setAuthenticationSuccess, setSelectedUser } from "./store/user/userSlice";
import { Menu } from "./components/menu";
import { fetchAuthSession } from "aws-amplify/auth";
import { getLastVisitedPlanAndMeasurement, setLastVisitedPlanAndMeasurement } from "./helpers/genericHelpers";
import { useTranslation } from "react-i18next";
import { fetchStoredAnnouncement } from "./components/announcement/announcement";
import * as announcementsAPI from "./api/netRail/announcements";

interface ProtectedRouteProps {
  children: JSX.Element;
}

export const ProtectedRoute: React.FunctionComponent<ProtectedRouteProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const currentUser = useSelector((state: AppState) => state.user.currentUser);
  const isAuthenticated = useSelector((state: AppState) => state.user.authenticationSuccess);
  const allPlans = useSelector((state: AppState) => state.scheduler.plans);

  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);
  const Today = new Date();

  useEffect(() => {
    const checkAuthenticatedUser = async () => {
      try {
        const session = await fetchAuthSession();

        if (!session || !session.tokens?.idToken) {
          dispatch(onLogin({ t }));
          const lastVisitedPage = getLastVisitedPlanAndMeasurement();
          if (lastVisitedPage?.lastPlan) dispatch(setSelectedPlan(lastVisitedPage.lastPlan));
          if (lastVisitedPage?.lastMeasurement) dispatch(setSelectedMeasurement(lastVisitedPage.lastMeasurement));
          else dispatch(setSelectedMeasurement(""));
          navigate("/login", { replace: true });
        } else {
          setUserIsLoggedIn(true);
        }
      } catch (err) {
        navigate("/login", { replace: true });
        console.error("Not authenticated", err);
      }
    };

    checkAuthenticatedUser();
  }, []);

  useEffect(() => {
    if (currentUser) {
      setUserIsLoggedIn(true);
      getAPIAnnouncementsLocally();

      const lastVisitedResources = getLastVisitedPlanAndMeasurement();
      if (lastVisitedResources?.lastPlan && allPlans.some(p => p.id === lastVisitedResources.lastPlan)) {
        dispatch(setSelectedPlan(lastVisitedResources.lastPlan));
      } else if (!lastVisitedResources?.lastPlan && allPlans.length === 0) {
        navigate("/scheduler");
        setLastVisitedPlanAndMeasurement(undefined, undefined);
      }

      if (lastVisitedResources?.lastMeasurement) {
        dispatch(setSelectedMeasurement(lastVisitedResources.lastMeasurement));
      }
    }
  }, [currentUser, allPlans.length]);

  useEffect(() => {
    if (currentUser === undefined) {
      dispatch(onLogin({ t }));
      dispatch(setAuthenticationSuccess(true));
    }
  }, []);

  const getAPIAnnouncementsLocally = async () => {
    try {
      const announcements = await announcementsAPI.getAnnouncements();
      const storedAnnouncements = fetchStoredAnnouncement();
      const filteredStoredAnnouncementList = announcements.filter(
        (announcement) =>
          storedAnnouncements.includes(announcement.id) &&
          new Date(announcement.expiresAt) > Today
      );
      const filteredIDs = filteredStoredAnnouncementList.map((a) => a.id);
      localStorage.setItem("storedAnnouncements", JSON.stringify(filteredIDs));
    } catch (err) {
      console.error("err", err);
    }
  };

  if (isAuthenticated === false) return <Navigate to="/login" replace />;
  if (!userIsLoggedIn) return null;
  if ((location.pathname.includes("announcements") || location.pathname.includes("manageCompanies")) &&
    currentUser?.roles.some((role) => role.name !== "ADMIN")) {
    return <Navigate to="/scheduler" replace />;
  }

  return (
    <>
      <Menu />
      {children}
    </>
  );
};
