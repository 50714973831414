// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PlanContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: auto;
}

.PlanContainerChild {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  max-width: var(--max-width);
  overflow: visible;
}

.PlanToolbar {
  position: relative;
  width: 100%;
  height: fit-content;
  z-index: 10000;
}
.plan-main {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  justify-content: center;
}
.NavigateBackButton {
  background-color: transparent;
}
`, "",{"version":3,"sources":["webpack://./src/components/scheduler/plan/plan.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,mBAAmB;EACnB,cAAc;AAChB;AACA;EACE,kBAAkB;EAClB,aAAa;EACb,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,uBAAuB;AACzB;AACA;EACE,6BAA6B;AAC/B","sourcesContent":[".PlanContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n  margin: auto;\n}\n\n.PlanContainerChild {\n  width: 100%;\n  height: fit-content;\n  display: flex;\n  flex-direction: column;\n  max-width: var(--max-width);\n  overflow: visible;\n}\n\n.PlanToolbar {\n  position: relative;\n  width: 100%;\n  height: fit-content;\n  z-index: 10000;\n}\n.plan-main {\n  position: relative;\n  display: flex;\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n  justify-content: center;\n}\n.NavigateBackButton {\n  background-color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
