import React from "react";
import { setMessages, setSelectedMessage } from "../../store/user/userSlice";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/store";
import "./adminMessageCard.css";
import { AppState } from "../../store/store";
import { adminMessageType } from "../../store/user/types";
import AdminMessage from "./adminMessage";
import * as announcementsAPI from "../../api/netRail/announcements";
import { useTranslation } from "react-i18next";

interface AdminMessageCardProps {
  message: adminMessageType;
}

export const AdminMessageCard: React.FunctionComponent<
  AdminMessageCardProps
> = ({ message }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const allCompanies = useSelector(
    (state: AppState) => state.company.allCompanies
  );

  const messageCompaniesNames = message.companies.map((companyID: string) => {
    const foundCompany = allCompanies.find(
      (fromAllCompany) => companyID === fromAllCompany.id
    );
    return foundCompany?.name;
  });

  const allMessages = useSelector((state: AppState) => state.user.messages);

  const removeMessage = () => {
    try {
      announcementsAPI.deleteAnnouncement(message.id);

      // Update redux
      dispatch(
        setMessages(allMessages.filter((msg) => msg.id !== message.id))
      );
    } catch (err) {
      console.error("Failed delete");
    }
  };

  const selectedMessage = useSelector(
    (state: AppState) => state.user.selectedMessage
  );

  const date = new Date();

  const expiringDate = new Date(date.setDate(date.getDate() - 1));
  const validAtDate = new Date(date.setDate(date.getDate() + 1));

  if (message.id !== selectedMessage) {
    return (
      <div
        className={
          "MessageCardContainer" +
          (new Date(message.expiresAt) < expiringDate
            ? " Expired"
            : new Date(message.validAt) > validAtDate
            ? " SoonValid"
            : "")
        }
      >
        <div>
          {new Date(message.expiresAt) < expiringDate
            ? t("adminMessageCard.passedEndDateDelete")
            : new Date(message.validAt) > validAtDate
            ? t("adminMessageCard.passedEndDateDelete")
            : ""}
        </div>
        <div className="MessageSplitRow">
          <div className="MessageSplitColumn">
            <div style={{ fontWeight: "bold" }}> {message.title}</div>
            <div>
              {t("adminMessageCard.validFrom")} {message.validAt}
            </div>
          </div>
          <div className="MessageSplitColumn">
            <div>{message.type}</div>
            <div>
              {t("adminMessageCard.validTo")} {message.expiresAt}
            </div>
          </div>
          <span
            className="k-icon k-font-icon k-i-edit removeChart"
            style={{
              fontSize: "24px",
            }}
            onClick={() => {
              dispatch(setSelectedMessage(message.id));
            }}
          />
          <span
            className="k-icon k-font-icon k-i-delete removeChart"
            style={{
              fontSize: "24px",
            }}
            onClick={() => {
              removeMessage();
            }}
          />
        </div>

        <div className="MessageBody">{message.message}</div>
        <div className="MessageCompanies">
          {t("to")}:{messageCompaniesNames.map((name) => " <" + name + ">")}
        </div>
      </div>
    );
  } else {
    return <AdminMessage />;
  }
};
