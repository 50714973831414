// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChartContainer {
  width: calc(100% - 60px - 10px);
  padding-left: 10px;
  height: 100%;
  overflow: "visible";
}
`, "",{"version":3,"sources":["webpack://./src/components/generic/chartContainer/chartContainer.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,kBAAkB;EAClB,YAAY;EACZ,mBAAmB;AACrB","sourcesContent":[".ChartContainer {\n  width: calc(100% - 60px - 10px);\n  padding-left: 10px;\n  height: 100%;\n  overflow: \"visible\";\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
