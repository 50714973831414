import React, { useMemo } from "react";
import { Libraries, useJsApiLoader } from "@react-google-maps/api";
import { GpsChart } from "./gpsChart";
import { gpsPointType, objectPointType } from "../../store/plot/types";

const libraries: Libraries = ["geometry", "drawing", "places"];

interface GpsChartWrapperProps {
  gpsSignal: gpsPointType[] | undefined;
  measurementObjects: objectPointType[];
  finishedLoadingObjects: boolean;
  selectedMeasurement: string;
}

export const GpsChartWrapper: React.FC<GpsChartWrapperProps> = ({
  gpsSignal,
  measurementObjects,
  finishedLoadingObjects,
  selectedMeasurement,
}) => {
  const loaderOptions = useMemo(
    () => ({
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
      libraries,
    }),
    []
  );

  const { isLoaded } = useJsApiLoader(loaderOptions);

  if (!isLoaded || !finishedLoadingObjects) {
    return <div>Loading...</div>;
  }
  
  return (
    <GpsChart
      gpsSignal={gpsSignal}
      measurementObjects={measurementObjects}
      finishedLoadingObjects={finishedLoadingObjects}
      selectedMeasurement={selectedMeasurement}
    />
  );
};
