import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { userState } from "./user/types";
import { machineState } from "./machine/types";
import { ChartType } from "./plot/types";
import { companyState } from "./company/types";
import { schedulerState } from "./scheduler/types";
import machineReducer from "./machine/machineSlice";
import userReducer from "./user/userSlice";
import schedulerReducer from "./scheduler/schedulerSlice";
import companyReducer from "./company/companySlice";
import plotReducer from "./plot/plotSlice";


const rootReducer = combineReducers({
  machine: machineReducer,
  user: userReducer,
  scheduler: schedulerReducer,
  company: companyReducer,
  plot: plotReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector = () => useSelector<RootState>;

export type AppState = {
  machine: machineState;
  user: userState;
  scheduler: schedulerState;
  company: companyState;
  plot: ChartType;
};

export default store;
