// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VisualizationContainer {
  width: 100%;
  height: 100%;
  /* padding-left: 10px; */
  background-color: var(--background-color);
}

.VisualizationContainerRS {
  width: 100%;
  height: 100%;
  /* padding-left: 10px; */
  background-color: transparent;
}
`, "",{"version":3,"sources":["webpack://./src/components/visualization/visualizationContainer.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,wBAAwB;EACxB,yCAAyC;AAC3C;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,wBAAwB;EACxB,6BAA6B;AAC/B","sourcesContent":[".VisualizationContainer {\n  width: 100%;\n  height: 100%;\n  /* padding-left: 10px; */\n  background-color: var(--background-color);\n}\n\n.VisualizationContainerRS {\n  width: 100%;\n  height: 100%;\n  /* padding-left: 10px; */\n  background-color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
