// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.charTabsMainContainer {
  width: 100%;
  margin-top: 20px;
  padding-bottom: 100px;
}

.charTabsSecondaryContainer {
  background-color: white;
  box-shadow: 0px -2px 3px 0px rgba(0, 0, 0, 0.26);
}

.tabButtonsContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.tabButtonSelected {
  min-width: 25%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  overflow-x: hidden;
  background-color: white;
  border-bottom: none;
  box-shadow: 0px -2px 3px 0px rgba(0, 0, 0, 0.26);
  z-index: 10;
  word-wrap: break-word;
}

.tabButtonNotSelected {
  min-width: 25%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  overflow-x: hidden;
  background-color: rgb(200, 200, 200);
  box-shadow: 0px -2px 3px 0px rgba(0, 0, 0, 0.26);
  border-bottom: solid 1px #d9d9d9;
  word-wrap: break-word;
}

.tabContentContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  color: black;
}
`, "",{"version":3,"sources":["webpack://./src/components/dashboard/chartTabs.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,uBAAuB;EACvB,gDAAgD;AAClD;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,6BAA6B;EAC7B,mBAAmB;EACnB,kBAAkB;EAClB,uBAAuB;EACvB,mBAAmB;EACnB,gDAAgD;EAChD,WAAW;EACX,qBAAqB;AACvB;;AAEA;EACE,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,6BAA6B;EAC7B,mBAAmB;EACnB,kBAAkB;EAClB,oCAAoC;EACpC,gDAAgD;EAChD,gCAAgC;EAChC,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;EAChB,YAAY;AACd","sourcesContent":[".charTabsMainContainer {\n  width: 100%;\n  margin-top: 20px;\n  padding-bottom: 100px;\n}\n\n.charTabsSecondaryContainer {\n  background-color: white;\n  box-shadow: 0px -2px 3px 0px rgba(0, 0, 0, 0.26);\n}\n\n.tabButtonsContainer {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n}\n\n.tabButtonSelected {\n  min-width: 25%;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-around;\n  align-items: center;\n  overflow-x: hidden;\n  background-color: white;\n  border-bottom: none;\n  box-shadow: 0px -2px 3px 0px rgba(0, 0, 0, 0.26);\n  z-index: 10;\n  word-wrap: break-word;\n}\n\n.tabButtonNotSelected {\n  min-width: 25%;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-around;\n  align-items: center;\n  overflow-x: hidden;\n  background-color: rgb(200, 200, 200);\n  box-shadow: 0px -2px 3px 0px rgba(0, 0, 0, 0.26);\n  border-bottom: solid 1px #d9d9d9;\n  word-wrap: break-word;\n}\n\n.tabContentContainer {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  overflow: hidden;\n  color: black;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
