/*
Read this before changing anything in this file!
Were you sent here by some well-meaning colleague? They don't understand what they ask of you.
Heed well my warning, for woe betide those who would dabble in the strange sorcery of the plotSlice.

For those brave and foolish souls who ignore my please and would dare to tweak or change the plotSlice,
you must be aware of the following:

Here is the component structure of the chart components:


Chartlist.ts ------------------ DASHBOARD --------- CurrentLongitudinalLevel.ts ------- VisualizationContainerRailshape.ts
      |                            |                           |                                           |
      |                GPSVisualizationContainer.ts            |                                 GroupChartContainterRailShape.ts
VisualizationContainer.ts          |           VisualizationContainerLongitudinalLevel.ts                  |
      |                    GPSChartContainer.ts                |                                 GroupChartRailShape.ts
GroupChartContainer.ts            |           GroupChartContainerLongitudinalLevel.ts                   
      |                    GPSChartWrapper.ts                 |                            
GroupChart.ts                      |           GroupChartLongitudinalLevel.ts
                              GPSChart.ts     

The relevant pipeline of the data for the charts is as follows:
1. The data for the GPS map is stored in the gpsData array in the plotSlice. This data is used in the GPSChart component.
2. The data for the group charts is stored in the plotData object in the plotSlice. This object contains an array of data points for each measurement type.
  The data is controlled by whatever is in the selectedChart key.
3. The data for the longitudinal level chart is stored in the plotData object as well, BUT, the setup for populating the chart is in the specialCharts object,
  which contains an array of special charts and a complex setup that is used to populate the longitudinal level chart. It's jerry-rigged to hijack the groupCharts setup.
  This is because the LL charts were basically copy-pasted from the group charts and then modified to fit the LL chart in two weeks.
  It's a complex setup that relies on setting the selectedChart with a special case for each LL chart type ("LLplt1" and "LAplt1") as a signal to populate the
  specialchartsarray and then use that data to populate the plotData object. I just spent the best part of two weeks and a small chunk of my sanity trying to
  divorce the LL charts from the group charts AND FAILED, so please, for the love of all that is holy, don't try to change this setup unless you have a very good reason to do so.
*/

import { createSlice, PayloadAction, current, createAsyncThunk } from '@reduxjs/toolkit';
import initialState from './initialState';
import { gpsPointType, GroupChartDataProps, SelectedTolerancesType, plotState, SignalInterval, plotStateLongitudinalLevel, objectPointType, notePointType } from './types';
import { measurementMetaData } from '../scheduler/types';
import { ScaleLinear } from 'd3';
import { getLongitudinalLevelChart, getNewChart } from '../../helpers/genericHelpers';
import { getMeasurementMetaData } from '../../api/netRail/measurements';
import * as plotAPI from '../../api/netRail/plot';
import { AppState } from '../store';
import { computeResolution } from '../../components/visualization/groupChart/chartConfig';
import { dataPointType } from './types';

export const fetchInitialData = createAsyncThunk(
  'plot/fetchPlotData',
  async (
    {
      measurement,
      chartID,
      signalInterval,
      measurementID,
      screenWidth,
      reset
    }: {
      measurement: string,
      chartID: string,
      signalInterval: SignalInterval,
      measurementID: string,
      screenWidth: number,
      reset?: boolean
    },
    { dispatch, getState }
  ) => {
    const state: AppState = getState() as AppState;
    const selectedChart = state.plot.charts.find(chart => chart.chartID === chartID);
    let xMin, xMax, chosenResolution;

    if (signalInterval.xMaxOrig === 0 || reset) {
      const plotMetaData = await getMeasurementMetaData(
        measurement === "crossLevelBIS" ? "crossLevel" : measurement,
        measurementID
      );
      xMin = plotMetaData.startKm * 1000 + plotMetaData.startMeter;
      xMax = plotMetaData.endKm * 1000 + plotMetaData.endMeter;
      chosenResolution = computeResolution(xMin, xMax, screenWidth);
      dispatch(setGlobalSignalInterval({ xMinOrig: xMin, xMaxOrig: xMax, xMin, xMax, resolution: chosenResolution }));
    } else {
      xMin = signalInterval.xMin;
      xMax = signalInterval.xMax;
      chosenResolution = signalInterval.resolution;
    }

    let plotData;
    if (measurement === "crossLevelBIS") {
      plotData = await plotAPI.getPlotData(
        measurementID,
        xMin,
        xMax,
        chosenResolution,
        "crossLevel",
        ["tick", "class", "reference", "errors", "bis", "quality"]
      );
      plotData = plotData.map((point) => ({
        x: point.x,
        tick: point.tick,
        y: point.bis,
        bis: point.bis,
        errors: point.errors,
        t: point.t,
        class: point.class,
        reference: point.reference,
        quality: point.quality,
        objects: point.objects,
        notes: point.notes,
      }));
    } else {
      plotData = await plotAPI.getPlotData(
        measurementID,
        xMin,
        xMax,
        chosenResolution,
        measurement,
        measurement === "crossLevel" ? ["tick", "class", "reference", "errors", "quality"] : ["tick", "class", "quality"]
      );
    }

    dispatch(setDataPoints({ ...selectedChart?.plotData, [measurement]: plotData }));
    return plotData;
  }
);

export const fetchLongitudinalLevelData = createAsyncThunk<
  void,
  { selectedMeasurement: string, screenWidth: number, signalInterval: SignalInterval, maxWindowSize: number },
  { state: AppState }
>(
  'plot/fetchLongitudinalLevelData',
  async ({ selectedMeasurement, screenWidth, signalInterval, maxWindowSize }, { dispatch, getState }) => {
    const state = getState() as AppState;
    const longitudinalLevelChartID = state.plot.specialCharts.longitudinalLevelChartID;
    const longitudinalAngleChartID = state.plot.specialCharts.longitudinalAngleChartID;

    const setsToPlot: GroupChartDataProps = {
      trackGauge: [] as dataPointType[],
      crossLevelBIS: [] as dataPointType[],
      crossLevel: [] as dataPointType[],
      crossLevelUnevenness: [] as dataPointType[],
      twist3m: [] as dataPointType[],
      twist6m: [] as dataPointType[],
      alignment: [] as dataPointType[],
      longitudinalLevel: [] as dataPointType[],
      alignmentLeft: [] as dataPointType[],
      longitudinalLevelLeft: [] as dataPointType[],
      alignmentRight: [] as dataPointType[],
      longitudinalLevelRight: [] as dataPointType[],
      longitudinalAngleHP: [] as dataPointType[],
    };

    // Fetch metadata for longitudinal level
    const plotMetaData = await getMeasurementMetaData("longitudinalLevel", selectedMeasurement);
    const xMin = plotMetaData.startKm * 1000 + plotMetaData.startMeter;
    const xMax = plotMetaData.endKm * 1000 + plotMetaData.endMeter;

    if (xMax - xMin <= 30) {
      dispatch(setLongitudinalLevelWindow(Number(((xMax - xMin) * 0.5).toPrecision(3))));
    } else {
      dispatch(setLongitudinalLevelWindow(maxWindowSize));
    }

    try {

      // Fetch plot data for longitudinalLevel
      const longitudinalLevelData = await dispatch(
        fetchInitialData({
          measurement: "longitudinalLevel",
          chartID: longitudinalLevelChartID,
          signalInterval,
          measurementID: selectedMeasurement,
          screenWidth,
          reset: true
        })
      ).unwrap();

      // Fetch plot data for longitudinalAngleHP
      const longitudinalAngleData = await dispatch(
        fetchInitialData({
          measurement: "longitudinalAngleHP",
          chartID: longitudinalAngleChartID,
          signalInterval,
          measurementID: selectedMeasurement,
          screenWidth,
          reset: true
        })
      ).unwrap();

      setsToPlot["longitudinalLevel"] = longitudinalLevelData;
      setsToPlot["longitudinalAngleHP"] = longitudinalAngleData;
      dispatch(addLongitudinalLevelChart(longitudinalLevelChartID));
      dispatch(addLongitudinalLevelChart(longitudinalAngleChartID));
      dispatch(setDataPointsLongitudinalLevel(setsToPlot));
    } catch (error) {
      console.error("Error fetching longitudinal level data:", error);
    }
  }
);

export const fetchLongitudinalAngleData = createAsyncThunk<
  void,
  { selectedMeasurement: string, screenWidth: number, signalInterval: SignalInterval, maxWindowSize: number },
  { state: AppState }
>(
  'plot/fetchLongitudinalAngleData',
  async ({ selectedMeasurement, screenWidth, signalInterval, maxWindowSize }, { dispatch, getState }) => {
    const state = getState() as AppState;
    const longitudinalAngleChartID = state.plot.specialCharts.longitudinalAngleChartID;

    const setsToPlot: GroupChartDataProps = {
      trackGauge: [] as dataPointType[],
      crossLevelBIS: [] as dataPointType[],
      crossLevel: [] as dataPointType[],
      crossLevelUnevenness: [] as dataPointType[],
      twist3m: [] as dataPointType[],
      twist6m: [] as dataPointType[],
      alignment: [] as dataPointType[],
      longitudinalLevel: [] as dataPointType[],
      alignmentLeft: [] as dataPointType[],
      longitudinalLevelLeft: [] as dataPointType[],
      alignmentRight: [] as dataPointType[],
      longitudinalLevelRight: [] as dataPointType[],
      longitudinalAngleHP: [] as dataPointType[],
    };

    // Fetch plot data for longitudinalAngleHP
    const longitudinalAngleData = await dispatch(
      fetchInitialData({
        measurement: "longitudinalAngleHP",
        chartID: longitudinalAngleChartID,
        signalInterval,
        measurementID: selectedMeasurement,
        screenWidth,
        reset: true
      })
    ).unwrap();

    setsToPlot["longitudinalAngleHP"] = longitudinalAngleData;
    dispatch(setSelectedChart(longitudinalAngleChartID));
    dispatch(setLongitudinalLevelToDisplay(["longitudinalAngleHP"]));
  }
);

export const plotSlice = createSlice({
  name: 'plot',
  initialState,
  reducers: {
    setDataPointsAnyChart: (state, action: PayloadAction<{ chartID: string, data: GroupChartDataProps }>) => {
      const chart = state.charts.find(chart => chart.chartID === action.payload.chartID);
      if (chart) {
        chart.plotData = action.payload.data;
      }
    },
    setDataPoints: (state, action: PayloadAction<GroupChartDataProps>) => {
      const chart = state.charts.find(chart => chart.chartID === state.selectedChart);
      if (chart) {
        chart.plotData = action.payload;
      }
    },
    addLongitudinalLevelChart: (state, action: PayloadAction<string>) => {
      const chart = getLongitudinalLevelChart(action.payload);
      state.specialCharts.specialChartArray.push(chart);
    },
    setLongitudinalLevelToDisplay: (state, action: PayloadAction<(keyof GroupChartDataProps)[]>) => {
      const chart = state.specialCharts.specialChartArray.find(chart => chart.chartID === state.selectedChart);
      if (chart) {
        chart.measurementToDisplay = action.payload;
      }
    },
    setDataPointsLongitudinalLevel: (state, action: PayloadAction<GroupChartDataProps>) => {
      const selectedChartID = state.selectedChart;
      const chartIndex = state.specialCharts.specialChartArray.findIndex(chart => chart.chartID === selectedChartID);

      if (chartIndex !== -1) {
        const existingPlotData = state.specialCharts.specialChartArray[chartIndex].plotData;
        state.specialCharts.specialChartArray[chartIndex].plotData = {
          ...existingPlotData,
          ...action.payload,
        };
      }
    },
    setGpsPoints: (state, action: PayloadAction<gpsPointType[]>) => {
      state.gpsData = action.payload;
    },
    setMeasurementToDisplay: (state, action: PayloadAction<(keyof GroupChartDataProps)[]>) => {
      const chart = state.charts.find(chart => chart.chartID === state.selectedChart);
      if (chart) {
        chart.measurementToDisplay = action.payload;
      }
    },
    setTolerancesToDisplay: (state, action: PayloadAction<SelectedTolerancesType>) => {
      if (state.selectedChart === state.specialCharts.longitudinalLevelChartID) {
        const chart = state.specialCharts.specialChartArray.find(chart => chart.chartID === state.selectedChart);
        if (chart) {
          chart.tolerancesToDisplay = action.payload;
        }
      } else {
        const chart = state.charts.find(chart => chart.chartID === state.selectedChart);
        if (chart) {
          chart.tolerancesToDisplay = action.payload;
        }
      }
    },
    setAllCharts: (state, action: PayloadAction<plotState[]>) => {
      state.charts = action.payload;
    },
    setSignalInterval: (state, action: PayloadAction<SignalInterval>) => {
      const chart = state.charts.find(chart => chart.chartID === state.selectedChart);
      if (chart) {
        chart.signalInterval = action.payload;
      }
    },
    setGlobalSignalInterval: (state, action: PayloadAction<SignalInterval>) => {
      state.globalSignalInterval = action.payload;
    },
    setSelectedChart: (state, action: PayloadAction<string>) => {
      state.selectedChart = action.payload;
    },
    addNewChart: (state, action: PayloadAction<string>) => {
      state.charts.push(getNewChart(action.payload));
    },
    removeChart: (state, action: PayloadAction<string>) => {
      state.charts = state.charts.filter(chart => chart.chartID !== action.payload);
      state.specialCharts.specialChartArray = state.specialCharts.specialChartArray.filter(chart => chart.chartID !== action.payload);
    },
    setPlotMetaData: (state, action: PayloadAction<measurementMetaData>) => {
      const chart = state.charts.find(chart => chart.chartID === state.selectedChart);
      if (chart) {
        chart.plotMetaData = action.payload;
      }
    },
    setMainXScale: (state, action: PayloadAction<ScaleLinear<number, number>>) => {
      state.globalXScale.mainXScale = action.payload;
    },
    setMainXScaleTicks: (state, action: PayloadAction<{ x: number[], tick: string[] }>) => {
      state.globalXScale.xScaleTicks = action.payload;
    },
    setFinishedLoading: (state, action: PayloadAction<{ chartID: string, finishedLoading: boolean }>) => {
      const chart = state.charts.find(chart => chart.chartID === action.payload.chartID);
      if (chart) {
        chart.finishedLoading = action.payload.finishedLoading;
      }
    },
    setXAxisIndex: (state, action: PayloadAction<number>) => {
      state.specialCharts.xAxisIndex = action.payload;
    },
    setLongitudinalLevelWindow: (state, action: PayloadAction<number>) => {
      state.specialCharts.windowSize = action.payload;
    },
    setInitialWindowPosition: (state, action: PayloadAction<boolean>) => {
      state.specialCharts.initialWindowPositon = action.payload;
    },
    setWindowXPosition: (state, action: PayloadAction<number>) => {
      state.specialCharts.windowXPosition = action.payload;
    },
    setAllMeasurementObjects: (state, action: PayloadAction<objectPointType[]>) => {
      state.allObjects = action.payload;
    },
    setAllMeasurementNotes: (state, action: PayloadAction<notePointType[]>) => {
      state.allNotes = action.payload;
    },
    setViewSpecialCharts: (state, action: PayloadAction<boolean>) => {
      state.specialCharts.viewSpecialCharts = action.payload;
    },
    setObjectsToDisplay: (state, action: PayloadAction<string[]>) => {
      state.objectsToDisplay = action.payload;
    },
    setAccelerationArea: (state, action: PayloadAction<number[]>) => {
      state.accelerationArea = action.payload;
    },
  },
});

export const {
  setDataPointsAnyChart,
  setDataPoints,
  setDataPointsLongitudinalLevel,
  setGpsPoints,
  setMeasurementToDisplay,
  setLongitudinalLevelToDisplay,
  setTolerancesToDisplay,
  setAllCharts,
  setSignalInterval,
  setGlobalSignalInterval,
  setSelectedChart,
  addNewChart,
  addLongitudinalLevelChart,
  removeChart,
  setPlotMetaData,
  setMainXScale,
  setMainXScaleTicks,
  setFinishedLoading,
  setXAxisIndex,
  setLongitudinalLevelWindow,
  setInitialWindowPosition,
  setWindowXPosition,
  setAllMeasurementObjects,
  setAllMeasurementNotes,
  setViewSpecialCharts,
  setObjectsToDisplay,
  setAccelerationArea,
} = plotSlice.actions;

export default plotSlice.reducer;