import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/store";
import { getMeasurementMetaData } from "../../api/netRail/measurements";
import { AppState } from "../../store/store";
import { updateCurrentUserProperty } from "../../store/user/userSlice";
import {
  setDataPoints,
  setSelectedChart,
} from "../../store/plot/plotSlice";
import {
  dataPointType,
  GroupChartDataProps,
} from "../../store/plot/types";
import * as plotAPI from "./../../api/netRail/plot";
import "./chartList.css";
import { computeResolution } from "./groupChart/chartConfig";
import { Visualization } from "./visualizationContainer";
import { AddNewChart } from "../generic/addNewChart/addNewChart";

interface ChartListProps {
  mainView: React.MutableRefObject<boolean>;
}
export const ChartList: React.FunctionComponent<ChartListProps> = ({
  mainView,
}) => {
  const dispatch = useAppDispatch();

  const [finishedLoading, setFinishedLoading] = useState(true);

  const screenWidth = useSelector(
    (state: AppState) => state.scheduler.screenWidth
  );

  const selectedMeasurement = useSelector(
    (state: AppState) => state.scheduler.selectedMeasurement
  );

  const viewSpecialCharts = useSelector(
    (state: AppState) => state.plot.specialCharts.viewSpecialCharts
  );

  const allCharts = useSelector((state: AppState) => state.plot.charts);

  useEffect(() => {
    return () => {
      setFinishedLoading(true);
    };
  }, []);

  const changeMeasurementToPlot = async () => {
    for (const i in allCharts) {
      const currChart = allCharts[i];
      dispatch(setSelectedChart(currChart.chartID));

      const metaData = await getMeasurementMetaData(
        "trackgauge",
        selectedMeasurement
      );
      const xMin = metaData.startKm * 1000 + metaData.startMeter;
      const xMax = metaData.endKm * 1000 + metaData.endMeter;
      const resolution = computeResolution(xMin, xMax, screenWidth);

      dispatch(
        updateCurrentUserProperty({
          xMinOrig: xMin,
          xMaxOrig: xMax,
          xMin,
          xMax,
          resolution,
        })
      );

      const setsToPlot: GroupChartDataProps = {
        trackGauge: [] as dataPointType[],
        crossLevelBIS: [] as dataPointType[],
        crossLevel: [] as dataPointType[],
        crossLevelUnevenness: [] as dataPointType[],
        twist3m: [] as dataPointType[],
        twist6m: [] as dataPointType[],
        alignment: [] as dataPointType[],
        longitudinalLevel: [] as dataPointType[],
        alignmentLeft: [] as dataPointType[],
        longitudinalLevelLeft: [] as dataPointType[],
        alignmentRight: [] as dataPointType[],
        longitudinalLevelRight: [] as dataPointType[],
        longitudinalAngleHP: [] as dataPointType[],
      };

      const getPoints = async (
        xMin: number,
        xMax: number,
        resolution: number
      ) => {
        for (const i in currChart.measurementToDisplay) {
          const currentProperty = currChart.measurementToDisplay[
            i
          ] as keyof GroupChartDataProps;

          let resp = await plotAPI
            .getPlotData(
              selectedMeasurement,
              xMin,
              xMax,
              resolution,
              currentProperty === "crossLevelBIS"
                ? "crossLevel"
                : currentProperty,
              currentProperty === "crossLevelBIS" ||
                currentProperty === "crossLevel"
                ? ["tick", "class", "reference", "errors", "bis", "quality"]
                : ["tick", "class", "quality"]
            )
            .catch((error) => console.error("Error in getpoints", error));

          if (currentProperty === "crossLevelBIS") {
            resp = (resp as dataPointType[]).map((point) => {
              return { ...point, y: point.bis };
            });
          }

          setsToPlot[currentProperty] = resp ? resp : [];
        }

        return setsToPlot;
      };

      const plotData = await getPoints(xMin, xMax, resolution);
      mainView.current = true;

      dispatch(setDataPoints(plotData));
    }
  };

  useEffect(() => {
    changeMeasurementToPlot();
  }, [selectedMeasurement]);
  const charts = allCharts
    .filter((chart) => chart.chartID !== "999999")
    .map((chart) => {
      return (
        <div className="DashboardMainSignals" key={chart.chartID}>
          <Visualization
            chartID={chart.chartID}
            mainView={mainView}
            finishedLoading={finishedLoading}
            setFinishedLoading={setFinishedLoading}
          />
        </div>
      );
    });
  return (
    <div>
      {charts} <AddNewChart key={"adds"} mainView={mainView} />
    </div>
  );
};
