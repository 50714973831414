import React from "react";
import { useAppDispatch } from "../../../store/store";
import { v4 as uuidv4 } from "uuid";
import { cPhoneWidthLim } from "../../../app";
import { AppState } from "../../../store/store";
import { useSelector } from "react-redux";
import {
  addNewChart,
  setSelectedChart,
} from "../../../store/plot/plotSlice";

interface AddNewChartProps {
  mainView: React.MutableRefObject<boolean>;
}

export const AddNewChart: React.FunctionComponent<AddNewChartProps> = () => {
  const dispatch = useAppDispatch();

  const width = useSelector((state: AppState) => state.scheduler.screenWidth);

  return (
    <div
      style={{ display: "flex", position: "relative", flexDirection: "row", justifyContent: "center", alignItems: "center", paddingBottom: "20px", paddingTop: "20px", }}
    >
      <img src="./icons/plus.svg" alt="" onClick={() => {
        const newChart = uuidv4();
        dispatch(addNewChart(newChart));
        dispatch(setSelectedChart(newChart)); // Set the new chart as the selected one 
      }}
      />
    </div>
  );
};