import React, { useEffect, useState } from "react";
import {
  Form,
  FormRenderProps,
  FormElement,
  Field,
} from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { Popup } from "@progress/kendo-react-popup";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/store";
import { AppState } from "../../store/store";
import { CompanyForm } from "./companyForm";
import { companyType } from "../../store/company/types";
import {
  setSelectedCompany,
  removeCompany,
  setCompanyHasChanged,
} from "../../store/company/companySlice";
import { setAuthenticationSuccess } from "../../store/user/userSlice";

interface CompanyListItemProps {
  company: companyType;
  edit: boolean;
  selectedCompany: companyType["id"];
  setNewCompanyAdded: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CompanyListItem: React.FC<CompanyListItemProps> = ({
  company,
  edit,
  selectedCompany,
  setNewCompanyAdded,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [trash, setTrash] = useState(false);
  const [show, setShow] = useState(false);

  const authenticationSuccess = useSelector(
    (state: AppState) => state.user.authenticationSuccess
  );

  useEffect(() => {
    if (!company?.sentToServer) {
      edit = true;
    }
  }, [selectedCompany]);

  const handleDeleteClick = () => {
    setShow(true);
  };

  const renderDeleteCompanyForm = (formRenderProps: FormRenderProps) => (
    <FormElement>
      <div className="deletePlanPwField">
        <Field
          name={"radera"}
          placeholder={t("common.delete")}
          component={Input}
          label={t("measurementListItem.confirmDelete")}
        />
        <div style={{ color: "red" }}>
          {authenticationSuccess ? "" : t("common.deleteValidator")}
        </div>
      </div>
      <div className="DeletePlanButtonContainer">
        <Button
          className="PopupButton BUTTON"
          onClick={(e) => {
            e.preventDefault();
            setShow(false);
          }}
        >
          {t("common.cancel")}
        </Button>
        <Button
          type={"submit"}
          className="PopupButton BUTTON"
          disabled={formRenderProps.valueGetter("radera") !== "radera"}
          style={{
            opacity: formRenderProps.valueGetter("radera") !== "radera" ? 0.5 : 1,
          }}
        >
          {t("common.delete")}
        </Button>
      </div>
    </FormElement>
  );

  const renderCompanyContent = () => {
    return edit && !trash ? (
      <CompanyForm setNewCompanyAdded={setNewCompanyAdded} />
    ) : (
      <div className="userCardUser">
        <div className="userCardColumnL">
          <h5 className="userCardRow">
            {company.name !== "" ? company.name : "-"}
          </h5>
          <div className="userCardRow">
            {company.description !== "" ? company.description : "-"}
          </div>
        </div>

        <div className="userCardColumnR">
          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <span
              className="k-icon k-font-icon k-i-change-manually removeChart"
              style={{
                fontSize: "24px",
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                dispatch(setCompanyHasChanged(false));
                dispatch(setSelectedCompany(company.id));
                setTrash(false);
              }}
            />
            <div style={{ marginRight: "10px" }} />
            <span
              onClick={handleDeleteClick}
              className="k-icon k-font-icon k-i-delete k-i-trash companyDeleteIcon"
              style={{ borderRadius: "5px", paddingLeft: "2px", cursor: "pointer", height: "24px", width: "24px", fontSize: "24px", backgroundColor: "var(--primary-color)", boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, .26);",  }}
            ></span>
            <Popup
              show={show}
              onClose={() => setShow(false)}
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(120, 120, 120, 0.5)",
              }}
            >
              <div className="PopupContentShare">
                <p style={{ fontWeight: "bold" }}>
                  {t("manageCompanies.deleteCompany")}
                </p>
                <Form
                  initialValues={{ deletePlan: "" }}
                  onSubmit={(dataItem) => {
                    if (company && dataItem.radera === "radera") {
                      dispatch(setAuthenticationSuccess(true));
                      if (selectedCompany === company?.id) {
                        dispatch(removeCompany(t, company.id));
                        setShow(false);
                      }
                    } else {
                      dispatch(setAuthenticationSuccess(false));
                    }
                  }}
                  render={(formRenderProps) => renderDeleteCompanyForm(formRenderProps)}
                />
              </div>
            </Popup>
            <div style={{ marginRight: "10px" }} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        border: selectedCompany === company?.id ? "solid var(--primary-color,#782441)" : "",
      }}
    >
      {renderCompanyContent()}
    </div>
  );
};