// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DescriptiveIcon{
    display: flex;
    flex-direction: column;
    align-items: center;
}


.ItemData{
    text-align: center;
}

.ItemText{
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/scheduler/planList/DescriptiveIcon.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;;AAGA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".DescriptiveIcon{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n\n.ItemData{\n    text-align: center;\n}\n\n.ItemText{\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
