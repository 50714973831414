import React, { useEffect, useState } from "react";
import {
  Form,
  FormRenderProps,
  FormElement,
  Field,
} from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
// Redux Stuff
import { setAuthenticationSuccess } from "../../store/user/userSlice";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/store";
import { AppState } from "../../store/store";
import { Popup } from "@progress/kendo-react-popup";
import { roleType } from "../../store/user/types";

import { machineType } from "../../store/machine/types";
import { MachineForm } from "./machineForm";
import {
  deleteMachine,
  setSelectedMachine,
  removeMachine,
  setMachineHasChanged,
  abortAddMachine,
} from "../../store/machine/machineSlice";
import { useTranslation } from "react-i18next";

interface machineListItemProps {
  machine: machineType;
  edit: boolean;
  selectedMachine: machineType["id"];
  setAddMachine: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MachineListItem: React.FC<machineListItemProps> = ({
  machine,
  edit,
  selectedMachine,
  setAddMachine,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [trash, setTrash] = useState(false);
  const [show, setShow] = useState(false);

  const authenticationSuccess = useSelector(
    (state: AppState) => state.user.authenticationSuccess
  );

  useEffect(() => {
    if (!machine?.sentToServer) {
      edit = true;
    }
  }, [selectedMachine]);

  const renderDeleteMachineForm = (formRenderProps: FormRenderProps) => {
    return (
      <FormElement>
        <div className="deletePlanPwField">
          <Field
            name={"radera"}
            placeholder={t("common.delete")}
            component={Input}
            label={t("measurementListItem.confirmDelete")}
          />
          <div style={{ color: "red" }}>
            {authenticationSuccess ? "" : t("common.deleteValidator")}
          </div>
        </div>
        <div className="DeletePlanButtonContainer">
          <button
            className="PopupButton BUTTON"
            onClick={(e) => {
              e.preventDefault();
              setShow(!show);
            }}
          >
            {t("common.cancel")}
          </button>
          <button
            type={"submit"}
            className="PopupButton BUTTON"
            disabled={
              formRenderProps.valueGetter("radera") !== t("common.delete")
            }
            style={{
              opacity:
                formRenderProps.valueGetter("radera") !== t("common.delete")
                  ? 0.5
                  : 1,
            }}
          >
            {t("common.delete")}
          </button>
        </div>
      </FormElement>
    );
  };

  const currentUser = useSelector((state: AppState) => state.user.currentUser);

  const companies = useSelector(
    (state: AppState) => state.company.allCompanies
  );

  const currentMachineCompany = companies.find(
    (company) => company.id === machine.companyID
  );

  // NOT DISMOUNTED PROPERLY
  const renderMachineContent = () => {
    if (edit) {
      console.log(machine.id)
    }
    return edit && !trash ? (
      <MachineForm setAddMachine={setAddMachine} />
    ) : (
      <div className="userCardUser">
        <div className="userCardColumnL">
          <h5 className="userCardRow">
            {machine.nameTag !== "" ? machine.nameTag : "-"}
          </h5>
          <h6 className="userCardRow">
            {machine.name !== "" ? machine.name : "-"}
          </h6>
          <div className="userCardRow">
            {machine.description !== "" ? machine.description : "-"}
          </div>
          <div className="userCardRow">
            {machine.kmCounter ? machine.kmCounter.toFixed(2) + " km" : "- km"}
          </div>
          {currentUser?.roles.some((role) => role.name === "ADMIN") ? (
            <div className="userCardRow">
              {currentMachineCompany?.name !== ""
                ? currentMachineCompany?.name
                : "-"}
            </div>
          ) : null}
        </div>

        <div className="userCardColumnR">
          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <span
              className="k-icon k-font-icon k-i-change-manually removeChart"
              style={{
                fontSize: "24px",
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                dispatch(setMachineHasChanged(false));
                dispatch(setSelectedMachine(machine.id));
                setTrash(false);
              }}
            />
            <div
              style={{
                marginRight: "10px",
              }}
            />
            {currentUser?.roles.some((role) => role.name === roleType.admin) ? (
              !machine.sentToServer && machine ? (
                <span
                  className="k-icon k-font-icon k-i-delete removeChart"
                  style={{
                    fontSize: "24px",
                  }}
                  onClick={() => {
                    dispatch(setSelectedMachine(machine.id));
                    setTrash(true);
                    setTimeout(() => {
                      dispatch(deleteMachine(machine.id));
                      dispatch(setSelectedMachine(""));
                    }, 200);
                    setAddMachine(true);
                  }}
                />
              ) : (
                <span
                  className="k-icon k-font-icon k-i-delete removeChart"
                  style={{
                    fontSize: "24px",
                  }}
                  onClick={() => {
                    dispatch(setSelectedMachine(machine.id));
                    setTrash(true);
                    setShow(!show);
                  }}
                />
              )
            ) : null}

            <Popup
              show={show}
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(120, 120, 120, 0.5)",
              }}
            >
              <div className="PopupContentShare">
                <p style={{ fontWeight: "bold" }}>
                  {t("manageMachines.deleteMachine")}
                </p>
                <Form
                  initialValues={{ deletePlan: "" }}
                  onSubmit={(dataItem) => {
                    if (machine && dataItem.radera === t("common.delete")) {
                      dispatch(setAuthenticationSuccess(true));
                      if (selectedMachine === machine?.id) {
                        dispatch(removeMachine(machine.id));
                        setShow(!show);
                      }
                    } else {
                      dispatch(setAuthenticationSuccess(false));
                    }
                  }}
                  render={(formRenderProps) =>
                    renderDeleteMachineForm(formRenderProps)
                  }
                />
              </div>
            </Popup>
            <div
              style={{
                marginRight: "10px",
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        border:
          selectedMachine === machine?.id
            ? "solid var(--primary-color,#782441)"
            : "",
      }}
    >
      {renderMachineContent()}
    </div>
  );
};
