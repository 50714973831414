import proj4 from "proj4";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { APIProvider, Map, Marker, InfoWindow, useMap } from "@vis.gl/react-google-maps";
import { Polyline } from "./visglComponents/PolyLine";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppState } from "../../store/store";
import {
  fromTickToMeters,
} from "../../helpers/genericHelpers";
import { setObjectsToDisplay } from "../../store/plot/plotSlice";
import {
  gpsPointType,
  objectPointType,
  objectTypes,
  translateToSelectedLanguage,
} from "../../store/plot/types";
import mapStyle from "./../../theme/mapStyle";
import { objectColors } from "./gpsChartContainer";
import "./groupChart.css";


export interface GpsChartProps {
  gpsSignal: gpsPointType[] | undefined;
  measurementObjects: objectPointType[];
  finishedLoadingObjects: boolean;
  selectedMeasurement: string;
}

interface MarkerType {
  lng: number;
  lat: number;
  type: objectTypes;
  color: string;
  tick: string;
  value?: string;
  description?: string;
}

interface ClusterInfo {
  lng: number;
  lat: number;
  [objectTypes.switch]: number;
  [objectTypes.culvert]: number;
  [objectTypes.trackBarrier]: number;
  [objectTypes.levelCrossing]: number;
  [objectTypes.notes]: number;
  [objectTypes.marker]: number;
  [objectTypes.trackCross]: number;
  [objectTypes.contactPole]: number;
  info?: string;
}

export const convertToWGS84 = (x: number, y: number) => {
  proj4.defs([
    [
      "WGS84",
      "+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees",
    ],
    [
      "SWEREF99TM",
      "+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs",
    ],
  ]);

  const source = "SWEREF99TM";
  const target = "WGS84";

  const result = proj4(source, target, [x, y]);

  return [result[0], result[1]];
};

const restructureLongObjects = 
  (
    allMarkers: MarkerType[],
    switchTraceChunks: gpsPointType[][]
  ) => {
    let switchMarkers = [] as MarkerType[];

    if (switchTraceChunks !== undefined && switchTraceChunks.length > 0)
      switchMarkers = switchTraceChunks.map((traceChunk) => {
        if (traceChunk.length > 0) {
          const [lng, lat] = convertToWGS84(
            traceChunk[0].gpsY,
            traceChunk[0].gpsX
          );

          return {
            lat: lat,
            lng: lng,
            type: objectTypes.switch,
            tick: `${traceChunk[0].tick} - ${traceChunk[traceChunk.length - 1].tick
              }`,
            color: objectColors(objectTypes.switch),
          } as MarkerType;
        } else {
          return {
            lat: 0,
            lng: 0,
            type: "undefined" as any,
            tick: `0+0,0m`,
            color: objectColors(objectTypes.switch),
          } as MarkerType;
        }
      });

    return allMarkers
      .filter((marker) => marker.type !== objectTypes.switch)
      .concat(switchMarkers);
  };

const getStartAndEndMarkers = (gpsSignal: gpsPointType[]) => {
  if (!gpsSignal || gpsSignal.length === 0) return undefined;
  const [lngStart, latStart] = convertToWGS84(gpsSignal[0].gpsY, gpsSignal[0].gpsX);
  const [lngEnd, latEnd] = convertToWGS84(gpsSignal[gpsSignal.length - 1].gpsY, gpsSignal[gpsSignal.length - 1].gpsX);
  return [{ tick: gpsSignal[0].tick, lng: lngStart, lat: latStart }, { tick: gpsSignal[gpsSignal.length - 1].tick, lng: lngEnd, lat: latEnd }];
};

const options = {
  strokeColor: "#782441",
  strokeOpacity: 0.8,
  strokeWeight: 4,
  fillColor: "#782441",
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 30000,
  zIndex: 1,
};

const optionsSwitchPath = {
  strokeColor: "#141adb",
  strokeWeight: 4,
  fillColor: "#141adb",
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 30000,
  zIndex: 2,
};

const createGpsPath = (gpsSignal: gpsPointType[]) => {
  const gpsVec = gpsSignal.map((point) => {
    const [lng, lat] = convertToWGS84(point.gpsY, point.gpsX);
    return { lat, lng };
  });
  return gpsVec;
};

let defaultCenter = { lat: 57.70887, lng: 11.97456 };

const GpsChartControl: React.FunctionComponent<any> = ({ mapControl }) => {
  const map = useMap();

  useEffect(() => {
    if (mapControl && map) {
      const { center, zoom } = mapControl;
      map.setCenter(center);
      map.setZoom(zoom);
    }
  }, [mapControl, map]);

  return null;
};

export const GpsChart: React.FunctionComponent<GpsChartProps> = ({
  gpsSignal,
  measurementObjects,
  finishedLoadingObjects,
  selectedMeasurement,
}) => {
  const { t } = useTranslation();
  const [markerCluster, setMarkerCluster] = useState<ClusterInfo>();
  const [selectedMarker, setSelectedMarker] = useState<number | string | null>(null);
  const [mapControl, setMapControl] = useState<{ center: { lat: number, lng: number }, zoom: number } | null>(null);
  
  const getGPSTraceOfSwitches = useCallback((markerPoints: objectPointType[]) => {
    const chunks: any = [];
    const testSwitches = markerPoints.filter((point) => {
      return point.type.some((type) => type === objectTypes.switch);
    });
  
    let iterSaved = 0;
    testSwitches.map((aSwitch, iter) => {
      const currentTick = fromTickToMeters(testSwitches[iter].tick);
  
      let previousTick;
      if (iter === 0) {
        previousTick = currentTick;
      } else {
        previousTick = fromTickToMeters(testSwitches[iter - 1].tick);
      }
  
      if (currentTick - previousTick > 0.5) {
        chunks.push({
          x1: testSwitches[iterSaved].x,
          x2: testSwitches[iter - 1].x,
        });
        iterSaved = iter;
      }
  
      if (iter === testSwitches.length - 1) {
        chunks.push({
          x1: testSwitches[iterSaved].x,
          x2: testSwitches[iter].x,
        });
        iterSaved = iter;
      }
    });
  
    const switchTraceChunks: gpsPointType[][] = [];
    for (let i = 0; i < chunks.length; i++) {
      const theTrace = markerPoints
        ?.filter(
          (signalValue) =>
            signalValue.x >= chunks[i].x1 && signalValue.x <= chunks[i].x2
        )
        .map((pt) => {
          const gpsPt = {
            x: pt.x,
            gpsX: pt.gpsX,
            gpsY: pt.gpsY,
            t: 1,
            class: "H0 - dummy",
            tick: pt.tick,
            errors: "",
            reference: 0,
            objects: ["switch"],
            notes: pt.note,
          } as gpsPointType;
          return gpsPt;
        });
  
      if (theTrace) switchTraceChunks.push(theTrace);
    }
  
    return switchTraceChunks;
  }, [
    fromTickToMeters,
    objectTypes
  ]);

  const switchTraceChunks = useMemo(() => getGPSTraceOfSwitches(measurementObjects), [measurementObjects]);

  let allMarkers: MarkerType[] = useMemo(() => restructureLongObjects([], switchTraceChunks), [switchTraceChunks]);

  const path: { lat: number; lng: number }[] = useMemo(() => {
    if (gpsSignal && gpsSignal.length > 0 && selectedMeasurement !== "-1") {
      return createGpsPath(gpsSignal);
    }
    return [];
  }, [gpsSignal, selectedMeasurement, createGpsPath]);


  useEffect(() => {
    if (gpsSignal && selectedMeasurement !== "-1") {
      const bounds = new google.maps.LatLngBounds();
      gpsSignal.forEach((point) => {
        const [lng, lat] = convertToWGS84(point.gpsY, point.gpsX);
        bounds.extend(new google.maps.LatLng(lat, lng));
      });

      // Calculate center and zoom level
      const center = bounds.getCenter().toJSON();
      const zoom = calculateZoomLevelToFitBounds(bounds);

      // Set the mapControl state
      setMapControl({ center, zoom });
    }
  }, [gpsSignal, selectedMeasurement]);

  if (gpsSignal && gpsSignal?.length > 0 && selectedMeasurement !== "-1") {
    allMarkers = measurementObjects
      .map((point) => {
        const [lng, lat] = convertToWGS84(point.gpsY, point.gpsX);
        return point.type
          .map((theType: objectTypes) => ({
            lat,
            lng,
            type: theType,
            color: objectColors(theType),
            tick: point.tick,
          }))
          .flat();
      })
      .flat();

    allMarkers = restructureLongObjects(allMarkers, switchTraceChunks);
    // Add notes to all markers
    const allNotes = useSelector((state: AppState) => state.plot.allNotes);

    allMarkers = allMarkers
      .concat(
        allNotes.map((point) => {
          const [lng, lat] = convertToWGS84(point.gpsY, point.gpsX);

          return {
            lat,
            lng,
            type: point.type,
            color: objectColors(point.type),
            tick: point.tick,
            value: point.value,
          } as MarkerType;
        })
      )
      .flat();
  }

  const objectsToDisplay = useSelector(
    (state: AppState) => state.plot.objectsToDisplay || []
  );

  const markers = useMemo(() => {
    if (!allMarkers || !objectsToDisplay || objectsToDisplay.length === 0) {
      return [];
    }
  
    const convertedMarkers = allMarkers.filter((marker) => objectsToDisplay.includes(marker.type));
    
    for (let i = 0; i < convertedMarkers.length; i++) {
      convertedMarkers[i].color = objectColors(convertedMarkers[i].type);
    }
  
    return convertedMarkers;
  }, [allMarkers, objectsToDisplay]);

  let startPoint: any = {};
  let endPoint: any = {};
  
  if (gpsSignal && gpsSignal?.length > 0 && selectedMeasurement !== "-1") {
    [startPoint, endPoint] = getStartAndEndMarkers(gpsSignal);
    defaultCenter = { lat: startPoint.lat, lng: startPoint.lng };
  }

  const startMarker = startPoint && {
    lat: startPoint.lat,
    lng: startPoint.lng,
    color: objectColors("Start"),
    type: "Start",
    tick: startPoint.tick,
  } as MarkerType;
  
  const endMarker = endPoint && {
    lat: endPoint.lat,
    lng: endPoint.lng,
    type: "End",
    color: objectColors("End"),
    tick: endPoint.tick,
  } as MarkerType;


  useEffect(() => {
    if (!gpsSignal || selectedMeasurement === "-1") return;

  }, [gpsSignal, selectedMeasurement]);

  const calculateZoomLevelToFitBounds = useCallback((bounds: google.maps.LatLngBounds) => {
    const WORLD_DIM = { height: 256, width: 256 };
    const ZOOM_MAX = 21;

    const latRad = (lat: number) => {
      const sin = Math.sin((lat * Math.PI) / 180);
      const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
      return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    };

    const zoom = (mapPx: number, worldPx: number, fraction: number) => {
      return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
    };

    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();

    const latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;
    const lngDiff = ne.lng() - sw.lng();
    const lngFraction = ((lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360);

    const latZoom = zoom(WORLD_DIM.height, 256, latFraction);
    const lngZoom = zoom(WORLD_DIM.width, 256, lngFraction);

    return Math.min(latZoom, lngZoom, ZOOM_MAX);
  }, []);

  return (
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY!}>
      <Map
        style={{ width: "100%", height: "420px" }}
        defaultZoom={10}
        defaultCenter={defaultCenter}
        maxZoom={21}
        minZoom={3}
        styles={mapStyle}
        streetViewControl={false}
        fullscreenControl={false}
      >
        {selectedMeasurement !== "-1" && startMarker && (
          <>
            <Marker
              position={{ lat: startMarker.lat, lng: startMarker.lng }}
              icon={{
                url: "./icons/location-dot-solid-" + startMarker.color + ".svg",
              }}
              onClick={() => setSelectedMarker("start")}
            />
  
            {selectedMarker === "start" && (
              <InfoWindow
                position={{ lat: startMarker.lat, lng: startMarker.lng }}
                onCloseClick={() => setSelectedMarker(null)}
              >
                <div>
                  <h6>
                    {startMarker.type === objectTypes.notes
                      ? startMarker.value
                      : startMarker.type === objectTypes.marker
                        ? t("gps.marker")
                        : translateToSelectedLanguage(t, startMarker.type)}
                  </h6>
                  <div>{startMarker.tick}</div>
                  <div>lat: {startMarker.lat.toFixed(6)}</div>
                  <div>lng: {startMarker.lng.toFixed(6)}</div>
                </div>
              </InfoWindow>
            )}
  
            {markers
              .filter((mark) => mark.type !== "End" && mark.type !== "Start")
              .map((marker, i) => {
                const icon = {
                  path: "M10 10 m-2, 0 a2,2 0 1,0 4,0 a2,2 0 1,0 -4,0",
                  fillColor: marker.color,
                  fillOpacity: 1,
                  anchor: new google.maps.Point(10, 10),
                  strokeWeight: 0,
                  scale: 1
                };
  
                return (
                  <>
                    <Marker
                      key={i}
                      icon={icon}
                      position={{ lat: marker.lat, lng: marker.lng }}
                      onClick={() => setSelectedMarker(i)}
                    />
                    {selectedMarker === i && (
                      <InfoWindow
                        position={{ lat: marker.lat, lng: marker.lng }}
                        onCloseClick={() => setSelectedMarker(null)}
                      >
                        <div>
                          <h6>
                            {marker.type === objectTypes.notes
                              ? marker.value
                              : marker.type === objectTypes.marker
                              ? t("gps.marker")
                              : translateToSelectedLanguage(t, marker.type)}
                          </h6>
                          <div> {marker.tick}</div>
                          <div>
                            lat: {Math.round(marker.lat * Math.pow(10, 6)) / Math.pow(10, 6)}
                          </div>
                          <div>
                            lng: {Math.round(marker.lng * Math.pow(10, 6)) / Math.pow(10, 6)}
                          </div>
                        </div>
                      </InfoWindow>
                    )}
                  </>
                );
              })}
  
            <Marker
              position={{ lat: endMarker.lat, lng: endMarker.lng }}
              icon={{
                url: "./icons/location-dot-solid-" + endMarker.color + ".svg",
              }}
              onClick={() => setSelectedMarker("end")}
            />
  
            {selectedMarker === "end" && (
              <InfoWindow
                position={{ lat: endMarker.lat, lng: endMarker.lng }}
                onCloseClick={() => setSelectedMarker(null)}
              >
                <div>
                  <h6>
                    {endMarker.type === objectTypes.notes
                      ? endMarker.value
                      : endMarker.type === objectTypes.marker
                        ? t("gps.marker")
                        : translateToSelectedLanguage(t, endMarker.type)}
                  </h6>
                  <div>{endMarker.tick}</div>
                  <div>lat: {endMarker.lat.toFixed(6)}</div>
                  <div>lng: {endMarker.lng.toFixed(6)}</div>
                </div>
              </InfoWindow>
            )}
  
            <Polyline path={path} {...options} />
            {!markerCluster &&
              switchTraceChunks.map((chunk, index) => {
                if (chunk) {
                  const switchPath = chunk.map((point) => {
                    const [lng, lat] = convertToWGS84(point.gpsY, point.gpsX);
                    return { lng, lat };
                  });
                  return (
                    <Polyline
                      key={index}
                      path={switchPath}
                      {...optionsSwitchPath}
                    />
                  );
                }
                return null;
              })}
          </>
        )}
      </Map>
      <GpsChartControl mapControl={mapControl} />
    </APIProvider>
  );
};
