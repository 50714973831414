import React, { useEffect, useState } from "react";
import "./formComponents.css";
import { useTranslation } from "react-i18next";

// Kendo imports
import { DatePicker } from "@progress/kendo-react-dateinputs";
import {
  DropDownList,
  ListItemProps,
  MultiSelect,
  MultiSelectFilterChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { FieldRenderProps } from "@progress/kendo-react-form";
import { Input, Switch } from "@progress/kendo-react-inputs";
import { Error, FloatingLabel } from "@progress/kendo-react-labels";
import { filterBy } from "@progress/kendo-data-query";

/* Redux Stuff*/
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../store/store";
import { emptyMeasurementsSelection } from "../../../development/initializer";
import { AppState } from "../../../store/store";
import {
  setPlanHasChanged,
  updatePlanProperty,
} from "../../../store/scheduler/schedulerSlice";

// Types
import { machineType } from "../../../store/machine/types";
import {
  keyNamePairs,
  keyNames,
  measurementsSelectionProps,
  planProps,
  planStatus,
  trackDirection,
} from "../../../store/scheduler/types";
import { roleType, userType } from "../../../store/user/types";
import { planStatustoTranslation } from "../../../store/scheduler/helpers";


export const NameInput = (fieldRenderProps: FieldRenderProps) => {
  return CustomPlanInput(fieldRenderProps, "name");
};

export const PlaceInput = (fieldRenderProps: FieldRenderProps) => {
  return CustomPlanInput(fieldRenderProps, "place");
};

export const CustomerInput = (fieldRenderProps: FieldRenderProps) => {
  return CustomPlanInput(fieldRenderProps, "customer");
};

export const PathFromInput = (fieldRenderProps: FieldRenderProps) => {
  return CustomPlanInput(fieldRenderProps, "pathFrom");
};

export const PathToInput = (fieldRenderProps: FieldRenderProps) => {
  return CustomPlanInput(fieldRenderProps, "pathTo");
};

// export const TrackPartInput = (fieldRenderProps: FieldRenderProps) => {
//   return CustomPlanInput(fieldRenderProps, "trackPart");
// };

export const TrackPartInput = (fieldRenderProps: FieldRenderProps) => {
  const valueToStateConverter = (value: string) => {
    return +value;
  };
  const stateToValueConverter = (state: number) => {
    return state || state === 0 ? state.toString() : "";
  };

  return CustomPlanInput(
    fieldRenderProps,
    "trackPart",
    stateToValueConverter,
    valueToStateConverter
  );
};

// export const TrackNumberInput = (fieldRenderProps: FieldRenderProps) => {
//   return CustomPlanInput(fieldRenderProps, "trackNumber");
// };

export const DirectionInput = (fieldRenderProps: FieldRenderProps) => {
  const stateToValueConverter = (state: trackDirection) => {
    switch (state) {
      case "negative":
        return "MOT";
      default:
        return "MED";
    }
  };

  const valueToStateConverter = (value: string): trackDirection => {
    switch (value) {
      case "MOT":
        return "negative";
      default:
        return "positive";
    }
  };
  return CustomPlanSelect(
    fieldRenderProps,
    "direction",
    ["MED", "MOT"],
    stateToValueConverter,
    valueToStateConverter
  );
};

export const ValidationToggle = (fieldRenderProps: FieldRenderProps) => {
  const found = useSelector((state: AppState) =>
    state.scheduler.plans.find(
      (plan) => plan.id === state.scheduler.selectedPlan
    )
  );
  const reduxValue = found ? found.isRepeatabilityValidation : false;

  const dispatch = useAppDispatch();
  const {
    label,
    value,
    onChange,
    visited,
    validationMessage,
    modified,
    ...other
  } = fieldRenderProps;

  const [hasLeft, setHasLeft] = useState(false);

  useEffect(() => {
    const e: any = {
      target: {
        value: reduxValue,
      },
    };
    onChange(e);
  }, [reduxValue, onChange]);

  const isValid = () =>
    visited && modified && hasLeft && validationMessage ? false : true;

  return (
    <div className="InputContent" style={{ borderRadius: "5px", border: "1px solid #ccc", padding: "10px" }}>
      <p>Repeatability Validation</p>
      <Switch
        {...other}
        checked={value}
        onChange={(e) => {
          onChange(e);
          dispatch(setPlanHasChanged(true));
        }}
        onBlur={(e) => {
          if (validationMessage === "") {
            dispatch(updatePlanProperty({ isRepeatabilityValidation: value }));
          }
          setHasLeft(true);
        }}
        valid={isValid()}
      />
      {!isValid() && <Error>{validationMessage}</Error>}
    </div>
  );
};

export const PlanStartAtInput = (fieldRenderProps: FieldRenderProps) => {
  return CustomPlanInput(fieldRenderProps, "startAt");
};

export const PlanStartAtKmInput = (fieldRenderProps: FieldRenderProps) => {
  const valueToStateConverter = (value: string) => {
    return +value;
  };
  const stateToValueConverter = (state: number) => {
    return state !== undefined ? state.toString() : "";
  };

  return CustomPlanInput(
    fieldRenderProps,
    "startAtKm",
    stateToValueConverter,
    valueToStateConverter
  );
};

export const PlanStartAtMeterInput = (fieldRenderProps: FieldRenderProps) => {
  const valueToStateConverter = (value: string) => {
    return +value;
  };
  const stateToValueConverter = (state: number) => {
    return state !== undefined ? state.toString() : "";
  };

  return CustomPlanInput(
    fieldRenderProps,
    "startAtMeter",
    stateToValueConverter,
    valueToStateConverter
  );
};

export const PlanEndAtInput = (fieldRenderProps: FieldRenderProps) => {
  return CustomPlanInput(fieldRenderProps, "endAt");
};

export const PlanEndAtKmInput = (fieldRenderProps: FieldRenderProps) => {
  const valueToStateConverter = (value: string) => {
    return +value;
  };
  const stateToValueConverter = (state: number) => {
    return state !== undefined ? state.toString() : "";
  };

  return CustomPlanInput(
    fieldRenderProps,
    "endAtKm",
    stateToValueConverter,
    valueToStateConverter
  );
};

export const PlanEndAtMeterInput = (fieldRenderProps: FieldRenderProps) => {
  const valueToStateConverter = (value: string) => {
    return +value;
  };
  const stateToValueConverter = (state: number) => {
    return state !== undefined ? state.toString() : "";
  };

  return CustomPlanInput(
    fieldRenderProps,
    "endAtMeter",
    stateToValueConverter,
    valueToStateConverter
  );
};

export const TrackClassificationInput = (
  fieldRenderProps: FieldRenderProps
) => {
  const speedTranslator = (railClass: string) => {
    if (railClass === "H0") {
      return "V\u226440";
    } else if (railClass === "H1") {
      return "40\u003CV\u226480";
    } else if (railClass === "H2") {
      return "80\u003CV\u2264120";
    } else if (railClass === "H3") {
      return "120\u003CV\u2264160";
    } else if (railClass === "H4") {
      return "160\u003CV\u2264200";
    } else if (railClass === "H5") {
      return "200\u003CV\u2264250";
    } else {
      return "";
    }
  };

  const valueToStateConverter = (value: string) => {
    if (value === "BIS") return "";
    return value.substring(0, 2);
  };

  const stateToValueConverter = (state: string) => {
    if (state == "") return "BIS";
    return state + " " + speedTranslator(state); //.substring(0, 2);
  };

  return CustomPlanSelect(
    fieldRenderProps,
    "classification",
    [
      "BIS",
      "H0 V\u226440",
      "H1 40\u003CV\u226480",
      "H2 80\u003CV\u2264120",
      "H3 120\u003CV\u2264160",
      "H4 160\u003CV\u2264200",
      "H5 200\u003CV\u2264250",
    ],
    stateToValueConverter,
    valueToStateConverter
  );
};

export const NumberMeasurementsInput = (fieldRenderProps: FieldRenderProps) => {
  const valueToStateConverter = (value: string) => {
    return value === "" ? 0 : +value;
  };

  const stateToValueConverter = (state: number) => {
    return state !== undefined ? state.toString() : "";
  };

  return CustomPlanInput(
    fieldRenderProps,
    "numberMeasurements",
    stateToValueConverter,
    valueToStateConverter
  );
};

const CustomPlanInput = (
  fieldRenderProps: FieldRenderProps,
  property: keyof planProps,
  stateToValueConverter?: (state: any) => string,
  valueToStateConverter?: (value: string) => any
) => {
  const found = useSelector((state: AppState) =>
    state.scheduler.plans.find(
      (plan) => plan.id === state.scheduler.selectedPlan
    )
  );
  const reduxValue = found ? found[property] : undefined;

  const dispatch = useAppDispatch();
  return CustomInput(
    fieldRenderProps,
    reduxValue,
    (value: string) =>
      dispatch(updatePlanProperty({ [property]: value as any } as any)),
    stateToValueConverter,
    valueToStateConverter
  );
};

export const MeasurementsSelection = (fieldRenderProps: FieldRenderProps) => {
  const { t } = useTranslation();
  const stateToValueConverter = (state: boolean[]) => {
    const entries: measurementsSelectionProps = {
      alignment: state[0],
      crossLevel: state[1],
      longitudinal: state[2],
      twist: state[3],
      trackGauge: state[4],
      gps: state[5],
    };

    return Object.entries(entries)
      .filter(([key, value]) => {
        return value;
      })
      .map(([key, value]) =>
        keyNamePairs(key as keyof measurementsSelectionProps, t)
      );
  };

  const valueToStateConverter = (value: string[]) => {
    const measurementsSelection: measurementsSelectionProps = {
      ...emptyMeasurementsSelection,
    };
    value.forEach((name) => {
      const found = Object.entries(keyNames).find(
        ([key, value]) => value === name
      );

      if (found) {
        const key = found[0];
        measurementsSelection[key as keyof measurementsSelectionProps] = true;
      }
    });

    const measurementsSelectionArray = [
      measurementsSelection.alignment,
      measurementsSelection.crossLevel,
      measurementsSelection.longitudinal,
      measurementsSelection.twist,
      measurementsSelection.trackGauge,
      measurementsSelection.gps,
    ];
    return measurementsSelectionArray;
  };
  const measurementMode = useSelector((state: AppState) => {
    return state.scheduler.plans.find(
      (plan) => plan.id === state.scheduler.selectedPlan
    )?.measurementMode;
  });
  return CustomPlanMultiSelect(
    fieldRenderProps,
    "measurementsSelection",
    Object.values(
      measurementMode === "automatic"
        ? keyNamePairs
        : [
            keyNamePairs("crossLevel", t),
            keyNamePairs("trackGauge", t),
            keyNamePairs("gps", t),
          ]
    ),
    stateToValueConverter,
    valueToStateConverter
  );
};

export const StatusSelection = (fieldRenderProps: FieldRenderProps) => {
  const { t } = useTranslation();

  const stateToValueConverter = (state: planStatus) => {
    switch (state) {
      case planStatus.awaiting:
        return planStatustoTranslation(planStatus.awaiting, t);
      case planStatus.canceled:
        return planStatustoTranslation(planStatus.canceled, t);
      case planStatus.done:
        return planStatustoTranslation(planStatus.done, t);
      case planStatus.inProgress:
        return planStatustoTranslation(planStatus.inProgress, t);
      case planStatus.archived:
        return planStatustoTranslation(planStatus.archived, t);
      default:
        return planStatustoTranslation(planStatus.inProgress, t);
    }
  };

  const valueToStateConverter = (value: string): planStatus => {
    switch (value) {
      case planStatustoTranslation(planStatus.awaiting, t):
        return planStatus.awaiting;
      case planStatustoTranslation(planStatus.canceled, t):
        return planStatus.canceled;
      case planStatustoTranslation(planStatus.done, t):
        return planStatus.done;
      case planStatustoTranslation(planStatus.inProgress, t):
        return planStatus.inProgress;
      case planStatustoTranslation(planStatus.archived, t):
        return planStatus.archived;

      default:
        return planStatus.inProgress;
    }
  };

  return CustomPlanSelect(
    fieldRenderProps,
    "status",
    [
      planStatustoTranslation(planStatus.awaiting, t),
      planStatustoTranslation(planStatus.canceled, t),
      planStatustoTranslation(planStatus.done, t),
      planStatustoTranslation(planStatus.inProgress, t),
      planStatustoTranslation(planStatus.archived, t),
    ],
    stateToValueConverter,
    valueToStateConverter
  );
};
export const AssigneesSelector = (fieldRenderProps: FieldRenderProps) => {
  const currentUser = useSelector((state: AppState) => state.user.currentUser);

  const allContacts = useSelector(
    (state: AppState) => state.user.allContacts // IF WE ARE ADMIN WE WANT TO BE ABLE TO ACCESS ALL COMPANIES OPERATORS/USERS
  );
  const allCompanyUsers = useSelector(
    (state: AppState) => state.user.allCompanyUsers
  );

  let validUsers: userType[];
  if (currentUser?.roles.some((role) => role.name === roleType.admin)) {
    validUsers = allContacts; // IF WE ARE ADMIN WE WANT TO BE ABLE TO ACCESS ALL COMPANIES OPERATORS/USERS
  } else {
    validUsers = allCompanyUsers;
  }

  const stateToValueConverter = (state: string[]) => {
    if (state) {
      const userNames = state.map((userID) => {
        const find = validUsers.find((user) => user.id === userID);
        if (find) return find.email;
      });
      return userNames ? userNames : [];
    } else {
      return [];
    }
  };

  const valueToStateConverter = (value: string[]) => {
    const userIDs = value.map((input) => {
      let email: string;
      if (input.includes("<")) {
        email = input
          .toString()
          .substring(
            input.toString().indexOf("<") + 1,
            input.toString().indexOf(">")
          );
      } else {
        email = input;
      }

      if (email.length > 0) {
        const find = validUsers.find((user) => user.email === email);
        if (find) return find.id;
      }
    });
    return userIDs ? userIDs : [];
    // return value;
  };

  const dispatch = useAppDispatch();

  const reduxValue = useSelector(
    (state: AppState) =>
      state.scheduler.plans.find(
        (plan) => plan.id === state.scheduler.selectedPlan
      )?.userAssignees
  );

  const data: string[] = validUsers
    .filter((user) => !reduxValue?.includes(user.id))
    .map(
      (user) =>
        user.firstName + " " + user.lastName + " | " + "<" + user.email + ">"
    );

  return CustomMultiSelect(
    fieldRenderProps,
    reduxValue,
    data,
    (value: any) => dispatch(updatePlanProperty({ userAssignees: value })),
    stateToValueConverter as any,
    valueToStateConverter
  );
};

export const MachinesSelector = (fieldRenderProps: FieldRenderProps) => {
  const allMachines = useSelector(
    (state: AppState) => state.machine.allMachines
  );
  const currentUser = useSelector((state: AppState) => state.user.currentUser);

  const companyMachines = allMachines.filter(
    (machine) => machine.companyID === currentUser?.companyID
  );

  let validMachines: machineType[];
  if (currentUser?.roles.some((role) => role.name === roleType.admin)) {
    validMachines = allMachines;
  } else {
    validMachines = allMachines.filter(
      (machine) => machine.companyID === currentUser?.companyID
    );
  }

  const stateToValueConverter = (state: string[]) => {
    if (state) {
      const machineNames = state.map((machineID) => {
        const find = validMachines.find((machine) => machine.id === machineID);
        if (find) return find.name;
      });
      return machineNames ? machineNames : [];
    } else {
      return [];
    }
  };

  const valueToStateConverter = (value: string[]) => {
    const machineIDs = value.map((machineName) => {
      if (machineName.length > 0) {
        const find = validMachines.find(
          (machine) => machine.name === machineName
        );
        if (find) return find.id;
      }
    });
    return machineIDs ? machineIDs : [];
  };

  const dispatch = useAppDispatch();

  const reduxValue = useSelector(
    (state: AppState) =>
      state.scheduler.plans.find(
        (plan) => plan.id === state.scheduler.selectedPlan
      )?.machineAssignees
  );
  return CustomMultiSelect(
    fieldRenderProps,
    reduxValue,
    companyMachines.map((machine) => machine.name),
    (value: any) => dispatch(updatePlanProperty({ machineAssignees: value })),
    stateToValueConverter as any,
    valueToStateConverter
  );
};

export const ScheduledAtDatePicker = (fieldRenderProps: FieldRenderProps) => {
  const stateToValueConverter = (state: string) => {
    // const date = new Date(state);
    return new Date(state); //.toISOString();
  };
  const valueToStateConverter = (value: any) => {
    if (value == null) {
      value = new Date();
    }
    const output = value.toISOString();
    return output;
  };
  return CustomPlanDatePicker(
    fieldRenderProps,
    "scheduledAt",
    stateToValueConverter,
    valueToStateConverter
  );
};

export const NotesTextArea = (fieldRenderProps: FieldRenderProps) => {
  return CustomPlanTextArea(fieldRenderProps, "notes");
};

export const FileUpload = (fieldRenderProps: FieldRenderProps) => {
  const { t } = useTranslation("translation", { keyPrefix: "formComponents" });
  const [file, setFile] = useState<File>();
  const [focus, setFocus] = useState(false);
  const [hover, setHover] = useState(false);
  const { onChange } = fieldRenderProps;
  const reduxFile = useSelector((state: AppState) =>
    state.scheduler.plans.find(
      (plan) => plan.id === state.scheduler.selectedPlan
    )
  )?.projectedTrackFile;

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (reduxFile) {
      setFile(reduxFile);
      onChange({ target: { value: undefined } });
    } else {
      setFile(undefined);
      onChange({ target: { value: undefined } });
    }
  }, [reduxFile]);
  return (
    <div
      className="InputContent"
      onFocus={(e) => {
        setFocus(true);
      }}
      onBlur={(e) => {
        setFocus(false);
      }}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      <label
        className="FileSelector"
        style={{
          borderBottom: hover || focus ? "1px solid black" : "1px solid #ccc",
        }}
      >
        <input
          type="file"
          accept=".xlsx, .csv"
          onChange={(e) => {
            const file = e.target.files
              ? e.target.files.length > 0
                ? e.target.files[0]
                : undefined
              : undefined;
            setFile(file);
            dispatch(updatePlanProperty({ projectedTrackFile: file }));
            dispatch(setPlanHasChanged(true));
          }}
          style={{ display: "none" }}
        />
        <div
          className="FileInputValue "
          style={{
            fontSize: "24",
            fontFamily: "Arial",
            opacity: file ? 1 : 0.7,
          }}
        >
          {file ? file.name : t("insertFile")}
        </div>
        <div className="FileInputIcon">
          <img
            src={file ? "./icons/removeFile.svg" : "./icons/uploadFile.svg"}
            alt=""
            style={{ width: "19px", opacity: hover || focus ? 1 : 0.5 }}
            onClick={() => {
              if (file) {
                onChange({ target: { value: undefined } });
                setFile(undefined);
              }
            }}
          />
        </div>
      </label>
    </div>
  );
};

const CustomInput = (
  fieldRenderProps: FieldRenderProps,
  reduxValue: any,
  dispatchFunction: (value: string) => void,
  stateToValueConverter?: (state: any) => string,
  valueToStateConverter?: (value: string) => any
) => {
  const {
    label,
    value,
    onChange,
    visited,
    validationMessage,
    modified,
    ...other
  } = fieldRenderProps;

  const [hasLeft, setHasLeft] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const e: any = {
      target: {
        value: stateToValueConverter
          ? stateToValueConverter(reduxValue)
          : reduxValue,
      },
    };
    onChange(e);
  }, [reduxValue, onChange]);

  const isValid = () =>
    visited && modified && hasLeft && validationMessage ? false : true;

  return (
    <div className="InputContent">
      <Input
        {...other}
        label={label}
        value={value}
        maxLength={30}
        onChange={(e) => {
          onChange(e);

          dispatch(setPlanHasChanged(true));
        }}
        onBlur={(e) => {
          if (validationMessage === "") {
            dispatchFunction(
              valueToStateConverter
                ? valueToStateConverter(value)
                : (value as any)
            );
          }
          setHasLeft(true);
        }}
        valid={isValid()}
      />
      {!isValid() && <Error>{validationMessage}</Error>}
    </div>
  );
};

const CustomPlanSelect = (
  fieldRenderProps: FieldRenderProps,
  property: keyof planProps,
  data: string[],
  stateToValueConverter?: (state: any) => string,
  valueToStateConverter?: (value: string) => any
) => {
  const found = useSelector((state: AppState) =>
    state.scheduler.plans.find(
      (plan) => plan.id === state.scheduler.selectedPlan
    )
  );
  const reduxValue = found ? found[property] : undefined;

  const dispatch = useAppDispatch();
  return CustomSelect(
    fieldRenderProps,
    reduxValue,
    data,
    (value: any) =>
      dispatch(updatePlanProperty({ [property]: value as any } as any)),
    stateToValueConverter,
    valueToStateConverter
  );
};

const CustomSelect = (
  fieldRenderProps: FieldRenderProps,
  reduxValue: any,
  data: string[],
  dispatchFunction: (value: string) => void,
  stateToValueConverter?: (state: any) => string,
  valueToStateConverter?: (value: string) => any
) => {
  const {
    label,
    value,
    onChange,
    onBlur,
    visited,
    validationMessage,
    modified,
    ...other
  } = fieldRenderProps;

  const [hasLeft, setHasLeft] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const e: any = {
      target: {
        value: stateToValueConverter
          ? stateToValueConverter(reduxValue)
          : reduxValue,
      },
    };
    onChange(e);
  }, [reduxValue]);

  const isValid = () =>
    visited && modified && hasLeft && validationMessage ? false : true;

  return (
    <div className="InputContent">
      <DropDownList
        // ref={referenceList}
        {...other}
        label={label}
        data={data}
        value={value}
        onChange={(e) => {
          onChange(e);
          dispatch(setPlanHasChanged(true));
          // referenceList.current.blur();
        }}
        onClose={(e) => {
          // Changed from onblur
          if (validationMessage === "") {
            dispatchFunction(
              valueToStateConverter
                ? valueToStateConverter(e.target.value)
                : (e.target.value as any)
            );
            onBlur();
          }
          setHasLeft(true);
        }}
      />
      {!isValid() && <Error>{validationMessage}</Error>}
    </div>
  );
};

const CustomPlanDatePicker = (
  fieldRenderProps: FieldRenderProps,
  property: keyof planProps,
  stateToValueConverter?: (state: any) => Date,
  valueToStateConverter?: (value: Date) => any
) => {
  const found = useSelector((state: AppState) =>
    state.scheduler.plans.find(
      (plan) => plan.id === state.scheduler.selectedPlan
    )
  );
  const reduxValue = found ? found[property] : undefined;
  const dispatch = useAppDispatch();
  return CustomDatePicker(
    fieldRenderProps,
    reduxValue,
    (value: any) =>
      dispatch(updatePlanProperty({ [property]: value as any } as any)),
    stateToValueConverter,
    valueToStateConverter
  );
};

const CustomDatePicker = (
  fieldRenderProps: FieldRenderProps,
  reduxValue: any,
  dispatchFunction: (value: string) => void,
  stateToValueConverter?: (state: any) => Date,
  valueToStateConverter?: (value: Date) => any
) => {
  const {
    label,
    value,
    onChange,
    visited,
    validationMessage,
    modified,
    ...other
  } = fieldRenderProps;
  const [hasLeft, setHasLeft] = useState(false);
  const [closeDatePicker, setCloseDatePicker] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    const e: any = {
      target: {
        value: stateToValueConverter
          ? stateToValueConverter(reduxValue)
          : new Date(),
      },
    };
    onChange(e);
  }, [reduxValue]);
  const Today = new Date();

  const isValid = () =>
    visited && modified && hasLeft && validationMessage ? false : true;

  return (
    <div className="InputContent">
      <FloatingLabel
        label={label}
        editorId="datePicker"
        editorValue={value ? value.toString() : value}
      >
        <DatePicker
          id={"datePicker"}
          {...other}
          value={value}
          onChange={(e) => {
            onChange(e);
            dispatch(setPlanHasChanged(true));
          }}
          format={"yyyy-MM-dd"}
          onBlur={(e) => {
            if (validationMessage === "") {
              dispatchFunction(
                valueToStateConverter
                  ? valueToStateConverter(value)
                  : (value as any)
              );
            }
            setHasLeft(true);
            setCloseDatePicker(false);
          }}
          onFocus={() => {
            setCloseDatePicker(true);
          }}
          show={closeDatePicker}
        />
      </FloatingLabel>

      {!isValid() && <Error>{validationMessage}</Error>}

      {value &&
      value.getFullYear() * 365 +
        (value.getMonth() + 1) * 12 +
        value.getDate() <
        Today.getFullYear() * 365 +
          (Today.getMonth() + 1) * 12 +
          Today.getDate() ? (
        <div style={{ color: "orange", fontSize: "11px", height: "11px" }}>
          {t("planForm.formComponents.dateOlderThanToday")}
        </div>
      ) : (
        <div style={{ height: "11px" }} />
      )}
    </div>
  );
};

const CustomPlanMultiSelect = (
  fieldRenderProps: FieldRenderProps,
  property: keyof planProps,
  data: string[],
  stateToValueConverter?: (state: any) => string[],
  valueToStateConverter?: (value: string[]) => any
) => {
  const plan = useSelector((state: AppState) =>
    state.scheduler.plans.find(
      (plan) => plan.id === state.scheduler.selectedPlan
    )
  );

  const reduxValue = plan ? plan[property] : undefined;

  const dispatch = useAppDispatch();
  return CustomMultiSelect(
    fieldRenderProps,
    reduxValue,
    data,
    (value: any) =>
      dispatch(updatePlanProperty({ [property]: value as any } as any)),
    stateToValueConverter,
    valueToStateConverter
  );
};

const CustomMultiSelect = (
  fieldRenderProps: FieldRenderProps,
  reduxValue: any,
  data: string[],
  dispatchFunction: (value: string) => void,
  stateToValueConverter?: (state: any) => string[],
  valueToStateConverter?: (value: string[]) => any
) => {
  const {
    label,
    value,
    onChange,
    visited,
    validationMessage,
    modified,
    ...other
  } = fieldRenderProps;
  const [hasLeft, setHasLeft] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const e: any = {
      target: {
        value: stateToValueConverter
          ? stateToValueConverter(reduxValue)
          : reduxValue,
      },
    };
    onChange(e);
  }, [reduxValue]);

  const isValid = () =>
    visited && modified && hasLeft && validationMessage ? false : true;

  // Handle eventual deleted values
  let valueList;
  if (value) {
    valueList = value.filter((val: any) => val !== undefined);
  } else {
    valueList = [];
  }
  return (
    <div className="InputContent">
      <FloatingLabel
        label={label}
        editorId={label}
        editorValue={value ? value.toString() : value}
        id={label + "Label"}
        style={{ overflowWrap: "anywhere" }}
      >
        <div style={{ marginBottom: "10px" }} onChange={onChange}></div>
        <MultiSelect
          {...other}
          id={label}
          data={data ? data : []}
          value={valueList} // handling if undefined, will otherwise crash if machine is removed
          onChange={(e) => {
            onChange(e);
            if (validationMessage === undefined || validationMessage === "") {
              dispatchFunction(
                valueToStateConverter
                  ? valueToStateConverter(e.target.value)
                  : (e.target.value as any)
              );
            }
            setHasLeft(true);
            dispatch(setPlanHasChanged(true));
          }}
          valid={isValid()}
          popupSettings={{ width: "fit-content" }}
        />
      </FloatingLabel>
      {!isValid() && <Error>{validationMessage}</Error>}
    </div>
  );
};

const CustomMultiSelectCachedRecivers = (
  fieldRenderProps: FieldRenderProps,
  originalDataSet: string[],
  reduxValue: any,
  data: string[],
  dispatchFunction: (value: string) => void,
  setEmailState: React.Dispatch<React.SetStateAction<string[]>>,
  stateToValueConverter?: (state: any) => string[],
  valueToStateConverter?: (value: string[]) => any
) => {
  const {
    label,
    value,
    onChange,
    visited,
    validationMessage,
    modified,
    ...other
  } = fieldRenderProps;

  const [hasLeft, setHasLeft] = useState(false);
  const dispatch = useAppDispatch();
  const emailRegex = new RegExp(/\S+@\S+\.\S+/);
  useEffect(() => {
    const e: any = {
      target: {
        value: (stateToValueConverter
          ? stateToValueConverter(reduxValue)
          : reduxValue
        ).filter((val: string) => emailRegex.test(val)),
      },
    };
    onChange(e);
  }, [reduxValue]);

  const isValid = () =>
    visited && modified && hasLeft && validationMessage ? false : true;

  // Handle eventual deleted values
  let valueList;
  if (value) {
    valueList = value.filter((val: any) => val !== undefined);
  } else {
    valueList = [];
  }

  const itemRenderDropDown = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const savedRecieversEmails = localStorage.getItem("savedRecievers");
    const savedRecieversEmailsArr: string[] = JSON.parse(
      savedRecieversEmails != null ? savedRecieversEmails : "[]"
    );
    if (savedRecieversEmailsArr.includes(itemProps.dataItem)) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "center",
            paddingRight: "10px",
          }}
        >
          <div style={{ width: "90%" }}>{li}</div>
          <span
            className="k-icon k-font-icon k-i-close"
            style={{ fontSize: "25px", width: "10%" }}
            onClick={() => {
              const emailToDelete = savedRecieversEmailsArr.find(
                (email) => email === itemProps.dataItem
              );
              setEmailState((emails) =>
                emails.filter((email) => emailToDelete !== email)
              );
              localStorage.setItem(
                "savedRecievers",
                JSON.stringify(
                  savedRecieversEmailsArr.filter(
                    (email) => email !== itemProps.dataItem
                  )
                )
              );
            }}
          />
        </div>
      );
    } else {
      return li;
    }
  };

  const valueRenderDropDown = (
    element: React.ReactElement<HTMLSpanElement>,
    value: any
  ) => {
    return element;
  };

  const handleFilterChange = (
    event: MultiSelectFilterChangeEvent,
    originalDataSet: string[]
  ) => {
    setEmailState(filterBy(originalDataSet.slice(), event.filter));
  };
  return (
    <div className="InputContent">
      <FloatingLabel
        label={label}
        editorId={label}
        editorValue={value ? value.toString() : value}
        id={label + "Label"}
        style={{ overflowWrap: "anywhere" }}
      >
        <div style={{ marginBottom: "10px" }} onChange={onChange}></div>
        <MultiSelect
          {...other}
          id={label}
          onOpen={() => setEmailState(originalDataSet)}
          data={data ? data.filter((val: string) => emailRegex.test(val)) : []}
          itemRender={itemRenderDropDown}
          // valueRender={valueRenderDropDown}
          filterable={true}
          onFilterChange={(e) => handleFilterChange(e, originalDataSet)}
          value={valueList.filter((val: string) => emailRegex.test(val))} // handling if undefined, will otherwise crash if machine is removed
          onChange={(e) => {
            const emailsFiltered = e.target.value.filter((val) =>
              emailRegex.test(val)
            );

            onChange(e);
            if (validationMessage === undefined || validationMessage === "") {
              dispatchFunction(
                valueToStateConverter
                  ? valueToStateConverter(emailsFiltered)
                  : (emailsFiltered as any)
              );
            }
            setHasLeft(true);
            dispatch(setPlanHasChanged(true));
          }}
          valid={isValid()}
          popupSettings={{ width: "fit-content" }}
          onClose={(e) => {
            if (
              e.target.state.text &&
              !e.target.value.includes(e.target.state.text)
            ) {
              let values = e.target.value;
              values.length === 0
                ? (values = [e.target.state.text])
                : values.push(e.target.state.text);

              onChange(e);
              if (validationMessage === undefined || validationMessage === "") {
                dispatchFunction(
                  (valueToStateConverter
                    ? valueToStateConverter(values)
                    : (values as any)
                  ).filter((val: string) => emailRegex.test(val))
                );
              }
              setHasLeft(true);
              dispatch(setPlanHasChanged(true));
              e.target.state.text = "";
            }
          }}
        />
      </FloatingLabel>
      {!isValid() && <Error>{validationMessage}</Error>}
    </div>
  );
};

const CustomPlanTextArea = (
  fieldRenderProps: FieldRenderProps,
  property: keyof planProps,
  stateToValueConverter?: (state: any) => string,
  valueToStateConverter?: (value: string) => any
) => {
  const plan = useSelector((state: AppState) =>
    state.scheduler.plans.find(
      (plan) => plan.id === state.scheduler.selectedPlan
    )
  );

  const reduxValue = plan ? plan[property] : "";

  const dispatch = useAppDispatch();
  return CustomTextArea(
    fieldRenderProps,
    reduxValue,
    (value: any) =>
      dispatch(updatePlanProperty({ [property]: value as any } as any)),
    stateToValueConverter,
    valueToStateConverter
  );
};

const CustomTextArea = (
  fieldRenderProps: FieldRenderProps,
  reduxValue: any,
  dispatchFunction: (value: string) => void,
  stateToValueConverter?: (state: any) => string,
  valueToStateConverter?: (value: string) => any
) => {
  const {
    touched,
    label,
    value,
    onChange,
    visited,
    validationMessage,
    modified,
    valid,
    ...other
  } = fieldRenderProps;

  const [hasLeft, setHasLeft] = useState(false);
  const dispatch = useAppDispatch();
  useEffect(() => {
    const e: any = {
      target: {
        value: stateToValueConverter
          ? stateToValueConverter(reduxValue)
          : reduxValue,
      },
    };
    onChange(e);
  }, [reduxValue]);

  const isValid = () =>
    visited && modified && hasLeft && validationMessage ? false : true;
  return (
    <div
      className="InputContent"
      onBlur={(e) => {
        if (validationMessage === "") {
          dispatchFunction(
            valueToStateConverter
              ? valueToStateConverter(value)
              : (value as any)
          );
        }
      }}
    >
      <FloatingLabel
        label={label}
        editorId="textArea"
        editorValue={value}
        id="textAreaLabel"
      >
        <div style={{ marginBottom: "2px" }}></div>
        <textarea
          id={"textarea"}
          {...other}
          value={value}
          onChange={(e) => {
            onChange(e);
            dispatch(setPlanHasChanged(true));
          }}
          className="CustomTextArea body-1"
          maxLength={499}
        />
      </FloatingLabel>
      {!isValid() && <Error>{validationMessage}</Error>}
    </div>
  );
};

export const ContactSelection = (fieldRenderProps: FieldRenderProps) => {
  const reduxValue = useSelector(
    (state: AppState) =>
      state.scheduler.plans.find(
        (plan) => plan.id === state.scheduler.selectedPlan
      )?.receivers
  );

  const allRecieversEmails = useSelector(
    (state: AppState) => state.user.allContacts
  ).map((contact) => contact.email);

  const savedRecieversEmails = localStorage.getItem("savedRecievers");
  const savedRecieversEmailsArr: string[] = JSON.parse(
    savedRecieversEmails != null ? savedRecieversEmails : "[]"
  );

  const unionOfEmails = Array.from(
    new Set([...allRecieversEmails, ...savedRecieversEmailsArr])
  );

  const stateToValueConverter = (state: string[]) => {
    return state;
  };

  const valueToStateConverter = (value: string[]) => {
    return value;
  };

  const dispatch = useAppDispatch();

  const [emailState, setEmailState] = useState<string[]>(unionOfEmails);

  return CustomMultiSelectCachedRecivers(
    fieldRenderProps,
    unionOfEmails,
    reduxValue,
    emailState,
    (value: any) => {
      let emails: string[] = savedRecieversEmailsArr;
      for (const idx in value) {
        const email = value[idx];
        if (
          !allRecieversEmails.some((iterMail) => {
            return email == iterMail;
          }) &&
          !savedRecieversEmailsArr.some((iterMail) => {
            return email == iterMail;
          })
        ) {
          emails = emails.concat(email);
        }
      }

      if (emails.length > 0) {
        localStorage.setItem("savedRecievers", JSON.stringify(emails));
        setEmailState((state) => Array.from(new Set([...state, ...emails])));
      }

      dispatch(updatePlanProperty({ receivers: value }));
    },
    setEmailState,
    stateToValueConverter as any,
    valueToStateConverter
  );
};
