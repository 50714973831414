import React, { useState } from "react";
import { useSelector } from "react-redux";
import { cPhoneWidthLim } from "../../app";
import { AppState } from "../../store/store";
import { GroupChartContainerLongitudinalLevel } from "./groupChartContainerLongitudinalLevel";
import "./visualizationContainer.css";
interface VisualizationLongitudinalLevelProps {
  chartID: string;
  mainView: React.MutableRefObject<boolean>;
  finishedLoading: boolean;
  setFinishedLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const VisualizationLongitudinalLevel: React.FunctionComponent<
  VisualizationLongitudinalLevelProps
> = ({ chartID, mainView, finishedLoading, setFinishedLoading }) => {
  const screenWidth = useSelector(
    (state: AppState) => state.scheduler.screenWidth
  );
  return (
    <div
      className="VisualizationContainerRS"
    >
      {finishedLoading ? null : (
        <div
          style={{
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 10000,
            height: "100%",
            width: "100%",
            backgroundColor: "transparent",
          }}
        ></div>
      )}

      <GroupChartContainerLongitudinalLevel
        chartID={chartID}
        mainView={mainView}
        finishedLoading={finishedLoading}
        setFinishedLoading={setFinishedLoading}
      />
    </div>
  );
};
