import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import React, { useEffect, useState } from "react";
import { setNotification, setUserHasChanged } from "../../store/user/userSlice";
import "./style.scss";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/store";
import { AppState } from "../../store/store";
import { CustomInputUser } from "./updateUserForm";
import { useTranslation } from "react-i18next";
import { updatePassword } from "aws-amplify/auth";

interface UpdateUserFormProps {
  user: any;
  allowEdit: boolean;
  setAllowEdit: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UpdatePasswordForm: React.FunctionComponent<
  UpdateUserFormProps
> = ({ user, allowEdit, setAllowEdit }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const notEmptyValidator = (value?: string) =>
    value && value.length > 0 ? "" : t("common.cannotBeEmptyValidator");

  const passwordValidator = (value?: string) => {
    let error = "";
    if (value === undefined) return t("user.fillInPassword");
    const error0 = value.length < 8 ? t("user.atleast8Char") + "\n" : "";
    const error1 = !new RegExp("(?=.*[a-z])").test(value)
      ? t("user.lowerCase") + "\n"
      : "";
    const error2 = !new RegExp("(?=.*[A-Z])").test(value)
      ? t("user.upperCase") + "\n"
      : "";
    const error3 = !new RegExp("(?=.*[0-9].*)").test(value)
      ? t("user.number") + "\n"
      : "";
    const error4 = !new RegExp("(?=.*[-+_!@#$%^&*.,?])").test(value)
      ? t("user.specialCharacter") + "\n"
      : "";
    error = error0 + error1 + error2 + error3 + error4;
    return error;
  };

  useEffect(() => {
    dispatch(setUserHasChanged(false));
    return () => {
      dispatch(setUserHasChanged(false));
    };
  }, []);

  const [newPassword, setNewPassword] = React.useState<string>("");

  useEffect(() => {
    dispatch(setUserHasChanged(false));
    return () => {
      dispatch(setUserHasChanged(false));
    };
  }, []);

  const authenticationSuccess = useSelector(
    (state: AppState) => state.user.authenticationSuccess
  );

  const [viewCurrentPassword, setViewCurrentPassword] = useState(false);
  const [viewNewPassword, setViewNewPassword] = useState(false);
  const renderUpdatePasswordFormContent = (
    formRenderProps: FormRenderProps
  ) => {
    const { valid, valueGetter } = formRenderProps;

    const passwordMatch =
      valueGetter("newPassword") === valueGetter("confirmPassword");
    setNewPassword(valueGetter("newPassword"));
    return (
      <FormElement className="userCardUser" style={{ width: "100%" }}>
        <div className="userCardColumnL">
          <div>
            <div className="userCardRow">
              <legend
                className={"k-form-legend"}
                style={{
                  color: "var(--primary-color)",
                  marginTop: "30px",
                  marginBottom: "0px",
                }}
              >
                {t("user.authorizeChanges")}
              </legend>
              <span
                className="k-icon k-font-icon  k-font-icon k-i-change-manually editUser "
                style={{
                  fontSize: "24px",
                  marginRight: "auto",
                  marginTop: "auto",
                  borderRadius: "6px",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setAllowEdit(!allowEdit);
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                position: "relative",
              }}
            >
              <Field
                name={"currentPassword"}
                type={viewCurrentPassword ? "text" : "password"}
                component={CustomInputUser}
                label={t("user.currentPassword")}
                validator={notEmptyValidator}
              />
              <span
                className="k-icon k-font-icon  k-font-icon k-i-preview "
                style={
                  viewCurrentPassword
                    ? {
                        fontSize: "16px",
                        backgroundColor: "var(--primary-color)",
                        color: "white",
                        borderRadius: "6px",
                        right: "0",
                        position: "absolute",
                      }
                    : {
                        fontSize: "16px",
                        borderRadius: "6px",
                        right: "0",
                        position: "absolute",
                        color: "var(--primary-color)",
                      }
                }
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setViewCurrentPassword(!viewCurrentPassword);
                }}
                onMouseLeave={() => {
                  setViewCurrentPassword(false);
                }}
              />
            </div>
            <div style={{ color: "red" }}>
              {authenticationSuccess ? "" : t("manageUsers.wrongPassword")}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                position: "relative",
              }}
            >
              <Field
                name={"newPassword"}
                type={viewNewPassword ? "text" : "password"}
                component={CustomInputUser}
                label={t("user.newPassword")}
                validator={passwordValidator}
              />
              <span
                className="k-icon k-font-icon  k-font-icon k-i-preview"
                style={
                  viewNewPassword
                    ? {
                        fontSize: "16px",
                        backgroundColor: "var(--primary-color)",
                        color: "white",
                        borderRadius: "6px",
                        right: "0",
                        position: "absolute",
                        top: "26px",
                      }
                    : {
                        fontSize: "16px",
                        borderRadius: "6px",
                        right: "0",
                        position: "absolute",
                        color: "var(--primary-color)",
                        top: "26px",
                      }
                }
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setViewNewPassword(!viewNewPassword);
                }}
                onMouseLeave={() => {
                  setViewNewPassword(false);
                }}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Field
                name={"confirmPassword"}
                type="password"
                component={Input}
                label={t("user.repeatPassword")}
                valid={passwordMatch}
              />
              {!passwordMatch ? (
                <div
                  style={{
                    color: "red",
                    height: "11px",
                    fontSize: "10px",
                  }}
                >
                  {t("user.passwordDontMatch")}
                </div>
              ) : (
                <div style={{ height: "11px" }} />
              )}
            </div>
          </div>
        </div>
        <div className="userCardColumnForm">
          <button
            className="AddUserButtonForm BUTTON"
            type={"submit"}
            disabled={!formRenderProps.allowSubmit || !valid || !passwordMatch}
            style={
              !formRenderProps.allowSubmit || !valid || !passwordMatch
                ? {
                    opacity: 0.5,
                  }
                : {}
            }
          >
            {t("common.update")}
          </button>
        </div>
      </FormElement>
    );
  };

  const openedContent = () => {
    return (
      <Form
        onSubmit={(dataItem) => {
          try {
            updatePassword({
              oldPassword: dataItem.currentPassword,
              newPassword: newPassword,
            });
            dispatch(
              setNotification({
                style: "success",
                message: t("user.passwordUpdated"),
                open: true,
              })
            );
          } catch (error: any) {
            dispatch(
              setNotification({
                style: "error",
                message: t("user.passwordCouldntUpdate") + " " + error.message,
                open: true,
              })
            );
          }

          setAllowEdit(!allowEdit);
        }}
        render={(formRenderProps) =>
          renderUpdatePasswordFormContent(formRenderProps)
        }
      />
    );
  };

  return <div> {openedContent()} </div>;
};
