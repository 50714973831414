import { TFunction } from "i18next";
import { checkTrafikverketError } from "../../components/scheduler/plan/measurementList/statusProgress";
import {
  planStatus,
  measurementStatus,
  planProps,
  processingState,
  processingStatus,
  pipelineProps,
} from "./types";

export const translationToPlanStatus = (
  value: string,
  translator: TFunction<"translation", "translation">
) => {
  switch (value) {
    case translator("common.planStatus.awaiting"):
      return planStatus.awaiting;
    case translator("common.planStatus.canceled"):
      return planStatus.canceled;
    case translator("common.planStatus.done"):
      return planStatus.done;
    case translator("common.planStatus.inProgress"):
      return planStatus.inProgress;
    case translator("common.planStatus.archived"):
      return planStatus.archived;
    default:
      return undefined;
  }
};

export const planStatustoTranslation = (
  value: string,
  translator: TFunction<"translation", "translation">
) => {
  switch (value) {
    case planStatus.awaiting:
      return translator("common.planStatus.awaiting");
    case planStatus.canceled:
      return translator("common.planStatus.canceled");
    case planStatus.done:
      return translator("common.planStatus.done");
    case planStatus.inProgress:
      return translator("common.planStatus.inProgress");
    case planStatus.archived:
      return translator("common.planStatus.archived");
    default:
      return translator("common.planStatus.awaiting");
  }
};

export const getMeasurementStatus = (
  t: TFunction<"translation", "translation">,
  status: measurementStatus,
  errorMSG?: string | undefined
) => {
  switch (status) {
    case measurementStatus.Uploading:
    case measurementStatus.Initialized:
    case measurementStatus.UploadingDone:
    case measurementStatus.Processing:
    case measurementStatus.ProcessingDone:
    case measurementStatus.AdjustingDistance:
    case measurementStatus.AdjustingDistanceDone:
    case measurementStatus.WritingToDynamodb:
    case measurementStatus.WritingToDynamodbDone:
    case measurementStatus.Merging:
    case measurementStatus.MergingDone:
    case measurementStatus.CreatingExcel:
    case measurementStatus.CreatingExcelDone:
    case measurementStatus.CreatingCSV:
    case measurementStatus.CreatingCSVDone:
      if (errorMSG !== "") {
        if (checkTrafikverketError(errorMSG) || errorMSG == null) {
          return t("common.measurementStatus.inProgress");
        } else {
          return t("common.measurementStatus.faulty");
        }
      } else {
        return t("common.measurementStatus.inProgress");
      }

    case measurementStatus.SendingEmail:
    case measurementStatus.SendingEmailDone:
      if (errorMSG !== "") {
        if (checkTrafikverketError(errorMSG) || errorMSG == null) {
          return t("common.measurementStatus.done");
        } else {
          return t("common.measurementStatus.faulty");
        }
      } else {
        return t("common.measurementStatus.done");
      }
    default:
      return t("common.measurementStatus.faulty");
  }
};

export const getMeasurementStatusFromPipeline = (
  t: TFunction<"translation", "translation">,
  // measurementID: string
  pipelines?: pipelineProps[]
) => {
  const newestProcessNumber =
    pipelines && pipelines.length > 0
      ? pipelines?.reduce((prevPipe, currPipe) =>
          prevPipe.processNumber > currPipe.processNumber ? prevPipe : currPipe
        ).processNumber
      : 0;
  const currentPipelines = pipelines?.filter(
    (pipe) => pipe.processNumber === newestProcessNumber
  );

  if (
    // Check if any state has encountered an error
    currentPipelines?.some(
      (pipeline) =>
        pipeline.state !== processingState.integration &&
        pipeline.status === processingStatus.error
    )
  ) {
    return t("common.measurementStatus.faulty");
  } else if (
    // Check if we have reached state send email and "done"
    currentPipelines?.some(
      (pipeline) =>
        pipeline.state === processingState.email &&
        pipeline.status === processingStatus.done
    )
  ) {
    return t("common.measurementStatus.done");
  } else {
    // Check if we don't have any errors and we are not done, we are in progress
    return t("common.measurementStatus.inProgress");
  }
};

export const getNumberOfMeasurements = (
  plan: planProps,
  totalMeasurements: number
) => {
  // let numDoneMeasurements = 0;

  // var i = 0;
  // while (i < plan.measurements.length) {
  //   if (plan.measurements[i].status === measurementStatus.SendingEmailDone)
  //     numDoneMeasurements += 1;
  //   i++;
  // }

  return (
    "" +
    (plan.measurements.length ? plan.measurements.length : 0) +
    ("/" + totalMeasurements)
  );
};

export const convertToReadableDate = (isoDate?: string) => {
  if (isoDate) {
    const date = new Date(isoDate);
    const y = date.getFullYear();
    const m =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    const d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    return y + "-" + m + "-" + d;
  }
  return "-";
};

export const getLatestPipelineStep = (pipelines: pipelineProps[]) => {
  const latestObjects: Record<string, pipelineProps> = {};

  pipelines.forEach((pipeline: pipelineProps) => {
    if (
      !latestObjects[pipeline.state] ||
      (new Date(pipeline.createdAt) >
        new Date(latestObjects[pipeline.state].createdAt) &&
        pipeline.parentID === latestObjects[pipeline.state].parentID)
    ) {
      latestObjects[pipeline.state] = pipeline;
    }
  });

  const latestObjectsArray = Object.values(latestObjects);
  return latestObjectsArray;
};
