// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ObjectSelection {
  max-width: 1900px;
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-bottom: 5px;
  margin-bottom: 5px;
}

.ObjectSelectorButtonDisabled {
  width: fit-content;
  max-height: 40px;
  min-height: 25px;
  padding: 2px 5px;
  border: solid 2px var(--primary-color);
  border-radius: 10px;
  display: flex;
  align-items: center;
  pointer-events: none;
}

.ObjectSelectorLine {
  width: 20px;
  height: 5px;
  border-radius: 2px;
}

.ObjectButtonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-bottom: 5px;
}

.ObjectListItem {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  font-weight: bold;
  pointer-events: visible;
}

.ObjectSelectorButton {
  width: fit-content;
  max-height: 40px;
  min-height: 25px;
  background-color: white;
  padding: 5px 5px 5px 5px;
  margin: 2px 2px 2px 2px;
  border: solid 2px var(--primary-color);
  border-radius: 10px;
  display: flex;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/generic/toggleObjects/toggleObjects.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,oBAAoB;EACpB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,sCAAsC;EACtC,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,WAAW;EACX,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,iBAAiB;EACjB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB,uBAAuB;EACvB,wBAAwB;EACxB,uBAAuB;EACvB,sCAAsC;EACtC,mBAAmB;EACnB,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".ObjectSelection {\n  max-width: 1900px;\n  display: flex;\n  flex-direction: row;\n  justify-content: end;\n  margin-bottom: 5px;\n  margin-bottom: 5px;\n}\n\n.ObjectSelectorButtonDisabled {\n  width: fit-content;\n  max-height: 40px;\n  min-height: 25px;\n  padding: 2px 5px;\n  border: solid 2px var(--primary-color);\n  border-radius: 10px;\n  display: flex;\n  align-items: center;\n  pointer-events: none;\n}\n\n.ObjectSelectorLine {\n  width: 20px;\n  height: 5px;\n  border-radius: 2px;\n}\n\n.ObjectButtonsContainer {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  flex-wrap: wrap;\n  padding-bottom: 5px;\n}\n\n.ObjectListItem {\n  display: flex;\n  flex-direction: row;\n  font-size: 12px;\n  font-weight: bold;\n  pointer-events: visible;\n}\n\n.ObjectSelectorButton {\n  width: fit-content;\n  max-height: 40px;\n  min-height: 25px;\n  background-color: white;\n  padding: 5px 5px 5px 5px;\n  margin: 2px 2px 2px 2px;\n  border: solid 2px var(--primary-color);\n  border-radius: 10px;\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
