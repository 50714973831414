import React, { useEffect, useState } from 'react';

const styles: { [key: string]: React.CSSProperties } = {
  banner: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    backgroundColor: 'red',
    color: 'white',
    textAlign: 'center',
    padding: '10px',
    zIndex: 1000,
    fontFamily: 'Arial, sans-serif',
    fontSize: '16px',
  },
};

const FirefoxWarning: React.FC = () => {
  const [isFirefox, setIsFirefox] = useState<boolean>(false);

  useEffect(() => {
    if (navigator.userAgent.toLowerCase().includes('firefox')) {
      setIsFirefox(true);
    }
  }, []);

  if (!isFirefox) return null;

  return (
    <div style={styles.banner}>
      TMS is not supported on Firefox. Please use Chrome or Safari.
    </div>
  );
};

export default FirefoxWarning;