import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { resetPassword } from "aws-amplify/auth";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { SignInSteps, errorCodes } from "../../pages/login/page";
import { emailValidator } from "../../helpers/validators";

interface ForgotPasswordFormProps {
  handleError: (
    err: any,
    setAuthError: React.Dispatch<React.SetStateAction<string>>,
    code?: number
  ) => void;
  setNextStep: React.Dispatch<React.SetStateAction<SignInSteps | undefined>>;
  forgotPasswordEmail: React.MutableRefObject<string | undefined>;
}

interface ForgotPasswordFieldProps {
  email: string;
}

export const ForgotPasswordForm: React.FunctionComponent<
  ForgotPasswordFormProps
> = ({ handleError, setNextStep, forgotPasswordEmail }) => {
  const { t } = useTranslation();
  const [hasBeenPressed, setHasBeenPressed] = useState(false);
  const [authError, setAuthError] = useState("");
  const initialValue = undefined;

  const handleForgotPassword = useCallback(
    async (dataItem: ForgotPasswordFieldProps) => {
      try {
        const output = await resetPassword({ username: dataItem.email });
        setNextStep(output.nextStep.resetPasswordStep);
      } catch (err) {
        handleError(err, setAuthError, errorCodes.forgotPasswordError);
      }
    },
    []
  );

  const form = (formRenderProps: FormRenderProps) => {
    return (
      <FormElement className="forgotPwForm">
        <p style={{ marginTop: "5px" }}>
          {t("loginPage.authenticationCodeInfo")}
        </p>
        <Field
          name={"email"}
          type={"email"}
          component={Input}
          label={"email"}
          validator={(v) => emailValidator(t, v)}
        />
        <div style={{ color: "red" }}>
          {authError && authError.length > 0 ? authError : ""}{" "}
        </div>
        <div className="k-form-buttons">
          <button
            type={"submit"}
            className="LoginButton BUTTON"
            disabled={!formRenderProps.allowSubmit || hasBeenPressed}
            style={
              !formRenderProps.allowSubmit || hasBeenPressed
                ? { opacity: 0.5 }
                : {}
            }
          >
            {t("common.ok")}
          </button>
        </div>
      </FormElement>
    );
  };

  return (
    <Form
      initialValues={initialValue}
      key={JSON.stringify(initialValue)}
      onSubmit={(dataItem) => {
        const input = dataItem as ForgotPasswordFieldProps;

        forgotPasswordEmail.current = input.email;

        setHasBeenPressed(true);
        setTimeout(() => {
          setHasBeenPressed(false);
        }, 1000);

        setAuthError("");
        handleForgotPassword(input);
      }}
      render={form}
    />
  );
};
